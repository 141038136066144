import {Typography} from "@material-ui/core";
import axios from "axios";
import React from "react";
import Slider from "react-slick";
import {BASE_API_URL, BASE_URL} from "../../../Constants";
import LoadingFullWidth from "../../Loading/LoadingFullWidth";
import "./ClientSlider.css";

const ClientSlider = (props) => {
	const [index, setIndex] = React.useState(0);
	let [clients, setClients] = React.useState([]);

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	let settings = {
		dots: true,
		lazyLoad: true,
		infinite: false,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: true,
		swipeToSlide: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
	};

	React.useEffect(() => {
		axios.get(BASE_API_URL + "client", {}).then((res) => {
			//console.log("CLients", res);
			if (res.data.data != null) {
				setClients(
					res.data.data.sort((a, b) => a.displayOrder - b.displayOrder)
				);
			}
		});
	}, []);
	// //console.log(testimonial);

	return (
		<div id="company">
			{props.loading ? (
				<LoadingFullWidth height="350px" />
			) : (
				<div className="container mt-5 mb-5">
					{clients.lenght > 0 && (
						<Typography variant="h4" align="center" className="pb-3">
							Our Clients
						</Typography>
					)}

					<Slider {...settings}>
						{clients.map(
							(item, index) =>
								item.isActive && (
									<div className="pt-4 pb-4" key={index}>
										<p className="text-center">
											<img
												className="companyImage"
												src={BASE_URL + item.pictureUrl}
												alt={"image of " + item.name}
											/>
										</p>
										<p className="text-center mt-2">{item.name}</p>
									</div>
								)
						)}
					</Slider>
				</div>
			)}
		</div>
	);
};

export default ClientSlider;
