import React from "react";
import {Element} from "react-scroll";
import {BASE_URL} from "../../../Constants";
import LoadingFullWidth from "../../Loading/LoadingFullWidth";
import "./ModernTech.css";
// import "./ModernTechResponsive.css";

const ModernTech = (props) => {
	//console.log(window.location.pathname);

	let [pathName, setPathName] = React.useState();
	let [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		setPathName(window.location.pathname);
	}, [pathName]);

	const modernTechDetail = props.data;

	return (
		<section className="bp-homepage-modernTech-section">
			{props.loading ? (
				<LoadingFullWidth height="350px" />
			) : (
				<div className="modernTech">
					<div className="container">
						{/* <div className="row">
                          <div className="col-md-12">
                              <h3 className="text-center pt-4">{props.title}</h3>
                          </div>
                      </div> */}
						<Element name="mordern">
							<div className="row">
								{modernTechDetail &&
									modernTechDetail.map((modernTechDetailsItem, index) =>
										index % 2 === 0 ? (
											<>
												<div
													className="col-md-6 d-flex align-items-center"
													key={Math.random()}
												>
													<img
														style={{width: "100%", height: "100%"}}
														src={BASE_URL + modernTechDetailsItem.pictureUrl}
														alt=""
													/>
												</div>
												<div
													className="col-md-6 d-flex align-items-center"
													key={Math.random()}
												>
													<div className="content">
														<p className="section-heading-large">
															{modernTechDetailsItem.title}
														</p>
														<div
															className="list-describe"
															dangerouslySetInnerHTML={{
																__html: modernTechDetailsItem.description,
															}}
														/>
														{/* <div className="list-describe">
                                {modernTechDetailsItem.description.replace(
                                  /<\/?p[^>]*>/g,
                                  ""
                                )}
                              </div> */}
													</div>
												</div>
											</>
										) : (
											<>
												<div
													className="col-md-6 d-flex align-items-center"
													key={Math.random()}
												>
													<div className="content">
														<p className="section-heading-large">
															{modernTechDetailsItem.title}
														</p>
														<div
															className="list-describe"
															dangerouslySetInnerHTML={{
																__html: modernTechDetailsItem.description,
															}}
														/>
														{/* <div className="list-describe">
                                {modernTechDetailsItem.description.replace(
                                  /<\/?p[^>]*>/g,
                                  ""
                                )}
                              </div> */}
													</div>
												</div>
												<div
													className="col-md-6 d-flex align-items-center"
													key={Math.random()}
												>
													<img
														style={{width: "100%", height: "100%"}}
														src={BASE_URL + modernTechDetailsItem.pictureUrl}
														alt=""
													/>
												</div>
											</>
										)
									)}
							</div>
						</Element>
					</div>
				</div>
			)}
		</section>
	);
};

export default ModernTech;
