export function convertPriceToLocalString(rate) {
  return rate.toLocaleString(undefined, {
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}


export function isFromPaymentCheck() {
		const isPaymentCheck = localStorage.getItem('fromPayment');

    if (!isPaymentCheck) {
      localStorage.setItem('fromPayment',true);
    }
}
