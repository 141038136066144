import axios from "axios";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import { context } from "../../../../App";
import { BASE_API_URL } from "../../../../Constants";
import FileUploader from "./FileUploader/FileUploader";
import "./ScheduleForm.css";


const customStyles = {
	option: (baseStyles, state) => ({
		...baseStyles,
		color: state.isSelected ? "#ffffff" : "#3E3E3E",
	}),

	indicatorSeparator: (styles) => ({
		...styles,
	}),

	valueContainer: (styles) => ({
		...styles,
		padding: "2px 8px 2px 10px",
	}),

	control: (styles) => ({
		...styles,
		backgroundColor: "#f4f4f4",
		minHeight: "43px",
		border: "none",
		borderRadius: "none",
		borderBottom: "1px solid #9999",
	}),
};

const ScheduleForm = ({ menuId, subMenuId, selectService, selectPackage }) => {
	
	const [selectedOption, setSelectedOption] = useState(selectPackage);

	const handleChange4 = (event) => {
	  setSelectedOption(event.target.value);
	};

	const { ID, setid, getFormData, setFormData } = React.useContext(context);
	let [step, setStep] = React.useState(1);
	let [id, setId] = React.useState(1);
	let [progressStep, setProgressStep] = React.useState(0);
	let [getInputVal, setInputVal] = React.useState({
		firstName: "",
		firstNameError: false,
		lastName: "",
		lastNameError: false,
		companyName: "",
		companyNameError: false,
		email: "",
		emailError: false,
		phone: "",
		phoneError: false,
		BusinessIndustry: "",
		businessError: false,
		companyTypeId: "",
		companySizeId: "",
		companyTypesErr: false,
		companySizesErr: false,
		file: "",
		// solutionSpecificity: "",
		solutionSpecificityErr: false,
		goalsToAchieveService: "",
		// serviceSpecificity: "",
		serviceSpecificityErr: false,
		goalsToAchieveSolution: "",
		// technologyPreference: "",
		technologyPreferenceErr: false,
		goalsToAchieveTechnology: "",
		goalsToAchieveTechnologyErr: false,
	});
	let [reqErr, setReqErr] = React.useState(false);
	let [reqErrMessage, setReqErrMessage] = React.useState();
	let [invalidEmailError, setInvalidEmailErr] = React.useState(false);
	let [success, setSuccess] = React.useState(false);

	// const [businessStages, setBusinessStages] = React.useState([]);
	// const [companyTypes, setCompanyTypes] = useState([]);
	// const [companySizes, setCompanySizes] = useState([]);
	const [solutionSpecificities, setSolutionSpecificities] = React.useState([]);
	const [serviceSpecificities, setServiceSpecificities] = React.useState([]);
	const [technologyPreferences, setTechnologyPreferences] = React.useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [loading, setLoading] = React.useState(false);
	const [formDataLoading, setformDataLoading] = React.useState(true);
	const [isVerified, setIsVerified] = React.useState(false);

	const servicePackages = [
		{
			id: 1,
			label: "Foundational Finance",
			value: "Foundational Finance",
		},
		{
			id: 2,
			label: "Executive Edge",
			value: "Executive Edge",
		},
		{
			id: 3,
			label: "Premium Performance",
			value: "Premium Performance",
		},
	];

	const industryList=[
		{
			id: 1,
			label: "Cannabis",
			value: "Cannabis",
		},
		{
			id: 2,
			label: "Consumer Business",
			value: "Consumer Business",
		},
		{
			id: 3,
			label: "Distribution/Wholesale",
			value: "Distribution/Wholesale",
		},
		{
			id: 4,
			label: "Education",
			value: "Education",
		},
		{
			id: 5,
			label: "Education: Higher Education",
			value: "Education: Higher Education",
		},
		{
			id: 6,
			label: "Education: Private",
			value: "Education: Private",
		},{
			id: 7,
			label: "Education: Public",
			value: "Education: Public",
		},{
			id: 8,
			label: "Education: Support Services",
			value: "Education: Support Services",
		},{
			id: 9,
			label: "Energy",
			value: "Energy",
		},{
			id: 10,
			label: "Entertainment",
			value: "Entertainment",
		},{
			id: 11,
			label: "Entertainment: Individual",
			value: "Entertainment: Individual",
		},{
			id: 12,
			label: "Entertainment: Sports",
			value: "Entertainment: Sports",
		},{
			id: 13,
			label: "Financial Services",
			value: "Financial Services",
		},{
			id: 14,
			label: "Financial Services: Credit Unions",
			value: "Financial Services: Credit Unions",
		},{
			id: 15,
			label: "Financial Services: Banking",
			value: "Financial Services: Banking",
		},{
			id: 16,
			label: "Financial Services: Brokers",
			value: "Financial Services: Brokers",
		},{
			id: 17,
			label: "Financial Services: Credit & Lending",
			value: "Financial Services: Credit & Lending",
		},{
			id: 18,
			label: "Financial Services: Cryptocurrency",
			value: "Financial Services: Cryptocurrency",
		},{
			id: 19,
			label: "Financial Services: Funds",
			value: "Financial Services: Funds",
		},{
			id: 20,
			label: "Financial Services: Insurance",
			value: "Financial Services: Insurance",
		},{
			id: 21,
			label: "Financial Services: Investments",
			value: "Financial Services: Investments",
		},{
			id: 22,
			label: "Financial Services: Venture Capital/Private Equity",
			value: "Financial Services: Venture Capital/Private Equity",
		},{
			id: 23,
			label: "Food & Beverage: Agriculture",
			value: "Food & Beverage: Agriculture",
		},{
			id: 24,
			label: "Food & Beverage: Dining & Services",
			value: "Food & Beverage: Dining & Services",
		},{
			id: 25,
			label: "Food & Beverage: Manufacturing & Distribution",
			value: "Food & Beverage: Manufacturing & Distribution",
		},{
			id: 26,
			label: "Gaming",
			value: "Gaming",
		},{
			id: 27,
			label: "Gaming: Developers & Publishers",
			value: "Gaming: Developers & Publishers",
		},{
			id: 28,
			label: "Gaming: Platforms & Distribution",
			value: "Gaming: Platforms & Distribution",
		},{
			id: 29,
			label: "Government",
			value: "Government",
		},{
			id: 30,
			label: "Healthcare",
			value: "Healthcare",
		},{
			id: 31,
			label: "Healthcare: Assisted Living",
			value: "Healthcare: Assisted Living",
		},{
			id: 32,
			label: "Healthcare: Diagnostics & Labs",
			value: "Healthcare: Diagnostics & Labs",
		},{
			id: 33,
			label: "Healthcare: Hospitals & Health Centers",
			value: "Healthcare: Hospitals & Health Centers",
		},{
			id: 34,
			label: "Healthcare: Medical Groups",
			value: "Healthcare: Medical Groups",
		},{
			id: 35,
			label: "Individual",
			value: "Individual",
		},{
			id: 36,
			label: "Individual: Estates & Trusts",
			value: "Individual: Estates & Trusts",
		},{
			id: 37,
			label: "Life Sciences: Biotech",
			value: "Life Sciences: Biotech",
		},{
			id: 38,
			label: "Life Sciences: Medical Devices",
			value: "Life Sciences: Medical Devices",
		},{
			id: 39,
			label: "Life Sciences: Pharmaceutical",
			value: "Life Sciences: Pharmaceutical",
		},{
			id: 40,
			label: "Manufacturing",
			value: "Manufacturing",
		},{
			id: 41,
			label: "Manufacturing: Electrical",
			value: "Manufacturing: Electrical",
		},{
			id: 42,
			label: "Manufacturing: High Tech",
			value: "Manufacturing: High Tech",
		},{
			id: 43,
			label: "Manufacturing: Materials",
			value: "Manufacturing: Materials",
		},{
			id: 44,
			label: "Manufacturing: Transportation",
			value: "Manufacturing: Transportation",
		},{
			id: 45,
			label: "Nonprofit",
			value: "Nonprofit",
		},{
			id: 46,
			label: "Nonprofit: Animal & Environmental Welfare",
			value: "Nonprofit: Animal & Environmental Welfare",
		},{
			id: 47,
			label: "Nonprofit: Arts & Museums",
			value: "Nonprofit: Arts & Museums",
		},{
			id: 48,
			label: "Nonprofit: Associations",
			value: "Nonprofit: Associations",
		},{
			id: 49,
			label: "Nonprofit: Foundation & Grantmakers",
			value: "Nonprofit: Foundation & Grantmakers",
		},{
			id: 50,
			label: "Nonprofit: Religious",
			value: "Nonprofit: Religious",
		},{
			id: 51,
			label: "Nonprofit: Social/Human Services",
			value: "Nonprofit: Social/Human Services",
		},{
			id: 52,
			label: "Professional Services",
			value: "Professional Services",
		},{
			id: 53,
			label: "Professional Services: Accounting",
			value: "Professional Services: Accounting",
		},{
			id: 54,
			label: "Professional Services: Human Resources",
			value: "Professional Services: Human Resources",
		},{
			id: 55,
			label: "Professional Services: Legal",
			value: "Professional Services: Legal",
		},{
			id: 56,
			label: "Professional Services: Marketing/Media",
			value: "Professional Services: Marketing/Media",
		},{
			id: 57,
			label: "Real Estate",
			value: "Real Estate",
		},{
			id: 58,
			label: "Real Estate: Architects & Engineers",
			value: "Real Estate: Architects & Engineers",
		},{
			id: 59,
			label: "Real Estate: Commercial/Retail/Industrial",
			value: "Real Estate: Commercial/Retail/Industrial",
		},{
			id: 60,
			label: "Real Estate: Contractors",
			value: "Real Estate: Contractors",
		},{
			id: 61,
			label: "Real Estate: Hospitality",
			value: "Real Estate: Hospitality",
		},{
			id: 62,
			label: "Real Estate: REIT, Funds, and Investment Companies",
			value: "Real Estate: REIT, Funds, and Investment Companies",
		},{
			id: 63,
			label: "Real Estate: Residential & Multi Family",
			value: "Real Estate: Residential & Multi Family",
		},{
			id: 64,
			label: "Rental & Leasing",
			value: "Rental & Leasing",
		},{
			id: 65,
			label: "Solid Waste",
			value: "Solid Waste",
		},{
			id: 66,
			label: "Technology",
			value: "Technology",
		},{
			id: 67,
			label: "Technology: Digital Media/Ad Tech",
			value: "Technology: Digital Media/Ad Tech",
		},{
			id: 68,
			label: "Technology: Hardware",
			value: "Technology: Hardware",
		},{
			id: 69,
			label: "Technology: HealthTech",
			value: "Technology: HealthTech",
		},{
			id: 70,
			label: "Technology: Services",
			value: "Technology: Services",
		},{
			id: 71,
			label: "Technology: Software",
			value: "Technology: Software",
		},{
			id: 72,
			label: "Transportation",
			value: "Transportation",
		}
	];

	const addOns = [
		{
			id: 1,
			label: "Ecommerce Accounting",
			value: "Ecommerce Accounting ",
		},
		{
			id: 2,
			label: "Project Accounting",
			value: "Project Accounting",
		},
		{
			id: 3,
			label: "Cryptocurrency Accounting",
			value: "Cryptocurrency Accounting",
		},
		{
			id: 4,
			label: "Bill Payment ",
			value: "Bill Payment ",
		},
		{
			id: 5,
			label: "Expense Reporting ",
			value: "Expense Reporting ",
		},
		{
			id: 5,
			label: "Payroll",
			value: "Payroll",
		},
		{
			id: 6,
			label: "Sales Tax Services",
			value: "Sales Tax Services",
		},
	];

	var verifyCaptcha = (response) => {
		// response.preventDefault();
		//console.log(response);
		response && setIsVerified(true);
	};

	let showSolutions = false;

	if (menuId === 1) {
		showSolutions = true;
	}

	let showAccountingOptions = false;

	if (window.location.pathname.includes("operational-accounting-and-support")) {
		showAccountingOptions = true;
	}

	const handleChange = (input) => (e) => {
		if (e.target.id === "emailError") {
			setReqErr(false);
			setInvalidEmailErr(false);
			e.target.value !== "" && !/\S+@\S+\.\S+/.test(e.target.value)
				? setInvalidEmailErr(true)
				: setInvalidEmailErr(false);
		}

		setInputVal({ ...getInputVal, [input]: e.target.value });
	};

	const turnToFirstCard = () => {
		setInputVal({});
		setInputVal({
			firstName: "",
			firstNameError: false,
			lastName: "",
			lastNameError: false,
			companyName: "",
			companyNameError: false,
			email: "",
			emailError: false,
			phone: "",
			phoneError: false,
			BusinessIndustry: "",
			businessError: false,
			companyTypeId: "",
			companySizeId: "",
			companyTypesErr: false,
			companySizesErr: false,
		});
		setFormData({});
		setid(0);
		setStep(1);
		setProgressStep(0);
		localStorage.removeItem("cta_category_id");
	};

	React.useEffect(() => { }, [step]);
	React.useEffect(() => {
		setformDataLoading(true);
		axios.get(BASE_API_URL + `CtaFunction/CtaFunctionModel`, {}).then((res) => {
			// ////console.log(res);
			// console.log(res.data.solutionSpecificities);
			if (res.data.length !== 0) {
				// setFunctionModel(res.data);
				// setBusinessStages(res.data.businessStages);
				// setCompanyTypes(res.data.companyTypes);
				// setCompanySizes(res.data.companySizes);
				let service = [];

				res.data.serviceSpecificities.map((item) => {
					service = [
						...service,
						{ value: item.name, label: item.name, id: item.id },
					];
				});

				

				// setDefaultServiceSpecificities(service.find( item => item.value === selectService ))
				// console.log("Service: ", service);
				setServiceSpecificities(service);

				let solution = [];

				res.data.solutionSpecificities.map((item) => {
					solution = [
						...solution,
						{ value: item.name, label: item.name, id: item.id },
					];
				});

				setSolutionSpecificities(solution);

				let technology = [];
				// //console.log(res.data.techStacks);
				res.data.techStacks.map((item) => {
					if (technology.length) {
						let cache = technology.find((obj) => obj.label === item.category);
						let position = technology.findIndex(
							(obj) => obj.label === item.category
						);
						if (cache) {
							let new_option = [
								...cache.options,
								{ value: item.name, label: item.name, id: item.id },
							];
							technology[position].options = new_option;
						} else {
							technology = [
								...technology,
								{
									label: item.category,
									options: [{ value: item.name, label: item.name, id: item.id }],
								},
							];
						}
					} else {
						technology = [
							{
								label: item.category,
								options: [{ value: item.name, label: item.name, id: item.id }],
							},
						];
					}
				});

				setTechnologyPreferences(technology);
			}
			setformDataLoading(false);
		});
	}, [selectService, serviceSpecificities], [selectPackage, servicePackages]);

	// console.log('lulu',formDataLoading, serviceSpecificities);

	const onSubmit = (e) => {
		e.preventDefault();
		//console.log(getInputVal);
		const checkErr =
			getInputVal.firstName !== "" &&
			getInputVal.lastName !== "" &&
			getInputVal.email !== "" &&
			getInputVal.phone !== "" &&
			getInputVal.companyName !== "" &&
			getInputVal.BusinessIndustry !== "";
		console.log("checkErr: ", checkErr);

		if (getInputVal.firstName === "") {
			getInputVal.firstNameError = true;
			setInputVal({ ...getInputVal, firstNameError: true });
		}

		if (getInputVal.lastName === "") {
			getInputVal.lastNameError = true;
			setInputVal({ ...getInputVal, lastNameError: true });
		}
		if (getInputVal.email === "") {
			getInputVal.emailError = true;
			setInputVal({ ...getInputVal, emailError: true });
		}
		if (getInputVal.phone === "") {
			getInputVal.phoneError = true;
			setInputVal({ ...getInputVal, phoneError: true });
		}
		if (getInputVal.companyName === "") {
			getInputVal.companyNameError = true;
			setInputVal({ ...getInputVal, companyNameError: true });
		}

		if (getInputVal.BusinessIndustry === "") {
			getInputVal.businessError = true;
			setInputVal({ ...getInputVal, businessError: true });
		}

		// if (getInputVal.solutionSpecificity === "") {
		// 	getInputVal.solutionSpecificityErr = true;
		// 	setInputVal({...getInputVal, solutionSpecificityErr: true});
		// } else {
		// 	getInputVal.solutionSpecificityErr = false;
		// 	setInputVal({...getInputVal, solutionSpecificityErr: false});
		// }

		// if (getInputVal.goalsToAchieveSolution === "") {
		// 	getInputVal.goalsToAchieveSolutionErr = true;
		// 	setInputVal({...getInputVal, goalsToAchieveSolutionErr: true});
		// }
		// if (getInputVal.goalsToAchieveService === "") {
		// 	getInputVal.goalsToAchieveServiceErr = true;
		// 	setInputVal({...getInputVal, goalsToAchieveServiceErr: true});
		// }

		if (getInputVal.serviceSpecificity == "") {
			getInputVal.serviceSpecificitiesErr = true;
			setInputVal({ ...getInputVal, serviceSpecificitiesErr: true });
		} else {
			getInputVal.serviceSpecificitiesErr = false;
			setInputVal({ ...getInputVal, serviceSpecificitiesErr: false });
		}

		if (getInputVal.technologyPreference == "") {
			getInputVal.technologyPreferenceErr = true;
			setInputVal({ ...getInputVal, technologyPreferenceErr: true });
		} else {
			getInputVal.technologyPreferenceErr = false;
			setInputVal({ ...getInputVal, technologyPreferenceErr: false });
		}

		if (getInputVal.goalsToAchieveTechnology == "") {
			getInputVal.goalsToAchieveTechnologyErr = true;
			setInputVal({ ...getInputVal, goalsToAchieveTechnologyErr: true });
		}

		if (getInputVal.servicePackage == "") {
			getInputVal.servicePackageErr = true;
			setInputVal({ ...getInputVal, servicePackageErr: true });
		}

		if (getInputVal.servicePackageAddOns == "") {
			getInputVal.servicePackageAddOnsErr = true;
			setInputVal({ ...getInputVal, servicePackageAddOnsErr: true });
		}

		//console.log(checkErr);

		if (checkErr && !invalidEmailError && isVerified) {
			setLoading(true);
			//console.log(getInputVal);

			const serviceData = new FormData();
			serviceData.append("firstName", getInputVal.firstName);
			serviceData.append("lastName", getInputVal.lastName);
			serviceData.append("companyName", getInputVal.companyName);
			serviceData.append("businessIndustry", getInputVal.BusinessIndustry);
			serviceData.append("email", getInputVal.email);
			serviceData.append("phone", getInputVal.phone);
			serviceData.append("serviceSpecificity", getInputVal.serviceSpecificity);
			serviceData.append(
				"solutionSpecificity",
				getInputVal.solutionSpecificity
			);
			serviceData.append(
				"goalsToAchieveService",
				getInputVal.goalsToAchieveService
			);
			serviceData.append(
				"technologyPreference",
				getInputVal.technologyPreference
			);
			serviceData.append(
				"goalsToAchieveTechnology",
				getInputVal.goalsToAchieveTechnology
			);
			serviceData.append("tellUsMore", getInputVal.tellUsMore);
			serviceData.append("description", getInputVal.description);
			serviceData.append("servicePackage", getInputVal.servicePackage);
			serviceData.append(
				"servicePackageAddOns",
				getInputVal.servicePackageAddOns
			);
			serviceData.append("file", selectedFile);

			const submitData = {
				firstName: getInputVal.firstName,
				lastName: getInputVal.lastName,
				companyName: getInputVal.companyName,
				businessIndustry: getInputVal.businessIndustry,
				email: getInputVal.email,
				phone: getInputVal.phone,
				serviceSpecificity: getInputVal.serviceSpecificity,
				solutionSpecificity: getInputVal.solutionSpecificity,
				goalsToAchieveService: getInputVal.goalsToAchieveService,
				technologyPreference: getInputVal.technologyPreference,
				goalsToAchieveTechnology: getInputVal.goalsToAchieveTechnology,
				tellUsMore: getInputVal.tellUsMore,
				description: getInputVal.description,
			};

			setSuccess(true);

			axios
				.post(BASE_API_URL + `SubserviceRequest/Create`, serviceData)
				.then(
					(res) => {
						console.log("response: " + JSON.stringify(res, undefined, 2));
						setLoading(false);
						if (res.data.status) {
							setReqErr(false);
						} else {
							setReqErr(true);
							setReqErrMessage(res.data.message);
						}
						setInputVal({
							firstName: "",
							firstNameError: false,
							lastName: "",
							lastNameError: false,
							companyName: "",
							companyNameError: false,
							email: "",
							emailError: false,
							phone: "",
							phoneError: false,
							BusinessIndustry: "",
							businessError: false,
							companyTypeId: "",
							companySizeId: "",
							companyTypesErr: false,
							companySizesErr: false,
							file: "",
						});

						// setSuccess(false);
					},
					(error) => {
						//console.log(error);
						//console.log(error.response);
						//console.log(
						//"Error-response: " + JSON.stringify(error.response, undefined, 2)
						//);
						setReqErr(true);
						console.log(error);

						if (error.response.data.status?.toString().includes("4")) {
							setReqErrMessage("Client side error");
						} else if (error.response.data.status?.toString().includes("5")) {
							setReqErrMessage("Server side error");
						} else {
							setReqErrMessage("Error Happen");
						}
					}
				)
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const onSolutionSpecificitiesSelect = (selectedItem) => {
		// //console.log(selectedList[0].id, selectedItem);
		setInputVal({ ...getInputVal, solutionSpecificity: selectedItem.id });
		// setFormData({...getFormData, solutionSpecificity: selectedItem.id});

		console.log(selectedItem);
	};

	const onIndustrySelect = (selectedItem) => {
		// //console.log(selectedList[0].id, selectedItem);
		setInputVal({ ...getInputVal, businessIndustry: selectedItem.id });
		// setFormData({...getFormData, solutionSpecificity: selectedItem.id});

		console.log(selectedItem);
	};

	const onServiceSpecificitiesSelect = (selectedItem) => {
		// //console.log(selectedItem);
		setInputVal({ ...getInputVal, serviceSpecificity: selectedItem.id });
		// setFormData({...getFormData, serviceSpecificity: selectedItem.id});

		console.log(selectedItem);
	};

	const ontechnologyPreferenceSelect = (selectedItem) => {
		let value = selectedItem.map((item) => item.id);

		value = value.join();

		setInputVal({ ...getInputVal, technologyPreference: value });
		// setFormData({...getFormData, technologyPreference: value});
	};

	const onServicePackageSelect = (selectedItem) => {
		setInputVal({ ...getInputVal, servicePackage: selectedItem.id });
	};

	const onServicePackageAddOnsSelect = (selectedItem) => {
		let value = selectedItem.map((item) => item.id);

		value = value.join();

		setInputVal({ ...getInputVal, servicePackageAddOns: value });
	};
	//alvi approach 1
	const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);

	const handleSuccess = () => {

		setIsRequestSuccessful(true);
		alert("Thanks for filling out this form! We'll get back to you soon.");
		// 	  // Alert
		//   const alertDiv = document.createElement('div');
		//   alertDiv.className = 'alert';
		//   alertDiv.textContent = 'Your Request was successful.';
		//   document.body.appendChild(alertDiv);
		//   setTimeout(() => {
		//     alertDiv.remove();
		//   }, 3000); 
	};
	const handleSubmit = () => {
		if (success) {
			handleSuccess();
		} else {
			alert("Please fill in all required fields to submit your inquiry.");
		}
	};

	// alvi approach 2

	return (
		<div className="container w-100">
			<div className="row">
				<div className="card w-100">
					<div className="card-body p-5">
						{/* {success && (
							// alvi
				
							<h5 className="text-success mb-2">
								
								Your Request was successfull.
							</h5>
						)} */}
						<div>
							<form onSubmit={onSubmit}>
								<div className="box">
									<div className="row">
										<div className="col-md-6 pl-0">
											<input
												className="from-val"
												type="text"
												name="firstName"
												id="firstNameError"
												onChange={handleChange("firstName")}
												value={getInputVal.firstName}
											/>
											<label>First Name</label>
											{getInputVal.firstNameError && (
												<p className="form-error-message">
													First Name is Required
												</p>
											)}
										</div>

										<div className="col-md-6 pl-0">
											<input
												className="from-val"
												type="text"
												name="lastName"
												id="lastNameError"
												onChange={handleChange("lastName")}
												value={getInputVal.lastName}
											/>
											<label>Last Name </label>
											{getInputVal.lastNameError && (
												<p className="form-error-message">
													Last Name is Required
												</p>
											)}
										</div>
									</div>

									<div className="row">
										<div className="col-md-6 pl-0">
											<input
												className="from-val"
												type="text"
												name="phone"
												id="phoneError"
												onChange={handleChange("phone")}
												value={getInputVal.phone}
											/>
											<label>Phone</label>
											{getInputVal.phoneError && (
												<p className="form-error-message">
													Phone Number is Required
												</p>
											)}
										</div>

										<div className="col-md-6 pl-0">
											<input
												className="from-val"
												type="email"
												name="email"
												id="emailError"
												onChange={handleChange("email")}
												value={getInputVal.email}
											/>
											<label>Work Email</label>
											{getInputVal.emailError && (
												<p className="form-error-message">
													Email Address is Required
												</p>
											)}
										</div>
									</div>

									<div className="row">
										<div className="col-md-12 pl-0">
											<input
												className="from-val"
												type="text"
												name="companyName"
												id="companyNameError"
												onChange={handleChange("companyName")}
												value={getInputVal.companyName}
											/>
											<label>Company/Business Name</label>
											{getInputVal.companyNameError && (
												<p className="form-error-message">
													Company/Business Name is Required
												</p>
											)}
										</div>

										
									</div>
								</div>

								<div className="form-group">
													<label className="dropdown-levels text-left w-100">
													Business Industry
													</label>
													<div className=" mb-5">
														<Select
															options={industryList}
															className="basic-multi-select"
															placeholder="Select Business Industry"
															name="businessIndustry"
															id="businessError"
															styles={customStyles}
															
															onChange={(value) =>
																onIndustrySelect(value)
															}
															classNamePrefix="select"
														/>

														{getInputVal.businessError && (
															<p className="form-error-message-v2">
																Business Industry is Required
															</p>
														)}
													</div>
												</div>

								{!formDataLoading && (
									<>
										{showSolutions ? (
											<>
												<div className="form-group">
													<label className="dropdown-levels text-left w-100">
														Solution Specificity
													</label>
													<div className=" mb-5">
														<Select
															options={solutionSpecificities}
															className="basic-multi-select"
															placeholder="Select Solution Specificity"
															name="solutionSpecificity"
															id="solutionSpecificityErr"
															styles={customStyles}
															defaultValue={solutionSpecificities[1]}
															onChange={(value) =>
																onSolutionSpecificitiesSelect(value)
															}
															classNamePrefix="select"
														/>

														{getInputVal.solutionSpecificityErr && (
															<p className="form-error-message-v2">
																Solution Specificity is Required
															</p>
														)}
													</div>
												</div>

												<div className="form-group box">
													<textarea
														className="form-control"
														id="goalsToAchieveSolutionErr"
														rows="3"
														name="goalsToAchieveSolution"
														onChange={handleChange("goalsToAchieveSolution")}
														style={{ marginBottom: "19px" }}
														value={getInputVal.goalsToAchieveSolution}
													></textarea>
													<label
														className=""
														htmlFor="goalsToAchieveSolutionErr"
													>
														What goals do you want to achieve with this
														solution?
													</label>
													{getInputVal.goalsToAchieveSolutionErr && (
														<p className="form-error-message-v3">
															This feild is required
														</p>
													)}
												</div>
											</>
										) : (
											<>
												<div className="form-group">
													<label className="dropdown-levels text-left w-100">
														Service Specificity
													</label>
													<div className=" mb-5">
														<Select
															defaultValue={serviceSpecificities.find(
																(service) =>
																	service.value.trim() === selectService.trim()
															)}
															// defaultValue={serviceSpecificities[1]}
															options={serviceSpecificities}
															className="basic-multi-select"
															placeholder="Select Service Specificity"
															name="serviceSpecificity"
															id="serviceSpecificitiesErr"
															styles={customStyles}
															onChange={(value) =>
																onServiceSpecificitiesSelect(value)
															}
															value={getInputVal.solutionSpecificity}
														/>
														{getInputVal.serviceSpecificitiesErr && (
															<p className="form-error-message-v2">
																Service Specificity is Required
															</p>
														)}
													</div>
												</div>
												{!showAccountingOptions && (
													<div className="form-group box">
														<textarea
															className="form-control"
															id="goalsToAchieveServiceErr"
															rows="3"
															name="goalsToAchieveService"
															onChange={handleChange("goalsToAchieveService")}
															style={{ marginBottom: "19px" }}
															value={getInputVal.goalsToAchieveService}
														></textarea>
														<label
															className=""
															htmlFor="goalsToAchieveServiceErr"
														>
															What goals do you want to achieve with this
															service?
														</label>
														{getInputVal.goalsToAchieveServiceErr && (
															<p className="form-error-message-v3">
																This feild is required
															</p>
														)}
													</div>
												)}
											</>
										)}
									</>
								)}

								<div className="form-group">
									<label className="dropdown-levels text-left w-100">
										Technology Preference
									</label>
									<div className=" mb-5">
										<Select
											options={technologyPreferences}
											isMulti
											className="basic-multi-select"
											placeholder="Select Technology Preference"
											name="technologyPreference"
											id="technologyPreferenceErr"
											styles={customStyles}
											onChange={(value) => ontechnologyPreferenceSelect(value)}
											classNamePrefix="select"
										/>
										{getInputVal.technologyPreferenceErr && (
											<p className="form-error-message-v2">
												Technology Preference is Required
											</p>
										)}
									</div>
								</div>

								{!showAccountingOptions && (
									<div className="form-group box">
										<textarea
											className="form-control"
											id="goalsToAchieveTechnologyErr"
											rows="3"
											name="goalsToAchieveTechnology"
											onChange={handleChange("goalsToAchieveTechnology")}
											style={{ marginBottom: "19px" }}
											value={getInputVal.goalsToAchieveTechnology}
										></textarea>
										<label className="" htmlFor="goalsToAchieveTechnologyErr">
											What goals do you want to achieve with this technology?
										</label>
										{getInputVal.goalsToAchieveTechnologyErr && (
											<p className="form-error-message-v3">
												This feild is required
											</p>
										)}
										{reqErr && (
											<p style={{ color: "red", marginTop: "-23px" }}>
												{reqErrMessage}
											</p>
										)}
									</div>
								)}

								{showAccountingOptions && (
									<>
										<div className="form-group" style={{ marginTop: "-30px" }}>
											<label className="dropdown-levels text-left w-100">
												Service package
											</label>
											<div className="form-group third_page">

											{selectPackage !== null &&  selectPackage ? (
           <input	
		   readOnly					
		   className="form-control w-100"
		   placeholder="Select Service package"
		   name="servicePackage"
		   id="servicePackageErr"
		   value = {selectPackage}
	   />
      ) : (
		<Select	

		options={servicePackages}						
		className="basic-multi-select"
		placeholder="Select Service package"
		name="servicePackage"
		id="servicePackageErr"
		styles={customStyles}
		onChange={(value) => onServicePackageSelect({selectPackage})}
	/>
      )}
										
											

                                             

												{getInputVal.servicePackageErr && (
													<p className="form-error-message-v2">
														Service package is Required
													</p>
												)}
											</div>
										</div>

										<div className="form-group">
											<label className="dropdown-levels text-left w-100">
												Add-ons
											</label>
											<div className=" mb-5">
												<Select
													options={addOns}
													isMulti
													className="basic-multi-select"
													placeholder="Select Add-ons"
													name="servicePackageAddOns"
													id="servicePackageAddOnsErr"
													styles={customStyles}
													onChange={(value) =>
														onServicePackageAddOnsSelect(value)
													}
												// classNamePrefix="select"
												/>
											</div>
										</div>
									</>
								)}

								<div className="box">
									<div className="form-group third_page">
										<textarea
											className="form-control"
											type="text"
											id="descriptionErr"
											rows="3"
											name="description"
											onChange={handleChange("description")}
											value={getInputVal.description}
										></textarea>
										<label htmlFor="exampleFormControlTextarea2">
											Please describe what you'd like done in under 300 words
										</label>
										{getInputVal.descriptionErr && (
											<p className="form-error-message-v4">
												This feild is required
											</p>
										)}
									</div>
								</div>

								<div className="box">
									<div className="form-group">
										<FileUploader
											onFileSelectSuccess={(file) => setSelectedFile(file)}
											onFileSelectError={({ error }) => alert(error)}
										/>
									</div>
								</div>

								<div
									className="captcha-checkbox d-flex justify-content-center"
									id="fai"
								>
									<ReCAPTCHA
										sitekey="6LfRCdAdAAAAAFgEXu1mtuL05-sL5vY_fSW24A_y"
										onChange={verifyCaptcha}
									/>
								</div>

								{/* <button
									className="btn btn-block btn-primary cta-form-btn mt-3"
									type="submit"
								>
									Submit
								</button> */}

								{/* //alvi approach 1 */}

								<button
									onClick={handleSubmit}
									className="btn btn-block btn-primary cta-form-btn mt-3"
									type="submit"
								>

									Submit
								</button>
								{/* approach 2 */}
								{/* {isRequestSuccessful && (
        <div className="alert">
          Your Request was successful.
        </div>
      )} */}

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ScheduleForm;
