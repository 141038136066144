import axios from "axios";
import $ from "jquery";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Navbar from "react-bootstrap/Navbar";
import { FcNext } from "react-icons/fc";
import { MdArrowDropDown } from "react-icons/md";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { BASE_API_URL } from "../../Constants";
import { ReactComponent as Logo } from "../../Images/logo.svg";
import { genarateNavbarSubSlug } from "../../utils/genarateNavbarSubSlug";
import Form from "./ConsultingForm/UserForm";
import "./NavBootstrap.css";
import "./NavBootstrapResponsive.css";
// alvi
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Career from "../Career/Career";
const NavBootstrap = (props) => {
  const history = useHistory();

  let [menu, setMenu] = React.useState([]);
  const [subMenu, setSubMenu] = React.useState();
  const [bold, setBold] = useState(false);
  const [locKey, setLocKey] = useState();
  const [collapse, setCollapse] = useState(false);
  // const [location, setLocation] = useState();

  const location = useLocation();
  // //console.log(location)

  useEffect(() => {
    if (location.key !== locKey) {
      if ($("#basic-navbar-nav").hasClass("show")) {
        // $("#basic-navbar-nav").classList.remove('show')
        // $("#basic-navbar-nav").collapse("hide");
        // $('#basic-navbar-nav').addClass('collapse');
        // setCollapse(false)
        $("#testing-id").click();
        // document.getElementById("testing-id").className += " collapsed";
      }

      setLocKey(location.key);
    }
  }, [locKey, location]);

  function handleToggling(e) {
    var body = document.body;
    body.classList.toggle("primary-nav-expanded");
    setCollapse(e);
  }

  let navRef = useRef([]);

  const solution_section = [
    {
      name: "Data & Technology",
      subNav: [
        "Business Technology",
        "Data and Analytics",
        "Digital Transformation",
        "Information Technology",
        "Information Management",
        "Systems and Platform Integration",
      ],
    },

    {
      name: "Accounting & Finance",
      subNav: [
        "Accounting Technology",
        "AP Automation",
        "AR Automation",
        "Inventory Management",
        "Expense Management",
      ],
    },

    {
      name: "Ecommerce",
      subNav: [
        "Online Store Set up",
        "Point of Sale",
        "Payment Gateways",
        "Sales Channels",
        "Integrated Ecommerce",
      ],
    },

    {
      name: "Application Development",
      subNav: [
        "App development",
        "Web App Development",
        "Mobile App Development",
        "Ecommerce Development",
        "Intranet, Client Portal",
      ],
    },
    // 	{
    // 	name: "Career", // New section "Career"
    // 	subNav: [
    // 		"Job Search",
    // 		"Resume Writing",
    // 		"Interview Preparation",
    // 		"Career Development",
    // 		"Professional Networking",
    // 	],
    // },
  ];

  const service_section = [
    {
      name: "Data & Technology",
      subNav: [
        "Systems Assessment & Optimization",
        "Systems Selection",
        "System Implementation",
        "Analytics & Business Intelligence",
        "Data Management",
      ],
    },

    {
      name: "Accounting & Finance",
      subNav: [
        "Finance Transformation",
        "Financial Reporting",
        "Planning and Analysis",
        "Operational Accounting and Support",
        "Business Process Optimization",
      ],
    },

    {
      name: "Ecommerce",
      subNav: [
        "Marketplace Store Development",
        "Design and Customization",
        "Product Management",
        "Platform Integration and Data Migration",
        "Ecommerce Accounting",
      ],
    },

    {
      name: "Application Development",
      subNav: ["Strategy", "Design", "Development", "Metrics & Analytics"],
    },
    // 	{
    // 	name: "Career", // New section "Career"
    // 	subNav: [
    // 		"Job Search",
    // 		"Resume Writing",
    // 		"Interview Preparation",
    // 		"Career Development",
    // 		"Professional Networking",
    // 	],
    // },
  ];

  // const popular_section = [
  // 	{
  // 		id: 1,
  // 		items: [
  // 			"Data and Analytics",
  // 			"Information Technology",
  // 			"Accounting Technology",
  // 			"AP Automation",
  // 			"Online Store Set up",
  // 			"Mobile App Development",
  // 			"Ecommerce Development",
  // 		],
  // 	},
  // 	{
  // 		id: 2,
  // 		items: [
  // 			"System Implementation",
  // 			"Analytics & Business Intelligence",
  // 			"Finance Transformation",
  // 			"Operational Accounting and Support",
  // 			"Marketplace Store Development",
  // 			"Data Integration and Migration",
  // 		],
  // 	},
  // 	{
  // 		id: 3,
  // 		items: [
  // 			"Financial Systems",
  // 			"E-commerce",
  // 			"Cloud Technologies",
  // 			"Blockchain",
  // 		],
  // 	},
  // 	{id: 4, items: ["Hourly", "Solution Discovery", "Access Retainer"]},
  // 	{id: 5, items: ["SharePoint", "Sage Intacct", "QuickBooks Online"]},
  // ];

  const popular_section = [
    {
      id: 1,
      items: [
        "Data and Analytics",
        "Information Technology",
        "Accounting Technology",
        "AP Automation",
        "Online Store Set up",
        "Mobile App Development",
        "Ecommerce Development",
      ],
      values: [
        {
          name: "Data and Analytics",
          submenu: "data-technology",
          subslug: "data-and-analytics",
          submenuName: "Data & Analytics",
          subId: 55,
        },
        {
          name: "Information Technology",
          submenu: "data-technology",
          submenuName: "Data & Analytics",
          subId: 5,
        },
        {
          name: "Accounting Technology",
          submenu: "accounting-finance",
          submenuName: "Accounting & Finance",
          subId: 6,
        },
        {
          name: "AP Automation",
          submenu: "accounting-finance",
          submenuName: "Accounting & Finance",
          subId: 6,
        },
        {
          name: "Online Store Set up",
          submenu: "ecommerce",
          submenuName: "Ecommerce",
          subId: 8,
        },
        {
          name: "Mobile App Development",
          submenu: "application-development",
          submenuName: "Application Development",
          subId: 9,
        },
        {
          name: "Ecommerce Development",
          submenu: "application-development",
          submenuName: "Application Development",
          subId: 9,
        },
      ],
    },
    {
      id: 2,
      items: [
        "System Implementation",
        "Analytics & Business Intelligence",
        "Finance Transformation",
        "Operational Accounting and Support",
        "Marketplace Store Development",
        "Data Integration and Migration",
      ],
      values: [
        {
          name: "System Implementation",
          submenu: "data-technology",
          subslug: "system-implementation",
          subId: 56,
          submenuName: "Data & Analytics",
          
        },
        {
          name: "Analytics & Business Intelligence",
          submenu: "data-technology",
          subslug: "analytics-&-business-intelligence",
          subId: 56,
          submenuName: "Data & Analytics",
        },
        {
          name: "Finance Transformation",
          submenu: "accounting-finance",
          submenuName: "Accounting & Finance",
          subslug: "finance-transformation",
          subId: 56,
        },
        {
          name: "Operational Accounting and Support",
          submenu: "accounting-finance",
          submenuName: "Accounting & Finance",
          subslug: "operational-accounting-and-support",
          subId: 56,
        },
        {
          name: "Marketplace Store Development",
          submenu: "ecommerce",
          submenuName: "Ecommerce",
          subslug: "marketplace-store-development",
          subId: 56,
        },
        {
          name: "Platform Integration and Data Migration",
          submenu: "ecommerce",
          submenuName: "Ecommerce",
          subslug: "platform-integration-and-data-migration",
          subId: 56,
        },
      ],
    },
    {
      id: 3,
      items: [
        "Financial Systems",
        "E-commerce",
        "Cloud Technologies",
        "Blockchain",
      ],
      values: [],
    },
    {
      id: 4,
      items: ["Hourly", "Solution Discovery", "Concierge"],
      values: [],
    },
    {
      id: 5,
      items: ["SharePoint", "Sage Intacct", "QuickBooks Online"],
      values: [],
    },
  ];

  const type_section = [
    {
      id: 1,
      items: [
        "Microsoft  365",
        "AWS",
        "Sage Intacct",
        "Stripe",
        "Shopify",
        "Amazon FBA",
        "Azure",
        "Flutter",
      ],
    },
    {
      id: 2,
      items: [
        "Consultation",
        "Assessment",
        "Implementation",
        "Training",
        "Integration",
        "Automation",
      ],
    },
    {
      id: 3,
      items: [
        "Financial Systems",
        "E-commerce",
        "Business Intelligence",
        "Business Productivity",
        "Web Development",
        "Cloud Technologies",
        "Blockchain",
      ],
    },
    {
      id: 4,
      items: ["Entrepreneur", "Startup", "Business", "Enterprise"],
    },
    {
      id: 5,
      items: ["On-demand Courses", "Live Virtual Classes"],
    },
    // 	{
    // 	id: 6, // New type section "Career"
    // 	items: [
    // 		"Job Search",
    // 		"Resume Writing",
    // 		"Interview Preparation",
    // 		"Career Development",
    // 		"Professional Networking",
    // 	],
    // },
  ];

  const getFirstSubmenuTitle = (menuName) => {
    switch (menuName) {
      case "Solutions":
        return "By Function";
      case "Services":
        return "By Function";
      case "Technology":
        return "By Function";
      case "Consulting":
        return "By Function";
      case "Training":
        return "By Function";
      // case "Career": // New submenu title for "Career"
      // return "By Function";
      default:
        return "By Function";
    }
  };

  const getSecondSubmenuTitle = (menuName) => {
    switch (menuName) {
      case "Solutions":
        return "By Platform";
      case "Services":
        return "By Service Package";
      case "Technology":
        return "By Technology ";
      case "Consulting":
        return "By Company Type";
      case "Training":
        return "By Course";
      // case "Career": // New submenu title for "Career"
      // return "By Category";
      default:
        return "By App";
    }
  };

  const getThirdSubmenuTitle = (menuName) => {
    switch (menuName) {
      case "Solutions":
        return "Popular";
      case "Services":
        return "Popular";
      case "Technology":
        return "Popular";
      case "Consulting":
        return "Popular";
      case "Training":
        return "Popular";
      // case "Career": // New submenu title for "Career"
      // return "Popular";
      default:
        return "Popular";
    }
  };

  ////console.log(window.location.pathname.split('/')[1].toLowerCase().includes('details'));
  //console.log(window.location.pathname);

  React.useEffect(() => {
    axios
      .get(BASE_API_URL + "MenuSubMenuMap/MenuSubmenus", { isAscending: true })
      .then(
        (response) => {
          if (response.status) {
            console.log("MenuSubmenus: ", response.data);
            setMenu(response.data.data);
            console.log("menu", response.data);
          }
        },
        (error) => {
          //console.log(error);
        }
      );
  }, []);

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const mql = window.matchMedia("(max-width: 991px)");

    if (mql.matches) {
      setMobileView(mql.matches);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    mql.addEventListener("change", (e) => {
      //console.log(e);
      setMobileView(e.matches);
    });
  }, []);

  const clickHandle = (e, menuId) => {
    history.push(`/InnovativeSolution/${menuId}`);
  };

  const handleSubmenu = (id, name, subId, slug, e) => {
    if (e) {
      e.preventDefault();
    }

    //console.log(`Id: ${id}, Name: ${name}, SubId: ${subId}, Slug: ${slug}`);

    if (id === 1) {
      let menu = solution_section.find((obj) => obj.name === name);
      //console.log(menu);
      let data = {
        menu,
        id,
        name,
        subId,
        slug,
      };
      //console.log(JSON.stringify(data));
      setSubMenu(data);
    } else if (id === 2) {
      let menu = service_section.find((obj) => obj.name === name);
      let data = {
        menu,
        id,
        name,
        subId,
        slug,
      };
      //console.log(JSON.stringify(data));
      setSubMenu(data);
      // setSubMenu(data);
    } else {
      setSubMenu("");
    }

    //console.log(name);
  };

  function discardSubmenu(e) {
    // $('.dropdown-menu').css('width', '40%')
    setSubMenu("");
  }

  const settingNav = useCallback(() => {
    // let path = window.location.pathname.split("/");
    if (location.state !== undefined) {
      if (navRef.current[location.state.id]) {
        navRef.current[location.state.id].className += " actives";

        for (let i = 1; i < navRef.current.length; i++) {
          if (i !== location.state.id) {
            if (navRef.current[i].classList.contains("actives")) {
              navRef.current[i].classList.remove("actives");
            }
          }
        }
      }
    } else {
      if (navRef.current.length) {
        for (let i = 1; i < navRef.current.length; i++) {
          if (
            navRef.current[i] &&
            navRef.current[i].classList.contains("actives")
          ) {
            navRef.current[i].classList.remove("actives");
          }
        }
      }
    }
  }, [location.state]);

  const handleRef = (el, name) => {
    //console.log(name, el);
    navRef.current[name] = createRef();
    navRef.current[name] = el;
    if (name === 5) {
      //console.log(location);

      let path = settingNav();
      console.log("path: ", path);
    }
  };

  if (subMenu) {
    //console.log(subMenu);
  }

  const [subContent, setSubcontent] = useState(false);
  const [checkId, setCheckId] = useState();
  function handleMobileMenu(e, id) {
    e.preventDefault();
    setSubcontent(!subContent);
    setCheckId(id);
  }

  const headerRef = useRef(null);

  //alvi
  const [isActive, setIsActive] = useState(false);

  const handleLinkClick = () => {
    setIsActive(!isActive);
  };

  const handleLinkBlur = () => {
    setIsActive(false);
  };
  return (
    <div>
      <div className="nav-section fixed-top main-navbar">
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          className={mobileView ? "mv" : "desktop"}
          onToggle={(e) => handleToggling(e)}
        >
          <div
            className={
              mobileView
                ? "d-flex justify-content-between responsive w-100 nav-head"
                : "d-flex justify-content-between responsive"
            }
          >
            <Link className="navbar-brand" to="/">
              <Logo />
            </Link>

            {/* <button
								className="navbar-toggler "
								type="button"
								data-toggle="collapse"
								data-target="#main_nav"
								aria-controls="main_nav"
								aria-expanded="false"
								aria-label="Toggle navigation"
								>
								<span className="navbar-toggler-icon"></span>
								</button> */}

            <Navbar.Toggle aria-controls="basic-navbar-nav" id="testing-id">
              {collapse ? (
                <span style={{ fontSize: "2em" }}>&times;</span>
              ) : (
                <span className="navbar-toggler-icon"></span>
              )}
            </Navbar.Toggle>
          </div>

          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav mr-auto" ref={headerRef}>
              {menu &&
                menu
                  .sort((a, b) => (a.menuId > b.menuId ? 1 : -1))
                  .map((menuItem, index) => {
                    return (
                      <li
                        className="nav-item dropdown position-static"
                        key={index}
                      >
                        {mobileView ? (
                          <div>
                            {subContent ? (
                              checkId === menuItem.menuId && (
                                <div
                                  className="drop-mobile"
                                  id="drop-menu"
                                  // onMouseLeave={(e) => discardSubmenu(e)}
                                >
                                  <div className="d-flex justify-content-end">
                                    <span
                                      style={{ fontSize: "2em" }}
                                      onClick={() => {
                                        setSubcontent(!subContent);
                                        setCheckId();
                                      }}
                                    >
                                      &times;
                                    </span>
                                  </div>
                                  <div className="row d-flex">
                                    <div className="pr-1 pb-3 flex-fill w-100">
                                      <h3 className="title">
                                        {(() => {
                                          switch (menuItem.menuName) {
                                            case "Solutions":
                                              return "By Function";
                                            case "Services":
                                              return "By Function";
                                            case "Technology":
                                              return "By Function";
                                            case "Consulting":
                                              return "By Function";
                                            case "Training":
                                              return "By Function";
                                            // 																		case "Career": // Handle "Career" section
                                            // return "By Category";
                                            default:
                                              return "By Function";
                                          }
                                        })()}
                                      </h3>
                                      <ul className="list-unstyled">
                                        {menuItem.subMenuViewModels
                                          .sort((a, b) =>
                                            a.id > b.id ? 1 : -1
                                          )
                                          .map(
                                            (subMenuItem, i) =>
                                              menuItem.menuId ===
                                                subMenuItem.menuId && (
                                                // <li key={i}><Link to={{pathname: `/DataTechnology/${subMenuItem.menuId}/${subMenuItem.id}`, state: `${subMenuItem.name}`}}>{subMenuItem.name}</Link></li>

                                                <li key={i}>
                                                  <Link
                                                    className="megamenu-link d-flex justify-content-between"
                                                    to={{
                                                      // pathname: `/${subMenuItem.menuId}/${subMenuItem.id}`,
                                                      // state: `${subMenuItem.name}`,
                                                      pathname: `/${menuItem.slug}/${subMenuItem.slug}`,
                                                      state: {
                                                        name: subMenuItem.name,
                                                        id: menuItem.menuId,
                                                        sub_id: subMenuItem.id,
                                                      },
                                                    }}
                                                  >
                                                    {subMenuItem.name}
                                                    <FcNext
                                                      onClick={(e) =>
                                                        handleSubmenu(
                                                          subMenuItem.menuId,
                                                          subMenuItem.name,
                                                          subMenuItem.id,
                                                          subMenuItem.slug,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  </Link>
                                                </li>
                                              )
                                          )}
                                      </ul>
                                    </div>
                                    {subMenu ? (
                                      <div className="pr-1 pb-3 flex-fill w-100">
                                        <h4
                                          className="title pr-1"
                                          style={{ color: "red" }}
                                        >
                                          {subMenu.menu.name}
                                        </h4>
                                        <ul className="list-unstyled">
                                          {subMenu.menu.subNav.map((data) => (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/${menuItem.slug}/${subMenu.slug}`,
                                                  state: {
                                                    name: subMenu.name,
                                                    id: menuItem.menuId,
                                                    sub_id: subMenu.subId,
                                                  },
                                                }}
                                                className="megamenu-link"
                                                style={{ color: "black" }}
                                              >
                                                {data}
                                              </Link>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="pr-1 pb-3 flex-fill w-100 ">
                                      <h3 className="title">
                                        {(() => {
                                          switch (menuItem.menuName) {
                                            case "Solutions":
                                              return "By Platform";
                                            case "Services":
                                              return "By Service Package";
                                            case "Technology":
                                              return "By Technology ";
                                            case "Consulting":
                                              return "By Company Type";
                                            case "Training":
                                              return "By Course";
                                            // 																		case "Career": // Handle "Career" section
                                            // return "By Category";
                                            default:
                                              return "By App";
                                          }
                                        })()}
                                      </h3>
                                      <ul className="list-unstyled">
                                        {type_section.map(
                                          (typeItems, index) =>
                                            menuItem.menuId === typeItems.id &&
                                            typeItems.items.map((items) => {
                                              if (typeItems.id === 1) {
                                                return (
                                                  <li>
                                                    <Link
                                                      to={{
                                                        pathname: `/solution-platform`,
                                                        state: {
                                                          name: "Solution",
                                                          id: 2,
                                                          service:
                                                            items.toLowerCase(),
                                                        },
                                                      }}
                                                      className="megamenu-link"
                                                    >
                                                      {items}
                                                    </Link>
                                                  </li>
                                                );
                                              } else if (typeItems.id === 2) {
                                                return (
                                                  <li>
                                                    <Link
                                                      to={{
                                                        pathname: `/services`,
                                                        state: {
                                                          name: "Services",
                                                          id: 2,
                                                          service:
                                                            items.toLowerCase(),
                                                        },
                                                      }}
                                                      className="megamenu-link"
                                                    >
                                                      {items}
                                                    </Link>
                                                  </li>
                                                );
                                              } else if (typeItems.id === 3) {
                                                return (
                                                  <li>
                                                    <Link
                                                      to={{
                                                        pathname: `/technology`,
                                                        state: {
                                                          name: "Technology",
                                                          id: 3,
                                                          byTech: items,
                                                        },
                                                      }}
                                                      className="megamenu-link"
                                                    >
                                                      {items}
                                                    </Link>
                                                  </li>
                                                );
                                              } else if (typeItems.id === 4) {
                                                return (
                                                  <li>
                                                    <Link
                                                      to={{
                                                        pathname: `/consulting`,
                                                        state: {
                                                          name: "Consulting",
                                                          id: 4,
                                                          byConsulting: items,
                                                        },
                                                      }}
                                                      className="megamenu-link"
                                                    >
                                                      {items}
                                                    </Link>
                                                  </li>
                                                );
                                              } else if (typeItems.id === 5) {
                                                return (
                                                  <li>
                                                    <Link
                                                      to={{
                                                        pathname: `/training`,
                                                        state: {
                                                          name: "Training",
                                                          id: 5,
                                                          byTraining: items,
                                                        },
                                                      }}
                                                      className="megamenu-link"
                                                    >
                                                      {items}
                                                    </Link>
                                                  </li>
                                                );
                                              }
                                            })
                                        )}
                                      </ul>
                                    </div>

                                    <div className="pb-3 flex-fill w-100">
                                      <h3 className="title">
                                        {(() => {
                                          switch (menuItem.menuName) {
                                            case "Solutions":
                                              return "Popular";
                                            case "Services":
                                              return "Popular";
                                            case "Technology":
                                              return "Popular";
                                            case "Consulting":
                                              return "Popular";
                                            case "Training":
                                              return "Popular";
                                            default:
                                              return "Popular";
                                          }
                                        })()}
                                      </h3>
                                      <ul className="list-unstyled">
                                        {popular_section.map(
                                          (popularItems, index) =>
                                            menuItem.menuId ===
                                              popularItems.id &&
                                            popularItems.items.map((items) => {
                                              if (popularItems.id === 3) {
                                                return (
                                                  <li>
                                                    <Link
                                                      to={{
                                                        pathname: `/technology`,
                                                        state: {
                                                          name: "Technology",
                                                          id: 3,
                                                          byTech: items,
                                                        },
                                                      }}
                                                      className="megamenu-link"
                                                    >
                                                      {items}
                                                    </Link>
                                                  </li>
                                                );
                                              } else if (
                                                popularItems.id === 4
                                              ) {
                                                return (
                                                  <li>
                                                    <Link
                                                      to={{
                                                        pathname: `/consulting`,
                                                        state: {
                                                          name: "Consulting",
                                                          id: 4,
                                                          subName:
                                                            "consulting-subSection",
                                                          byConsultingType:
                                                            items,
                                                        },
                                                      }}
                                                      className="megamenu-link"
                                                    >
                                                      {items}
                                                    </Link>
                                                  </li>
                                                );
                                              } else {
                                                return "";
                                              }
                                            })
                                        )}

                                        {popular_section.map(
                                          (popularSectionItem, index) =>
                                            menuItem.menuId ===
                                              popularSectionItem.id &&
                                            popularSectionItem.values.map(
                                              (value) => {
                                                if (
                                                  popularSectionItem.id === 1 ||
                                                  popularSectionItem.id === 2
                                                ) {
                                                  return (
                                                    <li>
                                                      <Link
                                                        to={{
                                                          pathname: `/${menuItem.slug}/${value.submenu}`,
            
                                                          state: {
                                                            name: value.submenuName,
                                                            id: menuItem.menuId,
                                                            sub_id: value.subId,
                                                          },
                                                        }}
                                                        className="megamenu-link"
                                                      >
                                                        {value.name}
                                                      </Link>
                                                      {/* {value.name} */}
                                                    </li>
                                                  );
                                                } else {
                                                }
                                              }
                                            )
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )
                            ) : (
                              <div className="d-flex justify-content-between align-items-stretch primary-nav-item">
                                <Link
                                  className="nav-link flex-grow-1"
                                  ref={(el) => {
                                    handleRef(el, menuItem.menuId);
                                  }}
                                  to={{
                                    pathname: `/${menuItem.slug}`,
                                    state: {
                                      name: menuItem.menuName,
                                      id: menuItem.menuId,
                                    },
                                  }}
                                  style={{ width: "100%" }}
                                >
                                  <label>{menuItem.menuName}</label>
                                </Link>
                                <button
                                  classname="btn primary-nav-item-btn align-self-stretch"
                                  style={{
                                    border: "none",
                                    background: "transparent",
                                    paddingLeft: "50px",
                                  }}
                                  onClick={(e) =>
                                    handleMobileMenu(e, menuItem.menuId)
                                  }
                                >
                                  <FcNext className="subNav-icon" />
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            className="nav-link"
                            style={{ position: "relative" }}
                            ref={(el) => {
                              handleRef(el, menuItem.menuId);
                            }}
                            to={{
                              pathname: `/${menuItem.slug}`,
                              state: {
                                name: menuItem.menuName,
                                id: menuItem.menuId,
                              },
                            }}
                          >
                            <label style={{ cursor: "pointer" }}>
                              {menuItem.menuName} <MdArrowDropDown />
                            </label>
                            <span></span>
                          </Link>
                        )}

                        {mobileView ? (
                          ""
                        ) : (
                          <div
                            className="dropdown-menu-two"
                            id="drop-menu"
                            onMouseLeave={(e) => discardSubmenu(e)}
                          >
                            <div className="row d-flex">
                              <div className="dropdown-title">
                                <h3>{menuItem.menuName}</h3>
                              </div>
                              <div className="p-2 flex-fill">
                                <h3 className="title">
                                  {getFirstSubmenuTitle(menuItem.menuName)}
                                </h3>
                                <ul className="list-unstyled">
                                  {menuItem.subMenuViewModels
                                    .sort((a, b) => (a.id > b.id ? 1 : -1))
                                    .map(
                                      (subMenuItem, i) =>
                                        menuItem.menuId ===
                                          subMenuItem.menuId && (
                                          <li key={i}>
                                            <Link
                                              className="megamenu-link submenu"
                                              to={{
                                                pathname: `/${menuItem.slug}/${subMenuItem.slug}`,
                                                state: {
                                                  name: subMenuItem.name,
                                                  id: menuItem.menuId,
                                                  sub_id: subMenuItem.id,
                                                },
                                              }}
                                              onMouseEnter={(e) =>
                                                handleSubmenu(
                                                  subMenuItem.menuId,
                                                  subMenuItem.name,
                                                  subMenuItem.id,
                                                  subMenuItem.slug,
                                                  e
                                                )
                                              }
                                            >
                                              {subMenuItem.name}
                                            </Link>
                                          </li>
                                        )
                                    )}
                                </ul>
                              </div>

                              {/* // sub menu */}

                              {subMenu ? (
                                <div className="p-2 flex-fill">
                                  <h4
                                    className="title pr-1"
                                    style={{ color: "red" }}
                                  >
                                    {subMenu.menu && subMenu.menu.name}&nbsp;
                                    {menuItem.slug}
                                  </h4>
                                  <ul className="list-unstyled">
                                    {subMenu.menu.subNav.map((data) => (
                                      <li>
                                        <Link
                                          to={{
                                            pathname:
                                              menuItem.slug === "solutions" &&
                                              subMenu.slug === "data-technology"
                                                ? `/${menuItem.slug}/${subMenu.slug}`
                                                : `/${menuItem.slug}/${
                                                    subMenu.slug
                                                  }/${genarateNavbarSubSlug(
                                                    data
                                                  )}`,
                                            // hash: `${subMenu.name}`,
                                            state: {
                                              name: subMenu.name,
                                              id: menuItem.menuId,
                                              sub_id: subMenu.subId,
                                            },
                                          }}
                                          className="megamenu-link"
                                          style={{ color: "black" }}
                                        >
                                          {data}
                                          {/* Here is the problem ashy  */}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}

       

                              <div className="p-2 flex-fill">
                                <h3 className="title">
                                  {getSecondSubmenuTitle(menuItem.menuName)}
                                </h3>
                                <ul className="list-unstyled">
                                  {type_section.map(
                                    (typeItems, index) =>
                                      menuItem.menuId === typeItems.id &&
                                      typeItems.items.map((items) => {
                                        if (typeItems.id === 1) {
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/solution-platform`,
                                                  state: {
                                                    name: "Solution",
                                                    id: 2,
                                                    service:
                                                      items.toLowerCase(),
                                                  },
                                                }}
                                                className="megamenu-link"
                                              >
                                                {items}
                                              </Link>
                                            </li>
                                          );
                                        } else if (typeItems.id === 2) {
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/services`,
                                                  state: {
                                                    name: "Services",
                                                    id: 2,
                                                    service:
                                                      items.toLowerCase(),
                                                  },
                                                }}
                                                className="megamenu-link"
                                              >
                                                {items}
                                              </Link>
                                            </li>
                                          );
                                        } else if (typeItems.id === 3) {
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/technology`,
                                                  state: {
                                                    name: "Technology",
                                                    id: 3,
                                                    byTech: items,
                                                  },
                                                }}
                                                className="megamenu-link"
                                              >
                                                {items}
                                              </Link>
                                            </li>
                                          );
                                        } else if (typeItems.id === 4) {
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/consulting`,
                                                  state: {
                                                    name: "Consulting",
                                                    id: 4,
                                                    byConsulting: items,
                                                  },
                                                }}
                                                className="megamenu-link"
                                              >
                                                {items}
                                              </Link>
                                            </li>
                                          );
                                        } else if (typeItems.id === 5) {
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/training`,
                                                  state: {
                                                    name: "Training",
                                                    id: 5,
                                                    byTraining: items,
                                                  },
                                                }}
                                                className="megamenu-link"
                                              >
                                                {items}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      })
                                  )}
                                </ul>
                              </div>

                              <div className="p-2 flex-fill">
                                <h3 className="title">
                                  {getThirdSubmenuTitle(menuItem.menuName)}
                                </h3>
                                <ul className="list-unstyled">
                                  {popular_section.map(
                                    (popularItems, pindex) =>
                                      menuItem.menuId === popularItems.id &&
                                      popularItems.items.map((items, index) => {
                                        if (popularItems.id === 3) {
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/technology`,
                                                  state: {
                                                    name: "Technology",
                                                    id: 3,
                                                    byTech: items,
                                                  },
                                                }}
                                                className="megamenu-link"
                                              >
                                                {items}
                                              </Link>
                                            </li>
                                          );
                                        } else if (popularItems.id === 4) {
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/consulting`,
                                                  state: {
                                                    name: "Consulting",
                                                    id: 4,
                                                    subName:
                                                      "consulting-subSection",
                                                    byConsultingType: items,
                                                  },
                                                }}
                                                className="megamenu-link"
                                              >
                                                {items}
                                              </Link>
                                            </li>
                                          );
                                        } else if (popularItems.id === 5) {
                                          return (
                                            <li>
                                              <Link
                                                to="/training-popular"
                                                className="megamenu-link"
                                              >
                                                {items}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      })
                                  )}

                                  {popular_section.map(
                                    (popularSectionItem, index) =>
                                      menuItem.menuId ===
                                        popularSectionItem.id &&
                                      popularSectionItem.values.map((value) => {
                                        if (
                                          popularSectionItem.id === 1 &&
                                          value.subId === 55
                                        ) {
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/${menuItem.slug}/${value.submenu}/${value.subslug}`,
                                                  state: {
                                                    name: value.submenuName,
                                                    id: menuItem.menuId,
                                                    sub_id: value.subId,
                                                  },
                                                }}
                                                className="megamenu-link"
                                              >
                                                {value.name}
                                              </Link>
                                              {/* {value.name} */}
                                            </li>
                                          );
                                        }else  if (
                                          popularSectionItem.id === 2 &&
                                          value.subId === 56
                                        ) {
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/${menuItem.slug}/${value.submenu}/${value.subslug}`,
                                                  state: {
                                                    name: value.submenuName,
                                                    id: menuItem.menuId,
                                                    sub_id: value.subId,
                                                  },
                                                }}
                                                className="megamenu-link"
                                              >
                                                {value.name}
                                              </Link>
                                              {/* {value.name} */}
                                            </li>
                                          );
                                        }
                                         else{
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/${menuItem.slug}/${value.submenu}`,
                                                  state: {
                                                    name: value.submenuName,
                                                    id: menuItem.menuId,
                                                    sub_id: value.subId,
                                                  },
                                                }}
                                                className="megamenu-link"
                                              >
                                                {value.name}
                                              </Link>
                                              {/* {value.name} */}
                                            </li>
                                          );
                                        }
                                      })
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    );
                  })}
              <div className="career-div border-none">
                <div className="nav-item dropdown position-static">
                  <Link
                    target=""
                    className={`career-link ${
                      isActive ? "active" : ""
                    }`}
                    to="/AboutUs"
                    onClick={handleLinkClick}
                    onBlur={handleLinkBlur}
                  >
                    Company
                  </Link>
                  <ArrowDropDownIcon />
                  {isActive && <span className="active-line"></span>}
                  {!isActive && <span className="hover-line"></span>}

                  <div className="dropdown-content">
                    <div className="row">
                     
                      <div className="col-10">
                      <div>
  <p className="by-function"></p>
</div>


                       
                        
                        <p>
                          <Link className="text-decoration-none" to="/AboutUs">
                            <p className="dp-color">About Us</p>
                          </Link>
                        </p>
                        <p>
                          <Link className="text-decoration-none" to="/careers">
                            <p className="dp-color">Career</p>
                          </Link>
                        </p>
                        <p>
                          <Link className="text-decoration-none" to="/insights">
                            <p className="dp-color">Insights</p>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>

            <form className="form-inline my-2 my-lg-0 pr-4 ">
              <div id="btn-grp" className="d-flex">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#mydiv_nav"
                  data-backdrop="static"
                  data-keyboard="false"
                  className="btn btn-success my-2 my-sm-0 pl-4 btn-grp-1"
                >
                  <div className="btn-text">
                    {/* {location.pathname === "/"
                      ? "Schedule a Consult"
                      : "Request For Proposal"} */}
                    Schedule a Consult
                  </div>
                </button>

                <button
                  className="btn btn-success my-2 my-sm-0 ml-2 btn-grp-2"
                  type="button"
                >
                  <div className="btn-text">
                    <a
                      target="blank"
                      className="text-white text-decoration-none"
                      href="https://client.bestpracticify.co/signin"
                    >
                      Login
                    </a>
                  </div>
                </button>
              </div>
            </form>
          </Navbar.Collapse>
          {/* <button
									className="btn btn-success my-2 my-sm-0 ml-2 btn-grp-2"
									type="button"
								>
									<div className="btn-text">
										<Link target=""
											className="text-white text-decoration-none" to="../Career">Career</Link>
									</div>
					</button> */}
        </Navbar>
      </div>

      <div
        className="modal fade"
        tabIndex="-1"
        role="document"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="mydiv_nav"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content p-4">
            {props.menuId ? (
              <Form menuId={props.menuId} formId="#mydiv_nav" />
            ) : (
              <Form formId="#mydiv_nav" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBootstrap;
