import React from "react";
import { BASE_API_URL, BASE_URL } from "../../../../../../Constants";
import { context } from "../../../../../../App";
import UploadFiles from "./UploadFiles/UploadFilesComponent";
import "./UserForm.css";

const UserFormDetailsCardFour = (props) => {
  console.log(props);

  const { ID } = React.useContext(context);
  console.log(ID);

  return (
    <>
      <div className="modal-body box">
        <div className="custom-file clearfix">
          <UploadFiles id={ID} />
        </div>

        <div className="d-flex justify-content-between">
          <input
            className="from-vals mr-2"
            type="submit"
            name=""
            value="Back"
            onClick={() => props.prevStep()}
            
          />
          <input
            className="from-vals ml-2"
            type="submit"
            name=""
            value="Continue"
            onClick={() => props.nextStep()}
          />
        </div>
      </div>
    </>
  );
};

export default UserFormDetailsCardFour;
