import axios from "axios";
import $ from "jquery";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {BASE_API_URL} from "../../Constants";
import "./Blog.css";
import Blogcategorypage from "./Blogcategorypage";
import BlogHome from "./BlogHome";
import BlogModal from "./BlogModal";
import SubscriptionForm from "./SubscriptionForm/SubscriptionForm";

// import $ from "jquery";

const Blog = (props) => {
	// //console.log(props);
	// //console.log(window.location.pathname);
	// //console.log("props.location:" + JSON.stringify(props.location, undefined, 2));
	// //console.log("props.match:" + JSON.stringify(props.match, undefined, 2));

	let [Blog, setBlog] = useState();
	const [err, setErr] = useState(false);
	const [loading, setLoading] = useState(false);
	const [confirm, setConfirm] = useState(false);

	let [blogSubCategorySingleItem, setBlogSubCategorySingleItem] =
		React.useState([]);
	let [blogSubCategoryItem, setBlogSubCategoryItem] = React.useState([]);
	const {register, handleSubmit, Controller, errors, watch} = useForm();

	$(document).ready(function () {
		if (!Blog) {
			setTimeout(function () {
				$("#blogModal").modal("show");
			}, 20000);

			setBlog(window.location.pathname);
		}
	});

	React.useEffect(() => {
		if (props.match.params.blog_subcategory_id) {
			axios
				.get(
					BASE_API_URL +
						`BlogPost/GetBlogBySubCategoryId?subCategorySlug=${props.match.params.blog_subcategory_id}&count=-1`
				)
				.then(
					(res) => {
						console.log("GetBlogBySubCategory: ", res);
						if (res.data.status) {
							if (res.data.data.length !== 0) {
								setBlogSubCategorySingleItem(res.data.data.item1);
							}
						} else {
							setBlogSubCategorySingleItem([]);
						}
					},
					(error) => {
						//console.log(error);
					}
				);
		}

		// var params = new URLSearchParams();
		// params.append("subCategoryId", props.match.params.blog_subcategory_id);
		// params.append("count", 4);
		// var request = {
		//   params: params,
		// };

		// axios.get(BASE_API_URL + `BlogPost/GetBlogBySubCategoryId`, request).then(
		//   (res) => {
		//     ////console.log(res);
		//     if (res.data.data.length !== 0) {
		//       setBlogSubCategorySingleItem(res.data.data.item1);
		//     }
		//   },
		//   (error) => {
		//     //console.log(error);
		//   }
		// );

		axios.get(BASE_API_URL + "BlogPost/GetBlogForHomepage").then(
			(res) => {
				if (res.status && res.data.length !== 0) {
					setBlogSubCategoryItem(res.data.data);
				}
			},
			(error) => {
				//console.log(error);
			}
		);
	}, [props.match.params.blog_subcategory_id]);

	const onSubmit = (data) => {
		//console.log(data);
		setLoading(true);
		axios
			.post(
				BASE_API_URL + `NewsletterSubscription/subscribe`,
				JSON.stringify(data),
				{headers: {"Content-Type": "application/json"}}
			)
			.then((res) => {
				////console.log(res);
				setLoading(false);
				if (res.data.status) {
					setErr(false);
					setConfirm(true);
					setTimeout(function () {
						$("#blogModal").modal("hide");
					}, 5000);
				} else {
					setErr(true);
					setConfirm(false);
				}
			});
	};

	// //console.log(blogSubCategorySingleItem);
	// //console.log(blogSubCategoryItem);

	const [mobileView, setMobileView] = useState(false);

	useEffect(() => {
		const mql = window.matchMedia("(max-width: 991px)");

		if (mql.matches) {
			setMobileView(mql.matches);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		mql.addEventListener("change", (e) => {
			// //console.log(e);
			setMobileView(e.matches);
		});
	}, []);

	return (
		<div className="blog">
			<div className="container">
				{/* <pre>
          "blogSubCategoryItem" :{" "}
          {JSON.stringify(blogSubCategoryItem, undefined, 2)}
        </pre> */}

				<BlogModal
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
					mobileView={mobileView}
					register={register}
					loading={loading}
					confirm={confirm}
					err={err}
				/>

				{props.match.params.blog_subcategory_id ? (
					<>
						{/* <span>blog subcategory page</span> */}
						<Blogcategorypage categoryItems={blogSubCategorySingleItem} />
					</>
				) : (
					<div>
						{/* <span>blog home page</span> */}
						{blogSubCategoryItem.map(
							(item, index) =>
								item.name !== "Class" && (
									<BlogHome
										items={item}
										indexs={index}
										location={props.location.state ? props.location.state : ""}
									/>
								)
						)}
					</div>
				)}
			</div>

			<SubscriptionForm />
		</div>
	);
};

export default Blog;
