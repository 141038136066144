import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import $ from "jquery";
import * as Scroll from "react-scroll";
import "../Details.css";
import "../DetailsResponsive.css";
import "./DetailsNav.css";

const DetailsNav = ({ pageTitle, routes }) => {
  let Link = Scroll.Link;

  $(document).ready(function () {
    $("form button:first").addClass("btn-grp-1");
    $("form button:last").addClass("btn-grp-2");
  });

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      // setScroll(window.scrollY > 10);
      let scrollVal = window.scrollY > 50;
      setScroll(scrollVal);
    });
  }, []);

  // if(routes){
  //   routes.reverse()
  // }
  return (
    <div className={scroll ? "w-100 details-nav shadow" : "w-100 details-nav"}>
      <div className="container-fluid ">
        <div className="header-text w-100">
          <h3 className="pl-4 mb-0">{pageTitle}</h3>
        </div>

        <nav className="">
          <ul className="details-nav-items d-flex justify-content-end mr-3 w-60 mb-0">
            <li>
              <Link
                to="overview"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
              >
                Overview
              </Link>
            </li>
            <li>
              <Link
                to="best-practices"
                spy={true}
                smooth={true}
                offset={-180}
                duration={500}
              >
                Best Practices
              </Link>
            </li>

            {routes
              ? routes.map((data) => {
                  if (
                    data.systemName !== "Overview" &&
                    data.systemName !== "BestPractices"
                  ) {
                    return (
                      <li>
                        <Link
                          to={data.systemName}
                          spy={true}
                          smooth={true}
                          offset={-180}
                          duration={500}
                        >
                          {data.name}
                        </Link>
                      </li>
                    );
                  }
                })
              : ""}
            {/* <li>
              <Link
                to="Solutions"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
              >
                Solutions
              </Link>
            </li>
            <li>
              <Link
                to="Services"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
              >
                Services
              </Link>
            </li>
            {/* <li><Link to="Technology" spy={true} smooth={true} offset={50} duration={500}>Technology</Link></li> */}
            {/* <li>
              <Link
                to="Consulting"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
              >
                Consulting
              </Link>
            </li>
            <li>
              <Link
                to="Training"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
              >
                Training
              </Link>
            </li> */}

            {/* <li className="font-weight-bold" id="0">Overview</li>
                                <li className="font-weight-bold" id="1">Best Practices</li>
                                <li className="font-weight-bold" id="2">Solutions</li>
                                <li className="font-weight-bold" id="3">Services</li> */}
            {/* <li>Technology</li> */}
            {/* <li className="font-weight-bold" id="4">Consulting</li>
                                <li className="font-weight-bold" id="5">Training</li> */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default DetailsNav;
