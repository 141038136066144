import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {Element, scroller} from "react-scroll";
import {BASE_URL} from "../../Constants";
import "./BlogHome.css";
import BlogItemCard from "./BlogItemCard";

const BlogHome = ({items, indexs, location}) => {
	console.log("Items: ", items);
	useEffect(() => {
		if (location.blog) {
			//(location.name);
			if (location.name === "Blog") {
				scroller.scrollTo("Blog", {
					duration: 100,
					smooth: true,
					offset: -100, // Scrolls to element + 50 pixels down the page
				});
			}

			if (location.name === "Resources") {
				scroller.scrollTo("Resources", {
					duration: 100,
					smooth: true,
					offset: -140, // Scrolls to element + 50 pixels down the page
				});
			}
		}
	}, [location.blog, location.name]);
	return (
		<>
			<Element name={items.name}>
				<div className="blog-main" key={indexs}>
					{items.blogSubCategories.map((item, index) => {
						return item.posts.length > 0 ? (
							<>
								<div className="blog-title pt-5 pb-3">
									<h3 className="pb-0 text-secondary">
										{item.subCategoryName}
									</h3>
									<div className="stripe-line"></div>
								</div>
								<div className="blog-item pb-5">
									<div className="row">
										{item.posts.map((item, index) => (
											<div className="col-xs-12 col-sm-4 mb-4">
												<BlogItemCard
													detailsLink={`/insights/details/${item.slug}`}
													imgSource={BASE_URL + item.pictureUrl}
												/>

												<div className="card shadow-sm rounded-lg" key={index}>
													<Link
														to={`/insights/details/${item.slug}`}
														className="img-card"
													>
														<img
															className="card-img-top"
															src={BASE_URL + item.pictureUrl}
															alt="Card image cap"
															style={{height: "200px"}}
														/>
													</Link>
													<div className="card-content">
														<h5 className="card-title">
															<Link
																to={`/insights/details/${item.slug}`}
																className="btn btn-link btn-block"
															>
																{item.title}
															</Link>
														</h5>
														<p className="card-details"
															dangerouslySetInnerHTML={{
																__html:
																	item.content
																		.split(" ")
																		.slice(0, 30)
																		.join(" ") + " ...",
															}}
														/>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</>
						) : (
							<> </>
						);
					})}
				</div>
			</Element>
		</>
	);
};

export default BlogHome;
