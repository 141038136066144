import React from "react";
import { Link } from "react-router-dom";
import * as Scroll from "react-scroll";
import { BASE_URL } from "../../../Constants";
import { genarateSlug } from "../../../utils/generateSlug";
import Form from "../ConsultingForm/FunctionPages/FunctionPagesSolutionsForm/UserForm";
import ContactRequest from "../ContactRequest/ContactRequest";
import "../Details.css";
import "../DetailsResponsive.css";
import "./CommonSection.css";
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-M1J4DTVBB4";
const CommonSection = ({
	commonData,
	name,
	menuId,
	subMenuId,
	indexing,
	location,
}) => {
	// console.log('devAshadul', commonData);
	// console.log('devAshadulName', name, menuId, subMenuId);

	let Element = Scroll.Element;
	let contactUsLeftSectionText = "";
	let contactUsRightSectionText = "";
	let traingOverview = "";
	React.useEffect(() => {}, []);

	switch (name) {
		case "Solutions":
			if (subMenuId === 5) {
				contactUsLeftSectionText =
					"Learn more about the technology solutions that can empower your business to thrive and grow.";
				contactUsRightSectionText =
					"Tell us about your business, your goals and your desired outcomes, and we will get to work on your customized technology solution today!";
			} else if (subMenuId === 6) {
				contactUsLeftSectionText = `Learn more about accounting and financial software that can enable your business’s financial processes. `;
				contactUsRightSectionText = `Tell us about your business, your goals and your desired outcomes, and we will get to work on your customized accounting and finance software solution today!`;
			} else if (subMenuId === 8) {
				contactUsLeftSectionText = `Learn more about the ecommerce platforms and software that can enable your business’s growth.`;
				contactUsRightSectionText = `Tell us about the goals and desired outcomes you have for your ecommerce business, and we will get to work on your customized solution today! `;
			} else if (subMenuId === 9) {
				contactUsLeftSectionText = `Learn more about app development that can help your business grow.`;
				contactUsRightSectionText = `Tell us about your business, your goals and your desired outcomes, and we will get to work on your customized app development solution today!`;
			}
			break;
		case "Services":
			if (subMenuId === 5) {
				contactUsLeftSectionText =
					"Learn more about the technology services that can help your business reach its goals.";
				contactUsRightSectionText = `Tell us about your business, your goals and your desired outcomes, and we will get to work on your customized technology services today!`;
			} else if (subMenuId === 6) {
				contactUsLeftSectionText = `Learn more about accounting and financial software that can enable your business’s financial processes. `;
				contactUsRightSectionText = `Tell us about your business, your goals and your desired outcomes, and we will get to work on your customized accounting and finance software solution today! `;
			} else if (subMenuId === 8) {
				contactUsLeftSectionText = `Discover the services we recommend for your ecommerce business, based on your unique needs. Schedule a free initial consultation with us today!`;
				contactUsRightSectionText = `Tell us about your business, your goals and your desired outcomes, and we will get to work on your customized ecommerce solution today!`;
			} else if (subMenuId === 9) {
				contactUsLeftSectionText = `Discover the services we recommend for your business, based on your unique needs. Schedule a free initial consultation with us today.`;
				contactUsRightSectionText = `Tell us about your business, your goals and your desired outcomes, and we will get to work on your customized web or mobile app solution today!`;
			}
			break;
		case "Training":
			if (subMenuId === 5) {
				contactUsLeftSectionText =
					"Learn more about technology trainings from Best Practicify.";
				contactUsRightSectionText = `Tell us more about your business and your tech training needs, and we’ll let you know how are training solutions can help.`;
			} else if (subMenuId === 6) {
				contactUsLeftSectionText = `Learn more about accounting and finance software trainings from Best Practicify.`;
				contactUsRightSectionText = `Tell us more about your business and your accounting and finance needs, and we’ll let you know how are training solutions can help.`;
			} else if (subMenuId === 8) {
				contactUsLeftSectionText = `Learn more about ecommerce trainings from Best Practicify.`;
				contactUsRightSectionText = `Tell us more about your ecommerce business and your training needs, and we’ll let you know how are training solutions can help.`;
			} else if (subMenuId === 9) {
				contactUsLeftSectionText = `Learn more about how our consulting services can support your business.`;
				contactUsRightSectionText = `Tell us about your business to see which consulting service we recommend for you.`;
			}
			break;
		default:
		// code block
	}

	switch (subMenuId) {
		case 5:
			traingOverview =
				"To get the most out of your business’s technology solutions, you and your team should know the features, functions and best practices needed to fully implement your tech stack. Learn from the experts who created your tech stack: With training from Best Practicify, your team will discover how to use your new technology in line with the business goals you set out to reach.";
			break;
		case 6:
			traingOverview =
				"To get the most out of your business’s finance and accounting solutions, you and your team should know the features, functions and best practices needed to take full advantage of the software’s features. Learn from the experts who helped you establish your software solutions: With training from Best Practicify, your team will discover how to use your accounting and finance software in line with your business practices and operations.";
			break;
		case 8:
			traingOverview =
				"To get the most out of your business’s ecommerce solutions, you and your team should know the features, functions and best practices needed to fully implement your platform and supporting technology. Learn from the experts who helped build and streamline your ecommerce business: With training from Best Practicify, your team will discover how to use your solutions in line with the business goals you set out to reach. ";
			break;
		default:
	}

	function removeHTML(str) {
		str = str.replace(/<[^>]+>/g, "");
		str = str.replace("&nbsp;", "");
		str = str.replace("&nbsp;", "");
		return str;
	}
	// console.log(props)

	ReactGA.initialize(TRACKING_ID);
			
	 ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: {name} });

	return (
		<Element name={name} id={name}>
			{/* <h1>{subMenuId}</h1> */}
			{(name === "Solutions" || name === "Training") && (
				<div className="separator_dark"></div>
			)}

			<div
				class={
					name === "Solutions" || name === "Training"
						? "demo plain dark-section"
						: "demo plain"
				}
			>
				<div className="container-fluid">
					<div id="overview">
						<div className="row">
							<div className="col-md-12">
								<div className="overview-top text-center">
									<h2 className="section-title">
										{commonData.title}
										{/* -- {name} -- {subMenuId} */}
									</h2>
									<h5>{commonData.subTitle}</h5>
								</div>
							</div>
						</div>

						{menuId === 5 && subMenuId === 9 && name === "Training" ? (
							<div className="row">
								<div className="col-md-10 offset-md-1">
									<p className="bg-danger text-white text-center">
										Content Missing
									</p>
								</div>
							</div>
						) : (
							""
						)}

						{name === "Training" ? (
							<>
								{/* -------- different design for training --------*/}
								<div className="overview-bottom">
									<div className="overview-bottom-top-section pt-3 pb-5">
										<div className="row">
											<div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1  training-overview">
												{traingOverview}
											</div>
										</div>
										<div className="row pt-2">
											{commonData.sectionItems
												? commonData.sectionItems.map((item, index) => (
														<div className="col-md-6 pl-5 pr-5" key={index}>
															<div className="list-describe pb-4">
																<h5 className="pt-3 pb-0 title">
																	{item.title}
																</h5>
																<h6 className="subtitle">{item.subtitle}</h6>
																<div className="training-item item-content">
																	{/* {removeHTML(item.description)} */}
																	<span
																		dangerouslySetInnerHTML={{
																			__html: item.description,
																		}}
																	/>
																</div>
																{item.ctaBtn && (
																	<button
																		type="button"
																		data-toggle="modal"
																		data-target="#scheduleConsultation"
																		data-backdrop="static"
																		data-keyboard="false"
																		className="btn btn-link detail-section-link"
																	>
																		{item.ctaBtnText}
																	</button>
																)}

																{item.link && (
																	<Link
																		to={{
																			pathname: `/training`,
																			state: {
																				name: "training",
																				id: menuId,
																				sub_id: subMenuId,
																			},
																		}}
																		className="btn btn-link mt-3 pl-0 ml-0"
																	>
																		{item.linkText}
																	</Link>
																)}
															</div>
														</div>
												  ))
												: ""}
											<div className="col-md-6 pl-5 pr-5">
												{commonData.pictureUrl && (
													<div className="d-flex justify-content-end ">
														<img
															src={BASE_URL + commonData.pictureUrl}
															alt={name}
															className="section-picture"
														/>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</>
						) : (
							<div className="overview-bottom">
								<div className="overview-bottom-top-section pt-5 pb-5">
									<div className="row">
										{commonData.sectionItems
											? commonData.sectionItems.map((item, index) => (
													<div className="col-md-6 pl-5 pr-5" key={index}>
														<div className="list-describe pb-4">
															<h5 className="pt-3 pb-0">{item.title}</h5>
															{/* <h6>{item.subtitle}</h6> */}
															<div className="item-content">
																{removeHTML(item.description)}
																{ menuId == 1 & subMenuId == 5  & item.title == 'Data and Analytics'? <>
															
																<button
																	class="btn btn-success read-more-btn skew-1"
																	type="button"
																>
																	<div class="btn-text">
																		<Link
																			to={{
																				pathname: `${location}/${genarateSlug(
																					item.title
																				)}`,
																				state: {
																					menuId,
																					subMenuId,

																					id: 5,
																				},
																			}}
																			className="text-white text-decoration-none"
																		>
																			Read more...
																		</Link>
																	</div>
																</button>
																</> : <></>}

																{ menuId == 2 && item.title == 'Systems Assessment & Optimization' ||  menuId == 2 && item.title == 'Systems Selection' || menuId == 2 && item.title == 'System Implementation' || menuId == 2 && item.title == 'Analytics & Business Intelligence' || menuId == 2 && item.title == 'Data Management'  || menuId == 2 && item.title == 'Accounting Technology' || menuId == 2 && item.title == 'Financial Reporting' || menuId == 2 && item.title == 'Planning and Analysis' || menuId == 2 && item.title == 'Operational Accounting and Support' || menuId == 2 && item.title == 'Business Process Optimization' || menuId == 2 && item.title == 'Finance Transformation' || menuId == 2 && item.title == 'Marketplace Store Development' || menuId == 2 && item.title == 'Design and Customization' || menuId == 2 && item.title == 'Product Management' || menuId == 2 && item.title == 'Platform Integration and Data Migration' || menuId == 2 && item.title == 'Ecommerce Accounting' || menuId == 2 && item.title == 'Strategy' || menuId == 2 && item.title == 'Design' || menuId == 2 && item.title == 'Development' || menuId == 2 && item.title == 'Metrics & Analytics' ? <> 
															
																<button
																	class="btn btn-success read-more-btn skew-1"
																	type="button"
																>
																	<div class="btn-text">
																		<Link
																			to={{
																				pathname: `${location}/${genarateSlug(
																					item.title
																				)}`,
																				state: {
																					menuId,
																					subMenuId,

																					id: 5,
																				},
																			}}
																			className="text-white text-decoration-none"
																		>
																			Read more...
																		</Link>
																	</div>
																</button>
																</> : <></>}

																{/* <span dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }} /> */}
															</div>
															{item.ctaBtn && (
																<>
																	<button
																		type="button"
																		data-toggle="modal"
																		// data-target="#shedule_a_consult_form"
																		data-target={`#shedule_a_consult_form_${item.id}`}
																		data-backdrop="static"
																		data-keyboard="false"
																		className="btn btn-link detail-section-link"
																	>
																		{item.ctaBtnText}
																	</button>

																	<div
																		className="modal fade"
																		tabIndex="-1"
																		role="document"
																		aria-hidden="true"
																		id={`shedule_a_consult_form_${item.id}`}
																	>
																		<div className="modal-dialog modal-dialog-centered modal-xl">
																			<div className="modal-content p-4">
																				<Form
																					formId={`#shedule_a_consult_form_${item.id}`}
																					subMenuId={subMenuId}
																					title="Schedule a Consult"
																				/>
																			</div>
																		</div>
																	</div>
																</>
															)}

															{item.link && (
																<Link
																	to={{
																		pathname: `/training`,
																		state: {
																			name: "training",
																			id: 5,
																		},
																	}}
																	className="btn btn-link detail-section-link"
																>
																	{item.linkText}
																</Link>
															)}
														</div>
													</div>
											  ))
											: ""}
										<div className="col-md-6 pl-5 pr-5">
											{commonData.pictureUrl && (
												<div className="d-flex justify-content-end ">
													<img
														src={BASE_URL + commonData.pictureUrl}
														alt={name}
														className="section-picture"
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* <div
        className="modal fade bd-example-modal-lg"
        id="scheduleConsultation"
        tabIndex="-1"
        role="document"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <UserForm menuId={menuId} subMenuId={subMenuId} />
          </div>
        </div>
      </div> */}

			{(name === "Solutions" || name === "Training") && (
				<div className="vertical separator_dark"></div>
			)}

			<ContactRequest
				menuId={menuId}
				subMenuId={subMenuId}
				leftSectionText={contactUsLeftSectionText}
				rightSectionText={contactUsRightSectionText}
			/>
		</Element>
	);
};

export default CommonSection;
