import React from "react";
import {Route} from "react-router-dom";
import BlogHeader from "../Components/Blog/BlogHeader";
import Footer from "../Components/Footer/Footer";
import NavBootstrap from "../Components/Navbar/NavBootstrap";


const HomePageLayout = ({children, ...rest}) => {
	console.log('laytou>>>>', {children, ...rest} )
	if (children.props.location.pathname.toLowerCase().includes("/insights")) {
		return (
			<>
				<BlogHeader />
				{children}
				<Footer />
			</>
		);
	}
	return (
		<>
			<NavBootstrap />
			<main className="main-section">{children}</main>
			<Footer />
		</>
	);
};

const HomePageLayoutRoute = ({component: Component, ...rest}) => {
	console.log({...rest},'Component', rest)
	return (
		<Route
			{...rest}
			render={(match) => (
				<HomePageLayout>
					<Component {...match} />
					
				</HomePageLayout>
				
			)}
		/>
	);
};

export default HomePageLayoutRoute;
