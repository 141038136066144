import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
// import ScheduleForm from "../../DetailsService/SubServiceForm/ScheduleForm";
import DataAnalysisSmallForm from "../DataAnalysicsSmallForm/DataAnalysicsSmallForm";
import DataAnalysisSmallFormAllField from "../DataAnalysicsSmallForm/DataAnalysicsSmallFormAllField";
import BPWaves from "./BPWaves";
import "./freeTrail.css";

const FreeTrail = ({title, menuId, subMenuId, data}) => {
  

  return (
    <>
    <BPWaves />
      <div className="freetrailwrapper">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="title">
                <h1>{data.title}</h1>
              </div>
              <div className={"freetrailwrapperList"}>
                {data.list.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start align-items-center py-2"
                  >
                    <div className="icon">
                      <FaRegCheckCircle />
                    </div>
                    <h6 className="m-0 ps-3">{item}</h6>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-8">
              {
                title === 'Customized support' ? (
                    <DataAnalysisSmallFormAllField />
                ) : (
                  <DataAnalysisSmallForm/>
                )
              }
              
					        {/* <ScheduleForm menuId={menuId} submenuId={subMenuId} /> */}
            </div>
          </div>
        </div>
      </div>
      <BPWaves className={`vertical`} />
    </>
  );
};

export default FreeTrail;
