import React, {useEffect, useState} from "react";
import NewConsulntRegistration from "../../../../Authentication/NewConsulntRegistration/NewConsulntRegistration";
import "../ConsultStyle.css";

const ConsultationDaily = ({daily, subMenuId}) => {
	// console.log(subMenuId)
	const [startupDaily, setStartupDaily] = useState({});
	const [enterprenureDaily, setEnterprenureDaily] = useState({});
	const [businessDaily, setBusinessDaily] = useState({});
	const [enterpriseDaily, setEnterpriseDaily] = useState({});
	const [signup, setSignup] = useState(false);
	const [createAccount, setCreateAccount] = useState(false);

	//   subMenuId = Number(subMenuId)

	useEffect(() => {
		if (daily) {
			daily.map((item) => {
				if (item.companyTypeId === 1) {
					console.log(typeof subMenuId, typeof item.submenuId);
					if (item.submenuId === Number(subMenuId)) {
						setEnterprenureDaily({rate: item.rate, name: "Entrepreneur"});
					}
				} else if (item.companyTypeId === 2) {
					if (item.submenuId === Number(subMenuId)) {
						setStartupDaily({rate: item.rate, name: "Startup"});
					}
				} else if (item.companyTypeId === 3) {
					if (item.submenuId === Number(subMenuId)) {
						setBusinessDaily({rate: item.rate, name: "Business"});
					}
				} else if (item.companyTypeId === 4) {
					if (item.submenuId === Number(subMenuId)) {
						setEnterpriseDaily({rate: item.rate, name: "Enterprise"});
					}
				}
			});
		}
	}, [daily, subMenuId]);

	if (startupDaily) {
		console.log(startupDaily);
	}

	const convertPricetoUsd = (rate) => {
		return rate.toLocaleString("en-US", {
			currency: "USD",
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
	};

	const openRegiserForm = () => {
		setSignup(true);
	};

	const closeAllCondition = () => {
		setSignup(false);
		setCreateAccount(false);
	};

	return (
		<div class="">
			<div
				className="modal fade "
				tabIndex="-1"
				role="document"
				aria-labelledby="myLargeModalLabel"
				aria-hidden="true"
				id="purchase_modal"
			>
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content">
						<div className="modal-header mb-3">
							<div className="container w-100">
								<div className="row modal-header-row ">
									<div className="d-flex justify-content-end w-100">
										<h2
											class="modal-title text-center"
											id="exampleModalLongTitle"
										>
											{createAccount
												? "Sign In"
												: signup
												? "Create New Account"
												: "Sign In Or Sign Up"}
										</h2>
										<button
											type="button"
											className="close"
											data-toggle="modal"
											data-target="#purchase_modal"
											data-dismiss="modal"
											aria-label="Close"
											onClick={closeAllCondition}
										>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							{createAccount ? (
								<div className="text-center m-4">
									<h6 className="mb-3 text-success">
										Your account has been created successfully. Please check
										your email to complete the purchase.
									</h6>

									<a
										href="https://client.bestpracticify.co/signin"
										target="_blank"
										class="btn btn-primary my-2"
										rel="noreferrer"
									>
										Sign In
									</a>

									{/* <div className="newToBP my-2">
                      <p className="text-center text-secondary bg-white"><span>New To Best Practicify?</span></p>
                      <hr />
                    </div>
                    <a onClick={ openRegiserForm } className="btn btn-primary my-2">Create Your Best Practicify Account</a> */}
								</div>
							) : (
								<>
									{!signup ? (
										<div className="text-center m-4">
											<h6 className="mb-3">
												To purchase the selected consultation, please sign in
												first.
											</h6>

											<a
												href="https://client.bestpracticify.co/signin"
												target="_blank"
												class="btn btn-primary my-2"
												rel="noreferrer"
											>
												Sign In
											</a>

											<div className="newToBP my-2">
												<p className="text-center text-secondary bg-white">
													<span>New To Best Practicify?</span>
												</p>
												<hr />
											</div>
											<a
												onClick={openRegiserForm}
												className="btn btn-primary my-2"
											>
												Create Your Best Practicify Account
											</a>
										</div>
									) : (
										<NewConsulntRegistration
											setCreateAccount={setCreateAccount}
										></NewConsulntRegistration>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<div class="container daily mt-2">
				<div class="row">
					<div class="col-md-3 col-sm-6 pl-2 pr-2 mt-2 mb-2">
						<div class="pricingTable pricing-hov">
							<div class="pricingTable-header pt-5">
								<h3>
									{enterprenureDaily.name
										? enterprenureDaily.name
										: "Entrepreneur"}
								</h3>
								<p className="pt-4">One-day Workshop </p>

								<p className="">
									Receive a solutions document tailored to your business
								</p>
							</div>

							<div class="price-value-daily">
								<p className="">Price</p>
								<p className="">Starting at</p>
								<div className="d-flex flex-column align-items-center">
									<span>
										<small class="fa fa-usd"></small>
										{/* {convertPricetoUsd(
											enterprenureDaily.rate ? enterprenureDaily.rate : ""
										)} */}
										{convertPricetoUsd(500)}
										<span style={{fontSize: "1.4rem"}}>
											<sub>/solution</sub>
										</span>
										{/* {enterprenureDaily.rate ? enterprenureDaily.rate : ""} */}
									</span>
									{/* <span
										style={{
											color: "#66666",
											fontSize: "20px",
											marginTop: "10px",
										}}
									>
										per Workshop
									</span> */}
									{/* <span class="subtitle">per hour</span> */}
								</div>
							</div>

							<div class="pricingTable-sign-up">
								<button
									type="button"
									data-toggle="modal"
									data-target="#purchase_modal"
									class="btn btn-block btn-default"
								>
									purchase
								</button>
							</div>
						</div>
					</div>

					<div class="col-md-3 col-sm-6 pl-2 pr-2 mt-2 mb-2">
						<div class="pricingTable pricing-hov">
							<div class="pricingTable-header pt-5">
								<h3>{startupDaily.name ? startupDaily.name : "Startup"}</h3>
								<p className="pt-4">One-day discovery workshop</p>

								<p className="">
									Receive a solutions document to grow your startup{" "}
								</p>
							</div>

							<div class="price-value-daily">
								<p className="">Price</p>
								<p className="">Starting at</p>
								<div className="d-flex flex-column align-items-center">
									<span>
										<small class="fa fa-usd"></small>
										{/* {convertPricetoUsd(
											startupDaily.rate ? startupDaily.rate : ""
										)} */}
										{convertPricetoUsd(500)}
										<span style={{fontSize: "1.4rem"}}>
											<sub>/solution</sub>
										</span>
										{/* {startupDaily.rate ? startupDaily.rate : ""} */}
									</span>
									{/* <span
										style={{
											color: "#66666",
											fontSize: "20px",
											marginTop: "10px",
										}}
									>
										per Workshop
									</span> */}
								</div>
							</div>

							<div class="pricingTable-sign-up">
								<button
									type="button"
									data-toggle="modal"
									data-target="#purchase_modal"
									class="btn btn-block btn-default"
								>
									purchase
								</button>
							</div>
						</div>
					</div>

					<div class="col-md-3 col-sm-6 pl-2 pr-2 mt-2 mb-2">
						<div class="pricingTable pricing-hov">
							<div class="pricingTable-header pt-5">
								<h3>{businessDaily.name ? businessDaily.name : "Business"}</h3>
								<p className="pt-4">One-day discovery workshop </p>

								<p className="">
									Receive a solutions document tailored to grow your online
									store{" "}
								</p>
							</div>

							<div class="price-value-daily">
								<p className="">Price</p>
								<p className="">Starting at</p>
								<div className="d-flex flex-column align-items-center">
									<span>
										<small class="fa fa-usd"></small>
										{/* {convertPricetoUsd(
											businessDaily.rate ? businessDaily.rate : ""
										)} */}
										{convertPricetoUsd(750)}
										<span style={{fontSize: "1.4rem"}}>
											<sub>/solution</sub>
										</span>
										{/* {businessDaily.rate ? businessDaily.rate : ""} */}
									</span>
									{/* <span
										style={{
											color: "#66666",
											fontSize: "20px",
											marginTop: "10px",
										}}
									>
										per Workshop
									</span> */}
								</div>
							</div>

							<div class="pricingTable-sign-up">
								<button
									type="button"
									data-toggle="modal"
									data-target="#purchase_modal"
									class="btn btn-block btn-default"
								>
									purchase
								</button>
							</div>
						</div>
					</div>

					<div class="col-md-3 col-sm-6 pl-2 pr-2 mt-2 mb-2">
						<div class="pricingTable pricing-hov">
							<div class="pricingTable-header pt-5">
								<h3>
									{enterpriseDaily.name ? enterpriseDaily.name : "Enterprise"}
								</h3>
								<p className="pt-4">One-day discovery workshop </p>

								<p className="">
									Receive a solutions document with tailored enterprise tech
									solutions{" "}
								</p>
							</div>

							<div class="price-value-daily">
								<p className="">Price</p>
								<p className="">Starting at</p>
								<div className="d-flex flex-column align-items-center">
									<span>
										<small class="fa fa-usd"></small>
										{/* {convertPricetoUsd(
											enterpriseDaily.rate ? enterpriseDaily.rate : ""
										)} */}
										{convertPricetoUsd(1000)}
										<span style={{fontSize: "1.4rem"}}>
											<sub>/solution</sub>
										</span>
										{/* {enterpriseDaily.rate ? enterpriseDaily.rate : ""} */}
									</span>
									{/* <span style={{color: "#66666", fontSize: "20px"}}>
										per Workshop
									</span> */}
								</div>
							</div>

							<div class="pricingTable-sign-up">
								<button
									type="button"
									data-toggle="modal"
									data-target="#purchase_modal"
									class="btn btn-block btn-default"
								>
									purchase
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConsultationDaily;
