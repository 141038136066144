/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
// import { Button, Card } from "react-bootstrap";
// import Accordion from "react-bootstrap/Accordion";
import {Link, useLocation} from "react-router-dom";
import * as Scroll from "react-scroll";
import {Element, scroller} from "react-scroll";
import Slider from "react-slick";
import methodTraining from "../../assets/img/methodTraining.png";
import ModernTech from "../../Components/Home/ModernTech/ModernTech";
import PersonalizedService from "../../Components/Home/PersonalizedService/PersonalizedService";
import UniqueSolution from "../../Components/Home/UniqueSolution/UniqueSolution";
import {BASE_API_URL, BASE_URL} from "../../Constants";
import rectangle from "../../Images/rectangle.png";
import Consultation from "../Home/Consultation";
import BusinessPrincipleDescriptor from "./Consulting/BusinessPrincipleDescriptor";
import ContactRequest from "./ContactRequest/ContactRequest";
import DailyConsult from "./DailyConsult";
import HourlyConsult from "./HourlyConsult";
import "./InnovativeSolution.css";
import MenuSection from "./MenuSection/Menusection";
import SubMenuItemList from "./SubMenuItemList/SubMenuItemList";
import TechnologyPartners from "./TechnologyPartnersSection/TechnologyPartners";
// import './InnovativeSolutionResponsive.css';
import TopVideo from "./TopVideo/TopVideo";
import TrainingRequestForm from "./Training/RequestForm/TrainingRequestForm";
import YearlyConsult from "./YearlyConsult";
import { FaRegCheckCircle } from "react-icons/fa";

const InnovativeSolution = (props) => {
	
	// // //console.log("match:" + JSON.stringify(props.match, undefined, 2));
	//   // //console.log(uuid());

	let [functionSliders, setFunctionSliders] = React.useState([]);
	const [sliderLoading, setSliderLoading] = useState(false);
	let [loading, setLoading] = React.useState(false);
	let [pageDetails, setPageDetails] = React.useState([]);
	let [uniqueSolution, setUniqueSolution] = React.useState([]);
	let [modernTech, setModernTech] = React.useState([]);
	let [personalizedService, setPersonalizedService] = React.useState([]);
	const [otherElements, setOtherElements] = useState();
	let [overviewConsult, setOverviewConsult] = React.useState([]);
	let [candidate, setCandidate] = React.useState([]);
	// let [menuId, setMenuId] = React.useState(props.id);
	// const [menuName, setMenuName] = useState(props.name);
	const [techSect, setTechSect] = useState();
	const [consulting, setConsulting] = useState();
	const [companyType, setCompanyType] = useState("enterprenure");
	const [companyTypeDaily, setCompanyTypeDaily] =
		useState("enterprenure_daily");
	const [companyTypeYearly, setCompanyTypeYearly] = useState(
		"enterprenure_yearly"
	);
	let textRef = useRef(null);
	// if(props.name){
	//   setMenuName(props.name)
	// }

	let settings = {
		dots: true,
		lazyLoad: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 10000,
		pauseOnHover: true,
		swipeToSlide: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		// variableWidth: true
	};

	const location = useLocation();
    let menu_id="";
	let id = 0;
	let name = "";

	console.log(location.state);
	
	if (location.state) {
		id = location.state.id;
		name = location.state?.name;

		console.log(id);
		console.log(name);

	} else {

		switch (props.location.pathname) {
			case "/solutions":
				id = 1;
				name = "Solutions";
				break;
			case "/services":
				id = 2;
				name = "Services";
				break;
			case "/technology":
				id = 3;
				name = "Technology";
				break;
			case "/consulting":
				id = 4;
				name = "Consulting";
				break;
			case "/training":
				id = 5;
				name = "Training";
				break;
			default:
				break;
		}

		console.log(id);
		console.log(name);
		
		// switch (props.match.params.menu_id) {
		// 	case "solutions":
		// 		id = 1;
		// 		name = "Solutions";
		// 		break;
		// 	case "services":
		// 		id = 2;
		// 		name = "Services";
		// 		break;
		// 	case "technology":
		// 		id = 3;
		// 		name = "Technology";
		// 		break;
		// 	case "consulting":
		// 		id = 4;
		// 		name = "Consulting";
		// 		break;
		// 	case "training":
		// 		id = 5;
		// 		name = "Training";
		// 		break;
		// 	default:
		// 		break;
		// }
		
	}

	// //console.log("location: ", JSON.stringify(location, undefined, 4));

	React.useEffect(() => {
		//axios.get(BASE_API_URL + `MenuSubMenuMap/GetSubmenuItemsByMenuId/${props.match.params.menu_id}`,
		if (id !== undefined) {
			axios
				.get(
					BASE_API_URL + `MenuSubMenuMap/GetFunctionSlidersByMenuId/${id}`,
					{}
				)
				.then((res) => {
					// ////console.log(res);
					// // //console.log(res.data.data);
					setSliderLoading(true);

					if (res.data.status) {
						if (res.data.data ?? res.data.data.length !== 0) {
							setFunctionSliders(res.data.data);
						}
					}

					setSliderLoading(false);
				});

			// axios.get(BASE_API_URL + `/ConsultationOverview`, {}).then((res) => {
			//   // ////console.log(res);
			//   // // //console.log(res.data.data);
			//   setLoading(true);

			//   if (res.data.status) {
			//     setOverviewConsult(res.data.data);
			//   }f

			//   setLoading(false);
			// });

			axios
				.get(BASE_API_URL + `TrainingCandidateDescriptor`, {})
				.then((res) => {
					// // ////console.log(res);
					// // //console.log(res.data.data);
					setLoading(true);

					if (res.data.status) {
						setCandidate(res.data.data);
					}

					setLoading(false);
				});

			if (id === 3) {
				axios
					.get(BASE_API_URL + `TechCategory/TechnologySections`, {})
					.then((res) => {
						// //console.log(res.data);
						// // //console.log(res.data.data);
						setLoading(true);

						if (res.data.status) {
							setTechSect(res.data.data);
						}

						setLoading(false);
					});
			}
		}
	}, [id, location.state]);
	// //console.log(pageDetails);
	// //console.log(window.location);

	useEffect(() => {
		if (id !== undefined) {
			// //console.log(id);
			axios
				.get(BASE_API_URL + `Menu/GetMenuPageDetailsByMenuId/${id}`, {})
				.then((res) => {
					// //console.log(res.data.data);
					setLoading(true);

					if (res.data.data.length != 0) {
						setPageDetails(res.data.data.menuSections);
						setUniqueSolution(res.data.data.uniqueSolution);
						setModernTech(res.data.data.modernTech);
						setPersonalizedService(res.data.data.personalizedService);
						// setPageDetails(res.data.data);
					}
					setLoading(true);
				});
		}
	}, [id, location.state]);

	const scroll = Scroll.animateScroll;

	useEffect(() => {
		if (props.location.state?.byTech) {
			scroller.scrollTo(props.location.state?.byTech, {
				duration: 0,
				smooth: true,
				offset: 0, // Scrolls to element + 50 pixels down the page
			});
		}

		if (props.location.state?.byConsultingType) {
			scroller.scrollTo(props.location.state?.subName, {
				// duration: 500,
				// smooth: true,
				offset: -10, // Scrolls to element + 50 pixels down the page
			});
		}
		if (props.location.state?.place) {
			////console.log("Scroll to: ", props.location.state?.place);
			setTimeout(() => {
				scroller.scrollTo(props.location.state?.place, {
					duration: 0,
					smooth: true,
					offset: -15, // Scrolls to element + 50 pixels down the page
				});
			}, 3000);
		}
	});

	useEffect(() => {
		////console.log("location-State: ", props.location.state);
		// if (props.location.state?.place) {
		// 	//console.log("location Scroll to: ", props.location.state?.place);
		// 	scroller.scrollTo(props.location.state?.place, {
		// 		duration: 500,
		// 		smooth: true,
		// 		offset: 0, // Scrolls to element + 50 pixels down the page
		// 	});
		// }
		if (props.location.state?.byTraining) {
			scroller.scrollTo(props.location.state?.byTraining, {
				duration: 500,
				smooth: true,
				offset: -15, // Scrolls to element + 50 pixels down the page
			});
		}

		if (props.location.state?.value) {
			// //console.log("abd");
			scroller.scrollTo("Consulting", {
				duration: 500,
				smooth: true,
				offset: 700, // Scrolls to element + 50 pixels down the page
			});
		}

		if (props.location.state?.position) {
			// //console.log(props.name);
			if (props.name === "Services") {
				scroller.scrollTo("personal", {
					duration: 500,
					smooth: true,
					offset: 680, // Scrolls to element + 50 pixels down the page
				});
			}
			if (props.name === "Technology") {
				scroller.scrollTo("mordern", {
					duration: 500,
					smooth: true,
					offset: 680, // Scrolls to element + 50 pixels down the page
				});
			}

			if (props.name === "Solutions") {
				scroller.scrollTo("unique", {
					duration: 500,
					smooth: true,
					offset: 680, // Scrolls to element + 50 pixels down the page
				});
			}
		}
	}, [props.location.state, props.name]);

	if (props.location.state === "first-circle") {
		document.addEventListener("DOMContentLoaded", (e) => {
			const getClassNameOfFirstCircle =
				document.getElementsByClassName("first-circle")[0];
			// //console.log(getClassNameOfFirstCircle);
			getClassNameOfFirstCircle.style.backgroundColor = "gray!important";
		});
	}

	function RenderUniqueSolution(props) {
		const menuId = props.menuId;
		if (menuId === 1) {
			return (
				<UniqueSolution
					title={uniqueSolution.title}
					subTitle={uniqueSolution.subTitle}
					data={uniqueSolution.uniqueSolutions}
				/>
			);
		}
		return <></>;
	}

	function RenderPersonalizedService(props) {
		const menuId = props.menuId;
		if (menuId === 2) {
			return (
				<PersonalizedService
					title={personalizedService.title}
					subTitle={personalizedService.subTitle}
					data={personalizedService.personalizedServices}
				/>
			);
		}
		return <></>;
	}

	function RenderModernTech(props) {
		const menuId = props.menuId;
		if (menuId === 3) {
			return (
				<ModernTech
					title={modernTech.title}
					subTitle={modernTech.subTitle}
					data={modernTech.modernTechs}
				/>
			);
		}
		return <></>;
	}

	function RenderMenuSection(props) {
		const menuId = props.menuId;
		// //console.log(props);
		const serviceId = props.serviceId?.service ? props.serviceId?.service : "";
		const title = "Get the exact services your business needs";

		if (menuId === 2) {
			return (
				<MenuSection data={pageDetails} service={serviceId} title={title} />
			);
		}
		return <></>;
	}

	function SolutionSpecificSections(props) {
		const menuId = props.menuId;
		// //console.log(props);

		if (menuId === 1) {
			// // //console.log(pageDetails);
			return (
				<section className="solutionSpecification">
					<div className="text-center">
						{pageDetails.map(
							(pageDetailsItem, i) =>
								i === 0 && (
									<h5
										className="section-title pt-5 text-uppercase pb-3"
										key={i}
									>
										{pageDetailsItem.title}
									</h5>
								)
						)}
					</div>
					<div className="modern-technology-images">
						<div className="container">
							<div className="row">
								<div className="d-flex justify-content-center">
									{pageDetails.map(
										(pageDetailsItem, i) =>
											i === 0 &&
											pageDetailsItem.menuSectionDetails.map((item, index) => (
												<div className="col-md-6" key={i + index}>
													<div className="list-describe">
														<img
															src={BASE_URL + item.pictureUrl}
															className="img-fluid pb-3"
															alt=""
															width="100%"
															height="60"
														/>
													</div>
												</div>
											))
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="diagram text-center">
						{pageDetails.map(
							(pageDetailsItem, i) =>
								i === 1 && (
									<div key={i}>
										<h5 className="section-title pt-5 text-uppercase pb-3">
											{pageDetailsItem.title}
										</h5>
										<img
											src={BASE_URL + pageDetailsItem.pictureUrl}
											className="img-fluid pt-3 pb-3 md-w-50 sm-w-100"
											alt=""
										/>
									</div>
								)
						)}
					</div>
				</section>
			);
		}

		return <></>;
	}

	function RenderServiceExtras(props) {
		const menuId = props.menuId;
		// //console.log(props);
		if (menuId === 2) {
			// //console.log(pageDetails);
			return (
				<div className="modern-technology-heading">
					<div className="row">
						<div className="col-md-12 text-center">
							{pageDetails.map(
								(pageDetailsItem, i) =>
									pageDetailsItem.systemName ===
										"service_provided_orderly_just_intime" && (
										<div key={i}>
											<h5 className="section-title text-uppercase">
												{pageDetailsItem.title}
											</h5>
											<img
												src={BASE_URL + pageDetailsItem.pictureUrl}
												className="img-fluid pt-3 pb-3"
												alt=""
												width="50%"
											/>
										</div>
									)
							)}
						</div>
					</div>
				</div>
			);
		}
		return <></>;
	}

	function RenderTechnologyExtras(props) {
		const menuId = props.menuId;
		// //console.log(props);

		if (menuId === 3) {
			// //console.log(pageDetails);
			return (
				<div className="modern-technology">
					<div className="modern-technology-heading">
						<div className="row">
							<div className="col-md-12 text-center">
								{pageDetails.map(
									(pageDetailsItem, i) =>
										i === 0 && (
											<h5 className="section-title text-uppercase" key={i}>
												{pageDetailsItem.title}
											</h5>
										)
								)}
							</div>
						</div>
					</div>

					<div className="modern-technology-images pt-5 pb-5">
						<div className="container">
							<div className="row">
								<div className="d-flex justify-content-center">
									{pageDetails.map(
										(pageDetailsItem, i) =>
											i === 0 &&
											pageDetailsItem.menuSectionDetails.map((item, index) =>
												index % 2 === 0 ? (
													<div className="row" key={i + index}>
														<div className="col-md-6 d-flex align-items-center">
															<div className="content">
																<p className="section-title">{item.title}</p>
																<div
																	className="list-describe"
																	dangerouslySetInnerHTML={{
																		__html: item.description,
																	}}
																/>
																{/* <div className="list-describe">
                                  {item.description.replace(/<\/?p[^>]*>/g, "")}
                                </div> */}
															</div>
														</div>
														<div
															className="col-md-6 d-flex align-items-center"
															key={Math.random()}
														>
															<img
																style={{width: "100%", height: "100%"}}
																src={BASE_URL + item.pictureUrl}
																alt=""
															/>
														</div>
													</div>
												) : (
													<div className="row">
														<div
															className="col-md-6 d-flex align-items-center"
															key={Math.random()}
														>
															<img
																style={{width: "100%", height: "100%"}}
																src={BASE_URL + item.pictureUrl}
																alt=""
															/>
														</div>
														<div
															className="col-md-6 d-flex align-items-center"
															key={Math.random()}
														>
															<div className="content">
																<p className="section-heading-large">
																	{item.title}
																</p>
																<div
																	className="list-describe"
																	dangerouslySetInnerHTML={{
																		__html: item.description,
																	}}
																/>
																{/* <div className="list-describe">
                                  {item.description.replace(/<\/?p[^>]*>/g, "")}
                                </div> */}
															</div>
														</div>
													</div>
												)
											)
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="modern-technology-heading">
						<div className="row">
							<div className="col-md-12 text-center">
								{pageDetails.map(
									(pageDetailsItem, i) =>
										pageDetailsItem.systemName ===
											"integrated_system_automated_workflow" && (
											<div key={i}>
												<h5 className="section-title text-uppercase ">
													{pageDetailsItem.title}
												</h5>

												<div className="tech-partners">
													<TechnologyPartners />
												</div>

												<h5 className="section-title text-uppercase ">
													DATA-CENTRIC ARCHITECTURE, SECURITY, MANAGEMENT
												</h5>

												<img
													src={BASE_URL + pageDetailsItem.pictureUrl}
													className="img-fluid pt-3 pb-3"
													alt=""
													width="50%"
												/>
											</div>
										)
								)}
							</div>
						</div>
					</div>
				</div>
			);
		}

		return <></>;
	}

	//Consulting page

	const [homepageDetails, setHomePageDetails] = React.useState([]);

	React.useEffect(() => {
		axios.get(BASE_API_URL + `HomePage/GetHomePage`, {}).then((res) => {
			if (res.data !== 0 && res.data != null) {
				setHomePageDetails(res.data);
			}
		});
	}, []);
	const [hourly, setHourly] = useState();
	const [daily, setDaily] = useState();
	const [yearly, setYearly] = useState();

	const [consult, setConsult] = useState(1);
	// const [training, setTraining] = useState(1);
	const [consultDaily, setConsultDaily] = useState(1);
	const [consultYearly, setConsultYearly] = useState(1);

	const callHourly = () => {
		axios.get(BASE_API_URL + `CtaPackageHourly/searchAll`, {}).then(
			(res) => {
				// // ////console.log(res);
				// //console.log(res.data.data.item1);

				if (res.data.data.item1.length) {
					setHourly(res.data.data.item1);
				}
				// if (res.data.data.length !== 0) {
				//   setInfo(res.data.data);
				// }
			},
			(error) => {
				// //console.log(error);
			}
		);
	};

	const callDaily = () => {
		axios.get(BASE_API_URL + `CtaPackageDaily/searchAll`, {}).then(
			(res) => {
				// // ////console.log(res);
				// //console.log(res.data.data.item1);

				if (res.data.data.item1.length) {
					setDaily(res.data.data.item1);
				}
				// if (res.data.data.length !== 0) {
				//   setInfo(res.data.data);
				// }
			},
			(error) => {
				// //console.log(error);
			}
		);
	};

	const callYearly = () => {
		axios.get(BASE_API_URL + `CtaPackageMonthlyYearly/searchAll`, {}).then(
			(res) => {
				// // ////console.log(res);
				// //console.log(res.data.data.item1);

				if (res.data.data.item1.length) {
					setYearly(res.data.data.item1);
				}
				// if (res.data.data.length !== 0) {
				//   setInfo(res.data.data);
				// }
			},
			(error) => {
				// //console.log(error);
			}
		);
	};

	useEffect(() => {
		if (location.state !== undefined) {
			if (id === 4) {
				callDaily();
				callHourly();
				callYearly();
				// ["Hourly", "Solution Discovery", "Access Retainer"]
				if (location.state.subName) {
					if (location.state.byConsultingType === "Hourly") {
						setConsulting(1);
						if (document.getElementById("hourly_solution")) {
							document.getElementById("hourly_solution").className +=
								" active-right-course";
						}
					} else if (location.state.byConsultingType === "Solution Discovery") {
						if (document.getElementById("solution_discover")) {
							document.getElementById("solution_discover").className +=
								" active-right-course";
						}
						setConsulting(2);
					} else if (location.state.byConsultingType === "Access Retainer") {
						if (document.getElementById("access_retainer")) {
							document.getElementById("access_retainer").className +=
								" active-right-course";
						}
						setConsulting(3);
					}
				} else {
					setConsulting(1);
					if (document.getElementById("hourly_solution")) {
						document.getElementById("hourly_solution").className +=
							" active-right-course";
					}
				}
				// document.getElementById("enterprenure").className += " active";
				// document.getElementById("enterprenure_daily").className += " active";
				// document.getElementById("enterprenure_yearly").className += " active";
			} else if (id === 5) {
				if (document.getElementById("data_tec")) {
					document.getElementById("data_tec").className +=
						" active-right-course";
				}
			}
		}
	}, [id, location.state]);

	function handleChangeDaily(e, value, id) {
		// //console.log(value);
		setConsultDaily(value);

		for (var i = 1; i < 5; i++) {
			if (value === i) {
				document.getElementById(id).className += " active";
			} else {
				if (
					id !== "enterprenure_daily" &&
					document
						.getElementById("enterprenure_daily")
						.classList.contains("active")
				) {
					document
						.getElementById("enterprenure_daily")
						.classList.remove("active");
				}

				if (
					id !== "business_daily" &&
					document.getElementById("business_daily").classList.contains("active")
				) {
					document.getElementById("business_daily").classList.remove("active");
				}

				if (
					id !== "startup_daily" &&
					document.getElementById("startup_daily").classList.contains("active")
				) {
					document.getElementById("startup_daily").classList.remove("active");
				}

				if (
					id !== "enterprise_daily" &&
					document
						.getElementById("enterprise_daily")
						.classList.contains("active")
				) {
					document
						.getElementById("enterprise_daily")
						.classList.remove("active");
				}
			}
		}
	}

	if (overviewConsult) {
		// //console.log(overviewConsult);
	}

	if (techSect) {
		// //console.log(techSect);
	}

	var color = "dark";
	if (id === 1 || id === 3 || id === 5) {
		color = "light";
	}
	if (id === 4) {
		color = "green";
	}

	var menuName = "Solution";
	var contactUsTitle =
		"Are you ready to discover best practices for your business?";
	var ctaLeft = "";
	var ctaRight = "";
	if (id === 1) {
		menuName = "Solution";
		contactUsTitle =
			"Are you ready to discover best practices for your business?";
		ctaLeft =
			"Schedule a free initial consultation with us today to discover the technology you need to empower your business.";
		ctaRight =
			"Tell us about your business, your goals and your desired outcomes, and we will get to work on your customized solution today!";
	} else if (id === 2) {
		menuName = "Service";
		ctaLeft =
			"Discover the services we recommend for your business, based on your unique needs.";
		ctaRight =
			"Tell us about your business, your goals and your desired outcomes, and we will get to work on your customized technology solution today!";
	} else if (id === 3) {
		menuName = "Technology";
		contactUsTitle =
			"Ready to discover how your business can thrive <br> with modern technology emboldened with best practices?";
		ctaLeft =
			"Learn more about how modern technology can help your business grow.";
		ctaRight =
			"Tell us more about your business to discover which modern technologies may be right for your business.";
	} else if (id === 4) {
		menuName = "Consulting";
		ctaLeft =
			"Learn more about how our consulting services can support your business.";
		ctaRight =
			"Tell us about your business to see which consulting service we recommend for you.";
	} else {
		menuName = "Training";
		ctaLeft = "Learn more about technology trainings from Best Practicify.";
		ctaRight =
			"Tell us more about your business and your tech training needs, and we’ll let you know how are training solutions can help.";
	}

	return (
		<section id="category-page" style={{minHeight: "400px"}}>
			<section className="bp-menu-hero-slider-section">
				<TopVideo menu_id={id} textColor={color} />
			</section>

			<section className="category-function-section">
				<div className="banner-bottom pt-5">
					{/*---------------- slider section --------------------
					# admin: MenuSubmenuMap (header + Deescription)
					# <pre>{JSON.stringify(functionSliders, undefined, 2)}</pre>
        --------------------------------------------------------*/}
					<Slider {...settings}>
						{sliderLoading ? (
							<p>loading</p>
						) : (
							functionSliders &&
							functionSliders
								.sort((a, b) => (a.subMenuId > b.subMenuId ? 1 : -1))
								.map((item, i) => (
									<div className="row d-flex " key={i}>
										<div className="col-md-4 image-block">
											{item.pictureUrl !== null ? (
												<img key={i} src={BASE_URL + item.pictureUrl} alt="" />
											) : (
												<img key={i} src={rectangle} alt="" />
											)}
										</div>

										<div className="col-md-8 d-flex align-items-center">
											<div className="content">
												<h2 className="">{item.header}</h2>
												{/* <p className="font-weight-bold ">{item.title}</p> */}
												<p
													className=""
													dangerouslySetInnerHTML={{
														__html: item.description,
													}}
												/>
											</div>
										</div>
									</div>
								))
						)}
					</Slider>
				</div>

				<div className="pt-3 pb-3">
					{/*---------------- submenu tabbed section --------------------
					# admin: MenuSubmenuMapItem + MenuSubmenuMapItemListItem
					--------------------------------------------------------*/}
					<SubMenuItemList menuId={id} />
				</div>
			</section>

			{/* ------- Consulting overview section ------------------ */}
			{id === 4 ? (
				<div>
					<Element style={{display: "none"}}>
						{overviewConsult ? (
							<div>
								<div
									ref={textRef}
									id="anchor"
									className="container-fluid element"
								>
									<div id="overview" className="overview">
										<div className="row">
											<div className="col-md-12">
												<div className="overview-top text-center">
													<h2 className="text-danger font-weight-bold">
														Overview ??
													</h2>
													{/* <h4 className="">{props.subtitle}</h4> */}
												</div>
											</div>
										</div>

										<div className="overview-bottom">
											<div className="overview-bottom-top-section pt-5 pb-5">
												<div className="row">
													{overviewConsult.map((Item, index) => (
														<div className="col-md-6 pl-5 pr-5" key={index}>
															<div className="list-describe pb-4">
																<ul>
																	<h5 className="pt-3 pb-3">{Item.title}</h5>
																	<li
																		dangerouslySetInnerHTML={{
																			__html: Item.description,
																		}}
																	/>
																	{/* <li>{Item.description}</li> */}
																</ul>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							""
						)}
					</Element>

					{BusinessPrincipleDescriptor}

					<section
						className="Consultation-section"
						name="Consulting"
						id="Consulting"
					>
						<Consultation
							title={homepageDetails.consultingTitle}
							subTitle={homepageDetails.consultingSubTitle}
							data={homepageDetails.homeConsultationTopics}
							onPage={true}
						/>
					</section>

					<div className="consulting-price-section-container">
						<div className="row">
							<div className="col text-center">
								<div className="section-title">
									Consultation tailored to your needs
								</div>

								<div class="container consulting-help-section">
									<strong>
										Select your desired consultation type and business type
										below to learn more
									</strong>
								</div>
							</div>
						</div>

						<div className="navbar-right-course">
							<Element name="consulting-subSection">
								<ul class="nav justify-content-center right-course training-tabs">
									<li
										className={`nav-item ${
											consulting === 1 ? "active-right-course" : ""
										}`}
										id="hourly_solution"
									>
										<a className="nav-link" onClick={() => setConsulting(1)}>
											Hourly Support
										</a>
									</li>
									<li
										className={`nav-item ${
											consulting === 2 ? "active-right-course" : ""
										}`}
										id="solution_discover"
									>
										<a className="nav-link" onClick={() => setConsulting(2)}>
											Solutions Discovery
										</a>
									</li>
									<li
										className={`nav-item ${
											consulting === 3 ? "active-right-course" : ""
										}`}
										id="access_retainer"
									>
										<a className="nav-link" onClick={() => setConsulting(3)}>
											Concierge
										</a>
									</li>
								</ul>
							</Element>
						</div>

						{consulting === 1 && (
							<div className="consulting-price-section">
								<ul class="nav justify-content-center">
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyType === "enterprenure" ? "active" : ""
											}`}
											id="enterprenure"
											onClick={() => {
												setConsult(1);
												setCompanyType("enterprenure");
											}}
										>
											Entrepreneur
										</a>
									</li>
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyType === "startup" ? "active" : ""
											}`}
											id="startup"
											onClick={() => {
												setConsult(2);
												setCompanyType("startup");
											}}
										>
											Startup
										</a>
									</li>
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyType === "business" ? "active" : ""
											}`}
											id="business"
											onClick={() => {
												setConsult(3);
												setCompanyType("business");
											}}
										>
											Business
										</a>
									</li>
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyType === "enterprise" ? "active" : ""
											}`}
											id="enterprise"
											onClick={() => {
												setConsult(4);
												setCompanyType("enterprise");
											}}
										>
											Enterprise
										</a>
									</li>
								</ul>

								<HourlyConsult consult={consult} hourly={hourly} />
							</div>
						)}

						{consulting === 2 && (
							<div className="consulting-price-section">
								<ul class="nav justify-content-center">
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyTypeDaily === "enterprenure_daily"
													? "active"
													: ""
											}`}
											id="enterprenure_daily"
											onClick={() => {
												setConsultDaily(1);
												setCompanyTypeDaily("enterprenure_daily");
											}}
										>
											Entrepreneur
										</a>
									</li>
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyTypeDaily === "startup_daily" ? "active" : ""
											}`}
											id="startup_daily"
											onClick={() => {
												setConsultDaily(2);
												setCompanyTypeDaily("startup_daily");
											}}
										>
											Startup
										</a>
									</li>
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyTypeDaily === "business_daily" ? "active" : ""
											}`}
											id="business_daily"
											onClick={() => {
												setConsultDaily(3);
												setCompanyTypeDaily("business_daily");
											}}
										>
											Business
										</a>
									</li>
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyTypeDaily === "enterprise_daily" ? "active" : ""
											}`}
											id="enterprise_daily"
											onClick={() => {
												setConsultDaily(4);
												setCompanyTypeDaily("enterprise_daily");
											}}
										>
											Enterprise
										</a>
									</li>
								</ul>
								<DailyConsult consultDaily={consultDaily} daily={daily} />
							</div>
						)}

						{consulting === 3 && (
							<div className="consulting-price-section">
								<ul class="nav justify-content-center">
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyTypeYearly === "enterprenure_yearly"
													? "active"
													: ""
											}`}
											id="enterprenure_yearly"
											onClick={() => {
												setConsultYearly(1);
												setCompanyTypeYearly("enterprenure_yearly");
											}}
										>
											Entrepreneur
										</a>
									</li>
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyTypeYearly === "startup_yearly" ? "active" : ""
											}`}
											id="startup_yearly"
											onClick={() => {
												setConsultYearly(2);
												setCompanyTypeYearly("startup_yearly");
											}}
										>
											Startup
										</a>
									</li>
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyTypeYearly === "business_yearly" ? "active" : ""
											}`}
											id="business_yearly"
											onClick={() => {
												setConsultYearly(3);
												setCompanyTypeYearly("business_yearly");
											}}
										>
											Business
										</a>
									</li>
									<li class="nav-item">
										<a
											className={`nav-link ${
												companyTypeYearly === "enterprise_yearly"
													? "active"
													: ""
											}`}
											id="enterprise_yearly"
											onClick={() => {
												setConsultYearly(4);
												setCompanyTypeYearly("enterprise_yearly");
											}}
										>
											Enterprise
										</a>
									</li>
								</ul>

								<YearlyConsult consultYearly={consultYearly} yearly={yearly} />
							</div>
						)}
					</div>
				</div>
			) : (
				<div>
					{/* ------- Training overview section ------------------ */}
					{id === 5 ? (
						<>
							{/* {TrainingOverview} */}
							{/*------ On-demand Courses & Live Virtua Classes -------*/}
							{candidate &&
								candidate.map((candidatesItem, index) =>
									index % 2 === 0 ? (
										<Element name={candidatesItem.title} key={index}>
											<div className="uniqueSolutionDetail-even">
												<div className="container">
													<div className="row">
														<div
															className="col-md-6 d-flex align-items-center "
															key={Math.random()}
														>
															<div className="content">
																<p className="section-heading-large">
																	{candidatesItem.title}
																</p>
																<div className="list-describe">
																	<p
																		dangerouslySetInnerHTML={{
																			__html: candidatesItem.description,
																		}}
																	/>
																</div>
																<Link
																	to={{
																		pathname: `/course/list`,
																	}}
																	className="card-link btn btn-primary"
																>
																	Explore Courses
																</Link>
															</div>
														</div>
														<div
															className="col-md-6 d-flex align-items-center"
															key={Math.random()}
														>
															<img
																style={{width: "100%", height: "100%"}}
																src={BASE_URL + candidatesItem.pictureUrl}
																alt=""
															/>
														</div>
													</div>
												</div>
											</div>
										</Element>
									) : (
										<Element
											name={candidatesItem.title}
											id="live-virtual-classes"
										>
											<div className="uniqueSolutionDetail-odd">
												<div className="container">
													<div className="row">
														<div
															className="col-md-6 d-flex align-items-center"
															key={Math.random()}
														>
															<img
																style={{width: "100%", height: "100%"}}
																src={BASE_URL + candidatesItem.pictureUrl}
																alt=""
															/>
														</div>
														<div
															className="col-md-6 d-flex align-items-center "
															key={Math.random()}
														>
															<div className="content mt-3">
																<p className="section-heading-large">
																	{candidatesItem.title}
																</p>
																<div className="list-describe">
																	<p
																		dangerouslySetInnerHTML={{
																			__html: candidatesItem.description,
																		}}
																	/>
																</div>
																<Link
																	to={{
																		pathname: `/class/list`,
																	}}
																	className="card-link btn btn-primary"
																>
																	Explore Classes
																</Link>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Element>
									)
								)}

							<div className="container-fluid">
								<div id="work" className="work">
									<div className="row">
										<div className="col-md-12">
											<div className="work-top text-center">
												<h2 className="font-weight-bold">
													HOW COURSES WORK?
												</h2>
												{/* <h4 className="">{props.subtitle}</h4> */}
											</div>
										</div>
									</div>

									<div className="work-bottom">
										<div className="container">
											<div className="work-bottom-top-section pt-5 pb-5">
												
												<div className={"featureContentList"}>
												<h4 p-2>
												On-demand Courses 
												</h4>
												<div className="d-flex justify-content-start align-items-center py-2"><div className="icon"><FaRegCheckCircle /></div>
                                                <h6 className="m-0 ps-3">Trainees discover courses on our Training page.</h6>
                                               </div>
											   <div className="d-flex justify-content-start align-items-center py-2"><div className="icon"><FaRegCheckCircle /></div>
                                                <h6 className="m-0 ps-3">They register and pay for selected courses through the website.</h6>
                                               </div>
											   <div className="d-flex justify-content-start align-items-center py-2"><div className="icon"><FaRegCheckCircle /></div>
                                                <h6 className="m-0 ps-3">Trainees receive login credentials to access the courses. </h6>
                                               </div>
											   <div className="d-flex justify-content-start align-items-center py-2"><div className="icon"><FaRegCheckCircle /></div>
                                                <h6 className="m-0 ps-3">They start the course immediately or at their convenience. </h6>
                                               </div>
											 
				                               </div>
											
												<div className={"featureContentList"}>
												<h4 className={"different"} p-2>
												Live Virtual Classes
												</h4>
												<div className="d-flex justify-content-start align-items-center py-2"><div className="icon"><FaRegCheckCircle /></div>
                                                <h6 className="m-0 ps-3">Trainees discover live virtual classes on our Training page.</h6>
                                               </div>
											   <div className="d-flex justify-content-start align-items-center py-2"><div className="icon"><FaRegCheckCircle /></div>
                                                <h6 className="m-0 ps-3">They register and pay for selected classes through the website.</h6>
                                               </div>
											   <div className="d-flex justify-content-start align-items-center py-2"><div className="icon"><FaRegCheckCircle /></div>
                                                <h6 className="m-0 ps-3">We send available dates for Live Virtual classes post-registration.</h6>
                                               </div>
											   <div className="d-flex justify-content-start align-items-center py-2"><div className="icon"><FaRegCheckCircle /></div>
                                                <h6 className="m-0 ps-3">Trainees join scheduled Live Virtual classes via webinar software.</h6>
                                               </div>
											 
				                               </div>

											
												<img
													className="w-100"
													src={methodTraining}
													alt="method"
												/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-12">
											<div className="work-top text-center">
												<h2 className="font-weight-bold">
													Tell us about the training you need
												</h2>
											</div>
										</div>
									</div>
									{/* <Training menuId={id} visibility={false} /> */}
									<TrainingRequestForm />
								</div>
							</div>
						</>
					) : (
						""
					)}
				</div>
			)}

			<div className="container-fluid">
				{/* -------- Unique solution ------------------------- */}
				<RenderUniqueSolution menuId={id} />
				<RenderPersonalizedService menuId={id} />
				<RenderModernTech menuId={id} />
				{/* <p>-------- Menu Section [{id}](start) ------------</p> */}
				<RenderMenuSection menuId={id} serviceId={props.location.state} />
				{/* <p>-------- SolutionSpecificSection ------------</p> */}
				<SolutionSpecificSections menuId={id} />
				{/* <p>-------- RenderServiceExtras ------------</p> */}
				<RenderServiceExtras menuId={id} />
				{/* <p>-------- RenderTechnologyExtras ------------</p> */}
				<RenderTechnologyExtras menuId={id} />
				{/* <p>-------- six-tech-types ------------</p> */}
				{location.state && id === 3 && (
					<div className="six-tech-types-container pt-5">
						{techSect
							? techSect.map((item, index) => {
									if (index % 2 === 0) {
										return (
											<Element name={item.name} key={index}>
												<div className="container">
													<div className="row ">
														<div className="container pt-5 pb-5">
															<div className="techStack-detail text-left">
																<div className="d-flex mt-2">
																	<img
																		src={BASE_URL + item.pictureUrl}
																		className="img-fluid mb-2 mr-4"
																		alt="img"
																	/>
																	<div className="flex-grow-1">
																		<h5 className="text-uppercase">
																			{item.name}
																		</h5>
																		<p className="text-left">
																			<span
																				dangerouslySetInnerHTML={{
																					__html: item.description,
																				}}
																			/>
																		</p>

																		<div className="d-flex flex-row justify-content-start flex-wrap">
																			{item.techStacks.map((stack, index) => (
																				<div className="pl-0 pr-1" key={index}>
																					<p className="tech-names">
																						{stack.name}
																					</p>
																				</div>
																			))}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Element>
										);
									} else {
										return (
											<Element name={item.name}>
												<div className="container">
													<div className="row ">
														<div className="container pt-5 pb-5">
															<div className="techStack-detail text-right">
																<div className="d-flex mt-2">
																	<div className="flex-grow-1">
																		<h5 className="text-uppercase">
																			{item.name}
																		</h5>
																		<p className="text-left">
																			<span
																				dangerouslySetInnerHTML={{
																					__html: item.description,
																				}}
																			/>
																		</p>

																		<div className="d-flex flex-row justify-content-end flex-wrap">
																			{item.techStacks.map((stack, index) => (
																				<div className="pl-0 pr-1" key={index}>
																					<p className="tech-names">
																						{stack.name}
																					</p>
																				</div>
																			))}
																		</div>
																	</div>
																	<img
																		src={BASE_URL + item.pictureUrl}
																		className="img-fluid mb-2 ml-4"
																		alt="img"
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Element>
										);
									}
							  })
							: ""}
					</div>
				)}
				<ContactRequest
					menuId={id}
					name={name}
					menuName={menuName}
					title={contactUsTitle}
					ctaLeft={ctaLeft}
					ctaRight={ctaRight}
					popup={props.location.state?.position ? true : false}
				/>
			</div>
		</section>
	);
};

export default InnovativeSolution;
