import React from "react";

const FileUploader = ({onFileSelectError, onFileSelectSuccess}) => {
	const handleFileInput = (e) => {
		const file = e.target.files[0];
		onFileSelectSuccess(file);
		// if (file.size > 8192)
		// 	onFileSelectError({error: "File size cannot exceed more than 4MB"});
		// else onFileSelectSuccess(file);
	};

	return (
		<div className="file-uploader">
			<input type="file" onChange={handleFileInput} />
		</div>
	);
};

export default FileUploader;
