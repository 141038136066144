import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {BASE_API_URL, BASE_URL} from "../../../../../../Constants";
import UploadService from "./UploadFilesService";
import "bootstrap/dist/css/bootstrap.min.css";
import http from "./HttpCommon";

export default class UploadFiles extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedFiles: undefined,
			progressInfos: [],
			message: [],
			fileInfos: [],
			fileInfosErr: false,
			ctaCategoryId: props.id,
			loading: false,
		};

		this.selectFile = this.selectFile.bind(this);
		this.uploadFiles = this.uploadFiles.bind(this);
		this.upload = this.upload.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
	}

	selectFile(event) {
		console.log((event.target.files[0].size / (1024 * 1024)).toFixed(2));
		(event.target.files[0].size / (1024 * 1024)).toFixed(2) <= 3
			? this.setState({
					progressInfos: [],
					selectedFiles: event.target.files,
			  })
			: this.setState({
					progressInfos: [],
					selectedFiles: undefined,
			  });

		if ((event.target.files[0].size / (1024 * 1024)).toFixed(2) >= 3) {
			this.setState((prev) => {
				let nextMessage = ["Sorry, you can upload any file up to 3 mb"];
				return {
					message: nextMessage,
				};
			});
		} else {
			this.setState((prev) => {
				let nextMessage = ["No File Uploaded"];
				return {
					message: nextMessage,
				};
			});
		}
	}

	uploadFiles() {
		const selectedFiles = this.state.selectedFiles;

		let _progressInfos = [];

		for (let i = 0; i < selectedFiles.length; i++) {
			_progressInfos.push({percentage: 0, fileName: selectedFiles[i].name});
		}

		this.setState(
			{
				progressInfos: _progressInfos,
				message: [],
			},
			() => {
				for (let i = 0; i < selectedFiles.length; i++) {
					this.upload(i, selectedFiles[i]);
				}
			}
		);
	}

	upload(idx, file) {
		let _progressInfos = [...this.state.progressInfos];
		let id = this.state.ctaCategoryId;
		this.setState({loading: true});

		UploadService.upload(file, id, (event) => {
			_progressInfos[idx].percentage = Math.round(
				(100 * event.loaded) / event.total
			);
			this.setState({
				_progressInfos,
			});
		})
			.then((response) => {
				this.setState({loading: false});
				console.log(response);

				let reqErr = "";
				if (typeof response === "number") {
					if (response.toString().includes("4")) {
						reqErr = "Client side error";
					} else if (response.toString().includes("5")) {
						reqErr = "Server side error";
					} else {
						reqErr = "Error Happen";
					}
				} else {
					this.setState((prev) => {
						let nextMessage = [
							...prev.message,
							"Uploaded the file successfully: " + file.name,
						];
						return {
							message: nextMessage,
						};
					});
				}

				// typeof response !== "number" ?
				// this.setState((prev) => {
				//     let nextMessage = [...prev.message, "Uploaded the file successfully: " + file.name];
				//     return {
				//         message: nextMessage
				//     };
				// }) :
				// this.setState((prev) => {
				//     let nextMessage = [...prev.message, reqErr];
				//     return {
				//         message: nextMessage
				//     };
				// })

				if (response !== "number") {
					http.get(`CtaDocument/GetCtaCategoryDocuments/${id}`).then((res) => {
						console.log(res);

						if (res.data.data) {
							this.setState({
								fileInfos: res.data.data,
							});
						}
					});
				}
			})
			//   .then((files) => {
			//     console.log(files);
			//     this.setState({
			//       fileInfos: files,
			//     });
			//   })

			.catch((errStatus) => {
				console.log(errStatus);
				_progressInfos[idx].percentage = 0;
				this.setState((prev) => {
					let nextMessage = [
						...prev.message,
						"Could not upload the file: " + file.name,
					];
					return {
						progressInfos: _progressInfos,
						message: nextMessage,
					};
				});
			});
	}

	deleteFile(e) {
		console.log(e.target.parentNode.id);
		console.log(
			"deleteId: ",
			Number(e.target.parentNode.id),
			"ctaCategoryId: ",
			this.state.ctaCategoryId
		);
		UploadService.deleteFile(
			Number(e.target.parentNode.id),
			this.state.ctaCategoryId
		).then((response) => {
			console.log(response);
			this.setState({
				fileInfos: response.data.data,
			});
		});
	}

	/*    componentDidMount() {
        UploadService.getFiles().then((response) => {
            this.setState({
                fileInfos: response.data,
            });
        });
}*/

	render() {
		const {selectedFiles, progressInfos, message, fileInfos} = this.state;
		console.log(
			"selectedFiles: ",
			selectedFiles,
			"progressInfos: ",
			progressInfos,
			"message: ",
			message,
			"fileInfos: ",
			fileInfos
		);

		return (
			<div>
				{/* {progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                        <div className="mb-2" key={index}>
                            <span>{progressInfo.fileName}</span>
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-info"
                                    role="progressbar"
                                    aria-valuenow={progressInfo.percentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: progressInfo.percentage + "%" }}
                                >
                                    {progressInfo.percentage}%</div>
                            </div>
                        </div>
                    ))} */}
				{this.state.loading ? (
					<div class="overlay" style={{zIndex: "999"}}>
						<div class="overlay__wrapper">
							<div class="overlay__spinner">
								<div class="spinner-border text-secondary" role="status"></div>
								<div className="loading-msg"> Please Wait...!</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}

				<div className="row my-3">
					<div className="col-md-8 col-sm-12">
						<label className="p-0 font-weight-bold text-secondary">
							Please upload related documents/files
						</label>

						<div class="input-group mb-3">
							<input type="file" multiple onChange={this.selectFile} />
							<div class="input-group-append">
								<button
									class="btn btn-primary upload-btn"
									type="button"
									disabled={!selectedFiles}
									onClick={this.uploadFiles}
								>
									Upload
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="card mt-2">
					<div className="card-header font-weight-bold text-secondary">
						List of Files
					</div>
					{fileInfos &&
						fileInfos.map((file, index) => (
							<div
								className="card-header d-flex justify-content-between"
								key={index}
							>
								<div>
									<span>({index + 1}) &nbsp;</span>
									<a href={BASE_URL + file.fileUrl}>
										{file.fileUrl && file.fileUrl.split("/")[2]}
									</a>
								</div>
								<FontAwesomeIcon
									icon={faTrashAlt}
									id={file.id}
									onClick={(e) => this.deleteFile(e)}
								/>
							</div>
						))}
				</div>
			</div>
		);
	}
}
