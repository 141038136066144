import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FaArrowLeft, FaMapMarkerAlt } from 'react-icons/fa';
import { BusinessCenter, Work } from '@material-ui/icons';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import './AllJobs.css'; // Import your CSS file

const AllJobs = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const sanitizeHTML = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || '';
    };

    const [jobPosts, setJobPosts] = useState([]);

   

    useEffect(() => {
        setIsLoading(true);
        fetch("https://api.bestpracticify.co/api/BpJobInformation/searchAll")
            .then((response) => response.json())
            .then((data) => {
                const filteredPosts = data?.data?.item1.filter(job => job.jobStatus === true);
                setJobPosts(filteredPosts);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching job posts:", error);
                setIsLoading(false);
            });
    }, []);
    if (isLoading) {
        // Display a loading indicator while fetching data
        return <p>Loading...</p>;
    }
    const handleGoBack = () => {
        history.goBack();
    };
    return (
        <div className='container mt-3 '>
            <button className="for-font-size text-decoration-none" onClick={handleGoBack} style={{ backgroundColor: 'transparent', border: 'none' }}>
                <FaArrowLeft /> Go Back
            </button>

            {/* <Link target=""
                                        className="text-white text-decoration-none apply-now-btn-dsgn" to={`/Career`}><FaArrowLeft /> Go Back</Link> */}
            {/* <Link target=""
                className="ml-3 for-font-size text-decoration-none " to={`/careers`}><FaArrowLeft /> Go Back
            </Link> */}


            <div className='container container-alljobs mt-5'>

                {jobPosts.map((post, index) => (
                    <Link to={`/jobpostdetails/${post.id}`} className="card-designer no-decoration" key={post.id}>
                        <div className="card">
                            <div className="category">
                                <p>
                                    {post.categoryName && (
                                        <span className="category-bg">
                                            {post.categoryName}
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className="pl-1 for-margin">
                                <h6 className="for-margin">{post.jobTitle}</h6>
                            </div>


                                  {post.numberOfVacancy && (
                                            <p
                                                className="for-margin"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#0096FF",
                                                }}
                                            >
                                                <Work
                                                    style={{ marginRight: "0.1rem", height: "1.25rem" }}
                                                />
                                                <span
                                                    className="use-for-m-top"
                                                    style={{ marginTop: "0rem" }}
                                                >
                                                    Available Position(s): {post.numberOfVacancy}
                                                </span>
                                            </p>
                                        )}

                            {post.jobType && (
                                <p
                                    className="for-margin"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#0096FF",
                                    }}
                                >
                                    <BusinessCenter
                                        style={{ marginRight: "0.1rem", height: "1.25rem" }}
                                    />
                                    <span
                                        className="use-for-m-top"
                                        style={{ marginTop: "0.2rem" }}
                                    >
                                        {post.jobType}
                                    </span>
                                </p>
                            )}

                            {post.careerLevel && (
                                <p
                                    className="for-margin"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#0096FF",
                                    }}
                                >
                                    <Work
                                        style={{ marginRight: "0.1rem", height: "1.25rem" }}
                                    />
                                    <span
                                        className="use-for-m-top"
                                        style={{ marginTop: "0rem" }}
                                    >
                                        {post.careerLevel}
                                    </span>
                                </p>
                            )}

                            {post.salaryRange && (
                                <p
                                    className="for-margin"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#0096FF",
                                    }}
                                >
                                    <AttachMoneyIcon
                                        style={{ marginRight: "0.1rem", height: "1.25rem" }}
                                    />
                                    <span
                                        className="use-for-m-top"
                                        style={{ marginTop: "0rem" }}
                                    >
                                        {post.salaryRange}
                                    </span>
                                </p>
                            )}

                            {post.location && (
                                <p
                                    className="for-margin"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#0096FF",
                                    }}
                                >
                                    <FaMapMarkerAlt
                                        style={{
                                            marginRight: "0.6rem",
                                            height: "1rem",
                                            marginLeft: "0.1rem",
                                        }}
                                    />
                                    <span
                                        className="use-for-m-top"
                                        style={{ marginTop: "0rem" }}
                                    >
                                        {post.location}
                                    </span>
                                </p>
                            )}

                            <p className="paragraph-title p-1">
                                {sanitizeHTML(post.jobDescription.slice(0, 150))}
                                {post.jobDescription.length > 500 && (
                                    <span className="details-text">...details</span>
                                )}
                            </p>
                        </div>

                    </Link>
                ))}
            </div>
        </div>
    );
};

export default AllJobs;
