import React from "react";
import {Element} from "react-scroll";
import {BASE_URL} from "../../../Constants";
import LoadingFullWidth from "../../Loading/LoadingFullWidth";
import "./UniqueSolution.css";
// import "./UniqueSolutionResponsive.css";

const UniqueSolution = (props) => {
	// //console.log(window.location.pathname);

	const [mobileView, setMobileView] = React.useState(false);
	let [pathName, setPathName] = React.useState();
	let uniqueSolutionDetail = props.data;

	React.useEffect(() => {
		setPathName(window.location.pathname);
	}, [pathName]);

	React.useEffect(() => {
		const mql = window.matchMedia("(max-width: 991px)");

		if (mql.matches) {
			setMobileView(mql.matches);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		mql.addEventListener("change", (e) => {
			// //console.log(e);
			setMobileView(e.matches);
		});
	}, []);

	return (
		<section className="bp-homepage-uniqueSolution-section">
			{props.loading ? (
				<LoadingFullWidth height="350px" />
			) : (
				<div className="uniqueSolutionDetail">
					<div className="container">
						{/* <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-center">{props.title}</h3>
                        </div>
                    </div> */}

						{mobileView ? (
							<Element name="unique">
								<div className="d-flex flex-column">
									{uniqueSolutionDetail &&
										uniqueSolutionDetail.map(
											(uniqueSolutionDetailsItem, index) => (
												<>
													<div
														className="d-flex align-items-center"
														key={index}
													>
														<img
															style={{width: "100%", height: "100%"}}
															src={
																BASE_URL + uniqueSolutionDetailsItem.pictureUrl
															}
															alt=""
														/>
													</div>
													<div
														className="d-flex align-items-center"
														key={Math.random()}
													>
														<div className="content">
															<p className="section-heading-large">
																{uniqueSolutionDetailsItem.title}
															</p>
															
															<div
															className="list-describe"
															dangerouslySetInnerHTML={{
																__html: uniqueSolutionDetailsItem.description,
															}}
														    />
														</div>
													</div>
												</>
											)
										)}
								</div>
							</Element>
						) : (
							<Element name="unique">
								<div className="row">
									{uniqueSolutionDetail &&
										uniqueSolutionDetail.map(
											(uniqueSolutionDetailsItem, index) =>
												index % 2 === 0 ? (
													<>
														<div
															className="col-md-6 d-flex align-items-center"
															key={index}
														>
															<div className="content">
																<p className="section-heading-large">
																	{uniqueSolutionDetailsItem.title}
																</p>
																<div
															className="list-describe"
															dangerouslySetInnerHTML={{
																__html: uniqueSolutionDetailsItem.description,
															}}
														    />
															</div>
														</div>
														<div
															className="col-md-6 d-flex align-items-center"
															key={index}
														>
															<img
																style={{width: "100%", height: "100%"}}
																src={
																	BASE_URL +
																	uniqueSolutionDetailsItem.pictureUrl
																}
																alt=""
															/>
														</div>
													</>
												) : (
													<>
														<div
															className="col-md-6 d-flex align-items-center"
															key={index}
														>
															<img
																style={{width: "100%", height: "100%"}}
																src={
																	BASE_URL +
																	uniqueSolutionDetailsItem.pictureUrl
																}
																alt=""
															/>
														</div>
														<div
															className="col-md-6 d-flex align-items-center"
															key={index}
														>
															<div className="content">
																<p className="section-heading-large">
																	{uniqueSolutionDetailsItem.title}
																</p>
																<div className="list-describe">
																	{uniqueSolutionDetailsItem.description.replace(
																		/<\/?p[^>]*>/g,
																		""
																	)}
																</div>
															</div>
														</div>
													</>
												)
										)}
								</div>
							</Element>
						)}

						{/* <Element name="unique">
              <div className="row">
                {uniqueSolutionDetail &&
                  uniqueSolutionDetail.map((uniqueSolutionDetailsItem, index) =>
                    index % 2 === 0 ? (
                      <>
                        <div
                          className="col-md-6 d-flex align-items-center"
                          key={Math.random()}
                        >
                          <div className="content">
                            <p className="section-heading-large">
                              {uniqueSolutionDetailsItem.title}
                            </p>
                            <div className="list-describe">
                              {uniqueSolutionDetailsItem.description.replace(
                                /<\/?p[^>]*>/g,
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-6 d-flex align-items-center"
                          key={Math.random()}
                        >
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={BASE_URL + uniqueSolutionDetailsItem.pictureUrl}
                            alt=""
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="col-md-6 d-flex align-items-center"
                          key={Math.random()}
                        >
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={BASE_URL + uniqueSolutionDetailsItem.pictureUrl}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-md-6 d-flex align-items-center"
                          key={Math.random()}
                        >
                          <div className="content">
                            <p className="section-heading-large">
                              {uniqueSolutionDetailsItem.title}
                            </p>
                            <div className="list-describe">
                              {uniqueSolutionDetailsItem.description.replace(
                                /<\/?p[^>]*>/g,
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )}
              </div>
            </Element> */}
					</div>
				</div>
			)}
		</section>
	);
};

export default UniqueSolution;
