import React, {useState} from "react";
import NewConsulntRegistration from "../../../../Authentication/NewConsulntRegistration/NewConsulntRegistration";
import {isFromPaymentCheck} from "../../../../Helpers/Helpers";
import "../ConsultStyle.css";
import ConsultationHourlyPriceItem from "./ConsultationHourlyPriceItem";

const ConsultationHourly = (props) => {
	//came data with props
	const [startupHourly, setStartupHourly] = useState();
	const [enterprenureHourly, setEnterprenureHourly] = useState();
	const [bussinessHourly, setBussinessHourly] = useState();
	const [enterpriseHourly, setEnterpriseHourly] = useState();
	const [signup, setSignup] = useState(false);
	const [createAccount, setCreateAccount] = useState(false);

	const openRegiserForm = () => {
		setSignup(true);
	};

	const closeAllCondition = () => {
		setSignup(false);
		setCreateAccount(false);
	};

	const {hourly} = props;

	let hourly_startup = [],
		price_startup = "",
		name_startUp = "",
		validity_startup = "",
		hourly_enterprenure = [],
		price_enterprenure = "",
		name_enterprenure = "",
		validity_enterprenure = "",
		hourly_bussiness = [],
		price_bussiness = "",
		name_bussiness = "",
		validity_bussiness = "",
		hourly_enterprise = [],
		validity_enterprise = "",
		price_enterprise = "",
		name_enterprise = "";

	if (hourly) {
		hourly.map((item, index) => {
			if (item.companyTypeId === 1) {
				hourly_enterprenure.push(item);
				if (item.ctaHourId === 1) {
					price_enterprenure = item.rate;
					name_enterprenure = item.companyTypeName;
					validity_enterprenure = item.validity;
				}
			} else if (item.companyTypeId === 2) {
				hourly_startup.push(item);
				if (item.ctaHourId === 1) {
					price_startup = item.rate;
					name_startUp = item.companyTypeName;
					validity_startup = item.validity;
				}
			} else if (item.companyTypeId === 3) {
				hourly_bussiness.push(item);
				if (item.ctaHourId === 1) {
					price_bussiness = item.rate;
					name_bussiness = item.companyTypeName;
					validity_bussiness = item.validity;
				}
			} else if (item.companyTypeId === 4) {
				hourly_enterprise.push(item);
				if (item.ctaHourId === 1) {
					price_enterprise = item.rate;
					name_enterprise = item.companyTypeName;
					validity_enterprise = item.validity;
				}
			}
		});
	}

	function set_startup_hourly(e) {
		console.log(e.target.value);
		setStartupHourly(e.target.value);
	}

	function set_enterprenure_hourly(e) {
		console.log(e.target.value);
		setEnterprenureHourly(e.target.value);
	}

	function set_bussiness_hourly(e) {
		console.log(e.target.value);
		setBussinessHourly(e.target.value);
	}

	function set_enterprise_hourly(e) {
		console.log(e.target.value);
		setEnterpriseHourly(e.target.value);
	}

	let startup_price, startup_validity;
	if (startupHourly) {
		startup_price = hourly_startup.find(
			(obj) => obj.ctaHourName === startupHourly
		);
		startup_validity = startup_price.validity;
		startup_price = startup_price.rate;
		console.log(startup_price);
	}

	let enterprenure_price, enterprenure_validity;
	if (enterprenureHourly) {
		enterprenure_price = hourly_enterprenure.find(
			(obj) => obj.ctaHourName === enterprenureHourly
		);
		enterprenure_validity = enterprenure_price.validity;
		enterprenure_price = enterprenure_price.rate;
		console.log(enterprenure_price);
	}

	let bussiness_price, bussiness_validity;
	if (bussinessHourly) {
		bussiness_price = hourly_bussiness.find(
			(obj) => obj.ctaHourName === bussinessHourly
		);
		bussiness_validity = bussiness_price.validity;
		bussiness_price = bussiness_price.rate;
		console.log(bussiness_price);
	}

	let enterprise_price, enterprise_validity;
	if (enterpriseHourly) {
		enterprise_price = hourly_enterprise.find(
			(obj) => obj.ctaHourName === enterpriseHourly
		);
		enterprise_validity = enterprise_price.validity;
		enterprise_price = enterprise_price.rate;
		console.log(enterprise_price);
	}

	return (
		<div className="">
			<div
				className="modal fade "
				tabIndex="-1"
				role="document"
				aria-labelledby="myLargeModalLabel"
				aria-hidden="true"
				id="purchase_modal"
			>
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content">
						<div className="modal-header mb-3">
							<div className="container w-100">
								<div className="row modal-header-row ">
									<div className="d-flex justify-content-end w-100">
										<h2
											class="modal-title text-center"
											id="exampleModalLongTitle"
										>
											{createAccount
												? "Sign In"
												: signup
												? "Create New Account"
												: "Sign In Or Sign Up"}
										</h2>
										<button
											type="button"
											className="close"
											data-toggle="modal"
											data-target="#purchase_modal"
											data-dismiss="modal"
											aria-label="Close"
											onClick={closeAllCondition}
										>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							{createAccount ? (
								<div className="text-center m-4">
									<h6 className="mb-3 text-success">
										Your account has been created successfully. Please check
										your email to complete the purchase.
									</h6>

									<a
										href="https://client.bestpracticify.co/signin?fromPayment=true"
										target="_blank"
										class="btn btn-primary my-2"
										rel="noreferrer"
										onClick={isFromPaymentCheck}
									>
										Sign In
									</a>

									{/* <div className="newToBP my-2">
                      <p className="text-center text-secondary bg-white"><span>New To Best Practicify?</span></p>
                      <hr />
                    </div>
                    <a onClick={ openRegiserForm } className="btn btn-primary my-2">Create Your Best Practicify Account</a> */}
								</div>
							) : (
								<>
									{!signup ? (
										<div className="text-center m-4">
											<h6 className="mb-3">
												To purchase the selected consultation, please sign in
												first.
											</h6>

											<a
												href="https://client.bestpracticify.co/signin?fromPayment=true"
												target="_blank"
												class="btn btn-primary my-2"
												rel="noreferrer"
												onClick={isFromPaymentCheck}
											>
												Sign In
											</a>

											<div className="newToBP my-2">
												<p className="text-center text-secondary bg-white">
													<span>New To Best Practicify?</span>
												</p>
												<hr />
											</div>
											<a
												onClick={openRegiserForm}
												className="btn btn-primary my-2"
											>
												Create Your Best Practicify Account
											</a>
										</div>
									) : (
										<NewConsulntRegistration
											setCreateAccount={setCreateAccount}
										></NewConsulntRegistration>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="container hourly mt-2">
				{/* <div className="row">
          <pre>{JSON.stringify(hourly, undefined, 2)}</pre>
        </div> */}
				<div className="row">
					<div className="col-lg-3 col-md-6 col-sm-6 col-xs-1 pl-2 pr-2 mt-2 mb-2">
						<ConsultationHourlyPriceItem
							title={name_enterprenure}
							textOne="On-demand, hourly consulting"
							textTwo="Tailored business technology"
							hourSelect={set_enterprenure_hourly}
							selectValue={hourly_enterprenure}
							price={enterprenure_price}
							defaultPrice={price_enterprenure}
							validity={enterprenure_validity}
							delautValidity={validity_enterprenure}
							startingPrice={79}
						/>
					</div>

					<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 pl-2 pr-2 mt-2 mb-2">
						<ConsultationHourlyPriceItem
							title={name_startUp}
							textOne="On-demand, hourly consulting"
							textTwo="Business-enabling technology"
							hourSelect={set_startup_hourly}
							selectValue={hourly_startup}
							price={startup_price}
							defaultPrice={price_startup}
							validity={startup_validity}
							delautValidity={validity_startup}
							startingPrice={79}
						/>
					</div>

					<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 pl-2 pr-2 mt-2 mb-2">
						<ConsultationHourlyPriceItem
							title={name_bussiness}
							textOne="On-demand, hourly consulting"
							textTwo="Tech solutions to support online businesses"
							hourSelect={set_bussiness_hourly}
							selectValue={hourly_bussiness}
							price={bussiness_price}
							defaultPrice={price_bussiness}
							validity={bussiness_validity}
							delautValidity={validity_bussiness}
							startingPrice={99}
						/>
					</div>

					<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 pl-2 pr-2 mt-2 mb-2">
						<ConsultationHourlyPriceItem
							title={name_enterprise}
							textOne="On-demand, hourly consulting"
							textTwo="Enterprise technology solutions"
							hourSelect={set_enterprise_hourly}
							selectValue={hourly_enterprise}
							price={enterprise_price}
							defaultPrice={price_enterprise}
							validity={enterprise_validity}
							delautValidity={validity_enterprise}
							startingPrice={119}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConsultationHourly;
