import React from "react";
import DashboardSliderSection from "../DashboardSlider/DashboardSliderSection";
import DataAnalysisSmallForm from "../DataAnalysicsSmallForm/DataAnalysicsSmallForm";
import DashboardMockup from "./DashboardMockup";

const UniversityDashboard = () => {

    const data = {
      link: "",
    };
    // https://app.powerbi.com/view?r=eyJrIjoiOWFkMTg2MDktN2UwMy00Nzc3LTgwOGEtNzY5ZWZlMzVlYzFiIiwidCI6ImFmNTU3MDBiLTMwY2QtNGRjYi05NGYxLTVmNjI0ZmVkZmRjOCIsImMiOjZ9

  return (
    <>
      <DashboardMockup data={data} />
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="content w-75">
            <h5 className="text-center" style={{ color : '#0096FF', fontSize: '25px'}}>Data Aanalysics Request</h5>  
            <DataAnalysisSmallForm />
          </div>
        </div>
      </div>
      <DashboardSliderSection />
    </>
  );
};

export default UniversityDashboard;
