/* eslint-disable no-const-assign */
import axios from "axios";
import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import {scroller} from "react-scroll";
import {BASE_API_URL} from "../../Constants";
// import Form from "../ConsultingForm/CategoryPages/CategoryPagesSolutionsForm/UserForm";
import CommonSection from "./commonSection/CommonSection";
import Consulting from "./Consulting/Consulting";
import "./Details.css";
import DetailsNav from "./DetailsNav/DetailsNav";
import "./DetailsResponsive.css";
import Overview from "./Overview/Overview";
import TechnologyParters from "./TechnologyPartners/TechnologyParters";



const Details = (props) => {
	// //console.log(props.location.state);
	// console.log('devAshadul', props);


	const location = useLocation();
	// console.log("location: ", location);
	// //console.log("location - match: ", props.match.params.sub_menu_id);
	//console.log("location - match: ", props.match);

	let id = 0;
	let sub_id = 0;
	let name = "";
	if (location.state) {
		id = location.state.id;
		sub_id = location.state.sub_id;
		name = location.state?.name;
	} else {
		// if user hit a url directly then this block will work
		switch (props.match.params.menu_id) {
			case "solutions":
				id = 1;
				break;
			case "services":
				id = 2;
				break;
			case "technology":
				id = 3;
				break;
			case "consulting":
				id = 4;
				break;
			case "training":
				id = 5;
				break;
			default:
				break;
		}

		switch (props.match.params.sub_menu_id) {
			case "data-technology":
				sub_id = 5;
				name = "Data & Technology";
				break;
			case "accounting-finance":
				sub_id = 6;
				name = "Accounting & Finance";
				break;
			case "ecommerce":
				sub_id = 8;
				name = "Ecommerce";
				break;
			case "application-development":
				sub_id = 9;
				name = "Application Development";
				break;
			default:
				break;
		}
	}

	const [info, setInfo] = useState();
	const [hourly, setHourly] = useState();
	const [daily, setDaily] = useState();
	const [yearly, setYearly] = useState();

	const callHourly = () => {
		axios.get(BASE_API_URL + `CtaPackageHourly`, {}).then(
			(res) => {
				// ////console.log(res);
				//console.log(res.data.data);

				if (res.data.data.length) {
					setHourly(res.data.data);
				}
				// if (res.data.data.length !== 0) {
				//   setInfo(res.data.data);
				// }
			},
			(error) => {
				//console.log(error);
			}
		);
	};

	const callDaily = () => {
		axios.get(BASE_API_URL + `CtaPackageDaily`, {}).then(
			(res) => {
				// ////console.log(res);
				//console.log(res.data.data);

				if (res.data.data.length) {
					setDaily(res.data.data);
				}
				// if (res.data.data.length !== 0) {
				//   setInfo(res.data.data);
				// }
			},
			(error) => {
				//console.log(error);
			}
		);
	};

	const callYearly = () => {
		axios.get(BASE_API_URL + `CtaPackageMonthlyYearly`, {}).then(
			(res) => {
				// ////console.log(res);
				//console.log(res.data.data);

				if (res.data.data.length) {
					setYearly(res.data.data);
				}
				// if (res.data.data.length !== 0) {
				//   setInfo(res.data.data);
				// }
			},
			(error) => {
				//console.log(error);
			}
		);
	};

	React.useEffect(() => {
		//console.log("menuId: " + sub_id, "faisal");
		axios
			.get(BASE_API_URL + `SubMenu/GetSubMenuPageDetailsById/${sub_id}`, {})
			.then(
				(res) => {
					////console.log(res);

					if (res.data.status) {
						setInfo(res.data.data);
					}
				},
				(error) => {
					//console.log(error);
				}
			);

		callHourly();
		callDaily();
		callYearly();
	}, [sub_id]);

	const data_all = info ? info.subMenuPageMenuItems : "";

	//console.log(data_all);
	//   0: {id: 0, name: "Overview", systemName: "Overview", displayOrder: 1}
	// 1: {id: 0, name: "Best Practices", systemName: "BestPractices", displayOrder: 2}
	// 2: {id: 1, name: "Solutions", systemName: "Solutions", displayOrder: 3}
	// 3: {id: 2, name: "Services", systemName: "Services", displayOrder: 4}
	// 4: {id: 3, name: "Technology", systemName: "Technology", displayOrder: 5}
	// 5: {id: 4, name: "Consulting", systemName: "Consulting", displayOrder: 6}
	// 6: {id: 5, name: "Training", systemName: "Training", displayOrder: 7}
	// length: 7

	React.useEffect(() => {
		if (data_all) {
			if (!location.data && name !== "") {
				if (id === 1) {
					scroller.scrollTo("Solutions", {
						duration: 100,
						smooth: true,
						offset: -180, // Scrolls to element + 50 pixels down the page
					});
				} else if (id === 2) {
					scroller.scrollTo("Services", {
						duration: 100,
						smooth: true,
						offset: -180, // Scrolls to element + 50 pixels down the page
					});
				} else if (id === 3) {
					scroller.scrollTo("Technology", {
						duration: 100,
						smooth: true,
						offset: -150, // Scrolls to element + 50 pixels down the page
					});
				} else if (id === 4) {
					scroller.scrollTo("Consulting", {
						duration: 100,
						smooth: true,
						offset: -150, // Scrolls to element + 50 pixels down the page
					});
				} else {
					scroller.scrollTo("Training", {
						duration: 100,
						smooth: true,
						offset: -150, // Scrolls to element + 50 pixels down the page
					});
				}
			}
		}
	});
	// if (data_all) setState(true);

	return (
		<section id="data-technology" style={{minHeight: "400px"}}>
			<DetailsNav menuId={id} pageTitle={name} routes={data_all} />
			<div className="details-content">
				{/* <div><pre>{JSON.stringify(info, null, 2) }</pre></div>; */}

				{data_all
					? data_all.map((data, index) => {
							if (data.systemName === "Overview") {
								return (
									<Overview
										menuId={id}
										overviewDetails={info.overViews}
										title={info.overViewTitle}
										subtitle={info.overViewSubtitle}
										background={info.overViewBackgroundPicture}
									/>
								);
							} else if (data.systemName === "BestPractices") {
								return (
									<div></div>

									// <div>
									//   <div className="bg-danger text-white text-center">
									//     Need to provide data "Best Practice"
									//   </div>
									//   <BestSolutionsPractices
									//     menuId={id}
									//     bestPracticeTitle={info.bestPracticeTitle}
									//     bestPracticeSubtitle={info.bestPracticeSubtitle}
									//     bestPracticeBackgroundPicture={
									//       info.bestPracticeBackgroundPicture
									//     }
									//     bestPracticeDetails={info.bestPractices}
									//     businessContexts={info.businessContexts}
									//     businessContextTitle={info.businessContextTitle}
									//     businessContextSubTitle={info.businessContextSubTitle}
									//     businessContextBackgroundPicture={
									//       info.businessContextBackgroundPicture
									//     }
									//   />
									// </div>
								);
							} else if (data.systemName === "Consulting") {
								let section_data = info.sections.find(
									(obj) => data.id === obj.menuId
								);
								return (
									<Consulting
										menuId={id}
										subMenuId={sub_id}
										consult={section_data}
										hourly={hourly}
										daily={daily}
										yearly={yearly}
										indexing={index}
									/>
								);
							} else if (data.systemName === "Technology") {
								let section_data = info.sections.find(
									(obj) => data.id === obj.menuId
								);
								return (
									<section>
										<TechnologyParters
											menuId={id}
											subMenuId={sub_id}
											techPartners={info.technologyPartners}
											imagelength={info.technologyPartners.length}
											name={data.systemName}
											indexing={index}
										/>
									</section>
								);
							} else {
								let section_data = info.sections.find(
									(obj) => data.id === obj.menuId
								);

								//console.log(section_data);
								return (
									<div>
										{/* <h4>
											---------- CommonSection (menuId: {id}) -- (SubmenuId:{" "}
											{sub_id})-------------
										</h4> */}
										<CommonSection
											menuId={id}
											subMenuId={sub_id}
											commonData={section_data}
											name={data.systemName}
											indexing={index}
											section="commonSection"
											location={props.match.url}
										/>
									</div>
								);
							}
					  })
					: ""}
			</div>
			
			{/* <DetailsNav menuId={props.match.params.menu_id} pageTitle={props.location.state} />
            <Overview menuId={props.match.params.menu_id} overviewDetails={overviewDetails} />
            <BestSolutionsPractices menuId={props.match.params.menu_id} bestPracticeDetails={bestPracticeDetails} />
            <Solutions menuId={props.match.params.menu_id} subMenuId={props.match.params.sub_menu_id} submenuMapDetails={submenuMapDetails} />
            <Services menuId={props.match.params.menu_id} subMenuId={props.match.params.sub_menu_id} submenuMapDetails={submenuMapDetails} />
            {/* <div class="clearfix">
                <Technology menuId={props.match.params.menu_id} subMenuId={props.match.params.sub_menu_id} submenuMapDetails={submenuMapDetails} />
            </div> */}
			{/* <Consulting menuId={props.match.params.menu_id} subMenuId={props.match.params.sub_menu_id} consult={consult} consultTitleSubtile={consultTitleSubtile} />
            <Training menuId={props.match.params.menu_id} subMenuId={props.match.params.sub_menu_id} submenuMapDetails={submenuMapDetails} /> {" "} */}
		</section>
	);
};

export default Details;
