import axios from "axios";
import Cookies from "js-cookie";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {context} from "../../../../../App";
import {BASE_API_URL} from "../../../../../Constants";
import "./UserForm.css";

const customStyles = {
	option: (provided, state) => ({
		...provided,
		borderBottom: "0",
		color: state.isSelected ? "#ffffff" : "#3E3E3E",
	}),

	indicatorSeparator: (styles) => ({
		...styles,
	}),

	valueContainer: (styles) => ({
		...styles,
		padding: "2px 8px 2px 0",
	}),

	control: (styles) => ({
		...styles,
		backgroundColor: "white",
		padding: "0px",
		minHeight: "50px",
		border: "none",
		borderRadius: "none",
		borderBottom: "2px solid #9999",
	}),
};

const UserFormDetailsCardTwo = (props) => {
	console.log(props);

	const {ID} = React.useContext(context);
	const [loading, setLoading] = useState(false);
	// console.log(ID);

	const {register, handleSubmit, Controller, errors, watch} = useForm({});

	let [getId, setGetId] = React.useState();

	const [categories, setCategories] = React.useState([]);
	const [selectedcategories, setSelectedCategories] = React.useState([]);

	const [specificities, setSpecificities] = React.useState([]);
	const [selectedSpecificities, setSelectedSpecificities] = React.useState([]);

	const [techStacks, setTechStacks] = React.useState([]);
	const [selectedTechnologies, setSelectedTechnologies] = React.useState([]);

	console.log("faisal", ID);
	async function nextForm() {
		await props.nextStep();
	}

	const jsonString = (object) => JSON.stringify(object, undefined, 2);

	const onSubmit = (data) => {
		console.log(
			selectedcategories,
			selectedSpecificities,
			selectedTechnologies
		);

		data.category = selectedcategories ? selectedcategories.id : "";
		data.specificity = selectedSpecificities ? selectedSpecificities.id : "";
		data.technologies = selectedTechnologies
			? selectedTechnologies.map((item, i) => item.id)
			: "";

		data.technologies = data.technologies.join();
		data.Id = ID;
		// data.BusinessStageNavigation = { Id: 10 };
		// data.Menu = { Id: 10, DisplayOrder: 10 };
		// console.log(data);
		console.log("onSubmit: " + JSON.stringify(data, undefined, 2));

		let data_saved = Cookies.get("info") ? JSON.parse(Cookies.get("info")) : "";

		data_saved = {...data, ...data_saved};

		console.log("Data_saved: " + JSON.stringify(data_saved, undefined, 2));

		var form_data = new FormData();

		for (var key in data_saved) {
			console.log(key);
			if (data_saved[key] === undefined) {
				form_data.append(key, 0);
			} else {
				form_data.append(key, data_saved[key]);
			}
		}

		setLoading(true);
		ID &&
			axios
				.put(
					BASE_API_URL + `CtaCategory/CtaCategoryUpdateForGuest`,
					form_data,
					{
						headers: {"Content-Type": "multipart/form-data"},
					}
				)
				.then(
					(res) => {
						setLoading(false);
						console.log("data_saved" + jsonString(data_saved));
						Cookies.set("info", data_saved);
						console.log("res" + jsonString(res));
						if (res.data !== undefined || res.data !== null) {
							if (res.data.data.length !== 0) {
								nextForm();
							}
						}
					},
					(error) => {
						console.log(error);
					}
				);
	};

	const onCategoriesSelect = (selectedList) => {
		console.log("Categories: " + jsonString(selectedList));
		setSelectedCategories(selectedList);
	};

	const onSpecificitiesSelect = (selectedList) => {
		console.log("onSpecificitiesSelect: " + jsonString(selectedList));
		setSelectedSpecificities(selectedList);
	};

	const onTechStacksSelect = (selectedList) => {
		console.log("ontechnologiessSelect: " + jsonString(selectedList));
		setSelectedTechnologies(selectedList);
	};

	React.useEffect(() => {
		// eslint-disable-next-line no-lone-blocks
		{
			props.menuId
				? axios
						.get(
							BASE_API_URL +
								`CtaCategory/CtaCategoryModel?menuId=${props.menuId}`,
							{}
						)
						.then((res) => {
							// console.log(res);
							console.log("CtaData: ", res.data);

							if (res.data.length != 0) {
								let category = [];

								res.data.categories.map((item) => {
									category = [
										...category,
										{value: item.name, label: item.name, id: item.value},
									];
								});

								setCategories(category);

								let specificities = [];

								res.data.specificities.map((item) => {
									specificities = [
										...specificities,
										{value: item.name, label: item.name, id: item.id},
									];
								});

								setSpecificities(specificities);

								let technology = [];
								console.log(res.data.techStacks);
								res.data.techStacks.map((item) => {
									if (technology.length) {
										let cache = technology.find(
											(obj) => obj.label === item.category
										);
										let position = technology.findIndex(
											(obj) => obj.label === item.category
										);
										if (cache) {
											let new_option = [
												...cache.options,
												{value: item.name, label: item.name, id: item.id},
											];
											technology[position].options = new_option;
										} else {
											technology = [
												...technology,
												{
													label: item.category,
													options: [
														{value: item.name, label: item.name, id: item.id},
													],
												},
											];
										}
									} else {
										technology = [
											{
												label: item.category,
												options: [
													{value: item.name, label: item.name, id: item.id},
												],
											},
										];
									}
								});

								setTechStacks(technology);
							}
						})
				: axios
						.get(BASE_API_URL + `CtaCategory/CtaCategoryModel`, {})
						.then((res) => {
							console.log(res);
							console.log(res.data);

							if (res.data.length != 0) {
								// // setBusinessStages(res.data.businessStages);
								// // setCompanyTypes(res.data.companyTypes);
								// // setCompanySizes(res.data.companySizes);
								// setCategories(res.data.categories);
								// // setSpecificities(res.data.specificities);

								// // const specificitiesVal = res.data.specificities.filter(
								// //   (item) =>
								// //     item.menuId === Number(props.menuId) &&
								// //     item.subMenuId === Number(props.subMenuId)
								// // );
								// setSpecificities(res.data.specificities);
								// setTechStacks(res.data.techStacks);
								// // console.log(specificitiesVal);

								let category = [];

								res.data.categories.map((item) => {
									category = [
										...category,
										{value: item.name, label: item.name, id: item.value},
									];
								});

								setCategories(category);

								let specificities = [];

								res.data.specificities.map((item) => {
									specificities = [
										...specificities,
										{value: item.name, label: item.name, id: item.id},
									];
								});

								setSpecificities(specificities);

								let technology = [];
								console.log(res.data.techStacks);
								res.data.techStacks.map((item) => {
									if (technology.length) {
										let cache = technology.find(
											(obj) => obj.label === item.category
										);
										let position = technology.findIndex(
											(obj) => obj.label === item.category
										);
										if (cache) {
											let new_option = [
												...cache.options,
												{value: item.name, label: item.name, id: item.id},
											];
											technology[position].options = new_option;
										} else {
											technology = [
												...technology,
												{
													label: item.category,
													options: [
														{value: item.name, label: item.name, id: item.id},
													],
												},
											];
										}
									} else {
										technology = [
											{
												label: item.category,
												options: [
													{value: item.name, label: item.name, id: item.id},
												],
											},
										];
									}
								});

								setSelectedTechnologies(technology);
							}
						});
		}
	}, [props.menuId, props.subMenuId]);
	// console.log(companyTypes);
	console.log(categories);
	console.log(specificities);

	return (
		<>
			<div className="modal-body">
				{loading ? (
					<div class="overlay" style={{zIndex: "999"}}>
						<div class="overlay__wrapper">
							<div class="overlay__spinner">
								<div class="spinner-border text-secondary" role="status"></div>
								<div className="loading-msg"> Please Wait...!</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				<form onSubmit={handleSubmit(onSubmit)}>
					<label className="dropdown-levels text-left w-100">
						{props.name} Catagory
					</label>

					<div className="mb-5">
						<Select
							options={categories}
							className="basic-multi-select"
							placeholder={`Select ${props.name ? props.name : ""} Category`}
							name="solutionCategory"
							styles={customStyles}
							onChange={(value) => onCategoriesSelect(value)}
							classNamePrefix="select"
						/>
					</div>

					{specificities.length > 0 && (
						<>
							<label className="dropdown-levels text-left w-100">
								{props.name} Specificity
							</label>

							<div className=" mb-5">
								<Select
									options={specificities}
									className="basic-multi-select"
									placeholder={`Select ${
										props.name ? props.name : ""
									} Specificity`}
									name="solutionSpecificity"
									styles={customStyles}
									onChange={(value) => onSpecificitiesSelect(value)}
									classNamePrefix="select"
								/>
							</div>
						</>
					)}

					<label className="dropdown-levels text-left w-100">
						{props.name} Preference
					</label>

					<div className=" mb-5">
						<Select
							options={techStacks}
							displayValue="name"
							placeholder={`Select ${props.name ? props.name : ""} Preference`}
							name="solutionSpecificity"
							isMulti
							className="basic-multi-select"
							// name="technologyPreference"
							// id="technologyPreferenceErr"
							styles={customStyles}
							onChange={(value) => onTechStacksSelect(value)}
							classNamePrefix="select"
						/>
					</div>

					<div className="d-flex justify-content-between">
						{/* <button
							className="btn btn-primary cta-form-btn w-100 mr-2"
							onClick={() => props.prevStep()}
						>
							Back
						</button> */}

						<button
							className="btn btn-primary cta-form-btn w-100"
							type="submit"
						>
							Continue
						</button>
					</div>
				</form>
			</div>

			<div className="modal-footer box"></div>
		</>
	);
};

export default UserFormDetailsCardTwo;
