import { createTheme, MuiThemeProvider } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useCookies } from "react-cookie";
import CookieConsent from "react-cookie-consent";
import { Switch, withRouter } from "react-router-dom";
import ScrollRestoration from "react-scroll-restoration";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/swiper-bundle.css";
import "./App.css";
import ConsultingForm from "./Components/CForm/CForm";
import SForm from "./Components/SForm/CForm";
import ContactForm from "./Components/ContactForm/CForm";
import AboutUs from "./Components/AboutUs/AboutUs";
import Admin from "./Components/Admin/Home/Admin";
import Blog from "./Components/Blog/Blog";
import BlogDetails from "./Components/Blog/BolgDetails";
import ContactUsForm from "./Components/ContactUs/ContactUsForm";
import Customer from "./Components/Details/DataAnalysics/Dashboard/Customer";
import Financial from "./Components/Details/DataAnalysics/Dashboard/Financial";
import Marketing from "./Components/Details/DataAnalysics/Dashboard/Marketing";
import Product from "./Components/Details/DataAnalysics/Dashboard/Product";
import Sales from "./Components/Details/DataAnalysics/Dashboard/Sales";
import UniversityDashboard from "./Components/Details/DataAnalysics/Dashboard/UniversityDashboard";
import { DataAnalysics } from "./Components/Details/DataAnalysics/DataAnalysics";
import Details from "./Components/Details/Details";
import DetailsService from "./Components/Details/DetailsService/DetailsService";
import ClassDetails from "./Components/ExploreClasses/ClassDetails";
import ExploreClasses from "./Components/ExploreClasses/ExploreClasses";
import ExploreCourse from "./Components/ExploreCourses/ExploreCourses";
import ContactUs from "./Components/Home/ContactUs";
import Home from "./Components/Home/Home";
import InnovativeSolution from "./Components/InnovativeSolution/InnovativeSolution";
import SolutionPlatformtform from "./Components/InnovativeSolution/SolutionPlatform";
import TrainingPopular from "./Components/InnovativeSolution/TrainingPopular/TrainingPopular";
import Login from "./Components/Login";
import PrivatePolicy from "./Components/PrivatePolicy/PrivatePolicy";
import Register from "./Components/Register";
import TermsOfServices from "./Components/TermsOfServices/TermsOfServices";
import HomePageLayoutRoute from "./Layouts/HomePageLayout";
import PageNotFound from "./PageNotFound";
import Career from "./Components/Career/Career";
import JobApplyForm from "./Components/JobApplyForm/JobApplyForm";
// import JobPostDetails from "./Components/JobPostDetails/JobPostDetails";

import JobPostDetails from "./Components/JobPostDetails/JobPostDetails";
import AllJobs from "./Components/AllJobs/AllJobs";
import ReactGA from "react-ga4";


const TRACKING_ID = "G-M1J4DTVBB4";

const customTheme = createTheme({
	palette: {
		primary: {
			main: "#0096FF",
		},
		secondary: {
			main: "#FFFFFF",
		},
		gray: {
			main: "#E5E5E5",
		},
	},
});

export const context = React.createContext();

const App = withRouter(({ location }) => {
	console.log('Current Path:', location.pathname);

	const [ID, setid] = React.useState(0);
	const [getFormData, setFormData] = React.useState({});
	const [cookieConsent, setCookieConsent] = useCookies(["CookieConsent"]);
	
	// ReactGA.initialize(TRACKING_ID); 
	// ReactGA.pageview(location.pathname);

	        ReactGA.initialize(TRACKING_ID); 
            ReactGA.send({ hitType: "pageview", page: "/", title: "BP" });

	function renderCookieConcent() {
		if (!cookieConsent) {
			
			return (
				<CookieConsent
					debug={true}
					buttonText="GOT IT"
					style={{
						background: "#0096ff",
						padding: "10px",
						opacity: "0.8",
						justifyContent: "flex-start",
					}}
					contentStyle={{ flex: "none !important" }}
					buttonStyle={{
						flex: "none",
						color: "#000000",
						background: "#ffffff",
						fontSize: "14px",
						borderRadius: "4px",
					}}
				>
					We use cookies to personalize your experience. By continuing to visit
					this website you agree to our use of cookies
					
				</CookieConsent>
			);
		}
	}

	return (
		
		<MuiThemeProvider theme={customTheme}>
			<React.Fragment>
				{/* <React.SuspenseList tail="collapsed" revealOrder="forwards"> */}
				<React.Suspense fallback={<></>}>
					<ScrollRestoration />
					<context.Provider value={{ ID, setid, getFormData, setFormData }}>
						<Switch>
							<HomePageLayoutRoute
								exact
								path="/class/list"
								component={ExploreClasses}
								
							/>

							<HomePageLayoutRoute
								exact
								path="/course/list"
								component={ExploreCourse}
							/>
							<HomePageLayoutRoute
								exact
								path="/insights/details/:blog_details_id"
								component={BlogDetails}
							/>
							<HomePageLayoutRoute
								exact
								path="/ClassDetails/:blog_details_id"
								component={ClassDetails}
							/>

							<HomePageLayoutRoute
								exact
								path="/insights/:blog_subcategory_id"
								component={Blog}
							/>
							{/* alvi */}
							<HomePageLayoutRoute
								exact
								path="/jobpostdetails/:id"
								component={JobPostDetails}
							/>
							<HomePageLayoutRoute
								path="/JobApplyForm/:id"
								exact
								component={JobApplyForm}
							/>
							<HomePageLayoutRoute
								exact
								path="/:menu_id/:sub_menu_id"
								component={Details}
							/>

							<HomePageLayoutRoute
								exact
								path="/solutions/data-technology/data-and-analytics"
								component={DataAnalysics}
							/>
							<HomePageLayoutRoute
								exact
								path="/solutions/data-technology/data-and-analytics/dashboard/university"
								component={UniversityDashboard}
							/>
							<HomePageLayoutRoute
								exact
								path="/solutions/data-technology/data-and-analytics/dashboard/sales"
								component={Sales}
							/>
							<HomePageLayoutRoute
								exact
								path="/solutions/data-technology/data-and-analytics/dashboard/marketing"
								component={Marketing}
							/>

							<HomePageLayoutRoute
								exact
								path="/solutions/data-technology/data-and-analytics/dashboard/financial"
								component={Financial}
							/>
							<HomePageLayoutRoute
								exact
								path="/solutions/data-technology/data-and-analytics/dashboard/product"
								component={Product}
							/>
							<HomePageLayoutRoute
								exact
								path="/solutions/data-technology/data-and-analytics/dashboard/customer"
								component={Customer}
							/>
							<HomePageLayoutRoute
								exact
								path="/solutions/data-technology/business-technology"
								component={DataAnalysics} // Replace with the actual component
							/>
							<HomePageLayoutRoute
								exact
								path="/solutions/data-technology/digital-workflows"
								component={DataAnalysics} // Replace with the actual component
							/>


							<HomePageLayoutRoute
								exact
								path="/solutions/data-technology/:title"
								component={Details}
							/>

							<HomePageLayoutRoute
								exact
								path="/solutions/accounting-finance/:title"
								component={Details}
							/>

							<HomePageLayoutRoute
								exact
								path="/solutions/ecommerce/:title"
								component={Details}
							/>

							<HomePageLayoutRoute
								exact
								path="/solutions/application-development/:title"
								component={Details}
							/>

							<HomePageLayoutRoute
								exact
								path="/:menu_id/:sub_menu_id/:title"
								component={DetailsService}
							/>
							{/* <HomePageLayoutRoute
								exact
								path="/services/data-technology/:sub-sesrvice"
								component={DetailsService}
							/> */}
							<HomePageLayoutRoute path="/insights" exact component={Blog} />
							<HomePageLayoutRoute path="/aboutUs" exact component={AboutUs} />

							<HomePageLayoutRoute path="/request-for-Proposal" exact component={ConsultingForm} />
							<HomePageLayoutRoute path="/schedule-a-consult" exact component={SForm} />
							<HomePageLayoutRoute path="/contact-us" exact component={ContactForm} />
							<HomePageLayoutRoute
								path="/contact-us"
								exact
								component={ContactUsForm}
							/>
							<HomePageLayoutRoute
								path="/ContactUs"
								exact
								component={ContactUs}
							/>
							{/* alvi */}

							<HomePageLayoutRoute
								path="/careers"
								exact
								component={Career}
							/>

							<HomePageLayoutRoute
								path="/JobApplyForm"
								exact
								component={JobApplyForm}
							/>
							<HomePageLayoutRoute
								path="/alljobs"
								exact
								component={AllJobs}
							/>

							<HomePageLayoutRoute
								exact
								path="/jobpostdetails"
								component={JobPostDetails}
							/>


							

							<HomePageLayoutRoute
								exact
								path="/privacy-policy"
								component={PrivatePolicy}
							/>

							<HomePageLayoutRoute
								path="/solution-platform"
								exact
								component={SolutionPlatformtform}
							/>
							<HomePageLayoutRoute
								path="/training-popular"
								exact
								component={TrainingPopular}
							/>
							<HomePageLayoutRoute
								exact
								path="/terms-and-conditions"
								component={TermsOfServices}
							/>
							<HomePageLayoutRoute
								exact
								path="/:menu_id"
								component={InnovativeSolution}
							/>

							<HomePageLayoutRoute exact path="/login" component={Login} />
							<HomePageLayoutRoute
								exact
								path="/register"
								component={Register}
							/>

							<HomePageLayoutRoute exact path="/admin" component={Admin} />
							<HomePageLayoutRoute path="/" exact component={Home} />
							<HomePageLayoutRoute path="*" component={PageNotFound} />
						
						</Switch>
					</context.Provider>
				</React.Suspense>
				
				{/* </React.SuspenseList> */}
				{renderCookieConcent()}
			</React.Fragment>
		</MuiThemeProvider>
	);
});

export default App;
