/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./dashboardMockup.css";
import Title from "./Title";
// import dashboard from "../../../../assets/img/dashboard/dashboard.png"

const DashboardMockup = ({data}) => {
  return (
    <>
      <section className="dashboardSection">
        <Title/>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dashboard__demoContainer">
                {/* <img
                  src={dashboard}
                  alt="University powerbi dashboard"
                /> */}
                {/* <div className="dashboard__demoContainer__embededCode">
                  <iframe src={data.link} frameborder="0"></iframe>
                </div> */}
                <div className="dashboard__demoContainer__frame">
                  <iframe src={data.link} frameborder="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardMockup;
