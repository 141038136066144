import axios from "axios";
import React, {useState} from "react";
import {BASE_API_URL} from "../../../Constants";

const NewConsulntRegistration = ({setCreateAccount}) => {
	const [companyTypes, setCompanyTypes] = useState([]);
	const [companySizes, setCompanySizes] = useState([]);
	const [loading, setLoading] = useState(false);
	const [signupFaild, setSignUpFaild] = useState("");

	//get types and size of a company
	React.useEffect(() => {
		axios.get(BASE_API_URL + `CtaFunction/CtaFunctionModel`, {}).then((res) => {
			if (res.data.length !== 0) {
				let size = [];
				res.data.companySizes.map((item) => {
					size = [...size, {value: item.name, label: item.name, id: item.id}];
				});
				setCompanySizes(size);

				let type = [];
				res.data.companyTypes.map((item) => {
					type = [...type, {value: item.name, label: item.name, id: item.id}];
				});
				setCompanyTypes(type);
			}
		});
	}, []);

	const initiaValues = {
		firstName: "",
		lastName: "",
		password: "123",
		cpassword: "123",
		phone: "",
		email: "",
		companyName: "",
		businessIndustry: "",
		num: 1,
	};
	const [formValues, setFormValues] = useState(initiaValues);
	const [errors, setErrors] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);

	const handleChange = (e) => {
		const {name, value} = e.target;
		setFormValues({...formValues, [name]: value});
	};

	const onsubmit = (e) => {
		e.preventDefault();
		setErrors(validation(formValues));
		setIsSubmit(true);
		if (Object.keys(errors).length === 0) {
			setLoading(true);

			axios
				.post(`${BASE_API_URL}CtaFunction/CreateSignUp`, {
					id: 0,
					firstName: formValues.firstName,
					lastName: formValues.lastName,
					companyName: formValues.companyName,
					email: formValues.email,
					phone: formValues.phone,
					companyTypeId: parseInt(formValues.companyTypeId),
					businessIndustry: formValues.businessIndustry,
					companySizeId: parseInt(formValues.companySizeId),
					password: formValues.password,
				})
				.then((res) => {
					////console.log("SIGNUPDATA", res.data);
					setLoading(false);
					if (res.data.status) {
						setCreateAccount(true);
					} else {
						// toast.error(`😥${res.data?.message}!`, {
						//     position: "bottom-right",
						//     autoClose: 5000,
						//     hideProgressBar: false,
						//     closeOnClick: true,
						//     pauseOnHover: false,
						//     draggable: true,
						//     progress: undefined,
						// });
						setSignUpFaild(res.data?.message);
					}
				});
		}
	};

	const validation = (values) => {
		const errors = {};
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

		if (!values.firstName) {
			errors.firstName = "First Name is required!";
		}

		if (!values.lastName) {
			errors.lastName = "Last Name is required!";
		}

		if (!values.email) {
			errors.email = "Email is required!";
		} else if (!regex.test(values.email)) {
			errors.email = "This is not a valid email format!";
		}

		// if (!values.password) {
		// 	errors.password = "Password is r
		// } else if (values.password.length <= 5) {
		// 	errors.password = "Password length will be minimum 6 charecter";
		// }

		// if (!values.cpassword) {
		// 	errors.cpassword = "Conform Password is required";
		// } else if (values.password !== values.cpassword) {
		// 	errors.cpassword = "Password does not match";
		// }

		if (!values.companyName) {
			errors.companyName = "Company Name is required!";
		}

		if (!values.businessIndustry) {
			errors.businessIndustry = "Company Name is required!";
		}

		if (!values.companyTypeId) {
			errors.companyTypeId = "Company Type is required!";
		}

		if (!values.companySizeId) {
			errors.companySizeId = "Company Size is required!";
		}

		return errors;
	};

	return (
		<>
			<div className="modal-body box">
				{loading ? (
					<div class="overlay" style={{zIndex: "999"}}>
						<div class="overlay__wrapper">
							<div class="overlay__spinner">
								<div class="spinner-border text-secondary" role="status"></div>
								<div className="loading-msg"> Please Wait...!</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}

				{/* {reqErr && <Alert variant="danger">{reqErrMessage}</Alert>} */}
				{/* onSubmit={onSubmit} */}

				{/* <ToastContainer /> */}

				{signupFaild && (
					<div class="alert alert-danger" role="alert">
						😥{signupFaild}
					</div>
				)}

				<form onSubmit={onsubmit}>
					<div className="row">
						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="text"
								name="firstName"
								onChange={handleChange}
								value={formValues.firstName}
							/>
							<label>First Name</label>
							{errors.firstName && (
								<p className="form-error-message">{errors.firstName}</p>
							)}
						</div>

						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="text"
								name="lastName"
								onChange={handleChange}
								value={formValues.lastName}
							/>
							<label>Last Name </label>
							{errors.lastName && (
								<p className="form-error-message">{errors.lastName}</p>
							)}
						</div>
					</div>

					{/* <div className="row">
						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="password"
								name="password"
								onChange={handleChange}
								value={formValues.password}
							/>
							<label>Password</label>
							{errors.password && (
								<p className="form-error-message">{errors.password}</p>
							)}
						</div>

						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="password"
								name="cpassword"
								onChange={handleChange}
								value={formValues.cpassword}
							/>
							<label>Conform Password</label>
							{errors.cpassword && (
								<p className="form-error-message">{errors.cpassword}</p>
							)}
						</div>
					</div> */}

					<div className="row">
						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="text"
								name="phone"
								onChange={handleChange}
								value={formValues.phone}
							/>
							<label>Phone</label>
						</div>

						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="text"
								name="email"
								onChange={handleChange}
								value={formValues.email}
							/>
							<label>Work Email</label>
							{errors.email && (
								<p className="form-error-message">{errors.email}</p>
							)}
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="text"
								name="companyName"
								onChange={handleChange}
								value={formValues.companyName}
							/>
							<label>Company/Business Name</label>
							{errors.companyName && (
								<p className="form-error-message">{errors.companyName}</p>
							)}
						</div>

						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="text"
								name="businessIndustry"
								onChange={handleChange}
								value={formValues.BusinessIndustry}
							/>
							<label>Business Industry</label>
							{errors.businessIndustry && (
								<p className="form-error-message">{errors.businessIndustry}</p>
							)}
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 pl-0">
							<label
								className="dropdown-levels text-left w-100"
								style={{top: "-9px", color: "#0096FF"}}
							>
								Company Type
							</label>

							<div className="pt-2 mb-4 mt-2 w-100">
								<select
									className="select-one w-100 p-4"
									name="companyTypeId"
									onChange={handleChange}
									// onChange={(value) => onCompanyTypesSelect(value)}
								>
									<option value="" disabled selected>
										select company type
									</option>
									{companyTypes.map((item) => (
										<option value={parseInt(item.id)} className="select-option">
											{item.value}
										</option>
									))}
								</select>
							</div>
							{errors.companyTypeId && (
								<p className="form-error-message">{errors.companyTypeId}</p>
							)}
						</div>

						<div className="col-md-6 pl-0">
							<label
								className=" text-left w-100"
								style={{top: "-9px", color: "#0096FF"}}
							>
								Company Size
							</label>
							<div className="pt-2 mb-4 mt-2 w-100">
								<select
									className="select-one w-100 p-4"
									name="companySizeId"
									onChange={handleChange}
								>
									<option value="" disabled selected>
										select company size
									</option>
									{companySizes.map((item) => (
										<option
											value={parseInt(item.id)}
											className="select-option p-2"
										>
											{item.value}
										</option>
									))}
								</select>
							</div>
							{errors.companySizeId && (
								<p className="form-error-message">{errors.companySizeId}</p>
							)}
						</div>
					</div>

					<button
						className="btn btn-block btn-primary cta-form-btn"
						type="submit"
					>
						Create Account
					</button>
				</form>
			</div>
		</>
	);
};

export default NewConsulntRegistration;
