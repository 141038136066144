import React, {useEffect, useState} from "react";
import NewConsulntRegistration from "../../../../Authentication/NewConsulntRegistration/NewConsulntRegistration";
import "../ConsultStyle.css";

const ConsultationMonthly = ({yearly, subMenuId}) => {
	// console.log(subMenuId)
	const [startupYearly, setStartupYearly] = useState({});
	const [enterprenureYearly, setEnterprenureYearly] = useState({});
	const [businessYearly, setBusinessYearly] = useState({});
	const [enterpriseYearly, setEnterpriseYearly] = useState({});
	const [signup, setSignup] = useState(false);
	const [createAccount, setCreateAccount] = useState(false);

	//   subMenuId = Number(subMenuId)

	const convertPricetoUsd = (rate) => {
		return rate.toLocaleString("en-US", {
			currency: "USD",
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
	};

	function currencyFormat(num) {
		return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	const openRegiserForm = () => {
		setSignup(true);
	};

	const closeAllCondition = () => {
		setSignup(false);
		setCreateAccount(false);
	};

	useEffect(() => {
		if (yearly) {
			yearly.map((item) => {
				if (item.companyTypeId === 1) {
					console.log(typeof subMenuId, typeof item.submenuId);
					if (item.submenuId === Number(subMenuId)) {
						setEnterprenureYearly({
							rateMonthly: item.monthlyRate,
							rateYearly: item.yearlyRate,
							name: "Entrepreneur",
						});
					}
				} else if (item.companyTypeId === 2) {
					if (item.submenuId === Number(subMenuId)) {
						setStartupYearly({
							rateMonthly: item.monthlyRate,
							rateYearly: item.yearlyRate,
							name: "Startup",
						});
					}
				} else if (item.companyTypeId === 3) {
					if (item.submenuId === Number(subMenuId)) {
						setBusinessYearly({
							rateMonthly: item.monthlyRate,
							rateYearly: item.yearlyRate,
							name: "Business",
						});
					}
				} else if (item.companyTypeId === 4) {
					if (item.submenuId === Number(subMenuId)) {
						setEnterpriseYearly({
							rateMonthly: item.monthlyRate,
							rateYearly: item.yearlyRate,
							name: "Enterprise",
						});
					}
				}
			});
		}
	}, [yearly, subMenuId]);

	if (startupYearly) {
		console.log(startupYearly);
	}

	return (
		<div className="">
			<div
				className="modal fade "
				tabIndex="-1"
				role="document"
				aria-labelledby="myLargeModalLabel"
				aria-hidden="true"
				id="purchase_modal"
			>
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content">
						<div className="modal-header mb-3">
							<div className="container w-100">
								<div className="row modal-header-row ">
									<div className="d-flex justify-content-end w-100">
										<h2
											class="modal-title text-center"
											id="exampleModalLongTitle"
										>
											{createAccount
												? "Sign In"
												: signup
												? "Create New Account"
												: "Sign In Or Sign Up"}
										</h2>
										<button
											type="button"
											className="close"
											data-toggle="modal"
											data-target="#purchase_modal"
											data-dismiss="modal"
											aria-label="Close"
											onClick={closeAllCondition}
										>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							{createAccount ? (
								<div className="text-center m-4">
									<h6 className="mb-3 text-success">
										Your account has been created successfully. Please check
										your email to complete the purchase.
									</h6>

									<a
										href="https://client.bestpracticify.co/signin"
										target="_blank"
										class="btn btn-primary my-2"
										rel="noreferrer"
									>
										Sign In
									</a>

									{/* <div className="newToBP my-2">
                      <p className="text-center text-secondary bg-white"><span>New To Best Practicify?</span></p>
                      <hr />
                    </div>
                    <a onClick={ openRegiserForm } className="btn btn-primary my-2">Create Your Best Practicify Account</a> */}
								</div>
							) : (
								<>
									{!signup ? (
										<div className="text-center m-4">
											<h6 className="mb-3">
												To purchase the selected consultation, please sign in
												first.
											</h6>

											<a
												href="https://client.bestpracticify.co/signin"
												target="_blank"
												class="btn btn-primary my-2"
												rel="noreferrer"
											>
												Sign In
											</a>

											<div className="newToBP my-2">
												<p className="text-center text-secondary bg-white">
													<span>New To Best Practicify?</span>
												</p>
												<hr />
											</div>
											<a
												onClick={openRegiserForm}
												className="btn btn-primary my-2"
											>
												Create Your Best Practicify Account
											</a>
										</div>
									) : (
										<NewConsulntRegistration
											setCreateAccount={setCreateAccount}
										></NewConsulntRegistration>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="container monthly mt-2">
				<div className="row">
					<div className="col-md-3 col-sm-6 pl-2 pr-2 mt-2 mb-2">
						<div className="pricingTable pricing-hov">
							<div className="pricingTable-header pt-5 pr-2 pl-2 pb-2">
								<h3>
									{enterprenureYearly.name
										? enterprenureYearly.name
										: "Entrepreneur"}
								</h3>
								<p className="pt-4">
									Receive on-demand, white-glove consulting services for a
									monthly fee
								</p>
							</div>

							<div className="price-value-daily">
								<div className="d-flex flex-column align-items-center">
									<p className="">Price</p>
									<p className="">Starting at</p>

									<span>
										<small class="fa fa-usd"></small>
										{/* {currencyFormat(sortedItems[0]?.monthlyRate)} */}
										{currencyFormat(3)}
										<span style={{fontSize: "1.4rem"}}>
											<sub>/day</sub>
										</span>
									</span>
									{/* <span>
										<small class="fa fa-usd"></small>
										{convertPricetoUsd(
											enterprenureYearly.rateMonthly
												? enterprenureYearly.rateMonthly
												: ""
										)}
									</span>

									<span
										style={{
											color: "#66666",
											fontSize: "20px",
											paddingBottom: "5px",
										}}
									>
										per month
									</span>

									<span
										style={{
											color: "#66666",
											fontSize: "20px",
											padding: "10px",
										}}
									>
										or
									</span>

									<span>
										<small class="fa fa-usd"></small>
										{convertPricetoUsd(
											enterprenureYearly.rateYearly
												? enterprenureYearly.rateYearly
												: ""
										)}
									</span>

									<span style={{color: "#66666", fontSize: "20px"}}>
										per year
									</span> */}
								</div>
								{/* <span className="subtitle">per hour</span> */}
							</div>

							<div className="notes">
								<ul className="list-group">
									<li className="list-group-item">Each call: 30 min (max)</li>
									<li className="list-group-item">
										Monthly Limit: 3 hours (max)
									</li>
								</ul>
							</div>

							<div className="pricingTable-sign-up">
								<button
									type="button"
									data-toggle="modal"
									data-target="#purchase_modal"
									className="btn btn-block btn-default"
								>
									{/* {location.pathname === "/"
                      ? "Schedule a Consult"
                      : "Request For Proposal"} */}
									purchase
								</button>
								{/* <a
                    href="https://client.bestpracticify.co/signin"
                    target="_blank"
                    className="btn btn-block btn-default"
                  >
                    Purchase
                  </a> */}
							</div>
						</div>
					</div>

					<div className="col-md-3 col-sm-6 pl-2 pr-2 mt-2 mb-2">
						<div className="pricingTable pricing-hov">
							<div className="pricingTable-header pt-5 pr-2 pl-2 pb-2">
								<h3>{startupYearly.name ? startupYearly.name : "Startup"}</h3>
								<p className="pt-4">
									Receive on-demand, white-glove consulting services for a
									monthly fee
								</p>
							</div>

							<div className="price-value-daily">
								<div className="d-flex flex-column align-items-center">
									<p className="">Price</p>
									<p className="">Starting at</p>

									<span>
										<small class="fa fa-usd"></small>
										{/* {currencyFormat(sortedItems[0]?.monthlyRate)} */}
										{currencyFormat(3)}
										<span style={{fontSize: "1.4rem"}}>
											<sub>/day</sub>
										</span>
									</span>

									{/* <span>
										<small class="fa fa-usd"></small>
										{convertPricetoUsd(
											startupYearly.rateMonthly ? startupYearly.rateMonthly : ""
										)}
									</span>

									<span
										style={{
											color: "#66666",
											fontSize: "20px",
											paddingBottom: "5px",
										}}
									>
										per month
									</span>

									<span
										style={{
											color: "#66666",
											fontSize: "20px",
											padding: "10px",
										}}
									>
										or
									</span>

									<span>
										<small class="fa fa-usd"></small>
										{convertPricetoUsd(
											startupYearly.rateYearly ? startupYearly.rateYearly : ""
										)}
									</span>

									<span style={{color: "#66666", fontSize: "20px"}}>
										per year
									</span> */}
								</div>
							</div>

							<div className="notes">
								<ul className="list-group">
									<li className="list-group-item">Each call: 30 min (max)</li>
									<li className="list-group-item">
										Monthly Limit: 3 hours (max)
									</li>
								</ul>
							</div>

							<div className="pricingTable-sign-up">
								<button
									type="button"
									data-toggle="modal"
									data-target="#purchase_modal"
									className="btn btn-block btn-default"
								>
									purchase
								</button>
							</div>
						</div>
					</div>

					<div className="col-md-3 col-sm-6 pl-2 pr-2 mt-2 mb-2">
						<div className="pricingTable pricing-hov">
							<div className="pricingTable-header pt-5 pr-2 pl-2 pb-2">
								<h3>
									{businessYearly.name ? businessYearly.name : "Business"}
								</h3>
								<p className="pt-4">
									Receive on-demand, white-glove consulting services for a
									monthly fee
								</p>
							</div>

							<div className="price-value-daily">
								<div className="d-flex flex-column align-items-center">
									<p className="">Price</p>
									<p className="">Starting at</p>

									<span>
										<small class="fa fa-usd"></small>
										{/* {currencyFormat(sortedItems[0]?.monthlyRate)} */}
										{currencyFormat(5)}
										<span style={{fontSize: "1.4rem"}}>
											<sub>/day</sub>
										</span>
									</span>

									{/* <span>
										<small class="fa fa-usd"></small>
										{convertPricetoUsd(
											businessYearly.rateMonthly
												? businessYearly.rateMonthly
												: ""
										)}
									</span>

									<span
										style={{
											color: "#66666",
											fontSize: "20px",
											paddingBottom: "5px",
										}}
									>
										per month
									</span>

									<span
										style={{
											color: "#66666",
											fontSize: "20px",
											padding: "10px",
										}}
									>
										or
									</span>

									<span>
										<small class="fa fa-usd"></small>
										{convertPricetoUsd(
											businessYearly.rateYearly ? businessYearly.rateYearly : ""
										)}
									</span>

									<span style={{color: "#66666", fontSize: "20px"}}>
										per year
									</span> */}
								</div>

								{/* <span className="subtitle">per hour</span> */}
							</div>

							<div className="notes">
								<ul className="list-group">
									<li className="list-group-item">Each call: 30 min (max)</li>
									<li className="list-group-item">
										Monthly Limit: 3 hours (max)
									</li>
								</ul>
							</div>

							<div className="pricingTable-sign-up">
								<button
									type="button"
									data-toggle="modal"
									data-target="#purchase_modal"
									className="btn btn-block btn-default"
								>
									purchase
								</button>
							</div>
						</div>
					</div>

					<div className="col-md-3 col-sm-6 pl-2 pr-2 mt-2 mb-2">
						<div className="pricingTable pricing-hov">
							<div className="pricingTable-header pt-5 pr-2 pl-2 pb-2">
								<h3>
									{enterpriseYearly.name ? enterpriseYearly.name : "Enterprise"}
								</h3>
								<p className="pt-4">
									Receive on-demand, white-glove consulting services for a
									monthly fee
								</p>
							</div>

							<div className="price-value-daily">
								<div className="d-flex flex-column align-items-center">
									<p className="">Price</p>
									<p className="">Starting at</p>

									<span>
										<small class="fa fa-usd"></small>
										{/* {currencyFormat(sortedItems[0]?.monthlyRate)} */}
										{currencyFormat(8)}
										<span style={{fontSize: "1.4rem"}}>
											<sub>/day</sub>
										</span>
									</span>

									{/* <span>
										<small class="fa fa-usd"></small>
										{convertPricetoUsd(
											enterpriseYearly.rateMonthly
												? enterpriseYearly.rateMonthly
												: ""
										)}
									</span>

									<span
										style={{
											color: "#66666",
											fontSize: "20px",
											paddingBottom: "5px",
										}}
									>
										per month
									</span>

									<span
										style={{
											color: "#66666",
											fontSize: "20px",
											padding: "10px",
										}}
									>
										or
									</span>

									<span>
										<small class="fa fa-usd"></small>
										{convertPricetoUsd(
											enterpriseYearly.rateYearly
												? enterpriseYearly.rateYearly
												: ""
										)}
									</span>

									<span style={{color: "#66666", fontSize: "20px"}}>
										per year
									</span> */}
								</div>
							</div>

							<div className="notes">
								<ul className="list-group">
									<li className="list-group-item">Each call: 30 min (max)</li>
									<li className="list-group-item">
										Monthly Limit: 3 hours (max)
									</li>
								</ul>
							</div>

							<div className="pricingTable-sign-up">
								<button
									type="button"
									data-toggle="modal"
									data-target="#purchase_modal"
									className="btn btn-block btn-default"
								>
									purchase
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConsultationMonthly;
