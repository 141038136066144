import React from "react";
import {Element} from "react-scroll";
import {BASE_URL} from "../../../Constants";
import LoadingFullWidth from "../../Loading/LoadingFullWidth";
import "./PersonalizedService.css";
// import "./PersonalizedServiceResponsive.css";

const PersonalizedService = (props) => {
	// //console.log(window.location.pathname);

	let [pathName, setPathName] = React.useState();
	let [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		setPathName(window.location.pathname);
	}, [pathName]);

	const personalizedServiceDetail = props.data;

	return (
		<section className="bp-homepage-personalizedService-section">
			{props.loading ? (
				<LoadingFullWidth height="350px" />
			) : (
				<div className="personalizedServiceDetail">
					{/* <pre>{JSON.stringify(personalizedServiceDetail, undefined, 2)}</pre> */}
					<div className="container">
						{/* <div className="row">
                          <div className="col-md-12">
                              <h3 className="text-center pt-4 ">{props.title}</h3>
                          </div>
                      </div> */}
						<Element name="personal">
							<div className="row">
								{personalizedServiceDetail &&
									personalizedServiceDetail.map(
										(personalizedServiceDetailsItem, index) =>
											index % 2 !== 0 ? (
												<>
													<div
														className="col-md-6 d-flex align-items-center"
														key={Math.random()}
													>
														<div className="content">
															<p className="section-heading-large">
																{personalizedServiceDetailsItem.title}
															</p>
															
															<div
															className="list-describe"
															dangerouslySetInnerHTML={{
																__html: personalizedServiceDetailsItem.description,
															}}
														    />
														</div>
													</div>
													<div
														className="col-md-6 d-flex align-items-center"
														key={Math.random()}
													>
														<img
															style={{width: "100%", height: "auto"}}
															src={
																BASE_URL +
																personalizedServiceDetailsItem.pictureUrl
															}
															alt=""
														/>
													</div>
												</>
											) : (
												<>
													<div
														className="col-md-6 d-flex align-items-center"
														key={Math.random()}
													>
														<img
															style={{width: "100%", height: "auto"}}
															src={
																BASE_URL +
																personalizedServiceDetailsItem.pictureUrl
															}
															alt=""
														/>
													</div>
													<div
														className="col-md-6 d-flex align-items-center"
														key={Math.random()}
													>
														<div className="content">
															<p className="section-heading-large">
																{personalizedServiceDetailsItem.title}
															</p>
															<div
															className="list-describe"
															dangerouslySetInnerHTML={{
																__html: personalizedServiceDetailsItem.description,
															}}
														    />
														
																
															
														</div>
													</div>
												</>
											)
									)}
							</div>
						</Element>
					</div>
				</div>
			)}
		</section>
	);
};

export default PersonalizedService;
