import axios from "axios";
import Cookies from "js-cookie";
import React, {useState} from "react";
import {Alert} from "react-bootstrap";
import {context} from "../../../../../App";
import {BASE_API_URL} from "../../../../../Constants";
import "./UserForm.css";

const UserFormDetailsCardThree = (props) => {
	//console.log(props);

	const {ID, getFormData, setFormData} = React.useContext(context);
	//console.log("ID: ", ID, "getFormData: ", getFormData);

	let [reqErr, setReqErr] = React.useState(false);
	let [reqErrMessage, setReqErrMessage] = React.useState();
	const [loading, setLoading] = useState(false);

	let [getId, setGetId] = React.useState();
	let [getInputVal, setInputVal] = React.useState({
		estimation: getFormData.estimation ? getFormData.estimation : "",
		estimationErr: getFormData.estimationErr
			? getFormData.estimationErr
			: false,
		tellUsMore: getFormData.tellUsMore ? getFormData.tellUsMore : "",
		tellUsMoreErr: getFormData.tellUsMoreErr
			? getFormData.tellUsMoreErr
			: false,
		description: getFormData.description ? getFormData.description : "",
		descriptionErr: getFormData.descriptionErr
			? getFormData.descriptionErr
			: false,
	});

	const handleChange = (input) => (e) => {
		//console.log({[input]: e.target.value});

		setInputVal({
			...getInputVal,
			[input]: e.target.value,
			[e.target.id]: e.target.value ? false : true,
		});

		setFormData({
			...getFormData,
			[input]: e.target.value,
			[e.target.id]: e.target.value ? false : true,
		});
	};

	async function nextForm() {
		await props.nextStep();
	}
	const onSubmit = (e) => {
		e.preventDefault();

		const checkErr =
			getInputVal.estimation !== "" &&
			getInputVal.tellUsMore !== "" &&
			getInputVal.description !== "";

		if (getInputVal.estimation === "") {
			getInputVal.estimationErr = true;
			setInputVal({...getInputVal, estimationErr: true});
		}
		if (getInputVal.tellUsMore === "") {
			getInputVal.tellUsMoreErr = true;
			setInputVal({...getInputVal, tellUsMoreErr: true});
		}
		if (getInputVal.description === "") {
			getInputVal.descriptionErr = true;
			setInputVal({...getInputVal, descriptionErr: true});
		}
		//console.log(getInputVal);
		//console.log(checkErr);

		const data = Cookies.get("info") ? JSON.parse(Cookies.get("info")) : "";

		const data_saved = {...data, ...getFormData};

		if (checkErr) {
			setLoading(true);
			ID &&
				axios
					.put(
						BASE_API_URL + `CtaFunction/CtaFunctionUpdateForGuest`,
						data_saved,
						{headers: {"Content-Type": "application/json"}}
					)
					.then(
						(res) => {
							setLoading(false);
							////console.log(res);
							setReqErr(false);

							if (res.data.data) {
								//console.log(data_saved);
								Cookies.remove("info");
								nextForm();
							}
						},
						(error) => {
							//console.log(error.response.status);

							setReqErr(true);

							if (error.response.data.status.toString().includes("4")) {
								setReqErrMessage("Client side error");
							} else if (error.response.data.status.toString().includes("5")) {
								setReqErrMessage("Server side error");
							} else {
								setReqErrMessage("Error Happen");
							}
						}
					);

			//if (ID && !reqErr) {
			if (ID) {
				props.nextStep();
			}
		}
	};

	function minDateToday() {
		var dtToday = new Date();

		var month = dtToday.getMonth() + 1;
		var day = dtToday.getDate();
		var year = dtToday.getFullYear();
		if (month < 10) month = "0" + month.toString();
		if (day < 10) day = "0" + day.toString();

		return year + "-" + month + "-" + day;
	}

	const handleBackBtn = () => {
		props.prevStep();
	};

	return (
		<>
			<div className="modal-body mt-4 box">
				{loading ? (
					<div class="overlay" style={{zIndex: "999"}}>
						<div class="overlay__wrapper">
							<div class="overlay__spinner">
								<div class="spinner-border text-secondary" role="status"></div>
								<div className="loading-msg"> Please Wait...!</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}

				{reqErr && <Alert variant="danger">{reqErrMessage}</Alert>}

				<form onSubmit={onSubmit}>
					<div className="form-group third_page">
						<input
							className="form-control"
							type="date"
							name="estimation"
							id="estimationErr"
							min={minDateToday()}
							onChange={handleChange("estimation")}
							value={getInputVal.estimation}
						/>
						<label htmlFor="example-date-input" className="col-form-label">
							When do you need to have this solution created?
						</label>
						{getInputVal.estimationErr && (
							<p className="form-error-message">This feild is required</p>
						)}
					</div>

					{/* <div className="form-group">
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name="textArea3" ref={register({ required: "This area is required" })} onChange={props.handleChange('textArea3')} ></textarea>
                        <label htmlFor="exampleFormControlTextarea1">If you're unsure, please give the estimate you can</label>
                        {errors.textArea3 && <p style={{ color: "red" }}>{errors.textArea3.message}</p>}
                    </div> */}

					<div className="form-group third_page">
						<textarea
							className="form-control"
							type="text"
							id="tellUsMoreErr"
							rows="3"
							name="tellUsMore"
							onChange={handleChange("tellUsMore")}
							value={getInputVal.tellUsMore}
							style={{marginBottom: "19px"}}
						></textarea>
						<label htmlFor="exampleFormControlTextarea1">
							Tell us more about the need for this solution/services
						</label>
						{getInputVal.tellUsMoreErr && (
							<p className="form-error-message-v4">This feild is required</p>
						)}
					</div>

					<div className="form-group third_page">
						<textarea
							className="form-control"
							type="text"
							id="descriptionErr"
							rows="3"
							name="description"
							onChange={handleChange("description")}
							value={getInputVal.description}
							style={{marginBottom: "19px"}}
						></textarea>
						<label htmlFor="exampleFormControlTextarea2">
							Please describe what you'd like done in under 300 words
						</label>
						{getInputVal.descriptionErr && (
							<p className="form-error-message-v4">This feild is required</p>
						)}
					</div>

					<div className="d-flex justify-content-between">
						<button
							className="btn btn-primary cta-form-btn w-100 mr-2"
							type="button"
							onClick={() => handleBackBtn()}
						>
							Back
						</button>

						<button
							className="btn btn-primary cta-form-btn w-100"
							type="submit"
						>
							Continue
						</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default UserFormDetailsCardThree;
