import {Container, Grid, Typography} from "@material-ui/core";
import React from "react";
import "./Consultation.css";

const Consultation = (props) => {
	//console.log(props);
	const [index, setIndex] = React.useState(0);
	let [loading, setLoading] = React.useState(true);
	const [it, setItem] = React.useState();

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	const handleEvent = (items, e) => {
		e.preventDefault();
		setItem(items);
	};

	const discardEvent = (e) => {
		e.preventDefault();
		setItem("");
	};

	return (
		<>
			{
				<div className="Consultation-section consulting mt-4 mb-4">
					<Container fixed>
						<h2 className="text-center"> {props.consult.title} </h2>
						<Typography variant="subtitle1" align="center" className="subTitle">
							{props.consult.subtitle}
						</Typography>

						<Grid container className="details-section">
							<Grid item md={12} className="pr-4">
								{props.consult.sectionItems.map((item, i) => (
									<Grid
										item
										key={i}
										onMouseEnter={(e) => handleEvent(item, e)}
										onMouseLeave={(e) => discardEvent(e)}
									>
										<Typography variant="h4">{item.title}</Typography>
										<Typography variant="subtitle1">
											<div
												dangerouslySetInnerHTML={{
													__html: `${item.description}`,
												}}
											/>
										</Typography>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Container>
				</div>
			}
		</>
	);
};

export default Consultation;
