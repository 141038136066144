import axios from "axios";
import React, {Suspense, useEffect} from "react";
import "./cform.css";
import Form from "../Home/consultingHome/ConsultingForm/CategoryPages/CategoryPagesSolutionsForm/UserForm";
//import Form from "../Details/ConsultingForm/FunctionPages/FunctionPagesSolutionsForm/UserForm";



const CForm = (props) => {
	// //console.log(props);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="about-us container" style={{marginTop: "4rem"}}>
			
			<Form/>
		</section>
	);
};

export default CForm;
