import axios from "axios";
import Cookies from "js-cookie";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {context} from "../../../../../App";
import {BASE_API_URL} from "../../../../../Constants";
import "./UserForm.css";

const UserFormDetailsCardThree = (props) => {
	//console.log(props);

	const {ID} = React.useContext(context);
	//console.log(ID);

	const {register, handleSubmit, Controller, errors, watch, reset} = useForm({
		defaultValues: props.getInputVal,
	});

	let [getId, setGetId] = React.useState();
	const [loading, setLoading] = useState(false);
	async function nextForm() {
		await props.nextStep();
	}

	function minDateToday() {
		var dtToday = new Date();

		var month = dtToday.getMonth() + 1;
		var day = dtToday.getDate();
		var year = dtToday.getFullYear();
		if (month < 10) month = "0" + month.toString();
		if (day < 10) day = "0" + day.toString();

		return year + "-" + month + "-" + day;
	}

	const onSubmit = (data) => {
		let data_saved = Cookies.get("info") ? JSON.parse(Cookies.get("info")) : "";

		data_saved = {...data_saved, ...data};
		let form_data = new FormData();

		for (var key in data_saved) {
			if (data_saved[key] === undefined) {
				form_data.append(key, 0);
			} else {
				form_data.append(key, data_saved[key]);
			}
		}

		setLoading(true);
		ID &&
			axios
				.put(
					BASE_API_URL + `CtaCategory/CtaCategoryUpdateForGuest`,
					form_data,
					{
						headers: {
							Authorization: `Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxIiwidW5pcXVlX25hbWUiOiJhZG1pbkBiZXN0cHJhY3RpY2lmeS5jb20iLCJyb2xlIjoiU3VwZXJBZG1pbiIsIm5iZiI6MTYxMjYyODkwMSwiZXhwIjoxNjE3NzI2NTAxLCJpYXQiOjE2MTI2Mjg5MDF9.PEa5F3_yQo6TUi8taUtZ2pbsnQFWjikPuqq_B1OP5pZ7JwKTEXzuMs8wuJRTxF0Dymvb3UCDFh6MKzJcoQIOUQ`,
							"Content-Type": "multipart/form-data",
						},
					}
				)
				.then(
					(res) => {
						setLoading(false);
						////console.log(res);
						//console.log(form_data);
						Cookies.remove("info");
						if (res.data.data.length !== 0) {
							reset();
							nextForm();
						}
					},
					(error) => {
						//console.log(error);
					}
				);

		// if (ID) {
		//   props.nextStep();
		// }
	};

	return (
		<>
			<div className="modal-body mt-2 box">
				{loading ? (
					<div class="overlay" style={{zIndex: "999"}}>
						<div class="overlay__wrapper">
							<div class="overlay__spinner">
								<div class="spinner-border text-secondary" role="status"></div>
								<div className="loading-msg"> Please Wait...!</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-group">
						<textarea
							className="form-control third_page"
							id="exampleFormControlTextarea1"
							rows="3"
							name="goalsToAchieve"
							ref={register({required: "This field is required"})}
						></textarea>
						<label htmlFor="exampleFormControlTextarea1">
							What goals do you want to achieve with this {props.name}?
						</label>
						{errors.goalsToAchieve && (
							<p className="form-error-message-v5">
								{errors.goalsToAchieve.message}
							</p>
						)}
					</div>

					<div className="form-group">
						<input
							className="form-control third_page"
							type="date"
							name="estimation"
							min={minDateToday()}
							ref={register({required: "This field is required"})}
						/>
						<label htmlFor="example-date-input" className="col-form-label">
							When do you need to have this {props.name} created?
						</label>
						{errors.estimation && (
							<p className="form-error-message-v5">
								{errors.estimation.message}
							</p>
						)}
					</div>

					<div className="form-group">
						<textarea
							className="form-control third_page"
							id="exampleFormControlTextarea1"
							rows="3"
							name="tellUsMore"
							ref={register({required: "This field is required"})}
						></textarea>
						<label htmlFor="exampleFormControlTextarea1">
							Tell us more about the need for this {props.name}
						</label>
						{errors.tellUsMore && (
							<p className="form-error-message-v5">
								{errors.tellUsMore.message}
							</p>
						)}
					</div>

					<div className="form-group">
						<textarea
							className="form-control third_page"
							id="exampleFormControlTextarea1"
							rows="3"
							name="description"
							ref={register({required: "This field is required"})}
						></textarea>
						<label htmlFor="exampleFormControlTextarea1">
							Please describe what you'd like done in under 300 words
						</label>
						{errors.description && (
							<p className="form-error-message-v5">
								{errors.description.message}
							</p>
						)}
					</div>

					<div className="d-flex justify-content-between">
						{/* <button
							className="btn btn-primary cta-form-btn w-100 mr-2"
							onClick={() => props.prevStep()}
						>
							Back
						</button> */}

						<button
							className="btn btn-primary cta-form-btn w-100"
							type="submit"
						>
							Continue
						</button>
					</div>
				</form>
			</div>

			<div className="modal-footer box"></div>
		</>
	);
};

export default UserFormDetailsCardThree;
