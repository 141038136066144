
export const genarateSlug = (str) => {

    let slug = str.toLowerCase()
                .trim()
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+|-+$/g, '');

    

    return slug;
}