import React from "react";
import "./freeTrail.css";
const BPWaves = ({className}) => {
  return (
    <div className={`waveswrapper ${className}`}>
    </div>
  );
};

export default BPWaves;
