import axios from "axios";
import React, {useEffect} from "react";
import {Element} from "react-scroll";
import {BASE_API_URL} from "../../Constants";
import "./ExploreClasses.css";

const ExploreClasses = (props) => {
	let [blogSubCategoryItem, setBlogSubCategoryItem] = React.useState([]);

	useEffect(() => {
		axios
			.get(BASE_API_URL + "BlogPost/GetBlogForHomepage", {
				categoryId: 1,
			})
			.then(
				(res) => {
					////console.log(res);
					if (res.data.length !== 0) {
						let value = res.data.data.find((obj) => obj.name === "Class");
						//console.log(value);
						setBlogSubCategoryItem(value.blogSubCategories);
					}
				},
				(error) => {
					//console.log(error);
				}
			);
	}, [props.match.params.blog_subcategory_id]);

	return (
		<Element name="class">
			<div className=" blog-main pt-5">
				<div className="container">
					<div className="alert alert-primary mt-5">
						<div className="no-result">New classes are coming soon</div>
					</div>
				</div>
				{/* {blogSubCategoryItem.length &&
					blogSubCategoryItem.map((item, index) => (
						<div className="container">
							<div className="blog-item pt-5  pb-2">
								{item?.posts.length > 0 ? (
									item.posts.map((item, index) => (
										<div className="card mt-4 mb-4" key={index}>
											<div className="row">
												<div
													className="col-md-2"
													style={{
														backgroundImage: `url(${
															BASE_URL + item.pictureUrl
														})`,
														backgroundPosition: "center",
														backgroundSize: "cover",
														backgroundRepeat: "no-repeat",
														width: "100%",
														height: "200px",
													}}
												></div>
												<div className="col-md-10">
													<div className="card-content">
														<p>{item.blogSubCategoryName}</p>
														<h6 className="pull-left card-title">
															{item.title}
														</h6>

														<p>Updated {item.createdAt}</p>
													</div>

													<div class="card-read-more">
														<Link
															to={`/ClassDetails/${item.id}`}
															className="btn btn-link btn-block"
														>
															Read More
														</Link>
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<div className="alert alert-primary mt-5">
										<div className="no-result">New classes are coming soon</div>
									</div>
								)}
							</div>
						</div>
					))} */}
			</div>
		</Element>
	);
};

export default ExploreClasses;
