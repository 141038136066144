import axios from "axios";
import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import {BASE_API_URL, BASE_URL} from "../../../Constants";
import "./TopVideo.css";
import "./TopVideoResponsive.css";

const TopVideo = (props) => {
	// //console.log(props.menu_id);

	const [index, setIndex] = useState(0);
	const [loading, setLoading] = React.useState(false);

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};
	let [sliders, setSliders] = React.useState([]);

	React.useEffect(() => {
		axios.get(BASE_API_URL + "MenuHeroSlider", {}).then((res) => {
			//  //console.log(res.data.data);
			setLoading(true);
			if (res.data.data.length !== 0) {
				setSliders(res.data.data);
			}
			setLoading(false);
		});
	}, []);
	// //console.log(sliders);

	// sliders.map((item,i)=>//console.log(item.pictureUrl));
	// sliders.map((item,i)=>//console.log(props.menu_id == item.menuId));

	return loading ? (
		<p>loading</p>
	) : (
		<Carousel
			activeIndex={index}
			onSelect={handleSelect}
			controls={false}
			indicators={false}
			className="menu-hero-slider"
		>
			{sliders.map(
				(item, i) =>
					props.menu_id === item.menuId && (
						<Carousel.Item
							key={i}
							style={{backgroundImage: `url(${BASE_URL + item.pictureUrl})`}}
						>
							{/* <img
              className="d-block w-100 img-fluid"
              src={BASE_URL + item.pictureUrl}
              alt="First slide"
            /> */}

							<Carousel.Caption>
								<Container fluid>
									<Row>
										<Col md={7}>
											<h3 className="caption-title">
												<span
													className={`${props.textColor}`}
													dangerouslySetInnerHTML={{__html: item.title}}
												/>
											</h3>
										</Col>
										<Col md={5}></Col>
									</Row>
								</Container>
							</Carousel.Caption>
						</Carousel.Item>
					)
			)}
		</Carousel>
	);
};

export default TopVideo;
