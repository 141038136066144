import http from "./HttpCommon";
import axios from "axios";


class UploadFilesService {
    getFiles(file) {
        //return http.get("")
        return file
    }

    upload(file, id, onUploadProgress) {
        console.log("file: ", file, "id: ", id, "onUploadProgress: ", onUploadProgress);

        let formData = new FormData();

        formData.append("file", file);
        formData.append("ctaFunctionId", id);

        return http.post("CtaDocument/DocumentCreateForGuest", formData, {
            onUploadProgress,
        }).then(res => {
            console.log(res);
            const files = this.getFiles(res);
            return files;
        }, (error) => {
            console.log(error.response);
            return error.response.status;
        })
    }

    deleteFile(documentId, ctaFunctionId) {
        return http.delete(`CtaDocument/DeleteCtaDocumentForGuest/${documentId}/0/${ctaFunctionId}`, {
            headers: {
                "Content-type": "multipart/form-data"
            }
        }).then(res => {
            console.log(res);
            return res;
        }, error => {
            console.log(error);
            console.log(error.response);
            return error.response.status;
        })
    }
}

export default new UploadFilesService();
