import React from "react";
import {FaRegCheckCircle} from "react-icons/fa";
import {useLocation} from "react-router-dom";
import {getDetailsServiceData} from "../../../utils/getDetailsServiceData";
import ScheduleForm from "./SubServiceForm/ScheduleForm";
import "./detailsService.css";
import  { useState } from 'react';
import  { useRef } from 'react';
import { Link } from "react-router-dom";
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-M1J4DTVBB4";

export default function DetailsService() {
	const [clickedValue, setClickedValue] = useState('');
	const secondDivRef = useRef(null);
	

  const handleClick = (value, event) => {
    setClickedValue(value);
    secondDivRef.current.scrollIntoView({ behavior: 'smooth' });
  };
	const location = useLocation();
	let package_list;
	const pathName = location.pathname;
	const {menuId, subMenuId, itemData, menuName, submenuName,subMenus} =
		getDetailsServiceData(pathName);

	console.log("dataT", itemData);

	 ReactGA.initialize(TRACKING_ID);
			
	 ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: {submenuName} });

	const imageName = () => {
		let subServiceImage = "bplogo.png";
		switch (subMenuId) {
			case 5:
				subServiceImage = "data-and-tech.png";
				break;

			case 6:
				subServiceImage = "accounting-and-finance.png";
				break;
			case 8:
				subServiceImage = "ecommerce.png";
				break;

			case 9:
				subServiceImage = "applicaiton-development.png";
				break;
			default:
				subServiceImage = "bplogo.png";
				break;
		}

		return subServiceImage;
	};

	return (
		<>
			<div className="sub-service-section-details">
				<div className="banner">
					<div className="d-flex justify-content-start">
						<div className="d-flex flex-column align-item-center">
							<div style={{fontSize: "2rem", fontWeight: "500",color:"#0096ff",padding:"10px"}}>
								
								<Link style={{color:"#0096ff"}}
                                                      to={{
                                                        pathname: `/${itemData?.menuslug}`,
                                                        state: {
                                                          name: "Services",
                                                          id: 2,
                                                          service:
														  menuName.toLowerCase(),
                                                        },
                                                      }}
                                                      className="megamenu-link"
                                                    >
                                                      {menuName}
                                                    </Link>
							</div>
							<div style={{fontSize: "1.3rem", fontWeight: "500"}}>
								{""}
								&nbsp; {""}
							</div>
						</div>
						<div className="d-flex flex-column align-item-center">
							
							<div style={{fontSize: "1.3rem", fontWeight: "500",paddingTop:"20px",paddingLeft:"10px"}}>
								{" | "}
							</div>
						</div>
						<div className="d-flex flex-column align-item-start">
							
							<div style={{fontSize: "1.3rem", fontWeight: "500",padding:"20px"}}>
								
								<Link style={{color:"#333e63"}}
                                                      to={{
                                                        pathname: `/${itemData?.menuslug}/${itemData?.submenuslug}`,
                                                        state: {
															name: submenuName,
															id: menuId,
															sub_id: subMenuId,
                                                        },
                                                      }}
                                                      className="megamenu-link"
                                                    >
                                                      {submenuName}
                                                    </Link>
							</div>
						</div>
					</div>
				</div>

				<div className="title1">{itemData?.name}</div>
				<div className="info-section">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-8 description angle-right">
								{itemData?.section1?.desc}
							</div>
							<div className="col-md-4 short-text">
								{itemData?.section1?.sub_tag}
							</div>
						</div>
					</div>
				</div>
				<div className="key-advantages-section">
					<div className="container">
						<div className="sub-title">{itemData?.section3?.title}</div>
						<div className="sub-title">{itemData?.section3?.subTitle}</div>
						<div className="row justify-content-md-center">
							{itemData?.section3?.blogs.map((item, index) => (
								<div className="col-md-4 mt-5" key={item.title}>
									<div className="key-item h-100">
										<img src="/bplogo.png" className="key-img" alt="info" />
										<div className="key-title">{item.title}</div>
										<div className="key-desc">{item.subTitle}</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="key-advantages-section">
					<div className="container">
						<div className="sub-title">{itemData?.section4?.title}</div>
						<div className="sub-title">{itemData?.section4?.subTitle}</div>
						<div className="row justify-content-md-center">
							{itemData?.section4?.blogs.map((item, index) => (
								<div className="col-md-4 mt-5" key={item.title}>
									<div className="key-item h-100">
										<img src="/bplogo.png" className="key-img" alt="info" />
										<div className="key-title">{item.title}</div>
										<div className="key-desc">{item.subTitle}</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="key-advantages-section">
					<div className="container">
						<div className="title">{itemData?.section1?.title}</div>
						<div className="sub-title">{itemData?.section1?.subTitle}</div>
						<div className="row justify-content-md-center">
							{itemData?.section1?.blogs.map((item, index) => (
								<div className="col-md-4 mt-5" key={item.title}>
									<div className="key-item h-100">
										<img src="/bplogo.png" className="key-img" alt="info" />
										<div className="key-title">{item.title}</div>
										<div className="key-desc">{item.subTitle}</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="info-section second">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-4 short-text">
								{itemData?.section2?.sub_tag}
							</div>
							<div className="col-md-8 description reverse angle-left">
								{itemData?.section2?.desc}
							</div>
						</div>
					</div>
				</div>

				<div className="key-benefits-section">
					<div className="container">
						<div className="title">{itemData?.section2?.title}</div>
						{/* <div className="sub-title">Our optimization process</div> */}
						<div className="row justify-content-center text-center">
							{itemData?.section2?.blogs.map((item, index) => (
								<div className="col-md-4 mt-5" key={item.title}>
									<div className="key-item h-100">
										<img src="/bplogo.png" className="key-img" alt="info" />
										<div className="key-title">{item.title}</div>
										<div className="key-desc">{item.subTitle}</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				{itemData.hasTable ? (
					<>
						<div className="title">Operational Accounting Service Packages</div>
						<div className="pricing-table-section mb-5 pb-5">
							<div className="container mt-2">
								<div className="row">
									{itemData?.packages.map((item, key) => (
										<div
											className="col-lg-4 col-md-6 col-sm-6 col-xs-1 pl-2 pr-2 mt-2 mb-2"
											key={key} onClick={(e) => handleClick(item.title, e)}
										>
											<div className="pricingTable service-package-table pricing-hov d-flex flex-column">
												<div className="pricingTable-header package">
													<h3 className="Name">{item.title}</h3>
													<p className="tagline">{item.tagline}</p>
												</div>
												<div className="package-content">
												    
													<ul className="list-group">
													
													        <b
																className="list-group-item d-flex justify-content-start align-items-start py-2"
																
															>
																<i>
																<div className="list-item-value1">{item.subtitle}</div>
																</i>
															</b>
														{item.contents.map((value, key) => (
															<li
																className="list-group-item d-flex justify-content-start align-items-start py-2"
																key={key}
															>
																<div className="icon">
																	<FaRegCheckCircle />
																</div>
																<div className="list-item-value">{value}</div>
															</li>
														))}
													</ul>
												</div>
												<div className="package-footer">
													
														<h6 className="text-uppercase title">
															Add-Ons
														</h6>
														<ul className="list-group">
															{item.addons.map((value, key) => (
																<li
																	className="list-group-item d-flex justify-content-start align-items-center py-2"
																	key={key}
																>
																	<div className="icon">
																		<FaRegCheckCircle />
																	</div>
																	<div className="pt-1 pl-2">{value}</div>
																</li>
															))}
														</ul>
													
													<div className="pricingTable-price">
												
														
															<p className="text-center4">Get Started</p>
															

														
														
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</>
				) : (
					""
				)}

				<div className="conclusion-section">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-6 text-part d-flex align-items-center py-5">
								{itemData?.conculation?.title}
							</div>
							<div className="col-md-6 text-center image-part">
								<img
									src={`/sub-service-img/${imageName()}`}
									width="auto"
									height="250px"
									alt="info"
									className="conclusion-image"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="conclusion-section">
					<div className="container-fluid">
						<div className="col large-text">
						We offer risk-free consultation. Contact us today to leverage best practices.
						</div>
					</div>
				</div>

				<div className="form-fields my-3" ref={secondDivRef}>
					<ScheduleForm
						menuId={menuId}
						submenuId={subMenuId}
						selectService={itemData?.name}
						selectPackage={clickedValue}
					/>
					
				</div>

				
			</div>
		</>
	);
}
