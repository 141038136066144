import axios from "axios";
import Cookies from "js-cookie";
import React, {useState} from "react";
import {Alert} from "react-bootstrap";
import Select from "react-select";
import {context} from "../../../App";
import {BASE_API_URL} from "../../../Constants";
import "./UserForm.css";

const customStyles = {
	option: (provided, state) => ({
		...provided,
		borderBottom: "0",
		color: state.isSelected ? "#ffffff" : "#3E3E3E",
	}),

	indicatorSeparator: (styles) => ({
		...styles,
	}),

	valueContainer: (styles) => ({
		...styles,
		padding: "2px 8px 2px 0",
	}),

	control: (styles) => ({
		...styles,
		backgroundColor: "white",
		padding: "0px",
		minHeight: "50px",
		border: "none",
		borderRadius: "none",
		borderBottom: "2px solid #9999",
	}),
};

const UserFormDetailsCardTwo = (props) => {
	// //console.log(props);

	const {ID, getFormData, setFormData} = React.useContext(context);
	// //console.log("ID: ", ID, "getFormData: ", getFormData);

	let path = window.location.pathname;
	// let pathSubmenuId = path && path.split("/")[3];
	// //console.log(pathSubmenuId);

	let [getId, setGetId] = React.useState();
	let [reqErr, setReqErr] = React.useState(false);
	let [reqErrMessage, setReqErrMessage] = React.useState();

	// const [businessStages, setBusinessStages] = React.useState([]);
	// const [companyTypes, setCompanyTypes] = useState([]);
	// const [companySizes, setCompanySizes] = useState([]);
	const [solutionSpecificities, setSolutionSpecificities] = React.useState([]);
	const [serviceSpecificities, setServiceSpecificities] = React.useState([]);
	const [technologyPreferences, setTechnologyPreferences] = React.useState([]);
	const [loading, setLoading] = useState(false);

	// //console.log("ID: ", ID);

	let [getInputVal, setInputVal] = React.useState({
		solutionSpecificity: getFormData.solutionSpecificity
			? getFormData.solutionSpecificity
			: "",
		solutionSpecificityErr: getFormData.solutionSpecificityErr
			? getFormData.solutionSpecificityErr
			: false,

		goalsToAchieveSolution: getFormData.goalsToAchieveSolution
			? getFormData.goalsToAchieveSolution
			: "",
		goalsToAchieveSolutionErr: getFormData.goalsToAchieveSolutionErr
			? getFormData.goalsToAchieveSolutionErr
			: false,

		goalsToAchieveService: getFormData.goalsToAchieveService
			? getFormData.goalsToAchieveService
			: "",
		goalsToAchieveServiceErr: getFormData.goalsToAchieveServiceErr
			? getFormData.goalsToAchieveServiceErr
			: false,

		serviceSpecificity: getFormData.serviceSpecificity
			? getFormData.serviceSpecificity
			: "",
		serviceSpecificitiesErr: getFormData.serviceSpecificitiesErr
			? getFormData.serviceSpecificitiesErr
			: false,

		technologyPreference: getFormData.technologyPreference
			? getFormData.technologyPreference
			: "",
		technologyPreferenceErr: getFormData.technologyPreferenceErr
			? getFormData.technologyPreferenceErr
			: false,

		goalsToAchieveTechnology: getFormData.goalsToAchieveTechnology
			? getFormData.goalsToAchieveTechnology
			: "",
		goalsToAchieveTechnologyErr: getFormData.goalsToAchieveTechnologyErr
			? getFormData.goalsToAchieveTechnologyErr
			: false,
	});

	const handleChange = (input) => (e) => {
		// //console.log({ [input]: e.target.value });

		setInputVal({
			...getInputVal,
			[input]: e.target.value,
			[e.target.id]: e.target.value ? false : true,
		});

		setFormData({
			...getFormData,
			id: ID,
			[input]: e.target.value,
			[e.target.id]: e.target.value ? false : true,
		});
	};

	async function nextForm() {
		await props.nextStep();
	}
	const onSubmit = (e) => {
		e.preventDefault();
		// //console.log(getInputVal);

		const checkErr =
			getInputVal.solutionSpecificity !== "" &&
			getInputVal.goalsToAchieveSolution !== "" &&
			getInputVal.goalsToAchieveService !== "" &&
			getInputVal.serviceSpecificity !== "" &&
			getInputVal.technologyPreference !== "" &&
			getInputVal.goalsToAchieveTechnology !== "";

		if (getInputVal.solutionSpecificity === "") {
			getInputVal.solutionSpecificityErr = true;
			setInputVal({...getInputVal, solutionSpecificityErr: true});
		} else {
			getInputVal.solutionSpecificityErr = false;
			setInputVal({...getInputVal, solutionSpecificityErr: false});
		}

		if (getInputVal.goalsToAchieveSolution === "") {
			getInputVal.goalsToAchieveSolutionErr = true;
			setInputVal({...getInputVal, goalsToAchieveSolutionErr: true});
		}
		if (getInputVal.goalsToAchieveService === "") {
			getInputVal.goalsToAchieveServiceErr = true;
			setInputVal({...getInputVal, goalsToAchieveServiceErr: true});
		}

		if (getInputVal.serviceSpecificity === "") {
			getInputVal.serviceSpecificitiesErr = true;
			setInputVal({...getInputVal, serviceSpecificitiesErr: true});
		} else {
			getInputVal.serviceSpecificitiesErr = false;
			setInputVal({...getInputVal, serviceSpecificitiesErr: false});
		}

		if (getInputVal.technologyPreference === "") {
			getInputVal.technologyPreferenceErr = true;
			setInputVal({...getInputVal, technologyPreferenceErr: true});
		} else {
			getInputVal.technologyPreferenceErr = false;
			setInputVal({...getInputVal, technologyPreferenceErr: false});
		}

		if (getInputVal.goalsToAchieveTechnology === "") {
			getInputVal.goalsToAchieveTechnologyErr = true;
			setInputVal({...getInputVal, goalsToAchieveTechnologyErr: true});
		}
		// //console.log(getInputVal);
		// //console.log(checkErr);

		const data = Cookies.get("info") ? JSON.parse(Cookies.get("info")) : "";

		const data_saved = {...data, ...getFormData};

		// //console.log(data_saved);
		if (checkErr) {
			setLoading(true);
			ID &&
				axios
					.put(
						BASE_API_URL + `CtaFunction/CtaFunctionUpdateForGuest`,
						data_saved,
						{headers: {"Content-Type": "application/json"}}
					)
					.then(
						(res) => {
							// ////console.log(res);
							setReqErr(false);
							setLoading(false);

							if (res.data.data) {
								Cookies.set("info", data_saved);
								nextForm();
							}
						},
						(error) => {
							// //console.log(error.response.status);

							setReqErr(true);

							if (error.response.status.toString().includes("4")) {
								setReqErrMessage("Client side error");
							} else if (error.response.data.status.toString().includes("5")) {
								setReqErrMessage("Server side error");
							} else {
								setReqErrMessage("Error Happen");
							}
						}
					);
		}
	};

	// const onBusinessStagesSelect = (selectedList, selectedItem) => {
	//   //console.log(selectedList, selectedItem);
	//   setInputVal({ ...getInputVal, businessStage: selectedList[0].id });
	//   setFormData({ ...getFormData, businessStage: selectedList[0].id });
	// };

	// const onCompanyTypesSelect = (selectedList, selectedItem) => {
	//   //console.log(selectedList[0].id, selectedItem);
	//   setInputVal({ ...getInputVal, companyTypeId: selectedList[0].id });
	//   setFormData({ ...getFormData, companyTypeId: selectedList[0].id });
	// };

	// const onCompanySizesSelect = (selectedList, selectedItem) => {
	//   //console.log(selectedList[0].id, selectedItem);
	//   setInputVal({ ...getInputVal, companySizeId: selectedList[0].id });
	//   setFormData({ ...getFormData, companySizeId: selectedList[0].id });
	// };

	const onSolutionSpecificitiesSelect = (selectedItem) => {
		// //console.log(selectedList[0].id, selectedItem);
		setInputVal({...getInputVal, solutionSpecificity: selectedItem.id});
		setFormData({...getFormData, solutionSpecificity: selectedItem.id});
	};

	const onServiceSpecificitiesSelect = (selectedItem) => {
		// //console.log(selectedItem);
		setInputVal({...getInputVal, serviceSpecificity: selectedItem.id});
		setFormData({...getFormData, serviceSpecificity: selectedItem.id});
	};

	const ontechnologyPreferenceSelect = (selectedItem) => {
		let value = selectedItem.map((item) => item.id);

		value = value.join();

		setInputVal({...getInputVal, technologyPreference: value});
		setFormData({...getFormData, technologyPreference: value});

		// if (value) {
		//   let new_value = [...value, selectedItem.id];
		//   setInputVal({ ...getInputVal, technologyPreference: new_value });
		//   setFormData({ ...getFormData, technologyPreference: new_value });
		// } else {
		//   setInputVal({ ...getInputVal, technologyPreference: [selectedItem.id] });
		//   setFormData({ ...getFormData, technologyPreference: [selectedItem.id] });
		// }
	};

	React.useEffect(() => {
		axios.get(BASE_API_URL + `CtaFunction/CtaFunctionModel`, {}).then((res) => {
			// ////console.log(res);
			// //console.log(res.data);

			if (res.data.length != 0) {
				// setFunctionModel(res.data);
				// setBusinessStages(res.data.businessStages);
				// setCompanyTypes(res.data.companyTypes);
				// setCompanySizes(res.data.companySizes);
				let service = [];

				res.data.serviceSpecificities.map((item) => {
					service = [
						...service,
						{value: item.name, label: item.name, id: item.id},
					];
				});

				setServiceSpecificities(service);

				let solution = [];

				res.data.solutionSpecificities.map((item) => {
					solution = [
						...solution,
						{value: item.name, label: item.name, id: item.id},
					];
				});

				setSolutionSpecificities(solution);

				let technology = [];
				// //console.log(res.data.techStacks);
				res.data.techStacks.map((item) => {
					if (technology.length) {
						let cache = technology.find((obj) => obj.label === item.category);
						let position = technology.findIndex(
							(obj) => obj.label === item.category
						);
						if (cache) {
							let new_option = [
								...cache.options,
								{value: item.name, label: item.name, id: item.id},
							];
							technology[position].options = new_option;
						} else {
							technology = [
								...technology,
								{
									label: item.category,
									options: [{value: item.name, label: item.name, id: item.id}],
								},
							];
						}
					} else {
						technology = [
							{
								label: item.category,
								options: [{value: item.name, label: item.name, id: item.id}],
							},
						];
					}
				});

				setTechnologyPreferences(technology);
			}
		});
	}, []);

	const handleBackBtn = () => {
		props.prevStep();
	};

	if (getInputVal.technologyPreference) {
		// //console.log(getInputVal.technologyPreference);
	}

	return (
		<>
			<div className="modal-body">
				{loading ? (
					<div class="overlay" style={{zIndex: "999"}}>
						<div class="overlay__wrapper">
							<div class="overlay__spinner">
								<div class="spinner-border text-secondary" role="status"></div>
								<div className="loading-msg"> Please Wait...!</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}

				{reqErr && <Alert variant="danger">{reqErrMessage}</Alert>}

				<form onSubmit={onSubmit}>
					<label className="dropdown-levels text-left w-100">
						Solution Specificity
					</label>
					<div className=" mb-5">
						<Select
							options={solutionSpecificities}
							className="basic-multi-select"
							placeholder="Select Solution Specificity"
							name="solutionSpecificity"
							id="solutionSpecificityErr"
							styles={customStyles}
							onChange={(value) => onSolutionSpecificitiesSelect(value)}
							classNamePrefix="select"
						/>

						{getInputVal.solutionSpecificityErr && (
							<p className="form-error-message-v2">
								Solution Specificity is Required
							</p>
						)}
					</div>

					<div className="form-group box">
						<textarea
							className="form-control"
							id="goalsToAchieveSolutionErr"
							rows="3"
							name="goalsToAchieveSolution"
							onChange={handleChange("goalsToAchieveSolution")}
							style={{marginBottom: "19px"}}
							value={getInputVal.goalsToAchieveSolution}
						></textarea>
						<label className="" htmlFor="goalsToAchieveSolutionErr">
							What goals do you want to achieve with this solution?
						</label>
						{getInputVal.goalsToAchieveSolutionErr && (
							<p className="form-error-message-v3">This feild is required</p>
						)}
					</div>

					<label className="dropdown-levels text-left w-100">
						Service Specificity
					</label>
					<div className=" mb-5">
						<Select
							options={serviceSpecificities}
							className="basic-multi-select"
							placeholder="Select Service Specificity"
							name="serviceSpecificity"
							id="serviceSpecificitiesErr"
							styles={customStyles}
							onChange={(value) => onServiceSpecificitiesSelect(value)}
							classNamePrefix="select"
						/>
						{getInputVal.serviceSpecificitiesErr && (
							<p className="form-error-message-v2">
								Service Specificity is Required
							</p>
						)}
					</div>

					<div className="form-group box">
						<textarea
							className="form-control"
							id="goalsToAchieveServiceErr"
							rows="3"
							name="goalsToAchieveService"
							onChange={handleChange("goalsToAchieveService")}
							style={{marginBottom: "19px"}}
							value={getInputVal.goalsToAchieveService}
						></textarea>
						<label className="" htmlFor="goalsToAchieveServiceErr">
							What goals do you want to achieve with this service?
						</label>
						{getInputVal.goalsToAchieveServiceErr && (
							<p className="form-error-message-v3">This feild is required</p>
						)}
					</div>

					<label className="dropdown-levels text-left w-100">
						Technology Preference
					</label>
					<div className="form-group">
						<div className=" mb-5">
							<Select
								options={technologyPreferences}
								isMulti
								className="basic-multi-select"
								placeholder="Select Technology Preference"
								// name="technologyPreference"
								// id="technologyPreferenceErr"
								styles={customStyles}
								onChange={(value) => ontechnologyPreferenceSelect(value)}
								classNamePrefix="select"
							/>
							{getInputVal.technologyPreferenceErr && (
								<p className="form-error-message-v2">
									Technology Preference is Required
								</p>
							)}
						</div>
					</div>

					<div className="form-group box">
						<textarea
							className="form-control"
							id="goalsToAchieveTechnologyErr"
							rows="3"
							name="goalsToAchieveTechnology"
							onChange={handleChange("goalsToAchieveTechnology")}
							style={{marginBottom: "19px"}}
							value={getInputVal.goalsToAchieveTechnology}
						></textarea>
						<label className="" htmlFor="goalsToAchieveTechnologyErr">
							What goals do you want to achieve with this technology?
						</label>
						{getInputVal.goalsToAchieveTechnologyErr && (
							<p className="form-error-message-v3">This feild is required</p>
						)}
					</div>

					{reqErr && (
						<p style={{color: "red", marginTop: "-23px"}}>{reqErrMessage}</p>
					)}

					<div className="d-flex justify-content-between">
						{/* <button
              className="btn btn-primary cta-form-btn w-100 mr-2"
              onClick={() => handleBackBtn()}
            >
              Back
            </button> */}

						<button
							className="btn btn-primary cta-form-btn w-100"
							type="submit"
						>
							Continue
						</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default UserFormDetailsCardTwo;
