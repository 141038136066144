import axios from "axios";
import React from "react";
import {BASE_API_URL, BASE_URL} from "../../../Constants";

const BusinessPrincipleDescriptor = () => {
	let [descriptor, setDiscriptor] = React.useState([]);
	let [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		setLoading(true);
		axios.get(BASE_API_URL + `BusinessPrincipalDescriptor`, {}).then((res) => {
			if (res.data.status) {
				setDiscriptor(res.data.data);
			}

			setLoading(false);
		});
	}, []);
	return (
		<>
			{loading ? (
				<h1>Loading...</h1>
			) : (
				<div>
					{descriptor &&
						descriptor.map((descriptorsItem, index) =>
							index % 2 === 0 ? (
								<div className="uniqueSolutionDetail-even">
									<div className="container">
										<div className="row">
											<div
												className="col-md-6 d-flex align-items-center"
												key={Math.random()}
											>
												<div className="content">
													<p className="section-heading-large">
														{descriptorsItem.title}
													</p>
													<div
														className="list-describe"
														dangerouslySetInnerHTML={{
															__html: descriptorsItem.description,
														}}
													/>
													{/* <div className="list-describe">
            {descriptorsItem.description.replace(
              /<\/?p[^>]*>/g,
              ""
            )}
          </div> */}
												</div>
											</div>
											<div
												className="col-md-6 d-flex align-items-center"
												key={Math.random()}
											>
												<img
													style={{width: "100%", height: "100%"}}
													src={BASE_URL + descriptorsItem.pictureUrl}
													alt=""
												/>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div className="uniqueSolutionDetail-odd">
									<div className="container">
										<div className="row">
											<div
												className="col-md-6 d-flex align-items-center"
												key={Math.random()}
											>
												<img
													style={{width: "100%", height: "100%"}}
													src={BASE_URL + descriptorsItem.pictureUrl}
													alt=""
												/>
											</div>
											<div
												className="col-md-6 d-flex align-items-center"
												key={Math.random()}
											>
												<div className="content">
													<p className="section-heading-large">
														{descriptorsItem.title}
													</p>
													<div
														className="list-describe"
														dangerouslySetInnerHTML={{
															__html: descriptorsItem.description,
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						)}
				</div>
			)}
		</>
	);
};

export default BusinessPrincipleDescriptor;
