import axios from "axios";
import React, {Suspense, useEffect} from "react";
import {BASE_API_URL} from "../../Constants";
import Logo from "../../Images/logo.svg";
import ClientSlider from "./ClientSlider/ClientSlider";
import "./Home.css";
import ReactGA from 'react-ga4';
import Career from "../Career/Career";
import { useHistory } from "react-router-dom";
const ContactRequest = React.lazy(() =>
	import("../Home/ContactRequest/ContactRequest")
);
const ErrorBoundary = React.lazy(() => import("../ErrorBoundary"));
const BpTraining = React.lazy(() => import("./BpTraining"));
const Consultation = React.lazy(() => import("./Consultation"));
const CoreValues = React.lazy(() => import("./CoreValues"));
const InterConnectivities = React.lazy(() => import("./InterConnectivities"));
const ModernTech = React.lazy(() => import("./ModernTech/ModernTech"));
const PersonalizedService = React.lazy(() =>
	import("./PersonalizedService/PersonalizedService")
);
const Services = React.lazy(() => import("./Services"));
const Testimonials = React.lazy(() => import("./Testimonials"));
const TopVideo = React.lazy(() => import("./TopVideo/TopVideo"));
const UniqueSolution = React.lazy(() =>
	import("./UniqueSolution/UniqueSolution")
);
const TRACKING_ID = "G-M1J4DTVBB4";
const Home = (props) => {
	
	// const Spinner = () => (
	//   <><img src={spinner} alt=""/></>
	// )
	const [homepageDetails, setHomePageDetails] = React.useState([]);
	const [navHeight, setNavHeight] = React.useState(80);
	const [loading, setLoading] = React.useState(false);

	
	
	React.useEffect(() => {
		
		// setLoading(true);
		axios.get(BASE_API_URL + `HomePage/GetHomePage`, {}).then((res) => {
			if (res.data !== 0 && res.data !== null) {
				// //console.log(res.data);
				setHomePageDetails(res.data);
				setLoading(false);

				ReactGA.initialize(TRACKING_ID);
			// Send pageview with a custom path
			    ReactGA.send({ hitType: "pageview", page: "/home", title: "Home Page" });
			}
		});

		
		
		
		// setTimeout(() => {
		//   axios.get(BASE_API_URL + `HomePage/GetHomePage`, {}).then((res) => {
		//     if (res.data !== 0 && res.data !== null) {
		//       //console.log(res.data);
		//       setLoading(false);
		//       setHomePageDetails(res.data);
		//     }
		//   });
		// }, 100000);
		
	}, [loading]);
	// alvi loading added
	

	return (
		<>
			<ErrorBoundary>
				<Suspense
					fallback={
						loading && (
							<div className="loading-section">
								<div class="overlay">
									<div class="overlay__inner">
										<div class="overlay__content">
											{/* <span class="spinner"></span> */}
											<div>
												<img src={Logo} width="100%" height="auto" alt="" />
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					}
				>
					<main className="main">
						<>
						   
							<TopVideo />
							<Services
								title={homepageDetails.shortIntroTitle}
								subTitle={homepageDetails.shortIntroSubTitle}
								data={homepageDetails.shortIntros}
								loading={loading}
							/>
							<InterConnectivities
								className="light-blue-bg"
								title={homepageDetails.functionAreaWalkthroughTitle}
								subTitle={homepageDetails.functionAreaWalkthroughSubTitle}
								data={homepageDetails.homePageFunctionAreaDetails}
								loading={loading}
							/>
							<UniqueSolution
								title={homepageDetails.uniqueSolutionTitle}
								subTitle={homepageDetails.uniqueSolutionSubTitle}
								data={homepageDetails.uniqueSolutionDetails}
								onPage={false}
								loading={loading}
							/>
							<PersonalizedService
								title={homepageDetails.personalizedServiceTitle}
								subTitle={homepageDetails.personalizedServiceSubTitle}
								data={homepageDetails.personalizedServiceDetails}
								loading={loading}
							/>
							<ModernTech
								title={homepageDetails.modernTechTitle}
								subTitle={homepageDetails.modernTechSubTitle}
								data={homepageDetails.modernTechDetails}
								loading={loading}
							/>
							<Consultation
								title={homepageDetails.consultingTitle}
								subTitle={homepageDetails.consultingSubTitle}
								data={homepageDetails.homeConsultationTopics}
								loading={loading}
								
							/>
							<CoreValues
								title={homepageDetails.coreValueTitle}
								subTitle={homepageDetails.coreValueSubtitle}
								data={homepageDetails.homepageCoreValueDetails}
								loading={loading}
							/>
							<BpTraining
								title={homepageDetails.trainingTitle}
								subTitle={homepageDetails.trainingSubtitile}
								loading={loading}
							/>
							{/* <Testimonials loading={loading} /> */}
							<ClientSlider loading={loading} />
							<ContactRequest loading={loading} />
							
						</>
					</main>
				</Suspense>
			</ErrorBoundary>
			{/* <PageView /> */}
		</>
	);
};

export default Home;
