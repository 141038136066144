import $ from "jquery";
import React, {useState} from "react";
import contactImg from "../../../Images/Contact-Us.png";
import UserForm from "../../Home/ConsultingForm/CategoryPages/CategoryPagesSolutionsForm/UserForm";
import "./ContactRequest.css";
// or import ".../bootstrap.min.js"

const ContactRequest = (props) => {
	const [pop, setPop] = useState(false);

	const onSetPupup = (data) => {
		setPop(data);
	};

	// this is for contnt-testing, just remove this if problem fixed
	var leftText = <span>{props.ctaLeft}</span>;
	// if (props.menuId == 1) {
	//   leftText = <span className="text-danger"> {props.ctaLeft}</span>;
	// } else {
	//   leftText = <span>{props.ctaLeft}</span>;
	// }

	React.useEffect(() => {
		if (props.popup) {
			setTimeout(function () {
				$("#footerConsultationModal").modal("show");
			}, 5000);
		}
	});

	return (
		<div className="contact-request">
			<div className="container mt-5">
				<div className="insights-header text-center pt-3 pb-4">
					<h3
						dangerouslySetInnerHTML={{
							__html: props.title,
						}}
					></h3>
				</div>
				<div className="contact mt-3 mb-3">
					<div className="row">
						<div className="col-md-6 p-0">
							<div className="contact-container">
								<div className="row">
									<div className="col-md-6">
										<div className="contact-left">
											<img src={contactImg} alt="" width="300" height="300" />
										</div>
									</div>
									<div className="col-md-6 p-3 d-flex align-items-center">
										<div className="contact-right d-flex flex-column">
											<p className="text-center">{leftText}</p>
											<button
												type="button"
												data-toggle="modal"
												data-target=".bd-example-modal-lg-contact"
												className="btn btn-info"
											>
												Contact Us
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-6 text-center p-0 ">
							<div className="request-container d-flex align-items-center">
								<div className="content">
									<p> {props.ctaRight}</p>
									<button
										type="button"
										data-toggle="modal"
										data-target="#footerConsultationModal"
										data-backdrop="static"
										data-keyboard="false"
										className="btn btn-light text-info"
									>
										Request For Proposal
									</button>
								</div>

								<div
									className="modal fade"
									id="footerConsultationModal"
									tabIndex="-1"
									role="document"
									aria-labelledby="myLargeModalLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered modal-xl">
										<div className="modal-content">
											<UserForm
												menuId={props.menuId}
												// popUp={onSetPupup}
												menuName={props.menuName}
												name={props.name}
												title="Request For Proposal"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactRequest;
