import axios from "axios";
import { BASE_API_URL } from '../../../../../../Constants';

export default axios.create({
    baseURL: BASE_API_URL,
    headers: {
        // "Content-type": "application/json"
        'Authorization': `Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxIiwidW5pcXVlX25hbWUiOiJhZG1pbkBiZXN0cHJhY3RpY2lmeS5jb20iLCJyb2xlIjoiU3VwZXJBZG1pbiIsIm5iZiI6MTYxMjYyODkwMSwiZXhwIjoxNjE3NzI2NTAxLCJpYXQiOjE2MTI2Mjg5MDF9.PEa5F3_yQo6TUi8taUtZ2pbsnQFWjikPuqq_B1OP5pZ7JwKTEXzuMs8wuJRTxF0Dymvb3UCDFh6MKzJcoQIOUQ`
    }
})
