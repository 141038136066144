import React from "react";
import {context} from "../../../../../App";
import UploadFiles from "./UploadFiles/UploadFilesComponent";
import "./UserForm.css";

const UserFormDetailsCardFour = (props) => {
	//console.log(props);

	const {ID} = React.useContext(context);
	// //console.log(ID);

	const handleNextBtn = () => {
		props.nextStep();
	};

	const handleBackBtn = () => {
		props.prevStep();
	};

	return (
		<>
			<div className="modal-body box">
				<div className="custom-file clearfix">
					<UploadFiles id={ID} />
				</div>

				<div className="d-flex justify-content-between">
					<button
						className="btn btn-primary cta-form-btn w-100 mr-2"
						type="button"
						onClick={() => handleBackBtn()}
					>
						Back
					</button>

					<button
						className="btn btn-primary cta-form-btn w-100"
						type="submit"
						onClick={() => handleNextBtn()}
					>
						Continue
					</button>
				</div>
			</div>

			{/* <div className="modal-footer box"></div> */}
		</>
	);
};

export default UserFormDetailsCardFour;
