import React, { useEffect, useState } from "react";
import "./Career.css";
// import headerImg from "../../assets/img/HeroSliderImg/homepage---slider.png"
import headerImg from "../../../src/Images/header-img-2.jpg";
import { Button } from "@material-ui/core";
import { FaChevronDown, FaMapMarkerAlt, FaMoneyBillWave } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import globeImg from "../../assets/img/1.png";
import health from "../../assets/img/2.png";
import remote from "../../assets/img/3.png";
import retreat from "../../assets/img/4.png";
import learning from "../../assets/img/5.png";
import annual from "../../assets/img/6.png";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import { Accessible, BusinessCenter, Work } from "@material-ui/icons";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-M1J4DTVBB4";
const Career = () => {
    // Function to sanitize HTML and remove tags
    const sanitizeHTML = (html) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    };

    const location = useLocation();

    //card
    const [jobPosts, setJobPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 8;
    const history = useHistory();
    const data = { jobPosts };

       ReactGA.initialize(TRACKING_ID);
       // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/careers", title: "Career Page" });

    // State variable for the spinner
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        fetch("https://api.bestpracticify.co/api/BpJobInformation/searchAll")
            .then((response) => response.json())
            .then((data) => {
                const filteredPosts = data?.data?.item1.filter(job => job.jobStatus === true);
                setJobPosts(filteredPosts);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching job posts:", error);
                setLoading(false);
            });
    }, []);
   

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    // const currentPosts = jobPosts?.slice(indexOfFirstPost, indexOfLastPost);
    const currentPosts = jobPosts?.slice(0, 9);
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    // Extract unique category names from jobPosts using a Set
    const uniqueCategoryNames = Array.from(
        new Set(jobPosts?.map((post) => post.categoryName))
    );

    const [selectedCategory, setSelectedCategory] = useState("rigatoni");

    // Function to filter jobPosts based on the selected category
    const filteredPosts = jobPosts?.filter((post) => {
        // If selectedCategory is "rigatoni," show all posts
        if (selectedCategory === "rigatoni") {
            return true;
        }
        // Otherwise, show posts that match the selected category
        return post.categoryName === selectedCategory;
    });
    return (
        <>
            <div>
                <div
                    className="career-header-section  "
                    style={{
                        backgroundImage: `url(${headerImg})`,
                        backgroundSize: "100% 100%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div className="header-section ">
                        <div className="for-career-main-text">
                            <h1 className="culture-text">
                                A Culture That <br /> Inspires and <br />
                                Transforms People
                            </h1>
                            <br />
                        </div>
                    </div>
                </div>

                {/* new */}
                <div>
                    {/* <div className='separator_dark'></div> */}
                    <div className="working p-3 bg-color-for-new ">
                        <div className="container">
                            <div className="row working-2">
                                <div className="col-md-6 ">
                                    {/* Content for the first div */}
                                    <div className=" card-2">
                                        <div className="card-body">
                                            {/* <div className='flex'>
                                                <img src="/bplogo.png" className="key-img" alt="info" />
                                            </div> */}
                                            <h3 className="header-text">
                                                Working at Best Practicify
                                            </h3>
                                            <p className="card-text-1">
                                                At Best Practicify, our culture is our compass, guiding
                                                us towards a future where innovation is the spark that
                                                ignites change, resilience the steel that fortifies our
                                                resolve, and integrity the bedrock of all we do. We
                                                value fidelity to our core principles, and we know that
                                                our greatest achievements are born from the synergy of a
                                                team united in purpose. We're not just looking for
                                                employees; we're seeking visionaries, change-makers, and
                                                relentless collaborators. Join us, and together we'll
                                                craft a future that's as thrilling as it is rewarding.
                                                Your talents are the key, and here, they'll unlock a
                                                world of endless possibilities.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    {/* Content for the second div */}
                                    {/* <div className="card">
                                        <div className="card-body">
                                            <div className='flex'>
                                                <img src="/bplogo.png" className="key-img" alt="info" />
                                            </div>
                                            <h5 className=" text-center p-2">Empowering Your Professional Success</h5>
                                            <p className="card-text-1">At Best Practicify, we're not just a company; we're your catalyst for professional success. We believe in nurturing your ambitions, empowering you to chase your passions, and achieving the growth goals that fuel your aspirations. We provide the platform, the guidance, and the opportunities, but it's your dedication, innovation, and passion that will define your path. Join us, and together, we'll craft a narrative of growth, excellence, and inspirational success that will leave a lasting impact.
                                            </p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className="row working-2">
                                <div className="col-md-6 ">
                                    {/* Content for the first div */}
                                    {/* <div className="card card-2">
                                        <div className="card-body">
                                            <div className='flex'>
                                                <img src="/bplogo.png" className="key-img" alt="info" />
                                            </div>
                                            <h5 className=" text-center p-2">Working at Best Practicify</h5>
                                            <p className="card-text-1">At Best Practicify, our culture is our compass, guiding us towards a future where innovation is the spark that ignites change, resilience the steel that fortifies our resolve, and integrity the bedrock of all we do. We value fidelity to our core principles, and we know that our greatest achievements are born from the synergy of a team united in purpose. We're not just looking for employees; we're seeking visionaries, change-makers, and relentless collaborators. Join us, and together we'll craft a future that's as thrilling as it is rewarding. Your talents are the key, and here, they'll unlock a world of endless possibilities..</p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-md-6 ">
                                    {/* Content for the first div */}
                                    <div className=" card-2">
                                        <div className="card-body">
                                            {/* <div className='flex'>
                                                <img src="/bplogo.png" className="key-img" alt="info" />
                                            </div> */}
                                            <h3 className="header-text">
                                                Empowering Your Professional Success
                                            </h3>
                                            <p className="card-text-1">
                                                At Best Practicify, we're not just a company; we're your
                                                catalyst for professional success. We believe in
                                                nurturing your ambitions, empowering you to chase your
                                                passions, and achieving the growth goals that fuel your
                                                aspirations. We provide the platform, the guidance, and
                                                the opportunities, but it's your dedication, innovation,
                                                and passion that will define your path. Join us, and
                                                together, we'll craft a narrative of growth, excellence,
                                                and inspirational success that will leave a lasting
                                                impact.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="vertical dark-2 separator_dark"></div>
                </div>

                {/* open-rules-section */}
                <div>
            <div className="container">
                <div className="open-rules-section " id="open-rules">
                    <h1 className="first-title mt-3">Open Roles</h1>
                    <div className="open-rules-section-container">
                        <div className="part-1">
                            <p className="for-top-gap paragraph-title">
                                Our company's foundation is built upon the capabilities of
                                our team. Right from the beginning, we've nurtured a culture
                                that's grounded in our unique philosophy and principles:
                                prioritizing individuals and unwaveringly backing our team
                                members, regardless of the circumstances.
                            </p>
                        </div>
                        <div className="part-2">
                            <label className="first-title-departments" htmlFor="dep-names">
                                Department
                            </label>
                            <br />
                            <select
                                name="dep-names"
                                id="dep-names"
                                style={{ fontSize: '20px', padding: '5px' }}
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                            >
                                <option className="" value="rigatoni" style={{ fontSize: '20px', padding: '15px' }}>
                                    All
                                </option>
                                {uniqueCategoryNames.map((categoryName, index) => (
                                    <option className="" name="dep-names" id="dep-names" key={index} value={categoryName} style={{ fontSize: '20px', padding: '10px' }}>
                                        {categoryName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                {/* job post */}
                <div>
                    <div className="job-post">
                        {loading && (
                            <div className="spinner-overlay">
                                {/* You can replace this with your spinner component */}
                                <div className="spinner">Loading...</div>
                            </div>
                        )}
                        {currentPosts 
                            .filter(post => selectedCategory === 'rigatoni' || post.categoryName === selectedCategory)
                            .map((post, index) => (

                                
                                <Link
                                    to={`/jobpostdetails/${post.id}`}
                                    className="card-designer no-decoration card-gap"
                                    key={post.id}
                                >
                                    <div className="card">
                                        <div className="category">
                                            <p>
                                                {post.categoryName && (
                                                    <span className="category-bg">
                                                        {post.categoryName}
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                        <div className="pl-1 for-margin">
                                            <h6 className="for-margin">{post.jobTitle}</h6>
                                        </div>

                                        {post.jobType && (
                                            <p
                                                className="for-margin"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#0096FF",
                                                }}
                                            >
                                                <BusinessCenter
                                                    style={{ marginRight: "0.1rem", height: "1.25rem" }}
                                                />
                                                <span
                                                    className="use-for-m-top"
                                                    style={{ marginTop: "0.2rem" }}
                                                >
                                                    {post.jobType}
                                                </span>
                                            </p>
                                        )}
 
                                        {post.numberOfVacancy && (
                                            <p
                                                className="for-margin"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#0096FF",
                                                }}
                                            >
                                                <Work
                                                    style={{ marginRight: "0.1rem", height: "1.25rem" }}
                                                />
                                                <span
                                                    className="use-for-m-top"
                                                    style={{ marginTop: "0rem" }}
                                                >
                                                    Available Position(s): {post.numberOfVacancy}
                                                </span>
                                            </p>
                                        )}

                                        {post.careerLevel && (
                                            <p
                                                className="for-margin"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#0096FF",
                                                }}
                                            >
                                                <Work
                                                    style={{ marginRight: "0.1rem", height: "1.25rem" }}
                                                />
                                                <span
                                                    className="use-for-m-top"
                                                    style={{ marginTop: "0rem" }}
                                                >
                                                    {post.careerLevel}
                                                </span>
                                            </p>
                                        )}

                                        {post.location && (
                                            <p
                                                className="for-margin"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#0096FF",
                                                }}
                                            >
                                                <LocationOnIcon
                                                    style={{ marginRight: "0.1rem", height: "1.25rem" }}
                                                />
                                                <span
                                                    className="use-for-m-top"
                                                    style={{ marginTop: "0rem" }}
                                                >
                                                    {post.location}
                                                </span>
                                            </p>
                                        )}
                                        {post.salaryRange && (
                                            <p
                                                className="for-margin"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#0096FF",
                                                }}
                                            >
                                                <AttachMoneyIcon
                                                    style={{ marginRight: "0.1rem", height: "1.25rem" }}
                                                />
                                                <span
                                                    className="use-for-m-top"
                                                    style={{ marginTop: "0rem" }}
                                                >
                                                    {post.salaryRange}
                                                </span>
                                            </p>
                                        )}

                                        <p className="paragraph-title p-1">
                                            {post.jobDescription && sanitizeHTML(post.jobDescription.slice(0, 150))}
                                            {post.jobDescription && post.jobDescription.length > 500 && (
                                                <span className="details-text">...details</span>
                                            )}
                                        </p>
                                    </div>
                                </Link>
                            
                        
                            ))}
                    </div>
                </div>
                <div className="mt-3">
                    <div>
                        <button className="btn btn-primary mt-2 mb-2">
                            <Link
                                to="/alljobs"
                                className="text-white text-decoration-none"
                            >
                                View More
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
                {/*  <div className="card">
                                            <div className='category'>
                                                <p > <span className=' category-bg'>{post.categoryName}</span></p>
                                            </div>
                                            <div className="pl-1 for-margin">
                                                <h6 className="for-margin">{post.jobTitle}</h6>
                                            </div>
                                           
                                                <p className='for-margin' style={{ display: 'flex', alignItems: 'center', color: "#0096FF" }}>
                                                    <BusinessCenter style={{ marginRight: '0.1rem',height: '1.5rem' }} />
                                                    <span className='use-for-m-top' style={{ marginTop: '0.5rem' }}>{post.jobType}</span>
                                                </p>
                                            
                                            
                                                <p className='for-margin' style={{ display: 'flex', alignItems: 'center', color: "#0096FF" }}>
                                                    <Work style={{ marginRight: '0.1rem',height: '1.5rem' }} />
                                                    <span className='use-for-m-top' style={{ marginTop: '0rem' }}>{post.careerLevel}</span>
                                                </p>
                                                <p className='for-margin' style={{ display: 'flex', alignItems: 'center', color: "#0096FF" }}>
                                                    <AttachMoneyIcon style={{ marginRight: '0.1rem',height: '1.5rem' }} />
                                                    <span className='use-for-m-top' style={{ marginTop: '0rem' }}>{post.salaryRange}</span>
                                                </p>
                                                <p className='for-margin' style={{ display: 'flex', alignItems: 'center', color: "#0096FF" }}>
                                                    <FaMapMarkerAlt style={{ marginRight: '0.6rem',height: '1.5rem' }} />
                                                    <span className='use-for-m-top' style={{ marginTop: '0rem' }}>{post.location}</span>
                                                </p>
                                            
                                            {/* <div className=' for-margin'>
                                                {post.jobType && (
                                                    <div className='job-title'>
                                                        <div>
                                                        <BusinessCenter />
                                                        </div>
                                                        <div>
                                                        <p className='for-margin'>{post.jobType}</p>
                                                        </div>
                                                    </div>
                                                    // <div className='job-title '>
                                                    //     <div>
                                                    //         <BusinessCenter />
                                                    //     </div>
                                                    //     <div className='ml-1'>
                                                    //         <p className='for-margin'>{post.jobType}</p>
                                                    //     </div>
                                                    // </div>
                                                )}
                                                {post.careerLevel && (
                                                    <div className='job-title text-align'>
                                                        <div>
                                                            <Work />
                                                        </div>
                                                        <div className='ml-1'>
                                                            <p className='for-margin'>{post.careerLevel}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {post.salaryRange && (
                                                <div className='job-title text-align'>
                                                    <div>
                                                        <AttachMoneyIcon />
                                                    </div>
                                                    <div className='ml-1'>
                                                        <p className='for-margin'>{post.salaryRange}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {post.location && (
                                                <div className='job-title text-align'>
                                                    <div>
                                                        <FaMapMarkerAlt />
                                                    </div>
                                                    <div className='ml-1'>
                                                        <p className=''>{post.location}</p>
                                                    </div>
                                                </div>
                                            )}
                                            
                                            <p className='paragraph-title p-1'>
                                                {sanitizeHTML(post.jobDescription.slice(0, 150))}
                                                {post.jobDescription.length > 500 && <span className="details-text">...details</span>}
                                            </p>
</div> */}

                {/* Recruitement-proces  */}
                <div className="recruitment-process">
                    <div className="container">
                        <div className="heading-part">
                            <h1 className="first-title-recruitement">Recruitment Process</h1>
                            {/* <p>Did we mention we’re growing fast? Join the Agency Partner <br />
                                team through a simple 4 step process!</p> */}
                        </div>
                        <div className="row justify-content-md-center ">
                            <div className="col-md-3 mt-5 mb-3 card-color">
                                <div className="key-item h-100">
                                    <img src="/bplogo.png" className="key-img" alt="info" />
                                    <div className="key-title">Application Review</div>
                                    <div className="key-desc">
                                        Our team carefully reviews all applications to identify
                                        qualified candidates based on specific criteria.
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mt-5 mb-3">
                                <div className="key-item h-100">
                                    <img src="/bplogo.png" className="key-img" alt="info" />
                                    <div className="key-title">Interviews</div>
                                    <div className="key-desc">
                                        Qualified candidates proceed to interview rounds, where we
                                        assess skills, experience, and cultural fit.
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mt-5 mb-3">
                                <div className="key-item h-100">
                                    <img src="/bplogo.png" className="key-img" alt="info" />
                                    <div className="key-title">Assessment</div>
                                    <div className="key-desc">
                                        Depending on the role, candidates may undergo skill
                                        assessments or other relevant evaluations.
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mt-5 mb-3">
                                <div className="key-item h-100">
                                    <img src="/bplogo.png" className="key-img" alt="info" />
                                    <div className="key-title">Offer Stage</div>
                                    <div className="key-desc">
                                        An offer is extended to the candidate who best meets the
                                        position requirements and company values.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Benefits-section*/}
                <div>
                    <div className="separator_dark"></div>

                    <div className="benefits-section ">
                        <div className="container">
                            <div className="benefit-heading-part">
                                <h1 className="benefit-title text-white text-center">
                                    Benefits
                                </h1>
                                <p className="for-top-gap sounding-color text-white text-center">
                                    Sounding good so far? Check out the great benefits you’ll
                                    have, too!
                                </p>
                            </div>
                            <div className="benefit-section-container">
                                <div className="part-1">
                                    <div className="img-div">
                                        <img c src={globeImg} alt="" />
                                    </div>
                                    <div className="text-div">
                                        <h1 className="second-title text-white">
                                            Flexible Work Arrangements
                                        </h1>
                                        <p className="paragraph-title text-white">
                                            Flexible work hours or remote work options when feasible.
                                        </p>
                                    </div>
                                </div>
                                <div className="part-1">
                                    <div className="img-div">
                                        <img src={retreat} alt="" />
                                    </div>
                                    <div className="text-div">
                                        <h1
                                            className="second-title text-white"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            Professional Development
                                        </h1>
                                        <p className="paragraph-title text-white">
                                            Ongoing learning and development through online courses,
                                            webinars, or subsidies for professional certifications.
                                        </p>
                                    </div>
                                </div>
                                <div className="part-1">
                                    <div className="img-div">
                                        <img src={remote} alt="" />
                                    </div>
                                    <div className="text-div">
                                        <h1 className="second-title text-white">
                                            Mentorship Programs
                                        </h1>
                                        <p className="paragraph-title text-white">
                                            Experienced employees are paired with newcomers to foster
                                            growth and learning.
                                        </p>
                                    </div>
                                </div>
                                <div className="part-1">
                                    <div className="img-div">
                                        <img src={health} alt="" />
                                    </div>
                                    <div className="text-div">
                                        <h1 className="second-title text-white">
                                            Health and Wellness Initiatives
                                        </h1>
                                        <p className="paragraph-title text-white">
                                            Employee well-being are promoted with wellness challenges,
                                            virtual fitness classes, or access to mental health
                                            resources.{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="part-1">
                                    <div className="img-div">
                                        <img src={learning} alt="" />
                                    </div>
                                    <div className="text-div">
                                        <h1
                                            className="second-title text-white"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            Recognition and Awards
                                        </h1>
                                        <p className="paragraph-title text-white">
                                            Employee recognition program, including awards,
                                            shout-outs, or spot bonuses.
                                        </p>
                                    </div>
                                </div>
                                <div className="part-1">
                                    <div className="img-div">
                                        <img src={annual} alt="" />
                                    </div>
                                    <div className="text-div">
                                        <h1 className="second-title text-white">
                                            Food and Beverage Perks
                                        </h1>
                                        <p className="paragraph-title text-white">
                                            Kitchen with snacks, and weekly catered meals.
                                        </p>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>

                    <div className="vertical separator_dark"></div>
                </div>

                {/* about section */}
                <div className="about-section   p-4">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-6 about-title ">
                                <div className="first-title-recruitement">
                                Best Practicify's Values
                                </div>
                                <div className="alphabets">
                                    <div className="alphabet-meaning">
                                        <div className="alphabet p-2">T</div>
                                        <p>– Transparency to make positive impact</p>
                                    </div>
                                    <div className="alphabet-meaning">
                                        <div className="alphabet p-2">R</div>
                                        <p>– Roadmap to achieve measurable results</p>
                                    </div>
                                    <div className="alphabet-meaning">
                                        <div className="alphabet p-2">A</div>
                                        <p>– Automation of technology for its full potential.</p>
                                    </div>
                                    <div className="alphabet-meaning">
                                        <div className="alphabet p-2">N</div>
                                        <p>
                                            – Navigation for complexity and technological disruption.
                                        </p>
                                    </div>
                                    <div className="alphabet-meaning">
                                        <div className="alphabet p-2">S</div>
                                        <p>– SMART goals for excellent performance.</p>
                                    </div>
                                    <div className="alphabet-meaning">
                                        <div className="alphabet p-2">F</div>
                                        <p>– Focused execution to deliver on our commitments.</p>
                                    </div>
                                    <div className="alphabet-meaning">
                                        <div className="alphabet p-2">O</div>
                                        <p>
                                            – Optimization with no compromise for next best practice.
                                        </p>
                                    </div>
                                    <div className="alphabet-meaning">
                                        <div className="alphabet p-2">R</div>
                                        <p>– Resiliency and reliability first in procurement.</p>
                                    </div>
                                    <div className="alphabet-meaning">
                                        <div className="alphabet p-2">M</div>
                                        <p>– Management with impactful strategies.</p>
                                    </div>
                                </div>
                                {/* <div className='send-application-btn'>
                                <button className='btn btn-primary ' style={{ padding: '15px', width: "200px" }}>
                                    <Link target=""
                                        className="text-white text-decoration-none" to="/AboutUs">About Us</Link>
                                </button>
                            </div> */}
                            </div>
                            <div className="col-md-6  ">
                                <img
                                    className="ml-4 img-fluid about-img"
                                    src="https://api.bestpracticify.co/Images/MenuSubMenuMap/FunctionPage__A&F-Solutions.png"
                                    alt="img"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Career;
