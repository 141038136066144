import {
	faFacebookSquare,
	faLinkedin,
	faTiktok,
	faPinterest,
	faTwitterSquare,
	faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";
import {faCopyright} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {BASE_API_URL, BASE_URL} from "../../Constants";
import footerLogo from "../../Images/logo.png";
import usaFlag from "../../Images/usa-flag.jpg";
import usaSage from "../../Images/sage.png";
import ContactUs from "../Home/ContactUs";
import "./Footer.css";
import "./FooterResponsive.css";

const Footer = (props) => {
	
	
	let [insightsBlogDetails, setInsightsBlogDetails] = React.useState([]);
	let [resourceBlogDetails, setResourceBlogDetails] = React.useState([]);
	let [randerFooter, setRanderFooter] = React.useState(true);
	let [menu, setMenu] = React.useState([]);

	const [menuSlug, setMenuSlug] = useState();

	const reqst = ["#mydiv_home", "#contactRequest", "#footerConsultationModal"];

	const [reqstForm, setReqstForm] = useState();

	const location = useLocation();
	console.log("Footer Location:", location);

	const showInsights = () => {
		
		// alert(location.pathname.includes("/solutions"));
		const pathArr = location.pathname.split("/");
	// Check if the current pathname is one of the excluded routes
  if (
    location.pathname.includes("/Career") ||
    location.pathname.includes("/JobPostDetails") ||
    location.pathname.includes("/JobApplyForm")
  ) {
    return true; // Exclude "Insights" and "Resources" sections
  }
		else if (location.pathname.includes("/insights")) {
			return true;
		} else if (pathArr.length === 4) {
			if (pathArr[1] === "solutions" || pathArr[1] === "services") {
				return true;
			}
		} else {
			return false;
		}
	};

	React.useEffect(() => {
		if (location) {
			if (location.state) {
				if (location.state.sub_id) {
					//console.log("abc");
					setReqstForm(reqst[1]);
					if (location.state.id === 1) {
						setMenuSlug("solutions");
					} else if (location.state.id === 2) {
						setMenuSlug("services");
					} else if (location.state.id === 3) {
						setMenuSlug("technology");
					} else if (location.state.id === 4) {
						setMenuSlug("consulting");
					} else if (location.state.id === 5) {
						setMenuSlug("training");
					}
				} else if (location.state.id) {
					setReqstForm(reqst[2]);
					if (location.state.id === 1) {
						setMenuSlug("solutions");
					} else if (location.state.id === 2) {
						setMenuSlug("services");
					} else if (location.state.id === 3) {
						setMenuSlug("technology");
					} else if (location.state.id === 4) {
						setMenuSlug("consulting");
					} else if (location.state.id === 5) {
						setMenuSlug("training");
					}
				}
			} else {
				setReqstForm(reqst[0]);
			}
		}
	}, [location, reqst]);

	React.useEffect(() => {
		axios.get(BASE_API_URL + `BlogCategory`, {}).then(
			(res) => {
				////console.log(res);
				// //console.log(res.data.data);

				if (res.data.data && res.data.data.length !== 0) {
					res.data.data.map((item, index) => {
						if (item.name === "Blog") {
							axios
								.post(BASE_API_URL + `BlogPost/LatestBlogs/`, {
									currentPage: 1,
									perPageCount: 3,
									categoryId: 1,
								})
								.then(
									(res) => {
										////console.log(res);

										// //console.log("latest-blog: ", res.data.data);
										if (res.data.data !== null) {
											if (res.data.data?.item1.length !== 0) {
												setInsightsBlogDetails(res.data.data.item1);
											}
										}
									},
									(error) => {
										//console.log(error);
									}
								);
						}

						if (item.name === "Resources") {
							axios
								.post(BASE_API_URL + `BlogPost/LatestBlogs`, {
									currentPage: 1,
									perPageCount: 3,
									categoryId: 3,
								})
								.then(
									(res) => {
										////console.log(res);
										// //console.log("latest-reource: ", res.data.data);
										if (res.data.data !== null) {
											if (res.data.data?.item1.length != 0) {
												setResourceBlogDetails(res.data.data.item1);
											}
										}
									},
									(error) => {
										//console.log(error);
									}
								);
						}
					});
				}
			},
			(error) => {
				//console.log(error);
			}
		);

		axios
			.get(BASE_API_URL + "MenuSubMenuMap/MenuSubmenus", {isAscending: true})
			.then(
				(response) => {
					setMenu(response.data.data);
				},
				(error) => {
					//console.log(error);
				}
			);
	}, []);

	// //console.log(insightsBlogDetails);
	// //console.log(resourceBlogDetails);

	// setTimeout(() => {
	// 	setRanderFooter(true);
	// }, 10000);

	return (
		<>
			{randerFooter && (
				<div id="footer">
					{/* <ModalExample buttonLabel="Test Modal" className="pexample-modal" /> */}
					{location.state && location.state.sub_id ? (
						<div className="container-fluid more-ways">
							<h5 className="text-center title">
								Explore the other ways Best Practicify can empower your business
							</h5>
							<div className="row">
								<div className="d-flex justify-content-around align-items-center w-100 mt-4 mb-3">
									{location.state.sub_id !== 5 && (
										<div className="more-ways-bottom-menu text-center">
											<Link
												to={{
													pathname: `/${menuSlug}/data-technology`,
													state: {
														name: "Data & Technology",
														id: location.state.id,
														sub_id: 5,
													},
												}}
											>
												Data & Technology
											</Link>
										</div>
									)}
									{location.state.sub_id !== 6 && (
										<div className="more-ways-bottom-menu text-center">
											<Link
												to={{
													pathname: `/${menuSlug}/accounting-finance`,
													state: {
														name: "Accounting & Finance",
														id: location.state.id,
														sub_id: 6,
													},
												}}
											>
												Accounting & Finance
											</Link>
										</div>
									)}

									{location.state.sub_id !== 8 && (
										<div className="more-ways-bottom-menu text-center">
											<Link
												to={{
													pathname: `/${menuSlug}/ecommerce`,
													state: {
														name: "Ecommerce",
														id: location.state.id,
														sub_id: 8,
													},
												}}
											>
												Ecommerce
											</Link>
										</div>
									)}

									{location.state.sub_id !== 9 && (
										<div className="more-ways-bottom-menu text-center">
											<Link
												to={{
													pathname: `/${menuSlug}/application-development`,
													state: {
														name: "Application Development",
														id: location.state.id,
														sub_id: 9,
													},
												}}
											>
												Application Development
											</Link>
										</div>
									)}
								</div>
							</div>
						</div>
					) : (
						""
					)}

					{showInsights() ? (
						""
					) : (
						<div>
							<div className="insights pt-5">
								<div className="container">
									<Link to="/insights" target="_blank">
										<div className="insights-header">
											<h3>Insights</h3>
										</div>
									</Link>
									<div className="insights-blog">
										<div className="card-deck">
											{insightsBlogDetails.map((item, index) => (
												<div className="col-md-4 mb-3" key={index}>
													<Link
														to={`/insights/details/${item.slug}`}
														target="_blank"
													>
														<div className="card m-0 shadow-sm border rounded">
															<div className="card-img-top blog-card-image">
																<img
																	src={BASE_URL + item.pictureUrl}
																	alt="Card cap"
																/>
															</div>

															<div className="card-body blog-card-body">
																{item.title.length > 25 ? (
																	<h5 className="card-title">
																		{item.title.substring(0, 25) + "..."}
																	</h5>
																) : (
																	<h5 className="card-title">{item.title}</h5>
																)}
																<div
																	className="card-text"
																	dangerouslySetInnerHTML={{
																		__html:
																			item.content
																				.split(" ")
																				.slice(0, 20)
																				.join(" ") + "...",
																	}}
																/>
																{/* <p className="card-text">{item.content.split(' ').slice(0, 30).join(" ") + " ....."}</p> */}
																{/* <p className="collapse show" id={`#multiCollapseExample${index}`}>
															{item.content}
														</p> */}
															</div>
														</div>
													</Link>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>

							<div className="resources mt-5 pt-5 mb-5">
								<div className="container">
									<Link to="/insights" target="_blank">
										<div className="resources-header">
											<h3>Resources</h3>
										</div>
									</Link>

									<div className="resources-blog">
										<div className="card-deck">
											{resourceBlogDetails.map((item, index) => (
												<div className="col-md-4" key={index}>
													<Link
														to={`insights/details/${item.slug}`}
														target="_blank"
													>
														<div className="card m-0 shadow-sm border rounded">
															<div className="card-img-top resource-card-image">
																<img
																	src={BASE_URL + item.pictureUrl}
																	alt="Card cap"
																/>
															</div>
															<div className="card-body blog-card-body">
																{item.title.length > 25 ? (
																	<h5 className="card-title">
																		{item.title.substring(0, 30) + "..."}
																	</h5>
																) : (
																	<h5 className="card-title">{item.title}</h5>
																)}
																<div
																	className="card-text"
																	dangerouslySetInnerHTML={{
																		__html:
																			item.content
																				.split(" ")
																				.slice(0, 25)
																				.join(" ") + " .....",
																	}}
																/>
															</div>
														</div>
													</Link>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="footer-main">
						<div className="row d-flex justify-content-between">
							<div className="col-md-2 footer-logo">
								<Link to="/">
									<img src={footerLogo} style={{width: "150px"}} alt="" />
								</Link>
								<div className="social-menu">
									<ul>
										<li>
											<a
												href="https://www.facebook.com/BestPracticify"
												target="blank"
											>
												<FontAwesomeIcon
													icon={faFacebookSquare}
													className="social-icon"
												/>
											</a>
										</li>
										<li>
											<a
												href="https://www.linkedin.com/company/best-practicify/"
												target="blank"
											>
												<FontAwesomeIcon
													icon={faLinkedin}
													className="social-icon"
												/>
											</a>
										</li>
										<li>
											<a
												href="https://twitter.com/BestPracticify"
												target="blank"
											>
												<FontAwesomeIcon
													icon={faTwitterSquare}
													className="social-icon"
												/>
											</a>
										</li>
										{/* <li>
											<a href="https://github.com/sanketbodke" target="blank">
												<FontAwesomeIcon
													icon={faInstagramSquare}
													className="social-icon"
												/>
											</a>
										</li> */}

                                        <li>
											<a
												href="https://www.tiktok.com/@bestpracticify"
												target="blank"
											>
												<FontAwesomeIcon
													icon={faTiktok}
													className="social-icon"
												/>
											</a>
										</li>
										<li>
											<a
												href="https://www.pinterest.com/bestpracticify"
												target="blank"
											>
												<FontAwesomeIcon
													icon={faPinterest}
													className="social-icon"
												/>
											</a>
										</li>
										<li>
											<a
												href="https://www.youtube.com/@bestpracticify"
												target="blank"
											>
												<FontAwesomeIcon
													icon={faYoutubeSquare}
													className="social-icon"
												/>
											</a>
										</li>
									</ul>
								</div>
								<div className="address d-flex align-items-center justify-content-start">
									<img
										src={usaFlag}
										style={{height: "18px"}}
										className="px-2"
										alt=""
									/>
									<span>California, USA </span>
									
								</div>
								<div className="social-menu">
									
									<img
										src={usaSage}
										style={{height: "100px",padding:"10px"}}
										className="px-2"
										alt=""
									/>
								</div>
							</div>

							<div className="col-md-2 mt-1">
								<h5 className="mb-3">Solutions</h5>
								<ul>
									{menu &&
										menu.map(
											(menuItem, index) =>
												menuItem.menuName === "Solutions" &&
												menuItem.subMenuViewModels
													.sort((a, b) => (a.id > b.id ? 1 : -1))
													.map((subMenuItem, i) => (
														<li key={i}>
															<Link
																to={{
																	pathname: `/solutions/${subMenuItem.slug}`,
																	state: {
																		name: subMenuItem.name,
																		id: subMenuItem.menuId,
																		sub_id: subMenuItem.id,
																	},
																}}
															>
																{subMenuItem.name}
															</Link>
														</li>
													))
										)}

									{/* <li><Link to="/">Data & Technology</Link></li>
										<li><Link to="/">Accounting and Finance</Link></li>
										<li><Link to="/">Ecommerce</Link></li>
										<li><Link to="/">Modern Web App</Link></li> */}
								</ul>
							</div>
							<div className="col-md-2 mt-1">
								<h5 className="mb-3">Services</h5>
								<ul>
									<li>
										<Link
											to={{
												pathname: `/services`,
												state: {
													name: "Services",
													id: 2,
													service: "consultation",
												},
											}}
											// to={{
											//   pathname: `/InnovativeSolution/2`,
											//   state: { service: "consultation" },
											// }}
										>
											Consultation
										</Link>
									</li>
									<li>
										<Link
											to={{
												pathname: `/services`,
												state: {
													name: "Services",
													id: 2,
													service: "assessment",
												},
											}}
											// to={{
											//   pathname: `/InnovativeSolution/2`,
											//   state: { service: "assessment" },
											// }}
										>
											Assessment
										</Link>
									</li>
									<li>
										<Link
											to={{
												pathname: `/services`,
												state: {
													name: "Services",
													id: 2,
													service: "implementation",
												},
											}}
											// to={{
											//   pathname: `/InnovativeSolution/2`,
											//   state: { service: "implementation" },
											// }}
										>
											Implementation
										</Link>
									</li>
									<li>
										<Link
											to={{
												pathname: `/services`,
												state: {
													name: "Services",
													id: 2,
													service: "training",
												},
											}}
											// to={{
											//   pathname: `/InnovativeSolution/2`,
											//   state: { service: "training" },
											// }}
										>
											Training
										</Link>
									</li>
									<li>
										<Link
											to={{
												pathname: `/services`,
												state: {
													name: "Services",
													id: 2,
													service: "integration",
												},
											}}
											// to={{
											//   pathname: `/InnovativeSolution/2`,
											//   state: { service: "integration" },
											// }}
										>
											Integration
										</Link>
									</li>
									<li>
										<Link
											to={{
												pathname: `/services`,
												state: {
													name: "Services",
													id: 2,
													service: "automation",
												},
											}}
											// to={{
											//   pathname: `/InnovativeSolution/2`,
											//   state: { service: "automation" },
											// }}
										>
											Automation
										</Link>
									</li>
								</ul>
							</div>
							<div className="col-md-2 mt-1">
								<h5 className="mb-3">Resource Center</h5>
								<ul>
									<li>
										<Link
											to={{
												pathname: "/insights",
												state: {blog: true, name: "Blog"},
											}}
										>
											Insights
										</Link>
									</li>
									<li>
										<Link
											to={{
												pathname: "/insights",
												state: {blog: true, name: "Resources"},
											}}
										>
											Resources
										</Link>
									</li>
									<li>
										<Link to="/course/list">Courses</Link>
									</li>
									<li>
										<Link to="/insights/ebook">eBook</Link>
									</li>
								</ul>
							</div>
							<div className="col-md-2 mt-1">
								<h5 className="mb-3">Company</h5>
								<ul>
									<li>
										<Link to="/AboutUs">About Us</Link>
									</li>
									<li>
										<Link to="/privacy-policy">Privacy Policy</Link>
									</li>
									<li>
										<Link to="/terms-and-conditions">Terms And Conditions</Link>
									</li>
								</ul>
							</div>
							<div className="col-md-2 mt-1">
								<h5 className="mb-3">Get Best Practices</h5>
								<ul>
									<li>
									<Link to="/request-for-Proposal">Request for Proposal</Link>
									</li>
									<li>

									<Link to="/schedule-a-consult">Schedule a Consult</Link>
									
									</li>
									<li>

									<Link to="/contact-us">Contact Us</Link>
									
									</li>
								</ul>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<div className="copyright-section">
									<p>
										<FontAwesomeIcon icon={faCopyright} className="mr-1" />
										Best Practicify, {new Date().getFullYear()}. All Rights Reserved.{" "}
									</p>
								</div>
							</div>
						</div>
						<div
							className="modal fade bd-example-modal-lg-contact"
							tabIndex="-1"
							role="document"
							aria-labelledby="myLargeModalLabel"
							aria-hidden="true"
						>
							<div className="modal-dialog modal-dialog-centered modal-lg">
								<div className="modal-content">
									<ContactUs menuId={props.menuId} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Footer;
