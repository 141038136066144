import React from "react";
import "./UserForm.css";

const UserFormDetailsCardSix = (props) => {
	// console.log(props);

	// props.updateStep(1);
	setTimeout(function () {
		props.turnToFirstCard();
	}, 13000);

	return (
		<>
			<div className="d-flex justify-content-center align-items-center flex-column">
				<h3 className="complete-title text-center w-100 mt-4 mb-1">
					Thanks for submitting your request.
				</h3>
				<h3 className="complete-sub-title text-center w-100 mt-4 mb-1">
					Please check your email for next step and further update.
				</h3>
				<button
					type="button"
					className="btn btn-primary cta-form-btn w-100 m-2 mt-3"
					data-dismiss="modal"
					style={{textAlign: "center !important"}}
					onClick={props.turnToFirstCard}
				>
					Close
				</button>
			</div>
		</>
	);
};

export default UserFormDetailsCardSix;
