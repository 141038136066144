import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_API_URL, BASE_URL } from "../../../Constants";

function TechnologyPartners(props) {
  const [techpartners, setTechpartners] = useState(null);

  useEffect(() => {
    getTechnologyPartners();
  });

  function getTechnologyPartners() {
    axios.get(BASE_API_URL + `TechnologyPartner`, {}).then((res) => {
      if (res.data.status) {
        setTechpartners(res.data.data);
      }
    });
  }
  if (techpartners) {
    return (
      <div className="pt-5 pb-5">
        {/* <pre>{JSON.stringify(techpartners, undefined, 4)}</pre> */}
        <div className="container">
          <div className="row">
            {techpartners
              .sort((a, b) => (a.submenuId > b.submenuId ? 1 : -1))
              .map((techPartner, i) => (
                <div className="col-md-6">
                  <img
                    src={BASE_URL + techPartner.pictureUrl}
                    className="img-fluid"
                    alt={techPartner.name}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default TechnologyPartners;
