import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import img from "../../../../assets/img/HeroSliderImg/data_analytics.png";
import img1 from "../../../../assets/img/HeroSliderImg/homepage-slider-1.png";
import img2 from "../../../../assets/img/HeroSliderImg/homepage-slider-2.png";

// import required modules
import { Autoplay, Navigation } from "swiper";
import { HeroSliderSingleCompo } from "./HeroSliderSingleCompo";

const data = [
  {
    title: "Unlock the Power of Your Data with Best Practicify's Data Analytics Solution",
    img: img,
  },
];

export const HeroSectionSlider = () => {
  return (
    <>
      <Swiper
        navigation={false}
      
        loop={false}
        modules={[Navigation, Autoplay]}
        className="mySwiper"
      >
        {data.map((slider, index) => (
          <SwiperSlide key={index}>
            <HeroSliderSingleCompo data={slider} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
