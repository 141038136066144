import React from "react";
import "./UserForm.css";

const UserFormDetailsCardFive = (props) => {
	console.log(props);

	setTimeout(function () {
		props.turnToFirstCard();
	}, 13000);
	function reset() {}

	return (
		<>
			<div className="modal-body box">
				<h3 className="complete-title text-center w-100 mt-4 mb-1">
					Thanks for submitting your request.
				</h3>
				<h3 className="complete-sub-title text-center w-100 mt-4 mb-1">
					Please check your email for next step and further update.
				</h3>
				<button
					type="button"
					className="btn btn-primary cta-form-btn w-100 mt-3"
					data-dismiss="modal"
					onClick={() => props.turnToFirstCard()}
					style={{textAlign: "center !important"}}
					onClick={props.turnToFirstCard}
				>
					Close
				</button>
			</div>
		</>
	);
};

export default UserFormDetailsCardFive;
