import http from "./HttpCommon";

class UploadFilesService {
  async getFiles(res) {
    //return http.get("");
    return await res;
  }

  upload(file, id, onUploadProgress) {
    console.log(
      "file: ",
      file,
      "id: ",
      id,
      "onUploadProgress: ",
      onUploadProgress
    );

    let formData = new FormData();

    formData.append("file", file);
    formData.append("ctaCategoryId", id);

    return http
      .post("CtaDocument/DocumentCreateForGuest", formData, {
        onUploadProgress,
      })
      .then(
        (res) => {
          console.log(res);
          const files = this.getFiles(res);
          return files;
        },
        (error) => {
          console.log(error.response);
          return error.response.status;
        }
      );
  }

  deleteFile(documentId, ctaCategoryId) {
    return http
      .delete(
        `CtaDocument/DeleteCtaDocumentForGuest/${documentId}/0/${ctaCategoryId}`,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      )
      .then(
        (res) => {
          console.log(res);
          return res;
        },
        (error) => {
          console.log(error);
          console.log(error.response);
          return error.response.status;
        }
      );
  }
}

export default new UploadFilesService();
