import axios from "axios";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {BASE_API_URL} from "../../../../Constants";
import "./TrainingRequestForm.css";

const TrainingRequestForm = () => {
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

	let initialValues = {
		fullName: "",
		companyName: "",
		phoneNumber: "",
		email: "",
		message: "",
	};

	const {register, reset, errors, handleSubmit} = useForm({
		defaultValues: initialValues,
	});

	const requestSubmit = (data) => {
		// console.log("data: ", data);

		try {
			setLoading(true);

			axios
				.post(`${BASE_API_URL}ContactUsMessage/SaveTrainingRequest`, data, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					setLoading(false);
					if (res.data.status) {
						reset();
						setSubmitted(true);
					} else {
						setSubmitted(false);
					}
				});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<div className="contact-us-form pt-4 pb-4">
				<div className="container">
					<div className="contact-form">
						<div className="card shadow">
							<div className="card-body">
								<form onSubmit={handleSubmit(requestSubmit)}>
									<div className="row contact-row">
										<div className="col-md-6">
											<div className="form-group">
												<label for="fullName">Your Name</label>
												<input
													type="text"
													className="form-control"
													name="fullName"
													ref={register({
														required: "Your name is required",
													})}
												/>
												{errors.fullName && (
													<p style={{color: "red"}}>
														{errors.fullName.message}
													</p>
												)}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label for="companyName">Company name</label>
												<input
													type="text"
													className="form-control"
													name="companyName"
													ref={register({
														required: "Company name is required",
													})}
												/>
												{errors.companyName && (
													<p style={{color: "red"}}>
														{errors.companyName.message}
													</p>
												)}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label for="email">Email</label>
												<input
													type="email"
													className="form-control"
													name="email"
													ref={register({
														required: "Email is required",
														pattern: {
															value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
															message: "Please Provide valid email address.",
														},
													})}
												/>
												{errors.email && (
													<p style={{color: "red"}}>{errors.email.message}</p>
												)}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label for="phoneNumber">Phone number</label>
												<input
													type="text"
													className="form-control"
													name="phoneNumber"
													ref={register}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<div className="form-group">
												<label for="message">
													Tell us about the training you need
												</label>
												<textarea
													style={{height: "auto"}}
													className="message"
													rows="4"
													name="message"
													ref={register({
														required: "Information about training is required",
													})}
												></textarea>
												{errors.message && (
													<p style={{color: "red"}}>{errors.message.message}</p>
												)}
											</div>
										</div>
									</div>
									<div className="row">
										{loading ? (
											<button className="btn btn-primary mt-3" disabled>
												<i class="fa fa-spinner fa-spin fa-fw mr-2"></i>
												<span class="sr-only">Loading...</span>
												Submitting...
											</button>
										) : (
											<button className="btn btn-primary mt-3" type="submit">
												<i
													class="fa fa-paper-plane mr-2"
													aria-hidden="true"
												></i>
												Submit
											</button>
										)}
									</div>
								</form>
								{submitted && (
									<div
										class="alert alert-success mt-3 text-center"
										role="alert"
									>
										Your request for training posted successfully.
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TrainingRequestForm;
