import React from "react";

const ContactUsForm = () => {
	return (
		<section>
			<form>
				<div className="container">
					<div className="row">
						<div className="col-md-6 left">
							<div className="form-group">
								<label for="fullName">Full name</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter your Name"
									required
								/>
							</div>
							<div className="form-group">
								<label for="companyName">Company name</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter company name"
									required
								/>
							</div>
							<div className="form-group">
								<label for="phoneNumber">Phone number</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter phone number"
									required
								/>
							</div>

							<div className="form-group">
								<label for="email">Email</label>
								<input
									type="email"
									required
									className="form-control"
									placeholder="Enter email"
								/>
							</div>
						</div>
						<div className="col-md-6 right">
							<div className="form-group">
								<label for="message">Message</label>
								<textarea
									className="message form-group"
									required
									rows="13"
									placeholder="Message"
								></textarea>
							</div>
						</div>
					</div>
					<div className="row">
						<button
							className="btn btn-primary mx-auto d-block mt-3"
							type="submit"
						>
							Submit
						</button>
					</div>
				</div>
			</form>

			
		</section>
	);
};

export default ContactUsForm;
