import React from 'react'
import Feature from './Feature/Feature';
import { HeroSectionSlider } from './Slider/HeroSectionSlider'
import feature4 from "../../../assets/img/features/features4.svg"
import feature6 from "../../../assets/img/features/features6.svg"
import feature7 from "../../../assets/img/features/features7.svg"
import DashboardSliderSection from './DashboardSlider/DashboardSliderSection';
import FreeTrail from './FreeTrail/FreeTrail';
import { useLocation } from 'react-router-dom';
import { getDetailsServiceData } from '../../../utils/getDetailsServiceData';

export const DataAnalysics = () => {

  const location = useLocation();

  const pathName = location.pathname;
	const {menuId, subMenuId} =
		getDetailsServiceData(pathName);

     const noManualInput = {
        imgUrl: feature4,
        title: "Modern Business needs Modern Solutions",
        subTitle: "Data Analytics is a powerful tool for businesses that are looking to turn data into actionable insights. It's the process of collecting and analyzing large sets of data to discover patterns, relationships, and trends. Business Intelligence (BI) takes the insights generated by data analytics and uses them to make strategic decisions that help companies to gain a competitive edge in their industry.",
        desc: "",
        list: [
        "Gain insights and make informed decisions",
        "Increase efficiency and productivity",
        "Improve customer experience",
        "Identify new revenue streams",
        "Competitive edge",
        ],
    };
  
  const autometicInsight = {
    imgUrl: feature6,
    title: "Best Practices and Support",
    subTitle: "Our team of expert data analysts use methodologies and modern business intelligence tools like Tableau and Power BI to extract, transform and load your data from different sources like ERP systems, spreadsheets and cloud-based platforms. We then create tailored dashboards that showcase your key metrics and KPIs, giving you the most accurate and reliable insights possible to make informed decisions and drive your business forward.",
    desc: "",
    list: [],
  };
  
  
  const quickShare = {
    imgUrl: feature7,
    title: "Metrics and Key Performance Indicators for all Business Areas",
    subTitle: "Business Intelligence have become essential for businesses of all sizes and industries. No more wading through pages of spreadsheets or struggling to make sense of complicated data - our solution is intuitive and easy to use. With just a few clicks, you can view your data in clear visualizations and gain an understanding of your business that was previously impossible",
    desc: "",
    list: [
      "Financial analytics",
      "Ecommerce analytics",
      "Customer analytics",
      "Retail analytics",
      "Marketing analytics",
    ],
  };

  
  const dataAnalysicsProcess = {
    imgUrl: feature7,
    title: "Our Data Analytics Development Process",
    subTitle: "",
    desc: "",
    list: [
      "Data ingestion: We'll help you ingest data from multiple sources, including ERP systems, spreadsheets, and cloud-based platforms.",
      "Data modeling: Our team will work with you to develop models that help you better understand your data and draw insights from it.",
      "Dashboard creation: We'll use powerful visualization tools like Tableau and Power BI to create easy-to-understand dashboards that help you track your business's key performance indicators (KPIs) and metrics.",
      "Data analysis: Our team of expert data scientists will use advanced analytical techniques to identify trends, patterns, and anomalies in your data.",
      "Reporting: We'll create custom reports that provide you with the information you need to make informed business decisions.",
    ],
  };

  const discoverPotential = {
    title : 'Discover the Potential of Your Business Data',
    list: [
      "Identify new opportunities and growth areas in your business",
      "Increase operational efficiency by automating repetitive tasks",
      "Reduce costs by identifying inefficiencies and streamlining processes",
      "Make informed, data-driven decisions for better outcomes",
      "Gain a competitive edge in your industry by leveraging data insights",
    ],
  }

  const loginformdataanalysis = {
    title : 'Customized Analytics for Actionable Insights',
    list: [
    ],
  }

  return (
    <>
      <HeroSectionSlider />
      <Feature data={noManualInput} />
      <FreeTrail title="Unlocking unique solutions" data={discoverPotential} menuId={menuId} subMenuId={subMenuId} />
      <Feature className="reverse" data={quickShare} />
      {/* <FreeTrail title="Data Analysis solutions" menuId={menuId} subMenuId={subMenuId}  /> */}
      <Feature data={autometicInsight} />
      <FreeTrail title="Customized support" data={loginformdataanalysis} menuId={menuId} subMenuId={subMenuId} />
      <Feature className="reverse" data={dataAnalysicsProcess} />
      <DashboardSliderSection />
    </>
  )
}
