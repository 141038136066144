import axios from "axios";
import Cookies from "js-cookie";
import React, {useState} from "react";
import {Alert} from "react-bootstrap";
import {context} from "../../../../../App";
import {BASE_API_URL} from "../../../../../Constants";
import "./UserForm.css";


const industryList=[
	{
		id: 1,
		label: "Cannabis",
		value: "Cannabis",
	},
	{
		id: 2,
		label: "Consumer Business",
		value: "Consumer Business",
	},
	{
		id: 3,
		label: "Distribution/Wholesale",
		value: "Distribution/Wholesale",
	},
	{
		id: 4,
		label: "Education",
		value: "Education",
	},
	{
		id: 5,
		label: "Education: Higher Education",
		value: "Education: Higher Education",
	},
	{
		id: 6,
		label: "Education: Private",
		value: "Education: Private",
	},{
		id: 7,
		label: "Education: Public",
		value: "Education: Public",
	},{
		id: 8,
		label: "Education: Support Services",
		value: "Education: Support Services",
	},{
		id: 9,
		label: "Energy",
		value: "Energy",
	},{
		id: 10,
		label: "Entertainment",
		value: "Entertainment",
	},{
		id: 11,
		label: "Entertainment: Individual",
		value: "Entertainment: Individual",
	},{
		id: 12,
		label: "Entertainment: Sports",
		value: "Entertainment: Sports",
	},{
		id: 13,
		label: "Financial Services",
		value: "Financial Services",
	},{
		id: 14,
		label: "Financial Services: Credit Unions",
		value: "Financial Services: Credit Unions",
	},{
		id: 15,
		label: "Financial Services: Banking",
		value: "Financial Services: Banking",
	},{
		id: 16,
		label: "Financial Services: Brokers",
		value: "Financial Services: Brokers",
	},{
		id: 17,
		label: "Financial Services: Credit & Lending",
		value: "Financial Services: Credit & Lending",
	},{
		id: 18,
		label: "Financial Services: Cryptocurrency",
		value: "Financial Services: Cryptocurrency",
	},{
		id: 19,
		label: "Financial Services: Funds",
		value: "Financial Services: Funds",
	},{
		id: 20,
		label: "Financial Services: Insurance",
		value: "Financial Services: Insurance",
	},{
		id: 21,
		label: "Financial Services: Investments",
		value: "Financial Services: Investments",
	},{
		id: 22,
		label: "Financial Services: Venture Capital/Private Equity",
		value: "Financial Services: Venture Capital/Private Equity",
	},{
		id: 23,
		label: "Food & Beverage: Agriculture",
		value: "Food & Beverage: Agriculture",
	},{
		id: 24,
		label: "Food & Beverage: Dining & Services",
		value: "Food & Beverage: Dining & Services",
	},{
		id: 25,
		label: "Food & Beverage: Manufacturing & Distribution",
		value: "Food & Beverage: Manufacturing & Distribution",
	},{
		id: 26,
		label: "Gaming",
		value: "Gaming",
	},{
		id: 27,
		label: "Gaming: Developers & Publishers",
		value: "Gaming: Developers & Publishers",
	},{
		id: 28,
		label: "Gaming: Platforms & Distribution",
		value: "Gaming: Platforms & Distribution",
	},{
		id: 29,
		label: "Government",
		value: "Government",
	},{
		id: 30,
		label: "Healthcare",
		value: "Healthcare",
	},{
		id: 31,
		label: "Healthcare: Assisted Living",
		value: "Healthcare: Assisted Living",
	},{
		id: 32,
		label: "Healthcare: Diagnostics & Labs",
		value: "Healthcare: Diagnostics & Labs",
	},{
		id: 33,
		label: "Healthcare: Hospitals & Health Centers",
		value: "Healthcare: Hospitals & Health Centers",
	},{
		id: 34,
		label: "Healthcare: Medical Groups",
		value: "Healthcare: Medical Groups",
	},{
		id: 35,
		label: "Individual",
		value: "Individual",
	},{
		id: 36,
		label: "Individual: Estates & Trusts",
		value: "Individual: Estates & Trusts",
	},{
		id: 37,
		label: "Life Sciences: Biotech",
		value: "Life Sciences: Biotech",
	},{
		id: 38,
		label: "Life Sciences: Medical Devices",
		value: "Life Sciences: Medical Devices",
	},{
		id: 39,
		label: "Life Sciences: Pharmaceutical",
		value: "Life Sciences: Pharmaceutical",
	},{
		id: 40,
		label: "Manufacturing",
		value: "Manufacturing",
	},{
		id: 41,
		label: "Manufacturing: Electrical",
		value: "Manufacturing: Electrical",
	},{
		id: 42,
		label: "Manufacturing: High Tech",
		value: "Manufacturing: High Tech",
	},{
		id: 43,
		label: "Manufacturing: Materials",
		value: "Manufacturing: Materials",
	},{
		id: 44,
		label: "Manufacturing: Transportation",
		value: "Manufacturing: Transportation",
	},{
		id: 45,
		label: "Nonprofit",
		value: "Nonprofit",
	},{
		id: 46,
		label: "Nonprofit: Animal & Environmental Welfare",
		value: "Nonprofit: Animal & Environmental Welfare",
	},{
		id: 47,
		label: "Nonprofit: Arts & Museums",
		value: "Nonprofit: Arts & Museums",
	},{
		id: 48,
		label: "Nonprofit: Associations",
		value: "Nonprofit: Associations",
	},{
		id: 49,
		label: "Nonprofit: Foundation & Grantmakers",
		value: "Nonprofit: Foundation & Grantmakers",
	},{
		id: 50,
		label: "Nonprofit: Religious",
		value: "Nonprofit: Religious",
	},{
		id: 51,
		label: "Nonprofit: Social/Human Services",
		value: "Nonprofit: Social/Human Services",
	},{
		id: 52,
		label: "Professional Services",
		value: "Professional Services",
	},{
		id: 53,
		label: "Professional Services: Accounting",
		value: "Professional Services: Accounting",
	},{
		id: 54,
		label: "Professional Services: Human Resources",
		value: "Professional Services: Human Resources",
	},{
		id: 55,
		label: "Professional Services: Legal",
		value: "Professional Services: Legal",
	},{
		id: 56,
		label: "Professional Services: Marketing/Media",
		value: "Professional Services: Marketing/Media",
	},{
		id: 57,
		label: "Real Estate",
		value: "Real Estate",
	},{
		id: 58,
		label: "Real Estate: Architects & Engineers",
		value: "Real Estate: Architects & Engineers",
	},{
		id: 59,
		label: "Real Estate: Commercial/Retail/Industrial",
		value: "Real Estate: Commercial/Retail/Industrial",
	},{
		id: 60,
		label: "Real Estate: Contractors",
		value: "Real Estate: Contractors",
	},{
		id: 61,
		label: "Real Estate: Hospitality",
		value: "Real Estate: Hospitality",
	},{
		id: 62,
		label: "Real Estate: REIT, Funds, and Investment Companies",
		value: "Real Estate: REIT, Funds, and Investment Companies",
	},{
		id: 63,
		label: "Real Estate: Residential & Multi Family",
		value: "Real Estate: Residential & Multi Family",
	},{
		id: 64,
		label: "Rental & Leasing",
		value: "Rental & Leasing",
	},{
		id: 65,
		label: "Solid Waste",
		value: "Solid Waste",
	},{
		id: 66,
		label: "Technology",
		value: "Technology",
	},{
		id: 67,
		label: "Technology: Digital Media/Ad Tech",
		value: "Technology: Digital Media/Ad Tech",
	},{
		id: 68,
		label: "Technology: Hardware",
		value: "Technology: Hardware",
	},{
		id: 69,
		label: "Technology: HealthTech",
		value: "Technology: HealthTech",
	},{
		id: 70,
		label: "Technology: Services",
		value: "Technology: Services",
	},{
		id: 71,
		label: "Technology: Software",
		value: "Technology: Software",
	},{
		id: 72,
		label: "Transportation",
		value: "Transportation",
	}
];

const UserFormDetailsCardOne = (props) => {
	// //console.log(props);
	// //console.log(props.subMenuId);

	const [loading, setLoading] = useState(false);
	const {ID, setid, getFormData, setFormData} = React.useContext(context);
	// //console.log("ID: ", ID, "getFormData: ", getFormData);

	const getInputVal = props.getInputValAndErr;
	const setInputVal = props.setInputValAndErr;

	let path = window.location.pathname;
	let pathSubmenuId = props.subMenuId;
	// //console.log(pathSubmenuId);

	let [getId, setGetId] = React.useState();
	const [companyTypes, setCompanyTypes] = useState([]);
	const [companySizes, setCompanySizes] = useState([]);
	let [invalidEmailError, setInvalidEmailErr] = React.useState(false);
	let [reqErr, setReqErr] = React.useState(false);
	let [reqErrMessage, setReqErrMessage] = React.useState();

	// let [getInputVal, setInputVal] = React.useState({
	// 	firstName: getFormData.firstName ? getFormData.firstName : "",
	// 	firstNameError: getFormData.firstNameError
	// 		? getFormData.firstNameError
	// 		: false,
	// 	lastName: getFormData.lastName ? getFormData.lastName : "",
	// 	lastNameError: getFormData.lastNameError
	// 		? getFormData.lastNameError
	// 		: false,
	// 	companyName: getFormData.companyName ? getFormData.companyName : "",
	// 	companyNameError: getFormData.companyNameError
	// 		? getFormData.companyNameError
	// 		: false,
	// 	email: getFormData.email ? getFormData.email : "",
	// 	emailError: getFormData.emailError ? getFormData.emailError : false,
	// 	phone: getFormData.phone ? getFormData.phone : "",
	// 	phoneError: getFormData.phoneError ? getFormData.phoneError : false,
	// 	BusinessIndustry: getFormData.BusinessIndustry
	// 		? getFormData.BusinessIndustry
	// 		: "",
	// 	businessError: getFormData.businessError
	// 		? getFormData.businessError
	// 		: false,
	// 	subMenuId: props.subMenuId ? Number(props.subMenuId) : "",
	// 	companyTypeId: getFormData.companyTypeId ? getFormData.companyTypeId : "",

	// 	companySizeId: getFormData.companySizeId ? getFormData.companySizeId : "",
	// 	companySizesErr: false,
	// 	companyTypesErr: false,
	// });

	const handleChange = (input) => (e) => {
		//console.log({[input]: e.target.value});
		//console.log(e.target.id);

		e.target.id === "emailError" &&
		e.target.value !== "" &&
		!/\S+@\S+\.\S+/.test(e.target.value)
			? setInvalidEmailErr(true)
			: setInvalidEmailErr(false);

		setInputVal({
			...getInputVal,
			[input]: e.target.value,
			[e.target.id]: e.target.value ? false : true,
		});

		setFormData({
			...getFormData,
			[input]: e.target.value,
			[e.target.id]: e.target.value ? false : true,
		});
	};

	React.useEffect(() => {
		axios
			.get(
				BASE_API_URL +
					`CtaFunction/CtaFunctionModel?submenuId=${pathSubmenuId}`,
				{}
			)
			.then((res) => {
				////console.log(res);
				//console.log(res.data);

				if (res.data.length != 0) {
					// setFunctionModel(res.data);
					// setBusinessStages(res.data.businessStages);
					// setCompanyTypes(res.data.companyTypes);
					// setCompanySizes(res.data.companySizes);

					let size = [];
					res.data.companySizes.map((item) => {
						size = [...size, {value: item.name, label: item.name, id: item.id}];
					});
					setCompanySizes(size);

					//console.log(size);
					let type = [];
					res.data.companyTypes.map((item) => {
						type = [...type, {value: item.name, label: item.name, id: item.id}];
					});
					setCompanyTypes(type);
				}
			});
	}, [pathSubmenuId, props.menuId, props.subMenuId]);

	async function nextForm() {
		await props.nextStep();
	}
	const onSubmit = (e) => {
		e.preventDefault();

		const checkErr =
			getInputVal.companyTypeId !== "" &&
			getInputVal.companySizeId !== "" &&
			getInputVal.firstName !== "" &&
			getInputVal.lastName !== "" &&
			getInputVal.companyName !== "" &&
			getInputVal.email !== "" &&
			getInputVal.phone !== "" &&
			getInputVal.BusinessIndustry !== "";

		if (getInputVal.companySizeId === "") {
			getInputVal.companySizesErr = true;
			setInputVal({...getInputVal, companySizesErr: true});
		}

		if (getInputVal.companyTypeId === "") {
			getInputVal.companyTypesErr = true;
			setInputVal({...getInputVal, companyTypesErr: true});
		}

		if (getInputVal.firstName === "") {
			getInputVal.firstNameError = true;
			setInputVal({...getInputVal, firstNameError: true});
		}
		if (getInputVal.lastName === "") {
			getInputVal.lastNameError = true;
			setInputVal({...getInputVal, lastNameError: true});
		}
		if (getInputVal.companyName === "") {
			getInputVal.companyNameError = true;
			setInputVal({...getInputVal, companyNameError: true});
		}
		if (getInputVal.email === "") {
			getInputVal.emailError = true;
			setInputVal({...getInputVal, emailError: true});
		}
		if (getInputVal.phone === "") {
			getInputVal.phoneError = true;
			setInputVal({...getInputVal, phoneError: true});
		}

		if (getInputVal.BusinessIndustry === "") {
			getInputVal.businessError = true;
			setInputVal({...getInputVal, businessError: true});
		}

		// //console.log(checkErr);

		// //console.log(getFormData);
		// //console.log(getInputVal);

		if (checkErr) {
			setLoading(true);
			axios
				.post(
					BASE_API_URL + `CtaFunction/CtaFunctionCreateForGuest`,
					getInputVal,
					{headers: {"Content-Type": "application/json"}}
				)
				.then(
					(res) => {
						setLoading(false);
						////console.log(res);

						Cookies.set("info", getInputVal);
						setid(res.data.data);
						setFormData({...getFormData, id: res.data.data});
						localStorage.setItem("cta_function_id", `${res.data.data}`);
						if (res.data.status) {
							nextForm();
						} else {
							setReqErr(true);
							setReqErrMessage(res.data.message);
						}
					},
					(error) => {
						//console.log(error.response.status);

						setReqErr(true);

						if (error.response.status.toString().includes("4")) {
							setReqErrMessage("Client side error");
						} else if (error.response.data.status.toString().includes("5")) {
							setReqErrMessage("Server side error");
						} else {
							setReqErrMessage("Error Happen");
						}
					}
				);
			// : axios
			//     .put(
			//       BASE_API_URL + `CtaFunction/CtaFunctionUpdateForGuest`,
			//       getFormData,
			//       { headers: { "Content-Type": "application/json" } }
			//     )
			//     .then(
			//       (res) => {
			//         ////console.log(res);
			//         Cookies.set("info", getFormData);
			//         setReqErr(false);

			//         if (res.data.data) {
			//           nextForm();
			//         }
			//       },
			//       (error) => {
			//         //console.log(error.response.status);

			//         setReqErr(true);

			//         if (error.response.status.toString().includes("4")) {
			//           setReqErrMessage("Client side error");
			//         } else if (
			//           error.response.data.status.toString().includes("5")
			//         ) {
			//           setReqErrMessage("Server side error");
			//         } else {
			//           setReqErrMessage("Error Happen");
			//         }
			//       }
			//     );
		}
	};

	const onCompanyTypesSelect = (selectedItem) => {
		//console.log(selectedItem.target.value);

		let type = companyTypes.find(
			(item) => item.value === selectedItem.target.value
		);
		//console.log(type);
		setInputVal({...getInputVal, companyTypesErr: false});
		setInputVal({...getInputVal, companyTypeId: type.id});
		setFormData({...getFormData, companyTypeId: type.id});
	};

	const onIndustrySelect = (selectedItem) => {
	
		let type = industryList.find(
			(item) => item.value === selectedItem.target.value
		);
		setInputVal({...getInputVal, businessError: false});
		setInputVal({...getInputVal, BusinessIndustry: selectedItem.target.value});
		setFormData({...getFormData, BusinessIndustry: selectedItem.target.value});

		console.log(selectedItem.target.value);
	};

	const onCompanySizesSelect = (selectedItem) => {
		//console.log(selectedItem);
		let type = companySizes.find(
			(item) => item.value === selectedItem.target.value
		);
		setInputVal({...getInputVal, companySizesErr: false});
		setInputVal({...getInputVal, companySizeId: type.id});
		setFormData({...getFormData, companySizeId: type.id});
	};

	return (
		<>
			<div className="modal-body box">
				{loading ? (
					<div class="overlay" style={{zIndex: "999"}}>
						<div class="overlay__wrapper">
							<div class="overlay__spinner">
								<div class="spinner-border text-secondary" role="status"></div>
								<div className="loading-msg"> Please Wait...!</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}

				{reqErr && <Alert variant="danger">{reqErrMessage}</Alert>}

				<form onSubmit={onSubmit}>
					<div className="row">
						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="text"
								name="firstName"
								id="firstNameError"
								onChange={handleChange("firstName")}
								value={getInputVal.firstName}
							/>
							<label>First Name</label>
							{getInputVal.firstNameError && (
								<p className="form-error-message">First Name is Required</p>
							)}
						</div>

						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="text"
								name="lastName"
								id="lastNameError"
								onChange={handleChange("lastName")}
								value={getInputVal.lastName}
							/>
							<label>Last Name</label>
							{getInputVal.lastNameError && (
								<p className="form-error-message">Last Name is Required</p>
							)}
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="text"
								name="phone"
								id="phoneError"
								onChange={handleChange("phone")}
								value={getInputVal.phone}
							/>
							<label>Phone</label>
							{getInputVal.phoneError && (
								<p className="form-error-message">Phone Number is Required</p>
							)}
						</div>
						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="email"
								name="email"
								id="emailError"
								onChange={handleChange("email")}
								value={getInputVal.email}
							/>
							<label>Work Email</label>
							{getInputVal.emailError && (
								<p className="form-error-message">Email Address is Required</p>
							)}
							{invalidEmailError && (
								<p className="form-error-message">invalid Email Address</p>
							)}
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 pl-0">
							<input
								className="from-val"
								type="text"
								name="companyName"
								id="companyNameError"
								onChange={handleChange("companyName")}
								value={getInputVal.companyName}
							/>
							<label>Company/Business Name</label>
							{getInputVal.companyNameError && (
								<p className="form-error-message">
									Company/Business Name is Required
								</p>
							)}
						</div>

						<div className="col-md-6 pl-0">

<label
	 className="dropdown-levels text-left w-100"
	 style={{top: "-9px", color: "#0096FF"}}
 >
	 Business Industry
 </label>
 {/* <input
	 className="from-val"
	 type="text"
	 name="businessIndustry"
	 id="businessError"
	 onChange={handleChange("BusinessIndustry")}
	 value={getInputVal.BusinessIndustry}
 /> */}

 <div className="pt-2 mb-4 mt-2 w-100">
	 <select
		 className="select-one w-100 p-4"
		 name="businessIndustry"
		 id="businessError"
		 defaultValue={"DEFAULT"}
		 onChange={(value) => onIndustrySelect(value)}
	 >
		 <option value="DEFAULT" disabled>
			 select business industry
		 </option>
		 {industryList.map((item) => (
			 <option className="select-option">{item.value}</option>
		 ))}
	 </select>
 </div>
 
 {getInputVal.businessError && (
	 <p className="form-error-message">
		 Business Industry is Required
	 </p>
 )}
</div>
					</div>

					<div className="row">
						<div className="col-md-6 pl-0">
							<label
								className="dropdown-levels text-left w-100"
								style={{top: "-9px", color: "#0096FF"}}
							>
								Company Type
							</label>
							{/* <div className="mb-4 pt-2">
                <Multiselect
                  options={companyTypes}
                  displayValue="name"
                  placeholder="Select Company Type"
                  name="solutionSpecificity"
                  id="solutionSpecificityErr"
                  selectionLimit="1"
                  onSelect={onCompanyTypesSelect}
                />
              </div> */}

							<div className="pt-2 mb-4 mt-2 w-100">
								{/* <MultiSelect
                  options={companyTypes}
                  
                  placeholder="Select Solution Specificity"
                  name="solutionSpecificity"
                  id="solutionSpecificityErr"
                  selectionLimit="1"
                  onSelect={onCompanyTypesSelect}
                /> */}

								{/* <Select
                  options={companyTypes}
                  className="basic-multi-select"
                  placeholder="select company type"
                  styles={customStyles}
                  onChange={(value) => onCompanyTypesSelect(value)}
                  classNamePrefix="select"
                /> */}

								<select
									className="select-one w-100 p-4"
									onChange={(value) => onCompanyTypesSelect(value)}
									defaultValue={"DEFAULT"}
								>
									<option value="DEFAULT" disabled>
										select company type
									</option>
									{companyTypes.map((item) => (
										<option className="select-option">{item.value}</option>
									))}
								</select>
							</div>
							{getInputVal.companyTypesErr && (
								<p className="form-error-message">Company type is Required</p>
							)}
						</div>
						<div className="col-md-6 pl-0">
							<label
								className="dropdown-levels text-left w-100"
								style={{top: "-9px", color: "#0096FF"}}
							>
								Company Size
							</label>
							{/* <div className="mb-4 pt-2">
                <Multiselect
                  options={companySizes}
                  displayValue="name"
                  placeholder="Select Company Size"
                  name="solutionSpecificity"
                  id="solutionSpecificityErr"
                  selectionLimit="1"
                  onSelect={onCompanySizesSelect}
                />
              </div> */}

							<div className="pt-2 mb-4 mt-2 w-100">
								{/* <MultiSelect
                  // options={companySizes}
                  // displayValue="name"
                  // placeholder="Select Solution Specificity"
                  // name="solutionSpecificity"
                  // id="solutionSpecificityErr"
                  // selectionLimit="1"
                  // onChange={onCompanySizesSelect}
                  options={options}
                  // value={selected}
                  onChange={onCompanySizesSelect}
                  labelledBy="Select"
                /> */}

								<select
									className="select-one w-100 p-4"
									onChange={(value) => onCompanySizesSelect(value)}
									defaultValue={"DEFAULT"}
								>
									<option value="DEFAULT" disabled>
										select company size
									</option>
									{companySizes.map((item) => (
										<option className="select-option">{item.value}</option>
									))}
								</select>
							</div>
							{getInputVal.companySizesErr && (
								<p className="form-error-message">Company size is Required</p>
							)}
						</div>
					</div>

					<button
						className="btn btn-block btn-primary cta-form-btn"
						type="submit"
					>
						Create Account and Continue
					</button>
				</form>
			</div>
		</>
	);
};

export default UserFormDetailsCardOne;
