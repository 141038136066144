import React from 'react';
import "./sliderBullet.css"
import styles from "./dashboard.module.css";
import { DashboardSlider } from './DashboardSlider';

const DashboardSliderSection = () => {

  const pagination = {
    el: ".my-custom-pagination-div",
    clickable: true,
    renderBullet: function (index, className) {
      return `<div class=${className}>  </div>`;
    },
  };

    return (
      <div className={styles.dashboardSliderSection}>
        <h3 className={`text-center ${styles.heading}`}>
          Real Time Performance Dashboards
        </h3>
        {/* sliders  */}
        <DashboardSlider pagination={pagination} />

        {/* pagination slider  */}
        <div className={styles.dashboardPaginationWrapper}>
          <div className="my-custom-pagination-div"></div>
        </div>
      </div>
    );
};

export default DashboardSliderSection;