import React from "react";
import "./cform.css";
import Form from "../Navbar/ConsultingForm/UserForm";


const CForm = (props) => {
	// //console.log(props);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="about-us container" style={{marginTop: "4rem"}}>
			<Form/>
		</section>
	);
};

export default CForm;
