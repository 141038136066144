import React from "react";
import style from "./slider.module.css";

export const HeroSliderSingleCompo = ({data}) => {
  

  return (
    <>
      <div className={style.singleSliderWrapper} 
        style={{
          backgroundImage : `url(${data?.img})`
        }}
      >
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 col-md-12 col-lg-6">
              <div className={style.sliderContent}>
                <h1 className={style.heading}>{data?.title}</h1>
                <div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
