  import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Formik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import {useHistory} from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
       Best Practicify
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Register() {
  const classes = useStyles();
  const history=useHistory()

  const validationSchema = Yup.object({
    username: Yup.string("Enter User Name").required("User Name is required"),
    password: Yup.string("Enter Password").required("Password is required")          
  });

 const values = {
  username: "",password:""   
  };
  const submit=(data,{resetForm})=>{
    console.log(data)
   axios.post('api/Auth/Register',{username:data.username,password:data.password,role:'admin'}).then(res=>{
       if(res.data.status===true)  {
         toast.success("Account Created successfuly");
         resetForm({})
       } 
       else toast.error(res.data.message)
   })
    resetForm({})
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar  className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>

        <Formik
         initialValues={values}
         onSubmit={submit}
         validationSchema={validationSchema}>
         {props => {
        const {
          values,
          touched,
          errors,
          dirty,
          isValid,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset
        } = props;
        return (
         
 <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"           
            fullWidth
            id="username"
            label="User Name"
            name="username"
            value={values.username}
            onChange={handleChange}           
            autoComplete="username"
            helperText={touched.username ? errors.username : ""}
            error={touched.username && Boolean(errors.username)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"          
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={values.password}
            onChange={handleChange}
            autoComplete="current-password"
            helperText={touched.password ? errors.password : ""}
            error={touched.password && Boolean(errors.password)}
          />
         
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting || !isValid}
          >
            Sign Up
          </Button>
          
         
        </form>
         );
        }}
        </Formik>
        <Grid container>
           
           <Grid item>
             <Link style={{cursor:'pointer'}} onClick={()=>history.push("/login")} variant="body2">
               {"Already have an account? Sign In"}
             </Link>
           </Grid>
         </Grid>

        <ToastContainer autoClose={2000} />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}