import React from "react";
// import { BASE_API_URL, BASE_URL } from "../../Constants";
// import axios from "axios";
// import { Element, scroller } from "react-scroll";
// import { Link } from "react-router-dom";
// import "./ExploreCourses.css";
// import Training from "../InnovativeSolution/Training/Training";

const ExploreCourse = (props) => {
	React.useEffect(() => {
		// document.getElementById("data_tec").className += " active-right-course";
	});

	return (
		<div style={{marginTop: "5.5rem"}} className="container">
			<div class="alert alert-info" role="alert">
				New courses are coming soon
			</div>
			{/* <Training menuId={5} /> */}
		</div>
	);
};

export default ExploreCourse;
