import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";
import DashboardSliderCard from "../DashboardSlider/DashboardSliderCard";
import imgMarketing from "../../../../assets/img/dashboard/marketing.png"
import imgSales from "../../../../assets/img/dashboard/sales.png"
import imgCustomer from "../../../../assets/img/dashboard/customer.png"
import imgProduct from "../../../../assets/img/dashboard/product.png"

const data = [

  {
    title: "Financial Dashboard",
    img: imgCustomer,
    link: "/solutions/data-technology/data-and-analytics/dashboard/financial",
  },
  {
    title: "Customer Dashboard",
    img: imgCustomer,
    link: "/solutions/data-technology/data-and-analytics/dashboard/customer",
  },
  {
    title: "Product Dashboard",
    img: imgProduct,
    link: "/solutions/data-technology/data-and-analytics/dashboard/product",
  },
  {
    title: "Marketing Matrics Dashboard",
    img: imgMarketing,
    link: "/solutions/data-technology/data-and-analytics/dashboard/marketing",
  },
  {
    title: "Sales Dashboard",
    img: imgSales,
    link: "/solutions/data-technology/data-and-analytics/dashboard/sales",
  },
];

export const DashboardSlider = ({ pagination }) => {
  return (
    <>
      <Swiper
        navigation={false}
        pagination={pagination}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        slidesPerView={2}
        loop={true}
        modules={[Navigation, Autoplay, Pagination]}
      >
        {data.map((slider, index) => (
          <SwiperSlide key={index}>
            <DashboardSliderCard slider={slider} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
