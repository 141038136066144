import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BASE_API_URL } from "../../../Constants";
import "./SubscriptionForm.css";

const SubscriptionForm = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  console.log(watch("example")); // you can watch individual input by pass the name of the input

  const onSubmit = (data, e) => {
    console.log("data: " + JSON.stringify(data, undefined, 2));
    setLoading(true);
    axios
      .post(
        BASE_API_URL + `NewsletterSubscription/subscribe`,
        JSON.stringify(data),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.data.status) {
          setErr(false);
          setConfirm(true);
          e.target.reset();
        } else {
          setErr(true);
          setConfirm(false);
        }
      });
  };

  return (
    <>
      <section className="subscrption-section">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="subscription-title">
                Not ready to start building? Keep in touch.
              </p>
              <div className="subscription-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Your Name*"
                      name="name"
                      ref={register({ required: true })}
                      required
                    />
                    <input
                      type="email"
                      className="form-control mb-2"
                      placeholder="Your Email*"
                      name="email"
                      ref={register({ required: true })}
                      required
                    />

                    <div
                      className="d-flex justify-content-center w-100"
                      style={{ position: "relative" }}
                    >
                      {confirm ? (
                        <button className="btn btn-success btn-block disabled">
                          {/* <AiOutlineCheck color="#fff" /> */}
                          Subscribed
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {loading ? (
                            <span className="spinner">
                              <i className="fa fa-spinner fa-spin"></i>
                            </span>
                          ) : (
                            "Subscribe"
                          )}
                        </button>
                      )}
                    </div>
                    <div className="text-center">
                      {err && (
                        <p className="mt-2 mb-2" style={{ color: "red" }}>
                          oops.. Something went wrong. Try again
                        </p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubscriptionForm;
