/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {BASE_URL} from "../../Constants";

const Blogcategorypage = ({categoryItems}) => {
	const [Title, setTitle] = useState();

	useEffect(() => {
		if (categoryItems.length) {
			let item = categoryItems[0];
			setTitle(item.blogSubCategoryName);
		}
	}, [categoryItems]);

	return (
		<div className="blog-category-main">
			{/* <pre>
				{"blogSubCategorySingleItem: " +
				JSON.stringify(categoryItems, undefined, 2)}
			</pre> */}

			{categoryItems.length > 0 ? (
				<>
					<div className="blog-category-title">{Title}</div>
					<div className="blog-category-items">
						<div className="container-fluid">
							<div className="row">
								{categoryItems &&
									categoryItems.map((item, index) => (
										<div className="col-xs-12 col-sm-4 mb-4">
											<div className="card shadow-sm rounded-lg" key={index}>
												<Link
													to={`/insights/details/${item.slug}`}
													className="img-card"
												>
													<img
														className="card-img-top"
														src={BASE_URL + item.pictureUrl}
														alt="Card image cap"
														style={{height: "200px"}}
													/>
												</Link>
												<div className="card-content">
													<h5 className="card-title">
														<Link
															to={`/insights/details/${item.slug}`}
															className="btn btn-link btn-block"
														>
															{item.title}
														</Link>
													</h5>
													<p
														dangerouslySetInnerHTML={{
															__html:
																item.content.split(" ").slice(0, 30).join(" ") +
																" ...",
														}}
													/>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div class="alert alert-secondary" role="alert">
						Content is coming soon...
					</div>
				</>
			)}
		</div>
	);
};

export default Blogcategorypage;
