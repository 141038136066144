import React, {useState} from "react";
import * as Scroll from "react-scroll";
import ContactRequest from "../ContactRequest/ContactRequest";
import "../Details.css";
import "../DetailsResponsive.css";
import classes from "./Consulting.module.css";
import Consultation from "./DataTechConsult/Consultation";
import ConsultationDaily from "./DataTechConsult/ConsultationDaily/ConsultationDaily";
import ConsultationHourly from "./DataTechConsult/ConsultationHourly/ConsultationHourly";
import ConsultationMonthly from "./DataTechConsult/ConsultationMonthly/ConsultationMonthly";

const Consulting = ({
	menuId,
	subMenuId,
	consult,
	hourly,
	daily,
	yearly,
	indexing,
}) => {
	let contactUsLeftSectionText = `Ready to learn more about how industry-leading tech can be customized 
  to your business by implementing best practices? `;
	let contactUsRightSectionText = `Tell us more about your business, 
  your current tech and your technology needs to discover how to get more from leading tech systems. `;

	if (subMenuId === 6) {
		contactUsLeftSectionText =
			"Learn more about how our consulting services can support your business. ";
		contactUsRightSectionText =
			"Tell us about your business to see which consulting service we recommend for you. ";
	} else if (subMenuId === 9) {
		contactUsLeftSectionText =
			"Learn more about how our consulting services can help you develop an app that’s right for your business. ";
		contactUsRightSectionText =
			"Tell us about your business and your application development needs to see which consulting service we recommend to help you reach your goals. ";
	} else if (subMenuId === 8) {
		contactUsLeftSectionText =
			"Learn more about how our consulting services can support your ecommerce business. ";
		contactUsRightSectionText =
			"Tell us about your ecommerce business to see which consulting service will help you reach your business goals. ";
	} else if (subMenuId === 5) {
		contactUsLeftSectionText =
			"Learn more about how our consulting services can support your business.";
		contactUsRightSectionText =
			"Tell us about your business to see which consulting service we recommend for you.";
	}

	const [consulting, setConsulting] = useState(1);

	let Element = Scroll.Element;

	return (
		<Element name="Consulting">
			<div className="container-fluid">
				<Consultation consult={consult} indexing={indexing} />

				<div className="row">
					<div className="container">
						<div className="section-title text-center">
							Consultation tailored to your needs
						</div>
						<div className="section-sub-title text-center">
							Select your business type and your desired consultation type below
							to learn more.
						</div>
					</div>
				</div>

				<div
					className={[
						"navbar-right-course",
						classes.consultationTypeSelectionSection,
					].join(" ")}
				>
					<ul class="nav justify-content-center right-course training-tabs">
						<li
							className={`nav-item ${
								consulting === 1 ? "active-right-course" : ""
							}`}
							id="hourly_solution"
						>
							<button className="nav-link" onClick={() => setConsulting(1)}>
								Hourly Support
							</button>
						</li>
						<li
							className={`nav-item ${
								consulting === 2 ? "active-right-course" : ""
							}`}
							id="solution_discover"
						>
							<a className="nav-link" onClick={() => setConsulting(2)}>
								Solutions Discovery
							</a>
						</li>
						<li
							className={`nav-item ${
								consulting === 3 ? "active-right-course" : ""
							}`}
							id="access_retainer"
						>
							<a className="nav-link" onClick={() => setConsulting(3)}>
								Concierge
							</a>
						</li>
					</ul>
				</div>
				{consulting === 1 && (
					<ConsultationHourly hourly={hourly} subMenuId={subMenuId} />
				)}

				{consulting === 2 && (
					<ConsultationDaily daily={daily} subMenuId={subMenuId} />
				)}

				{consulting === 3 && (
					<ConsultationMonthly yearly={yearly} subMenuId={subMenuId} />
				)}

				<ContactRequest
					menuId={menuId}
					subMenuId={subMenuId}
					leftSectionText={contactUsLeftSectionText}
					rightSectionText={contactUsRightSectionText}
				/>
			</div>
		</Element>
	);
};

export default Consulting;
