import {data} from "../Components/Details/DetailsService/data";

export const getDetailsServiceData = (path) => {
	// '/solutions/data-technology/business-technology'
	let menuId = null;
	let subMenuId = null;
	let itemTitle = null;
	let itemSubTitle = null;
	let itemData = null;
	let menuName = null;
	let submenuName = null;

	const pathArr = path.split("/");
	// console.log('location form details page:',pathArr);

	for (const menuItem of data) {
		if (menuItem.slug === pathArr[1]) {
			menuId = menuItem.id;
			menuName = menuItem.name;

			for (const subMenuItem of menuItem?.subMenus) {
				if (subMenuItem.slug === pathArr[2]) {
					subMenuId = subMenuItem.id;
					submenuName = subMenuItem.name;

					for (const item of subMenuItem?.item) {
						if (item.slug === pathArr[3]) {
							itemTitle = item.name;
							itemSubTitle = item.desc;
							itemData = item;
							// console.log(item);
						}
					}
				}
			}
		}
	}

	return {
		menuId,
		subMenuId,
		title: itemTitle,
		desc: itemSubTitle,
		itemData,
		menuName,
		submenuName,
	};
};
