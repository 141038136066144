import React from "react";
import ContactUs from "../../../Components/Home/ContactUs";
import contactImg from "../../../Images/Contact-Us.png";
import UserForm from "../ConsultingForm/FunctionPages/FunctionPagesSolutionsForm/UserForm";
import "./ContactRequest.css";

const ContactRequest = (props) => {
	//console.log(props);
	React.useEffect(() => {}, []);

	return (
		<container>
			<div className="contact pt-5 pb-5 pl-0 pr-0 container">
				<div className="row">
					<div className="col-md-6 pl-0 pr-0">
						<div className="contact-container">
							<div className="row">
								<div className="col-md-6 ">
									<div className="contact-left">
										<img
											src={contactImg}
											className="pt-5 pb-5"
											alt=""
											width="300"
											height="300"
										/>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-center">
									<div className="contact-right d-flex flex-column">
										<p className="text-center">
											{props.leftSectionText
												? props.leftSectionText
												: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore`}
										</p>
										{/* <Link to='/ContactUs'><button type="button" className="btn btn-success text-info">Contact Us</button></Link> */}

										<button
											type="button"
											data-toggle="modal"
											data-target=".bd-example-modal-lg-contact"
											className="btn btn-primary text-white"
										>
											Contact Us
										</button>
										<div
											className="modal fade bd-example-modal-lg-contact"
											tabIndex="-1"
											role="document"
											aria-labelledby="myLargeModalLabel"
											aria-hidden="true"
										>
											<div className="modal-dialog modal-dialog-centered modal-lg">
												<div className="modal-content">
													<ContactUs menuId={props.menuId} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6 pl-0 pr-0 text-center">
						<div className="request-container d-flex align-items-center">
							<div className="d-flex flex-column">
								<p>
									{props.rightSectionText
										? props.rightSectionText
										: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore`}
								</p>
								<button
									type="button"
									data-toggle="modal"
									data-target="#contactRequest"
									data-backdrop="static"
									data-keyboard="false"
									className="btn btn-light text-info"
								>
									Request For Proposal
								</button>
							</div>

							<div
								className="modal fade"
								id="contactRequest"
								tabIndex="-1"
								role="document"
								aria-hidden="true"
							>
								<div className="modal-dialog modal-dialog-centered modal-xl">
									<div className="modal-content">
										<UserForm
											menuId={props.menuId}
											subMenuId={props.subMenuId}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</container>
	);
};

export default ContactRequest;
