import React from "react";
import "./SolutionPlatform.css";

const SolutionPlatform = () => {
	const [title, setTitle] = React.useState("Solution Platform");
	return (
		<section className="solution-partners">
			<div className="container">
				<div className="row">
					<h1>{title}</h1>
				</div>
				<div className="row">
					<ul class="list-group list-group-flush">
						<li class="list-group-item">
							<div className="partner-title">Microsoft 365 </div>
							<div className="partner-desc">
								Microsoft 365, formerly Office 365, is a line of subscription
								services offered by Microsoft which adds to and includes the
								Microsoft Office product line. The brand was launched on July
								10, 2017, for a superset of Office 365 with Windows 10
								Enterprise licenses and other cloud-based security and device
								management products.
							</div>
						</li>
						<li class="list-group-item">
							<div className="partner-title">
								Amazon Web Services, Inc. (AWS)
							</div>
							<div className="partner-desc">
								Amazon Web Services, Inc. (AWS) is a subsidiary of Amazon
								providing on-demand cloud computing platforms and APIs to
								individuals, companies, and governments, on a metered
								pay-as-you-go basis.
							</div>
						</li>
						<li class="list-group-item">
							<div className="partner-title">Sage Intacct</div>
							<div className="partner-desc">
								Sage Intacct, Inc is an American provider of cloud-based
								Financial management and services available in five regions
								around the globe -- including the United States, Canada, the UK,
								Australia, and South Africa.
							</div>
						</li>
						<li class="list-group-item">
							<div className="partner-title">Stripe</div>
							<div className="partner-desc">
								Stripe primarily offers payment processing software and
								application programming interfaces (APIs) for e-commerce
								websites and mobile applications.
							</div>
						</li>
						<li class="list-group-item">
							<div className="partner-title">Shopify</div>
							<div className="partner-desc">
								The Shopify platform offers online retailers a suite of services
								including payments, marketing, shipping and customer engagement
								tools.
							</div>
						</li>
						<li class="list-group-item">
							<div className="partner-title">Amazon FBA </div>
							<div className="partner-desc">
								Amazon FBA is short for Fulfillment by Amazon, which is a
								service Amazon offers to assist online business owners in
								managing inventory, packaging and delivering orders.
							</div>
						</li>
						<li class="list-group-item">
							<div className="partner-title">Azure</div>
							<div className="partner-desc">
								Microsoft Azure, commonly referred to as Azure is a cloud
								computing service created by Microsoft for building, testing,
								deploying, and managing applications and services through
								Microsoft-managed data centers.
							</div>
						</li>
						<li class="list-group-item">
							<div className="partner-title">Flutter </div>
							<div className="partner-desc">
								Flutter is an open-source UI software development kit created by
								Google. It is used to develop cross platform applications for
								Android, iOS, Linux, Mac, Windows, Google Fuchsia, and the web
								from a single codebase.
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default SolutionPlatform;
