/* eslint-disable react-hooks/exhaustive-deps */
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Container, Grid, Typography} from "@material-ui/core";
import React, {useEffect} from "react";
import {Carousel} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {scroller} from "react-scroll";
import {BASE_URL} from "../../Constants";
import LoadingFullWidth from "../Loading/LoadingFullWidth";
import "./Consultation.css";

const Consultation = (props) => {
	const [index, setIndex] = React.useState(0);
	let [loading, setLoading] = React.useState(false);
	const [it, setItem] = React.useState();

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	const consultationDetail = props.data;

	const handleEvent = (items, e) => {
		e.preventDefault();
		setItem(items);
	};

	const discardEvent = (e) => {
		e.preventDefault();
		setItem("");
	};

	const location = useLocation();

	useEffect(() => {
		if (location.state && location.state.byConsulting) {
			scroller.scrollTo(location.state.byConsulting, {
				duration: 500,
				smooth: true,
				offset: -80, // Scrolls to element + 50 pixels down the page
			});
		}
	}, []);

	return (
		<>
			{props.loading ? (
				<LoadingFullWidth height="350px" />
			) : (
				<section className="bp-homepage-Consultation-section light-blue-bg">
					<div className="Consultation-section">
						<Container fixed>
							<Typography variant="h4" align="center" className="Title">
								{props.title}
							</Typography>

							{!props.onPage ? (
								<div>
									{props?.subTitle && (
										<Typography
											variant="subtitle1"
											align="center"
											className="subTitle"
										>
											{props.subTitle}
										</Typography>
									)}
								</div>
							) : (
								<div className="consultation-overview">
									We know not all businesses are the same; that’s why we offer a
									variety of consultation options, available to a variety of
									business types. Whether you are an entrepreneur in need of
									on-demand consultation to help you troubleshoot problems and
									maximize your technology or an enterprise-level business in
									need of a digital transformation, Best Practicify can help.
								</div>
							)}

							<Grid container className="details-section">
								{props.onPage ? (
									<div className="row">
										{consultationDetail &&
											consultationDetail.map((item, i) => (
												<div className="col-md-6 pb-4">
													<h4>{item.name}</h4>
													<div className="subtitle1">
														<div
															dangerouslySetInnerHTML={{
																__html: item.description,
															}}
														/>
													</div>
												</div>
											))}
									</div>
								) : (
									<div className="consultation-content col-md-6">
										<div className="overview">
											We know not all businesses are the same; that’s why we
											offer a variety of consultation options, available to a
											variety of business types. Whether you are an entrepreneur
											in need of on-demand consultation to help you troubleshoot
											problems and maximize your technology or an
											enterprise-level business in need of a digital
											transformation, Best Practicify can help.
										</div>
										<div className="consultation-items">
											<ul className="list-group">
												{consultationDetail &&
													consultationDetail.map((item, i) => (
														<li
															className="list-group-item consultation-item"
															key={i}
															onMouseEnter={(e) => handleEvent(item, e)}
															onMouseLeave={(e) => discardEvent(e)}
														>
															<FontAwesomeIcon icon={faCircle} /> {item.name}
														</li>
													))}
											</ul>
										</div>
									</div>
								)}
								{!props.onPage && (
									<Grid item md={6} className="image-section">
										{it ? (
											<div className="text-center">
												<img
													className="d-block w-100 ml-2"
													src={BASE_URL + it.pictureUrl}
													alt="First slide"
												/>
											</div>
										) : (
											<Carousel indicators={false} onSelect={handleSelect}>
												{consultationDetail &&
													consultationDetail.map((item, i) => (
														<Carousel.Item key={i}>
															<img
																className="d-block w-100 ml-2"
																src={BASE_URL + item.pictureUrl}
																alt="First slide"
															/>
														</Carousel.Item>
													))}
											</Carousel>
										)}
										<div className="d-flex justify-content-center mt-3">
											<Link
												to={{
													pathname: `/consulting`,
													state: {
														name: "Consulting",
														value: "overview",
														id: 4,
													},
												}}
												className="btn btn-primary"
											>
												Let's get started
											</Link>
										</div>
									</Grid>
								)}
							</Grid>
						</Container>
					</div>
				</section>
			)}
		</>
	);
};

export default Consultation;
