import React from "react";
import * as Scroll from "react-scroll";
import "../Details.css";
import "../DetailsResponsive.css";

const Overview = (props) => {
  let Element = Scroll.Element;

  // console.log("faisal", props.menuId);

  let subtitle = props.overviewDetails.findIndex(
    (item) => item.id === props.menuId
  );
  // console.log("faisal", subtitle);

  return (
    <Element name="overview">
      <div class="plain">
        {/* <section>
          <header></header>
          <div class="separator"></div>
          <footer></footer>
        </section> */}

        <div className="container-fluid overview-page">
          <div id="overview" className="overview">
            <div className="row">
              <div className="col-md-12">
                <div className="overview-top text-center">
                  <h2 className="">{props.title}</h2>
                  <h4 className="">{props.subtitle}</h4>
                </div>
              </div>
            </div>

            <div className="overview-bottom">
              <div className="overview-bottom-top-section pt-5 pb-5">
                <div className="row">
                  {props.overviewDetails.map((overviewDetailsItem, index) => (
                    <div className="col-md-6 pl-5 pr-5" key={index}>
                      <div className="list-describe pb-4">
                        <ul>
                          <h5 className="pt-3 pb-3">
                            {overviewDetailsItem.title}
                          </h5>
                          <li>{overviewDetailsItem.description}</li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Overview;
