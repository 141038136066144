import React from "react";
import "./TrainingPopular.css";

const TrainingPopular = () => {
	const [title, setTitle] = React.useState("Popular Training");
	return (
		<section className="solution-partners">
			<div className="container">
				<div className="row">
					<h1>{title}</h1>
				</div>
				<div className="row">
					<ul class="list-group list-group-flush">
						<li class="list-group-item">
							<div className="partner-title">SharePoint </div>
							<div className="partner-desc">
								SharePoint is a web-based collaborative platform that integrates
								natively with Microsoft Office. Launched in 2001, SharePoint is
								primarily sold as a document management and storage system, but
								the product is highly configurable and its usage varies
								substantially among organizations.
							</div>
						</li>
						<li class="list-group-item">
							<div className="partner-title">Sage Intacct</div>
							<div className="partner-desc">
								Sage Intacct, Inc is an American provider of cloud-based
								Financial management and services available in five regions
								around the globe -- including the United States, Canada, the UK,
								Australia, and South Africa.
							</div>
						</li>
						<li class="list-group-item">
							<div className="partner-title">QuickBooks Online</div>
							<div className="partner-desc">
								QuickBooks is an accounting software package developed and
								marketed by Intuit. First introduced in 1983, QuickBooks
								products are geared mainly toward small and medium-sized
								businesses and offer on-premises accounting applications as well
								as cloud-based versions that accept business payments, manage
								and pay bills, and payroll functions.
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default TrainingPopular;
