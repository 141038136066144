import React from "react";
import * as Scroll from "react-scroll";
import { BASE_URL } from "../../../Constants";
import ContactRequest from "../ContactRequest/ContactRequest";
import "../Details.css";
import "../DetailsResponsive.css";

const TechnologyParters = ({
  techPartners,
  name,
  menuId,
  subMenuId,
  indexing,
  imagelength,
}) => {
  let Element = Scroll.Element;
  let contactUsLeftSectionText = `Ready to learn more about how industry-leading tech can be customized 
  to your business by implementing best practices? `;
  let contactUsRightSectionText = `Tell us more about your business, 
  your current tech and your technology needs to discover how to get more from leading tech systems. `;

  let techSubtitle = "";

  if (subMenuId === 6) {
    techSubtitle =
      "Best Practicify works with trusted names in accounting and finance software. We help you select and optimize accounting and finance software by customizing software solutions to your business, implementing best practices and integrating those solutions into your workflows.";
  } else if (subMenuId === 9) {
    techSubtitle =
      "Best Practicify works with the technology names you trust. We help you to optimize your application by customizing solutions to your business, implementing best practices and integrating those solutions into your workflows.";
    contactUsLeftSectionText =
      "Ready to learn more about how app development -- implemented with best practices -- can help your business thrive?";
    contactUsRightSectionText =
      "Tell us more about your business, your goals and needs to discover how app development can help you grow.";
  } else if (subMenuId === 8) {
    techSubtitle =
      "Best Practicify works with the ecommerce technologies you trust. We help you to optimize ecommerce platforms and software by customizing solutions to your business, implementing best practices and integrating those solutions into your workflows.";
    contactUsLeftSectionText =
      "Ready to learn more about how industry-leading ecommerce solutions can be customized to your business by implementing best practices? ";
    contactUsRightSectionText =
      "Tell us more about your business, your current operations and your goals to discover how to get move your ecommerce business forward.";
  } else if (subMenuId === 5) {
    techSubtitle =
      "Technology from the names you trust, customized to your business. Best Practicify optimizes technology tools from top trusted technology partners by customizing solutions to your business by implementing best practices and integrating solutions into your workflows.";
  }

  const renderImageSection = () => {
    if (imagelength > 0) {
      if (imagelength > 1) {
        return (
          techPartners &&
          techPartners.map((item, index) => (
            <div className="col-md-3" key={index}>
              <div className="list-describe">
                <img
                  src={BASE_URL + item.pictureUrl}
                  className="img-fluid pb-3"
                  alt=""
                />
              </div>
            </div>
          ))
        );
      } else {
        return (
          <div className="col-md-12">
            <div className="tech-partner-image">
              <img
                src={BASE_URL + techPartners[0].pictureUrl}
                className="img-fluid pb-3"
                alt=""
                width="100%"
                height="auto"
              />
            </div>
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  // console.log(props)
  return (
    <Element name={name}>
      <div className="vertical separator"></div>

      <div className="gray-section">
        <div className="container  modern-technology">
          <div className="modern-technology-heading pb-5">
            <div className="row">
              <div className="col text-center">
                <h2 className="section-title text-uppercase">
                  Technology Partners
                </h2>
                <h3 className="section-sub-title">{techSubtitle}</h3>
              </div>
            </div>
          </div>

          <div className="modern-technology-images">
            <div className="container">
              <div className="row d-flex justify-content-center">
                {renderImageSection()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="separator"></div>
      <ContactRequest
        menuId={menuId}
        subMenuId={subMenuId}
        leftSectionText={contactUsLeftSectionText}
        rightSectionText={contactUsRightSectionText}
      />
    </Element>
  );
};

export default TechnologyParters;
