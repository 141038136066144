import React, {useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {useForm} from "react-hook-form";
import useSSR from "use-ssr";
import {BASE_API_URL} from "../../Constants";
import "./ContactUs.css";

const ContactUs = (props) => {
	const [isVerified, setIsVerified] = React.useState(false);
	const [errorMsgShow, setErrorMsgShow] = React.useState(false);
	let [getInputVal, setInputVal] = React.useState({
		fullName: "",
		companyName: "",
		phoneNumber: "",
		email: "",
		message: "",
	});

	const [modalControl, setModalControl] = useState(false);

	const handleChange = (input) => (e) => {
		//console.log({[input]: e.target.value});

		setInputVal({...getInputVal, [input]: e.target.value});
	};

	const {register, handleSubmit, Controller, errors, watch} = useForm({
		defaultValues: props.getInputVal,
	});

	const recaptchaLoaded = () => {
		//console.log("recaptcha loaded successfully!!!");
	};

	var verifyCaptcha = (response) => {
		// response.preventDefault();
		//console.log(response);
		response && setIsVerified(true);
	};

	const onSubmit = (e) => {
		e.preventDefault();

		if (isVerified) {
			fetch(`${BASE_API_URL}ContactUsMessage/SaveMessage`, {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(getInputVal),
			})
				.then((res) => res.json())
				.then((data) => {
					//console.log("datastatus", data.status);
					if (data.status) {
						setErrorMsgShow(false);
						setModalControl(true);
					} else {
						setErrorMsgShow(true);
					}
				});

			//console.log("responseContact2", getInputVal);

			// axios.post(
			// 	`${BASE_API_URL}ContactUsMessage/SaveMessage`,
			// 	{
			// 		fullName: data.fullName,
			// 		companyName: data.companyName,
			// 		phoneNumber: data.phoneNumber,
			// 		email: data.email,
			// 		message: data.message,
			// 	}
			// ).then( res => {
			// 	//console.log("responseContact",res.data);
			// })

			// const response = await axios.post(
			// 	`${BASE_API_URL}ContactUsMessage/SaveMessage`,
			// 	{
			// 		fullName: data.fullName,
			// 		companyName: data.companyName,
			// 		phoneNumber: data.phoneNumber,
			// 		email: data.email,
			// 		message: data.message,
			// 	}
			// );

			// if (response.data.status) {
			// 	//console.log("responseContact",response.data);
			// 	setModalControl(true);
			// }
			// //console.log(response);
		} else {
			alert("Please verify that you are a human !!!");
		}
	};
	// //console.log(getInputVal);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const {isBrowser} = useSSR();

	return (
		<>
			<div className="contact-us-form pt-4 pb-4">
				<div className="container">
					{modalControl ? (
						""
					) : (
						<>
							<div className="d-flex justify-content-between">
								<h3 className="text-left pl-4">CONTACT US</h3>
								<div className="close-btn">
									<button
										type="button"
										className="close mb-3 pr-3"
										data-toggle="modal"
										data-target=".bd-example-modal-lg-contact"
										data-dismiss="modal"
										aria-label="Close"
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
							</div>
							{errorMsgShow && (
								<div class="alert alert-danger" role="alert">
									Something Wrong Try Out Again!??
								</div>
							)}
						</>
					)}

					<div className="contact-form">
						<div className="card shadow">
							<div className="card-body">
								{modalControl ? (
									<div className="modal-body box text-center">
										<h2
											className="modal-title text-center w-100 mt-4 mb-1"
											id="exampleModalLongTitle"
										>
											{/* Thank's for your Feedback */}
											{/* alvi */}
											Thank You 
										</h2>

										<button
											type="button"
											className="btn btn-secondary  mb-4"
											data-dismiss="modal"
										>
											Close
										</button>
									</div>
								) : (
									<form onSubmit={onSubmit}>
										<div className="row contact-row">
											<div className="col-md-6 left">
												<div className="form-group">
													<label htmlFor="fullName">Full name Your</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter your Name"
														name="fullName"
														id="fullName"
														required
														ref={register({
															required: "Full name is required",
														})}
														onChange={handleChange("fullName")}
													/>
													{errors.fullName && (
														<p style={{color: "red"}}>
															{errors.fullName.message}
														</p>
													)}
												</div>

												<div className="form-group">
													<label htmlFor="companyName">Company name</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter company name"
														name="companyName"
														id="companyName"
														required
														ref={register({
															required: "Company name is required",
														})}
														onChange={handleChange("companyName")}
													/>
													{errors.companyName && (
														<p style={{color: "red"}}>
															{errors.companyName.message}
														</p>
													)}
												</div>

												<div className="form-group">
													<label htmlFor="phoneNumber">Phone number</label>
													<input
														type="number"
														className="form-control"
														placeholder="Enter phone number"
														name="phoneNumber"
														id="phoneNumber"
														required
														ref={register({
															required: "Phone number is required",
														})}
														onChange={handleChange("phoneNumber")}
													/>
													{errors.phoneNumber && (
														<p style={{color: "red"}}>
															{errors.phoneNumber.message}
														</p>
													)}
												</div>

												<div className="form-group">
													<label htmlFor="email">Email</label>
													<input
														type="email"
														required
														className="form-control"
														placeholder="Enter email"
														name="email"
														id="email"
														ref={register({required: "Email is required"})}
														onChange={handleChange("email")}
													/>
													{errors.email && (
														<p style={{color: "red"}}>{errors.email.message}</p>
													)}
												</div>
											</div>
											<div className="col-md-6 right">
												<label htmlFor="message">Message</label>
												<textarea
													className="message"
													required
													rows="13"
													placeholder="Message"
													name="message"
													id="message"
													ref={register({required: "Message is required"})}
													onChange={handleChange("message")}
												></textarea>
												{errors.message && (
													<p style={{color: "red"}}>{errors.message.message}</p>
												)}
											</div>
										</div>

										<div
											className="captcha-checkbox d-flex justify-content-center"
											id="fai"
										>
											{/* {
												isBrowser && (
													<Recaptcha
														sitekey="6LfRCdAdAAAAAFgEXu1mtuL05-sL5vY_fSW24A_y"
														render="explicit"
														verifyCallback={verifyCaptcha}
														onloadCallback={recaptchaLoaded}
													/>
												)
											} */}

											{/* <Recaptcha
												sitekey="6Le6qEUeAAAAABW_PzuOTj-8cB34NDHlIkLcSZbh"
												render="explicit"
												verifyCallback={verifyCaptcha}
												onloadCallback={recaptchaLoaded}
											/> */}
											<ReCAPTCHA
												sitekey="6LfRCdAdAAAAAFgEXu1mtuL05-sL5vY_fSW24A_y"
												onChange={verifyCaptcha}
											/>
										</div>

										<button
											className="btn btn-primary mx-auto d-block mt-3"
											type="submit"
										>
											Submit
										</button>
									</form>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactUs;
