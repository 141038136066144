import React from "react";

const ConsultationHourlyPriceItem = ({
	title,
	textOne,
	textTwo,
	hourSelect,
	selectValue,
	price,
	defaultPrice,
	validity,
	delautValidity,
	startingPrice,
}) => {
	// const priceString = convertPriceToLocalString(price);

	const convertPricetoUsd = (rate) => {
		return rate.toLocaleString("en-US", {
			currency: "USD",
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
	};

	return (
		<>
			<div className="pricingTable pricing-hov d-flex flex-column">
				<div className="pricingTable-header">
					<h3 className="title">{title}</h3>
					<p className="desc-text">{textOne}</p>
					<p className="desc-text">{textTwo}</p>
					<select
						aria-label="Select"
						title="Select Hour"
						onChange={(e) => hourSelect(e)}
						className="form-control selection"
					>
						{selectValue.length
							? selectValue.map((item, index) => (
									<option>{item.ctaHourName}</option>
							  ))
							: ""}
					</select>
				</div>
				<div className="pricingTable-price">
					<div className="price-value">
						<p className="">Price</p>
						<p className="">Starting at</p>
						<small className="fa fa-usd"></small>
						{/* <span>{price ? price : defaultPrice}</span> */}
						<span>
							{convertPricetoUsd(startingPrice ? startingPrice : defaultPrice)}
							<sub>/hour</sub>
						</span>
					</div>

					<div className="price-value">
						<p className="">Validity</p>
						<span>{validity ? validity : delautValidity} days</span>
					</div>
				</div>
				<div className="pricingTable-sign-up">
					<button
						type="button"
						data-toggle="modal"
						data-target="#purchase_modal"
						className="btn btn-block btn-default"
					>
						{/* {location.pathname === "/"
            ? "Schedule a Consult"
            : "Request For Proposal"} */}
						purchase
					</button>
					{/* <a
              href="https://client.bestpracticify.co/signin"
              target="_blank"
              className="btn btn-block btn-default"
            >
              Purchase
            </a> */}
				</div>
			</div>
		</>
	);
};

export default ConsultationHourlyPriceItem;
