import React, {useEffect, useState} from "react";
import {Element, scroller} from "react-scroll";
import {BASE_URL} from "../../../Constants";
import "./MenuSection.css";
// import "./UniqueSolutionResponsive.css";

const MenuSection = (props) => {
	//console.log(props);

	let menuDetails = props.data.filter(
		(item) => item.menuSectionDetails.length > 0
	);

	const [mobileView, setMobileView] = useState(false);

	React.useEffect(() => {
		const mql = window.matchMedia("(max-width: 991px)");

		if (mql.matches) {
			setMobileView(mql.matches);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		mql.addEventListener("change", (e) => {
			//console.log(e);
			setMobileView(e.matches);
		});
	}, []);

	useEffect(() => {
		if (props.service && menuDetails) {
			let obj = menuDetails.find((obj) => obj.systemName === props.service);
			//console.log(obj);
			if (obj) {
				scroller.scrollTo(props.service, {
					duration: 50,
					smooth: true,
					offset: -70, // Scrolls to element + 50 pixels down the page
				});
			}
		}
	}, [props.service, menuDetails]);
	return (
		<div className="uniqueSolutionDetail">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3 className="section-title text-center pb-5">{props.title}</h3>
					</div>
				</div>

				{menuDetails.length > 0 &&
					menuDetails.map((pageDetails, index) =>
						mobileView ? (
							<Element name={pageDetails.systemName}>
								<div className="row">
									<div
										className="col-md-6 d-flex align-items-center"
										key={Math.random()}
									>
										<img
											style={{width: "100%", height: "auto"}}
											src={
												BASE_URL + pageDetails.menuSectionDetails[0].pictureUrl
											}
											alt=""
										/>
									</div>
									<div
										className="col-md-6 d-flex align-items-center"
										key={Math.random()}
									>
										<div className="content">
											<p className="section-heading-large">
												{pageDetails.menuSectionDetails[0].title}
											</p>
											{/* <div className="list-describe">
                        {pageDetails.menuSectionDetails[0].description.replace(
                          /<\/?p[^>]*>/g,
                          ""
                        )}
                      </div> */}

											<div
												className="list-describe"
												dangerouslySetInnerHTML={{
													__html: pageDetails.menuSectionDetails[0].description,
												}}
											/>
										</div>
									</div>
								</div>
							</Element>
						) : (
							<div>
								{index % 2 === 0 ? (
									<Element name={pageDetails.systemName}>
										<div className="row">
											<div
												className="col-md-6 d-flex align-items-center"
												key={Math.random()}
											>
												<div className="content">
													<p className="section-heading-large">
														{pageDetails.menuSectionDetails[0].title}
													</p>
													{/* <div className="list-describe">
                        {pageDetails.menuSectionDetails[0].description.replace(
                          /<\/?p[^>]*>/g,
                          ""
                        )}
                      </div> */}
													<div
														className="list-describe"
														dangerouslySetInnerHTML={{
															__html:
																pageDetails.menuSectionDetails[0].description,
														}}
													/>
												</div>
											</div>
											<div
												className="col-md-6 d-flex align-items-center"
												key={Math.random()}
											>
												<img
													style={{width: "100%", height: "auto"}}
													src={
														BASE_URL +
														pageDetails.menuSectionDetails[0].pictureUrl
													}
													alt=""
												/>
											</div>
										</div>
									</Element>
								) : (
									<Element name={pageDetails.systemName}>
										<div className="row">
											<div
												className="col-md-6 d-flex align-items-center"
												key={Math.random()}
											>
												<img
													style={{width: "100%", height: "auto"}}
													src={
														BASE_URL +
														pageDetails.menuSectionDetails[0].pictureUrl
													}
													alt=""
												/>
											</div>
											<div
												className="col-md-6 d-flex align-items-center"
												key={Math.random()}
											>
												<div className="content">
													<p className="section-heading-large">
														{pageDetails.menuSectionDetails[0].title}
													</p>
													{/* <div className="list-describe">
                        {pageDetails.menuSectionDetails[0].description.replace(
                          /<\/?p[^>]*>/g,
                          ""
                        )}
                      </div> */}

													<div
														className="list-describe"
														dangerouslySetInnerHTML={{
															__html:
																pageDetails.menuSectionDetails[0].description,
														}}
													/>
												</div>
											</div>
										</div>
									</Element>
								)}
							</div>
						)
					)}
			</div>
		</div>
	);
};

export default MenuSection;
