import React from "react";
import {context} from "../../../App";
import UploadFiles from "./UploadFiles/UploadFilesComponent";
import "./UserForm.css";

const UserFormDetailsCardFour = (props) => {
	//console.log(props);

	const {ID} = React.useContext(context);
	//console.log(ID);

	return (
		<>
			<div className="modal-body box">
				<div className="custom-file clearfix">
					<UploadFiles id={ID} />
				</div>

				<div className="d-flex justify-content-between">
					{/* <button className="btn btn-primary cta-form-btn w-100 mr-2" type="button" onClick={() => props.prevStep()}>
                Back
            </button> */}

					<button
						className="btn btn-primary cta-form-btn w-100"
						type="submit"
						onClick={() => props.nextStep()}
					>
						Continue
					</button>
				</div>
			</div>
		</>
	);
};

export default UserFormDetailsCardFour;
