import axios from "axios";
import React from "react";
import SubscriptionForm from "./SubscriptionForm/SubscriptionForm";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
	PinterestIcon,
	PinterestShareButton,
} from "react-share";
import {BASE_API_URL, BASE_URL} from "../../Constants";
// import topImg from '../../Images/rectangle.png';
import "./BolgDetails.css";
// import './BolgDetailsResponsive.css';

const BlogDetails = (props) => {
	let [blogSubCategoryItem, setBlogSubCategoryItem] = React.useState([]);
	let [insightsBlogDetails, setInsightsBlogDetails] = React.useState([]);
	let [resourceBlogDetails, setResourceBlogDetails] = React.useState([]);
	const URL = "https://bestpracticify.co/";

	React.useEffect(() => {
		axios
			.get(
				BASE_API_URL + `BlogPost/Details/${props.match.params.blog_details_id}`,
				{}
			)
			.then(
				(res) => {
					//////console.log(res);
					if (res.data.data.length != 0) {
						setBlogSubCategoryItem(res.data.data);
					}
				},
				(error) => {
					//console.log(error);
				}
			);

		axios.get(BASE_API_URL + `BlogCategory`, {}).then(
			(res) => {
				//////console.log(res);
				// //console.log(res.data.data);

				if (res.data.data.length != 0) {
					res.data.data.map((item, index) => {
						if (item.name == "Blog") {
							axios
								.post(BASE_API_URL + `BlogPost/LatestBlogs`, {
									currentPage: 1,
									perPageCount: 3,
									categoryId: 1,
								})
								.then(
									(res) => {
										// ////console.log(res);

										if (res.data.data.item1.length != 0) {
											setInsightsBlogDetails(res.data.data.item1);
										}
									},
									(error) => {
										//console.log(error);
									}
								);
						}

						if (item.name == "Resources") {
							axios
								.post(BASE_API_URL + `BlogPost/LatestBlogs`, {
									currentPage: 1,
									perPageCount: 3,
									categoryId: 3,
								})
								.then(
									(res) => {
										//////console.log(res);

										if (res.data.data.item1.length != 0) {
											setResourceBlogDetails(res.data.data.item1);
										}
									},
									(error) => {
										//console.log(error);
									}
								);
						}
					});
				}
			},
			(error) => {
				//console.log(error);
			}
		);
	}, [props.match.params.blog_details_id]);

	// //console.log(blogSubCategoryItem);
	// //console.log(insightsBlogDetails);
	// //console.log(resourceBlogDetails);

	return (
		<>
			<Helmet>
				<meta property="og:title" content={blogSubCategoryItem.title} />
				<meta property="og:type" content="article" />
				<meta property="og:description" content={blogSubCategoryItem.title} />
				<meta
					property="og:image"
					content={BASE_URL + blogSubCategoryItem.pictureUrl}
				/>
				<meta
					property="og:url"
					content={
						"https://bestpracticify.co/insights/details/" +
						blogSubCategoryItem.slug
					}
				/>
				<meta name="twitter:card" content={blogSubCategoryItem.title} />
			</Helmet>

			<div className="container">
				<div className="blog_details mt-5">
					<div className="row">
						{/* {JSON.stringify(blogSubCategoryItem, undefined, 2)} */}
						{/* <pre>
							{"https://user.bestpracticify.co/insights/details/" +
								blogSubCategoryItem.slug}
						</pre> */}
						<div className="col-md-8">
							<div className="blog-title pb-3">
								<h2 className="text-center">{blogSubCategoryItem.title}</h2>
							</div>
							<div className="blog-img pb-3">
								<img
									className="mx-auto d-block w-75"
									src={BASE_URL + blogSubCategoryItem.pictureUrl}
									alt=""
								/>
							</div>
							<div className="blog-description">
								{/* <p>{blogSubCategoryItem.content}</p> */}
								<p
									dangerouslySetInnerHTML={{
										__html: blogSubCategoryItem.content,
									}}
								/>
							</div>
							<div className="my-3">
								<div className="social-share d-flex justify-content-right align-items-center">
									<div className="share-btns">
										<FacebookShareButton
											url={URL + "insights/details/" + blogSubCategoryItem.slug}
											title={blogSubCategoryItem.title}
											className="Demo__some-network__share-button"
										>
											<FacebookIcon size={45} round />
										</FacebookShareButton>
									</div>
									<div className="share-btns">
										<TwitterShareButton
											url={URL + "insights/details/" + blogSubCategoryItem.slug}
											title={blogSubCategoryItem.title}
											className="Demo__some-network__share-button"
										>
											<TwitterIcon size={45} round />
										</TwitterShareButton>
									</div>
									<div className="share-btns">
										<LinkedinShareButton
											url={URL + "insights/details/" + blogSubCategoryItem.slug}
											title={blogSubCategoryItem.title}
											source={
												URL + "insights/details/" + blogSubCategoryItem.slug
											}
											className="Demo__some-network__share-button"
										>
											<LinkedinIcon size={45} round />
										</LinkedinShareButton>
									</div>
									<div className="share-btns">
										<PinterestShareButton
											url={URL + "insights/details/" + blogSubCategoryItem.slug}
											media={BASE_URL + blogSubCategoryItem.pictureUrl}
											description={blogSubCategoryItem.title}
											className="Demo__some-network__share-button"
										>
											<PinterestIcon size={45} round />
										</PinterestShareButton>
									</div>
								</div>
							</div>
							<SubscriptionForm />
						</div>

						<div className="col-md-4">
							<div className="blog-category">
								{/* <div className="blog-category-heading pt-2 pb-4">
                                    <h4>Recent Post</h4>
                                </div> */}

								<div className="insights-blog pb-3">
									<div className="blog-category-heading pt-4 pb-2">
										<h5>Insights</h5>
									</div>

									{insightsBlogDetails.map((item, index) => (
										<>
											<Link
												to={`/insights/details/${item.slug}`}
												className="blog-link"
											>
												<div className="blog-category-insights-items d-flex mb-2">
													<img
														className="blog-side-image rounded-circle"
														src={BASE_URL + item.pictureUrl}
														alt=""
													/>
													<div className="blog-category-title-description ml-3">
														<h6>{item.title}</h6>

														<p
															dangerouslySetInnerHTML={{
																__html:
																	item.content
																		.split(" ")
																		.slice(0, 9)
																		.join(" ") + " .....",
															}}
														/>
													</div>
												</div>
											</Link>
										</>
									))}
								</div>

								<div className="insights-blog">
									{/* <div className="blog-category-heading pt-4 pb-2">
                                        <h5>Resources Blog</h5>
                                    </div> */}

									{resourceBlogDetails.map((item, index) => (
										<>
											<Link
												to={`/insights/details/${item.slug}`}
												className="blog-link"
											>
												<div className="blog-category-insights-items d-flex mb-2">
													<img
														className="blog-side-image rounded-circle"
														src={BASE_URL + item.pictureUrl}
														alt=""
													/>
													<div className="blog-category-title-description ml-3">
														<h6>{item.title}</h6>
														<p
															dangerouslySetInnerHTML={{
																__html:
																	item.content
																		.split(" ")
																		.slice(0, 9)
																		.join(" ") + " .....",
															}}
														/>
													</div>
												</div>
											</Link>
										</>
									))}
								</div>
							</div>

							<div className="social-share d-flex justify-content-left align-items-center">
								<div className="share-btns">
									<FacebookShareButton
										url={URL + "insights/details/" + blogSubCategoryItem.slug}
										quote={blogSubCategoryItem.title}
										className="Demo__some-network__share-button"
									>
										<FacebookIcon size={45} round />
									</FacebookShareButton>
								</div>
								<div className="share-btns">
									<TwitterShareButton
										url={URL + "insights/details/" + blogSubCategoryItem.slug}
										title={blogSubCategoryItem.title}
										className="Demo__some-network__share-button"
									>
										<TwitterIcon size={45} round />
									</TwitterShareButton>
								</div>
								<div className="share-btns">
									<LinkedinShareButton
										url={URL + "insights/details/" + blogSubCategoryItem.slug}
										title={blogSubCategoryItem.title}
										source={"https://bestpracticify.co/"}
										className="Demo__some-network__share-button"
									>
										<LinkedinIcon size={45} round />
									</LinkedinShareButton>
								</div>
								<div className="share-btns">
									<PinterestShareButton
										url={URL + "insights/details/" + blogSubCategoryItem.slug}
										media={BASE_URL + blogSubCategoryItem.pictureUrl}
										description={blogSubCategoryItem.title}
										
		
									>
										<PinterestIcon size={45} round />
									</PinterestShareButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlogDetails;
