import React, {useState} from "react";
import {isFromPaymentCheck} from "../../Components/Helpers/Helpers";
import NewConsulntRegistration from "../Authentication/NewConsulntRegistration/NewConsulntRegistration";
import "./CardConsult.css";

const HourlyConsult = ({consult, hourly}) => {
	const [signup, setSignup] = useState(false);
	const [createAccount, setCreateAccount] = useState(false);

	const items = hourly
		? hourly.filter((item) => item.companyTypeId === consult)
		: "";

	let sortedItems = [...items];
	sortedItems.sort((a, b) => {
		if (a.displayOrder < b.displayOrder) {
			return -1;
		}
		if (a.displayOrder > b.displayOrder) {
			return 1;
		}
		return 0;
	});

	var texts = {};

	switch (consult) {
		case 1:
			// Entrepreneur == 1
			texts.firstLine = "On-demand, hourly consulting";
			texts.secondLine = "Tailored business technology";
			texts.price = 79;
			break;
		case 2:
			// Startup  == 2
			texts.firstLine = "On-demand, hourly consulting ";
			texts.secondLine = "Business-enabling technology ";
			texts.price = 79;
			break;
		case 3:
			// Ecommerce   == 3
			texts.firstLine = "On-demand, hourly consulting ";
			texts.secondLine = "Tech solutions to support online businesses ";
			texts.price = 99;
			break;
		case 4:
			// Enterprise   == 4
			texts.firstLine = "On-demand, hourly consulting ";
			texts.secondLine = "Enterprise technology solutions ";
			texts.price = 119;
			break;
		default:
			texts.firstLine = "On-demand, hourly consulting";
			texts.secondLine = "";
	}

	function currencyFormat(num) {
		return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	const openRegiserForm = () => {
		setSignup(true);
	};

	const closeAllCondition = () => {
		setSignup(false);
		setCreateAccount(false);
	};

	return (
		<div class="pt-3 hourly">
			{/* <div>
				<b>items: </b>
				<pre>{JSON.stringify(items, undefined, 2)}</pre>
			</div> */}
			{/* <div>
				<b>hourly: </b>
				<pre>{JSON.stringify(hourly, undefined, 2)}</pre>
			</div> */}

			{/* {
        newUser && (
            <NewConsulntRegistration></NewConsulntRegistration>
        )
      } */}

			<div
				className="modal fade "
				tabIndex="-1"
				role="document"
				aria-labelledby="myLargeModalLabel"
				aria-hidden="true"
				id="purchase_modal"
			>
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content">
						<div className="modal-header mb-3">
							<div className="container w-100">
								<div className="row modal-header-row ">
									<div className="d-flex justify-content-end w-100">
										<h2
											class="modal-title text-center"
											id="exampleModalLongTitle"
										>
											{createAccount
												? "Sign In"
												: signup
												? "Create New Account"
												: "Sign In or Sign Up"}
										</h2>
										<button
											type="button"
											className="close"
											data-toggle="modal"
											data-target="#purchase_modal"
											data-dismiss="modal"
											aria-label="Close"
											onClick={closeAllCondition}
										>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							{createAccount ? (
								<div className="text-center m-4">
									<h6 className="mb-3 text-success">
										Your account has been created successfully. Please check
										your email to complete the purchase.
									</h6>

									<a
										href="https://client.bestpracticify.co/signin?fromPayment=true"
										target="_blank"
										class="btn btn-primary my-2"
										rel="noreferrer"
										onClick={isFromPaymentCheck}
									>
										Sign In
									</a>

									{/* <div className="newToBP my-2">
                      <p className="text-center text-secondary bg-white"><span>New To Best Practicify?</span></p>
                      <hr />
                    </div>
                    <a onClick={ openRegiserForm } className="btn btn-primary my-2">Create Your Best Practicify Account</a> */}
								</div>
							) : (
								<>
									{!signup ? (
										<div className="text-center m-4">
											<h6 className="mb-3">
												To purchase the selected consultation, please sign in
												first.
											</h6>

											<a
												href="https://client.bestpracticify.co/signin?fromPayment=true"
												target="_blank"
												class="btn btn-primary my-2"
												rel="noreferrer"
												onClick={isFromPaymentCheck}
											>
												Sign In
											</a>

											<div className="newToBP my-2">
												<p className="text-center text-secondary bg-white">
													<span>New To Best Practicify?</span>
												</p>
												<hr />
											</div>
											<a
												onClick={openRegiserForm}
												className="btn btn-primary my-2"
											>
												Create Your Best Practicify Account
											</a>
										</div>
									) : (
										<NewConsulntRegistration
											setCreateAccount={setCreateAccount}
										></NewConsulntRegistration>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{sortedItems.length ? (
				<div class="container">
					{/* <div class="row text-center">
            <h3 class="heading-title text-center">Hourly Pricing</h3>
          </div> */}

					<div class="row">
						<div class="col-md-4 col-sm-6 pricing-table-col">
							<div class="pricingTable hourly pricing-hov">
								<div class="pricingTable-header pt-5">
									<h3>{sortedItems[0]?.ctaHourName}</h3>
									<p className="pt-4">{texts.firstLine}</p>
									<p className="">{texts.secondLine}</p>
								</div>

								<div className="pricing-details">
									<div class="price-value">
										<p className="">Price</p>
										<p className="">Starting at</p>
										<span>
											<small class="fa fa-usd"></small>
										</span>
										{/* <span>{currencyFormat(sortedItems[0]?.rate)}</span> */}
										<span>{currencyFormat(texts.price)}</span>
										<span>
											<sub>/hour</sub>
										</span>
									</div>

									<div className="price-value">
										<p className="">Validity</p>
										<span>{sortedItems[0]?.validity} days</span>
										{/* <span class="subtitle">days</span> */}
									</div>
								</div>

								<div class="pricingTable-sign-up">
									<button
										type="button"
										data-toggle="modal"
										data-target="#purchase_modal"
										class="btn btn-block btn-default"
									>
										{/* {location.pathname === "/"
                      ? "Schedule a Consult"
                      : "Request For Proposal"} */}
										purchase
									</button>
									{/* <a
                    href="https://client.bestpracticify.co/signin"
                    target="_blank"
                    class="btn btn-block btn-default"
                  >
                    Purchase
                  </a> */}
								</div>
							</div>
						</div>

						<div class="col-md-4 col-sm-6 pricing-table-col">
							<div class="pricingTable pricing-hov">
								<div class="pricingTable-header pt-5">
									<h3>{sortedItems[1]?.ctaHourName}</h3>
									<p className="pt-4">{texts.firstLine}</p>
									<p className="">{texts.secondLine}</p>
								</div>

								<div className="pricing-details">
									<div class="price-value">
										<p className="">Price</p>
										<p className="">Starting at</p>
										<span>
											<small class="fa fa-usd"></small>
										</span>
										{/* <span>{currencyFormat(sortedItems[1]?.rate)}</span> */}
										<span>{currencyFormat(texts.price)}</span>
										<span>
											<sub>/hour</sub>
										</span>
										{/* <span class="subtitle">per hour</span> */}
									</div>

									<div className="price-value">
										<p className="">Validity</p>
										<span>{sortedItems[1]?.validity} days</span>
										{/* <span class="subtitle"></span> */}
									</div>
								</div>

								<div class="pricingTable-sign-up">
									<button
										type="button"
										data-toggle="modal"
										data-target="#purchase_modal"
										class="btn btn-block btn-default"
									>
										purchase
									</button>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-sm-6 pricing-table-col">
							<div class="pricingTable pricing-hov">
								<div class="pricingTable-header pt-5">
									<h3>{sortedItems[2]?.ctaHourName}</h3>

									<p className="pt-4">{texts.firstLine}</p>

									<p className="">{texts.secondLine}</p>
								</div>

								<div className="pricing-details">
									<div class="price-value">
										<p className="">Price</p>
										<p className="">Starting at</p>
										<span>
											<small class="fa fa-usd"></small>
										</span>
										{/* <span>{currencyFormat(sortedItems[2]?.rate)}</span> */}
										<span>{currencyFormat(texts.price)}</span>
										<span>
											<sub>/hour</sub>
										</span>

										{/* <span class="subtitle">per hour</span> */}
									</div>

									<div className="price-value">
										<p className="">Validity</p>
										<span>{sortedItems[2]?.validity} days</span>
										{/* <span class="subtitle">days</span> */}
									</div>
								</div>

								<div class="pricingTable-sign-up">
									<button
										type="button"
										data-toggle="modal"
										data-target="#purchase_modal"
										class="btn btn-block btn-default"
									>
										purchase
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};
export default HourlyConsult;
