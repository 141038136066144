import React from "react";
import PlaceholderLoading from "react-placeholder-loading";
import "./LoadingFullWidth.css";

const LoadingFullWidth = (props) => {
  return (
    <div
      className="loadersection full-width-loader"
      style={{ height: props.height }}
    >
      <div className="container">
        {props.repeat ? (
          Array.from(Array(props.repeat), (e, i) => {
            return (
              <div className="row pb-4" key={i}>
                <div className="col-12 pb-2">
                  <PlaceholderLoading shape="rect" width="100%" height={40} />
                </div>
                <div className="col-12 pb-2">
                  <PlaceholderLoading shape="rect" width="100%" height={40} />
                </div>
                <div className="col-12 pb-2">
                  <PlaceholderLoading shape="rect" width="100%" height={40} />
                </div>
                <div className="col-12 pb-2">
                  <PlaceholderLoading shape="rect" width="100%" height={40} />
                </div>
                <div className="col-12 pb-2">
                  <PlaceholderLoading shape="rect" width="50%" height={40} />
                </div>
              </div>
            );
          })
        ) : (
          <div className="row">
            <div className="col-12 pb-2">
              <PlaceholderLoading shape="rect" width="100%" height={40} />
            </div>
            <div className="col-12 pb-2">
              <PlaceholderLoading shape="rect" width="100%" height={40} />
            </div>
            <div className="col-12 pb-2">
              <PlaceholderLoading shape="rect" width="100%" height={40} />
            </div>
            <div className="col-12 pb-2">
              <PlaceholderLoading shape="rect" width="100%" height={40} />
            </div>
            <div className="col-12 pb-2">
              <PlaceholderLoading shape="rect" width="50%" height={40} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadingFullWidth;
