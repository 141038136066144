import React from "react";
import DashboardSliderSection from "../DashboardSlider/DashboardSliderSection";
import DataAnalysisSmallForm from "../DataAnalysicsSmallForm/DataAnalysicsSmallForm";
import BPWaves from "../FreeTrail/BPWaves";
import DashboardMockup from "./DashboardMockup";
import FreeTrail from '../FreeTrail/FreeTrail';
import { useLocation } from 'react-router-dom';
import { getDetailsServiceData } from '../../../../utils/getDetailsServiceData';

const Financial = () => {

    const data = {
      link: "https://app.powerbi.com/view?r=eyJrIjoiZWNkNjc0YzgtY2EzOS00ZThhLThkYjgtZGQ2OGI4ZWEyOTI2IiwidCI6ImFmNTU3MDBiLTMwY2QtNGRjYi05NGYxLTVmNjI0ZmVkZmRjOCIsImMiOjZ9",
    };
   

    const location = useLocation();

    const pathName = location.pathname;
    const {menuId, subMenuId} =
      getDetailsServiceData(pathName);
    const discoverPotential = {
      title : 'Discover the Potential of Your Business Data',
      list: [
        "Identify new opportunities and growth areas in your business",
        "Increase operational efficiency by automating repetitive tasks",
        "Reduce costs by identifying inefficiencies and streamlining processes",
        "Make informed, data-driven decisions for better outcomes",
        "Gain a competitive edge in your industry by leveraging data insights",
      ],
    }


  return (
    <>
      <DashboardMockup data={data} />
      <FreeTrail title="Unlocking unique solutions" data={discoverPotential} menuId={menuId} subMenuId={subMenuId} />
      <DashboardSliderSection />
    </>
  );
};

export default Financial;
