import React from "react";
import "./aboutus.css";
const ContactRequest = React.lazy(() =>
	import("../Home/ContactRequest/ContactRequest")
);

const AboutUs = (props) => {
	// //console.log(props);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="about-us" style={{marginTop: "4rem"}}>
			<div className="pt-5 pb-5 mb-5">
				<div className="container">
					<div className="about-us-heading row">
						<div className="col">
							{/* <div className="about-break"></div> */}
							<span>About Us</span>
							
						</div>
					</div>
				</div>
				<div class="benifit-as-a-partner mb-3">
							<div className="container">
							<div className="row">
								<div className="col">
								<h1 className="text-color1">
								Growth Hacking Innovators. Hybrid Business-Minded
								
								Technologist. Intelligence Transformers.
							</h1>
							<p className="text-color1">
							Empowering businesses with innovative solutions and strategic guidance that drive growth, efficiency, and success.
								
							</p>
							<br />

								</div>
							</div>
							</div>
							
							</div>
				<div className="about-us-list">
					<div className="container">
						<div className="row mb-3">
							<div className="col">
								 <h3>We Transform Businesses and Create New Possibilities</h3>
								 <br/>
								<p>
								At Best Practicify, we are a dynamic team of growth hacking innovators, blending business acumen with <a href="https://bestpracticify.co/technology">cutting-edge technology</a> to drive transformative change. Our mission is simple yet powerful: to be the intelligence transformers that propel businesses to new heights of success. Best Practicify’s partnership allows its clients to harness industry <a href="https://bestpracticify.co/services">best practices</a> and success while increasing productivity and innovation.
								</p>
							
							   <br />
								<br />
								<br />
								
								</div>
						
						</div>
					</div>

					<div class="benifit-as-a-partner mb-3">
							<div className="container">
							<div className="row">
								<div className="col">
								<h3 className="text-color">Unlock Success by Implementing Industry Best Practices</h3>
								<p className="text-color">
								Best Practicify was founded in response to the growing demand for reliable <a href="https://bestpracticify.co/services/accounting-finance">accounting & finance services</a> as well as comprehensive <a href="https://bestpracticify.co/services/data-technology"></a>data & technology solutions that integrate seamlessly with business processes, policies and people. Over the years, we have built a reputation for excellence by delivering high-quality services that drive efficiency, productivity, and growth for our clients. Our global distributed workforce, spanning multiple continents, comprises seasoned professionals who bring together a wealth of experience and knowledge gained from working with businesses across various industries such as real estate, e-commerce, non-profit, professional services, healthcare, and retail. 
								</p>
								<br />
								<p className="text-color">At Best Practicify, our clients come first. We take the time to understand your unique challenges and goals, developing customized <a href="https://bestpracticify.co/solutions">IT solutions</a> and <a href="https://bestpracticify.co/services/application-development">web and mobile apps</a> that deliver measurable results. We have also been at the forefront of helping businesses achieve their goals through scalable <a href="https://bestpracticify.co/services/accounting-finance">accounting services</a> and building a <a href="https://bestpracticify.co/services/accounting-finance/operational-accounting-and-support">financial foundation</a> to achieve exceptional results.</p>
								<br/>
								<p className="text-color"><a href="https://bestpracticify.co/consulting">Partner with Best Practicify</a> today and experience the transformative power of intelligence-driven solutions for your business. </p>
							
								
								</div>
							</div>
							</div>
							
							</div>

					<div className="container">
						<div className="row mb-3">
							<div className="col">
							<br /><br /><br /><br />
								<h5>MISSION</h5>
								<p>
									To align technology outcomes with business outcomes by
									applying best practices so an enterprise can create business
									value reliably for its investors, workforces, and communities
									around them.
								</p>
							</div>
							<div className="col"></div>
						</div>
					</div>

					<div className="container">
						<div className="row mb-3">
							<div className="col"></div>
							<div className="col">
								<h5>VISION </h5>
								<p>
									Best Practicify believes that every business, irrespective of
									its size, ought to have equal access to best practices and
									techniques to confidently maneuver the ever changing
									technological landscape so it can leverage modern technology
									to deliver results faster, adapt plans in time of uncertainty,
									and create sustainable business value.
								</p>
							</div>
							
						</div>
					</div>
					<br /><br /><br /><br />

					<div className="container">
						<div className="row mb-3">
							<div className="col about-us-core-values">
								<h5>CORE VALUES: </h5>

								<h2 className="core-values-bg-transform">TRANSFORM</h2>

								<div className="alphabets">
									<div className="alphabet-meaning">
										<div className="alphabet">T</div>
										<p>
											– Transparency is provided in management, communications,
											and operations to make a positive impact.
										</p>
									</div>
									<div className="alphabet-meaning">
										<div className="alphabet">R</div>
										<p>
											- Roadmap with manageable milestones is prepared for each
											engagement to achieve measurable results.
										</p>
									</div>
									<div className="alphabet-meaning">
										<div className="alphabet">A</div>
										<p>
											- Automation is adapted for scalability so modern
											technology is leveraged to its full potential.
										</p>
									</div>
									<div className="alphabet-meaning">
										<div className="alphabet">N</div>
										<p>
											- Navigation for the future is built in partnership with
											us in this era of complexity and technological disruption.
										</p>
									</div>
									<div className="alphabet-meaning">
										<div className="alphabet">S</div>
										<p>
											– SMART goals framework governs each project for excellent
											performance by our team.  
										</p>
									</div>
									<div className="alphabet-meaning">
										<div className="alphabet">F</div>
										<p>
											– Focused execution is an integral part of our
											methodologies to deliver on our commitments.  
										</p>
									</div>
									<div className="alphabet-meaning">
										<div className="alphabet">O</div>
										<p>
											– Optimization with no compromise drives the innovation of
											our next best practice.  
										</p>
									</div>
									<div className="alphabet-meaning">
										<div className="alphabet">R</div>
										<p>
											– Resiliency and reliability are two fundamental criteria
											in our procurement process.    
										</p>
									</div>
									<div className="alphabet-meaning">
										<div className="alphabet">M</div>
										<p>
											– Management strategies employed by us include planning,
											leadership, innovation, and influencing.   
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="benifit-as-a-partner mb-3">
						<div className="container">
							<div className="row">
								<div className="col">
									<h2>Benefits of a productive partnership with us:</h2>
								</div>
							</div>
							<div class="row g-2 mt-3">
								<div class="col-md-4 col-12 benifit-as-a-partner-section">
									<div class="card border-secondary text-white bg-transparent m-0 shadow-none">
										<div class="card-body d-flex justify-content-left align-items-center  h-100 ">
											<div class="">
												Drive efficient business activities&nbsp;
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 col-12 benifit-as-a-partner-section">
									<div class="card border-secondary text-white bg-transparent m-0 shadow-none">
										<div class="card-body d-flex justify-content-left align-items-center h-100">
											<div class="">Increase productivity and innovation</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 col-12 benifit-as-a-partner-section">
									<div class="card border-secondary text-white bg-transparent m-0 shadow-none">
										<div class="card-body d-flex justify-content-left align-items-center h-100">
											<div class="">
												Improve stakeholders satisfaction &amp; loyalty
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 col-12 benifit-as-a-partner-section">
									<div class="card border-secondary text-white bg-transparent m-0 shadow-none">
										<div class="card-body d-flex justify-content-left align-items-center  h-100">
											<div class="">
												Harness industry best practices &amp; success
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 col-12 benifit-as-a-partner-section">
									<div class="card border-secondary text-white bg-transparent m-0 shadow-none">
										<div class="card-body d-flex justify-content-left align-items-center  h-100">
											<div class="">Save time, money, and control risks</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 col-12 benifit-as-a-partner-section">
									<div class="card border-secondary text-white bg-transparent m-0 shadow-none">
										<div class="card-body d-flex justify-content-left align-items-center  h-100">
											<div class="">
												Benchmark for assessment and value maximization
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container">
						<div className="row mb-3">
							<ContactRequest />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
