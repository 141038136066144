import React from "react";

const PageNotFound = () => {
	return (
		<div>
			<h1>PageNotFound</h1>
			<p>Please try to access other links. Thanks</p>
		</div>
	);
};

export default PageNotFound;
