/* eslint-disable array-callback-return */
import axios from "axios";
import $ from "jquery";
import React, {createRef, useEffect, useRef, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import {FaToggleOff, FaToggleOn} from "react-icons/fa";
import {Link} from "react-router-dom";
import {BASE_API_URL} from "../../../Constants";
import "./ProgressWizard.css";
import "./ProgressWizardEdited.css";
import "./SubMenuItemList.css";

const SubMenuItemList = (props) => {
	//console.log(props.menuId);

	let [menu, setMenu] = React.useState([]);
	let [subMenu, setSubMenu] = React.useState([]);
	let [menuId, setMenuId] = React.useState();
	let [circleId, setCircleId] = React.useState("5");
	const [menuSlug, setMenuSlug] = useState();
	let [tabId, setTabId] = React.useState("1");
	const [menuCheck, setMenuCheck] = useState();
	const [collapse, setCollapse] = useState(false);

	function handleToggling(e) {
		setCollapse(e);
	}

	const type_section = [
		{
			id: 3,
			items: [
				"Financial Systems",
				"E-commerce",
				"Business Intelligence",
				"Business Productivity",
				"Web Development",
				"Cloud Technologies",
				"Blockchain",
			],
		},
		{
			id: 4,
			items: ["Entrepreneur", "Startup", "Business", "Enterprise"],
		},
		{
			id: 5,
			items: ["On-demand Courses", "Live Virtual Classes"],
		},
	];

	// const type_section = [
	//   {
	//     id: 3,
	//     items: [
	//       [
	//         "Financial Systems",
	//         "E-commerce",
	//         "Business Intelligence",
	//         "Business Productivity",
	//       ],
	//       ["Web Development", "Cloud Technologies", "Blockchain"],
	//     ],
	//   },
	//   {
	//     id: 4,
	//     items: [
	//       ["Entrepreneur", "Startup"],
	//       ["Business", "Enterprise"],
	//     ],
	//   },
	//   {
	//     id: 5,
	//     items: [["On-demand Courses"], ["Live Virtual Classes"]],
	//   },
	// ];

	const menuRef = useRef([]);

	useEffect(() => {
		setMenuId(props.menuId);
		if (props.menuId === 1) {
			setMenuSlug("solutions");
		} else if (props.menuId === 2) {
			setMenuSlug("services");
		} else if (props.menuId === 3) {
			setMenuSlug("technology");
		} else if (props.menuId === 4) {
			setMenuSlug("consulting");
		} else if (props.menuId === 5) {
			setMenuSlug("training");
		}
	}, [props.menuId]);

	// useEffect(() => {
	//   if (menu && menuId) {
	//     //console.log(menuId);

	//     menu.map((menuItem, index) => {
	//       if (menuItem.menuId === menuId) {
	//         document.getElementById(menuItem.menuId).className += " active";
	//       }
	//     });
	//   }
	// }, [menu, menuId]);

	React.useEffect(() => {
		axios
			.get(BASE_API_URL + "MenuSubMenuMap/MenuSubmenus", {isAscending: true})
			.then(
				(response) => {
					if (response.data.status) {
						// //console.log(response.data.data);
						setMenu(response.data.data);
					}
				},
				(error) => {
					//console.log(error);
				}
			);

		axios
			.get(
				BASE_API_URL +
					`MenuSubMenuMap/GetMenuPageSubMenuMapItemsByMenuId/${menuId}`,
				{isAscending: true}
			)
			.then(
				(response) => {
					if (response.data.status) {
						//console.log("response: " + JSON.stringify(response, undefined, 4));
						setSubMenu(response.data.data);
					}
				},
				(error) => {
					//console.log(error);
				}
			);
	}, [menuId]);

	$(document).ready(function () {
		$(function () {
			$("ul.submenuHeaderItemTabBorder li").on("click", function (e) {
				$(this).parent().find("li.active").removeClass("active");
				$(this).addClass("active");
			});
		});

		// $(function () {
		//   $(".nav-pills li a").on("click", function () {
		//     //console.log($(this).parent().find("a.actived"))
		//     $(this).parent().parent().find("a.actived").removeClass("actived");
		//     $(this).addClass("actived");
		//   });
		// });
	});

	const circleClick = (e) => {
		// //console.log(e.target.id);
		setCircleId(e.target.id);
	};

	const handleClick = (value) => {
		menu.map((item, index) => {
			if (item.menuId === value) {
				// //console.log(e.target.id);

				if (menuRef.current[index]) {
					menuRef.current[index].className += " actived";
					//console.log(menuRef.current[index].className);
				}
				for (let i = 0; i < menuRef.length; i++) {
					if (i !== index) {
						if (menuRef.current[i]) {
							if (menuRef.current[i].classList.contains("actived")) {
								menuRef.current[i].classList.remove("actived");
							}
						}
					}
				}
			}
		});
	};

	const tabClick = (id) => (e) => {
		e.preventDefault();

		setMenuId(id);
		setTabId(id);
		let id_for_slug = parseInt(id);
		if (id_for_slug === 1) {
			setMenuSlug("solutions");
		} else if (id_for_slug === 2) {
			setMenuSlug("services");
		} else if (id_for_slug === 3) {
			setMenuSlug("technology");
		} else if (id_for_slug === 4) {
			setMenuSlug("consulting");
		} else if (id_for_slug === 5) {
			setMenuSlug("training");
		}

		handleClick(parseInt(id));
	};

	// //console.log(menu);
	// //console.log(subMenu);
	// //console.log(circleId);
	// //console.log(tabId);
	// //console.log(menuId);
	const handleSelected = () => {
		menu.map((item, index) => {
			if (item.menuId === menuId) {
				if (menuRef.current[index]) {
					menuRef.current[index].className += " actived";
				}
				// for (let i = 0; i < menuRef.length; i++) {
				//   if (i !== index) {
				//     if (menuRef.current[i]) {
				//       if (menuRef.current[i].classList.contains("actived")) {
				//         menuRef.current[i].classList.remove("actived");
				//       }
				//     }
				//   }
				// }
			} else {
				if (menuRef.current[index]) {
					if (menuRef.current[index].classList.contains("actived")) {
						menuRef.current[index].classList.remove("actived");
					}
				}
			}
		});
	};

	function handleRef(el, index) {
		menuRef.current[index] = createRef();
		menuRef.current[index] = el;
		if (index === menu.length - 1) {
			handleSelected();
		}
	}

	if (subMenu) {
		//console.log(subMenu);
	}

	return (
		<>
			<div className="submenuHeaderItemTab mt-5">
				<div className="w-100 clearfix">
					<ul
						className="submenuHeaderItemTabBorder nav nav-pills mt-3 d-flex justify-content-around progress-indicator"
						id="pills-tab"
					>
						{subMenu
							? subMenu
									.sort((a, b) => (a.id > b.id ? 1 : -1))
									.map((subMenuItem, index) =>
										subMenuItem.id === 5 ? (
											<li
												id={subMenuItem.id}
												className="nav-item active"
												data-toggle="pill"
												href={`#${subMenuItem.title
													.split(" ")
													.join("")
													.split("&")
													.join("")}`}
												onClick={(e) => circleClick(e)}
											>
												<span
													id={subMenuItem.id}
													data-toggle="pill"
													href={`#${subMenuItem.title
														.split(" ")
														.join("")
														.split("&")
														.join("")}`}
													className="bubble"
													onClick={(e) => circleClick(e)}
												></span>

												<p
													id={subMenuItem.id}
													data-toggle="pill"
													href={`#${subMenuItem.title
														.split(" ")
														.join("")
														.split("&")
														.join("")}`}
													onClick={(e) => circleClick(e)}
												>
													{subMenuItem.title}
												</p>
											</li>
										) : (
											<li
												id={subMenuItem.id}
												className="nav-item"
												data-toggle="pill"
												href={`#${subMenuItem.title
													.split(" ")
													.join("")
													.split("&")
													.join("")}`}
												onClick={(e) => circleClick(e)}
											>
												<span
													id={subMenuItem.id}
													data-toggle="pill"
													href={`#${subMenuItem.title
														.split(" ")
														.join("")
														.split("&")
														.join("")}`}
													className="bubble"
													onClick={(e) => circleClick(e)}
												></span>

												<p
													id={subMenuItem.id}
													data-toggle="pill"
													href={`#${subMenuItem.title
														.split(" ")
														.join("")
														.split("&")
														.join("")}`}
													onClick={(e) => circleClick(e)}
												>
													{subMenuItem.title}
												</p>
											</li>
										)
									)
							: ""}
					</ul>
				</div>
			</div>

			<div className="submenuMainItemTab">
				<div className="tab-content">
					{subMenu && subMenu.length
						? subMenu.map(
								(subMenuItem, index) =>
									subMenuItem.id === parseInt(circleId) && (
										<>
											<div
												id={subMenuItem.title
													.split(" ")
													.join("")
													.split("&")
													.join("")}
												className="tab-pane fade show active"
											>
												<section id="main-section">
													<div className="sub-menu container mb-3">
														<div className="row">
															<div className="col-md-6">
																<div className="sub-menu-left">
																	<div className="sub-menu-list">
																		{subMenuItem.menuSubmenuItems.map(
																			(item, i) =>
																				i === 0 && (
																					<ul class="list-group list-group-flush">
																						{item.menuSubMenuMapItemListItems
																							.length
																							? item.menuSubMenuMapItemListItems.map(
																									(items, index) => (
																										<li class="list-group-item">
																											<Link
																												to={{
																													pathname: `/${menuSlug}/${item.slug}`,
																													state: {
																														name: subMenuItem.title,
																														id: menuId,
																														sub_id:
																															subMenuItem.id,
																													},
																												}}
																											>
																												<i
																													className="fa fa-check-circle"
																													aria-hidden="true"
																												></i>
																												{items.text}
																											</Link>
																										</li>
																									)
																							  )
																							: type_section.map(
																									(typeItems, index) =>
																										parseInt(menuId) ===
																											typeItems.id &&
																										typeItems.items.map(
																											(item, i) => {
																												if (
																													typeItems.id === 3
																												) {
																													return (
																														i < 4 && (
																															<li class="list-group-item">
																																<Link
																																	to={{
																																		pathname: `/technology`,
																																		state: {
																																			name: "Technology",
																																			id: 3,
																																			byTech:
																																				item,
																																		},
																																	}}
																																	// className="megamenu-link"
																																>
																																	<i
																																		className="fa fa-check-circle"
																																		aria-hidden="true"
																																	></i>
																																	{item}
																																</Link>
																															</li>
																														)
																													);
																												} else if (
																													typeItems.id === 4
																												) {
																													return (
																														i < 2 && (
																															<li class="list-group-item">
																																<Link
																																	to={{
																																		pathname: `/consulting`,
																																		state: {
																																			name: "Consulting",
																																			id: 4,
																																			byConsulting:
																																				item,
																																		},
																																	}}
																																	// className="megamenu-link"
																																>
																																	<i
																																		className="fa fa-check-circle"
																																		aria-hidden="true"
																																	></i>
																																	{item}
																																</Link>
																															</li>
																														)
																													);
																												} else if (
																													typeItems.id === 5
																												) {
																													return (
																														i < 1 && (
																															<li class="list-group-item">
																																<Link
																																	to={{
																																		pathname: `/training`,
																																		state: {
																																			name: "Training",
																																			id: 5,
																																			byTraining:
																																				item,
																																		},
																																	}}
																																	// className="megamenu-link"
																																>
																																	<i
																																		className="fa fa-check-circle"
																																		aria-hidden="true"
																																	></i>
																																	{item}
																																</Link>
																															</li>
																														)
																													);
																												}
																											}
																										)
																							  )}
																					</ul>
																				)
																		)}
																	</div>
																</div>
															</div>

															<div className="col-md-6">
																<div className="sub-menu-right">
																	<div className="sub-menu-list">
																		<ul class="list-group list-group-flush">
																			{subMenuItem.menuSubmenuItems.map(
																				(item, i) =>
																					i === 1 && (
																						<ul class="list-group list-group-flush">
																							{item.menuSubMenuMapItemListItems
																								.length
																								? item.menuSubMenuMapItemListItems.map(
																										(items, index) => (
																											<li class="list-group-item">
																												<Link
																													to={{
																														pathname: `/${menuSlug}/${item.slug}`,
																														state: {
																															name: subMenuItem.title,
																															id: menuId,
																															sub_id:
																																subMenuItem.id,
																														},
																													}}
																												>
																													<i
																														className="fa fa-check-circle"
																														aria-hidden="true"
																													></i>
																													{items.text}
																												</Link>
																											</li>
																										)
																								  )
																								: type_section.map(
																										(typeItems, index) =>
																											parseInt(menuId) ===
																												typeItems.id &&
																											typeItems.items.map(
																												(items, i) => {
																													if (
																														typeItems.id === 3
																													) {
																														return (
																															i >= 4 && (
																																<li class="list-group-item">
																																	<Link
																																		to={{
																																			pathname: `/technology`,
																																			state: {
																																				name: "Technology",
																																				id: 3,
																																				byTech:
																																					items,
																																			},
																																		}}
																																		// className="megamenu-link"
																																	>
																																		<i
																																			className="fa fa-check-circle"
																																			aria-hidden="true"
																																		></i>
																																		{items}
																																	</Link>
																																</li>
																															)
																														);
																													} else if (
																														typeItems.id === 4
																													) {
																														return (
																															i >= 2 && (
																																<li class="list-group-item">
																																	<Link
																																		to={{
																																			pathname: `/consulting`,
																																			state: {
																																				name: "Consulting",
																																				id: 4,
																																				byConsulting:
																																					items,
																																			},
																																		}}
																																		// className="megamenu-link"
																																	>
																																		<i
																																			className="fa fa-check-circle"
																																			aria-hidden="true"
																																		></i>
																																		{items}
																																	</Link>
																																</li>
																															)
																														);
																													} else if (
																														typeItems.id === 5
																													) {
																														return (
																															i >= 1 && (
																																<li class="list-group-item">
																																	<Link
																																		to={{
																																			pathname: `/training`,
																																			state: {
																																				name: "Training",
																																				id: 5,
																																				byTraining:
																																					items,
																																			},
																																		}}
																																		// className="megamenu-link"
																																	>
																																		<i
																																			className="fa fa-check-circle"
																																			aria-hidden="true"
																																		></i>
																																		{items}
																																	</Link>
																																</li>
																															)
																														);
																													}
																												}
																											)
																								  )}
																						</ul>
																					)
																			)}
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
											</div>
										</>
									)
						  )
						: ""}
				</div>
			</div>

			<div className="submenuFooterItemTab">
				<Navbar
					collapseOnSelect
					expand="lg"
					onToggle={(e) => handleToggling(e)}
				>
					{/* <span style={{ fontSize: "2em" }}>&times;</span> */}
					<Navbar.Toggle aria-controls="nav-second" id="testing-id">
						{collapse ? <FaToggleOn size="2em" /> : <FaToggleOff size="2em" />}
					</Navbar.Toggle>
					<Navbar.Collapse id="nav-second">
						<ul
							className="nav nav-pills d-flex justify-content-end"
							id="pills-tab"
						>
							{menu.map((menuItem, index) => (
								<>
									<li className="nav-item" key={index}>
										<a
											className="nav-link"
											ref={(el) => handleRef(el, index)}
											style={{cursor: "pointer"}}
											// data-toggle="pill"
											id={menuItem.menuId}
											onClick={tabClick(menuItem.menuId)}
										>
											{menuItem.menuName.toUpperCase()}
										</a>
									</li>
								</>
							))}
						</ul>
					</Navbar.Collapse>
				</Navbar>
			</div>

			{/* <div className="submenuFooterItemTab">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-end">
            <ul className="nav nav-pills mt-3" id="pills-tab">
              {menu.map((menuItem, index) => {
                if (menuItem.menuId === menuId) {
                  return (
                    <>
                      <li className="nav-item" key={index}>
                        <a
                          className="nav-link active"
                          style={{ cursor: "pointer" }}
                          data-toggle="pill"
                          id={menuItem.menuId}
                          onClick={(e) => tabClick(e)}
                        >
                          {menuItem.menuName.toUpperCase()}
                        </a>
                      </li>
                    </>
                  );
                } else {
                  return (
                    <>
                      <li className="nav-item" key={index}>
                        <a
                          className="nav-link"
                          style={{ cursor: "pointer" }}
                          data-toggle="pill"
                          id={menuItem.menuId}
                          onClick={(e) => tabClick(e)}
                        >
                          {menuItem.menuName.toUpperCase()}
                        </a>
                      </li>
                    </>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </div> */}
		</>
	);
};

export default SubMenuItemList;
