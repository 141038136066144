import axios from "axios";
import React from "react";
import Select from "react-select";
import  {useForm, Controller}  from "react-hook-form";
import './formstyle.css';
import { useState } from "react";

const DataAnalysisSmallFormAllField = () => {

  const [success , setSuccess] = useState(false);


  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
  } = useForm();

  const technologyOptions = [
    {label : 'Quick Books', value : 'quickbook'},
    {label : 'Power Bi', value : 'power bi'},
    {label : 'Zero', value : 'zero'},
    {label : 'Sege Intact', value : 'sege intacct'},
    {label : 'Sege 100|300', value : 'sege 100|300'},
    {label : 'Buildium', value : 'buildium'},
    {label : 'App Folio', value : 'app folio'},
    {label : 'Bill.com', value : 'bill.com'},
    {label : 'Expensify', value : 'expensify'},
    {label : 'Tallie', value : 'tallie'},
  ]

  const onSubmit = (data) => {

    console.log(data);

    // const allTechnology = data.technology.map( item => item.value).join(',');
    // const allDataSource= data.dataSource.map( item => item.value).join(',');
    // let date = new Date();

    // const submitData = {
    //     name : data.name,
    //     phone : data.phone,
    //     email : data.email,
    //     businessName : data.businessName,
    //     howManyDashboard : Number(data.dashboard),
    //     technologyPreference : allTechnology,
    //     dataSource : allDataSource,
    //     note : data.note,
    //     creationTime: date,
    // }

    // console.log(submitData);
    

    
    // setSuccess( prev => !prev);
    // axios({
    //     method: "post",
    //     url: "https://api.bestpracticify.co/api/DataAnalysisRequest",
    //     data: submitData,
    //   })
    //     .then(function (response) {
    //       if (response.data.status) {
            
    //         // setSuccess( prev => !prev);
    //         console.log(response.date);
    //         reset(
    //           {
    //             firstName: "",
    //             lastName: "",
    //             phone: "",
    //             email: "",
    //             technology: "",
    //             dataSource: "",
    //             dashboard: "",
    //             businessName: "",
    //             note: "",
    //           },
    //           {
    //             keepErrors: false,
    //           }
    //         );
            
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });


  };

  return (
    <>
        { success && <h5 className="text-white">Your request is submitted successfully.</h5>}
        <form  onSubmit={handleSubmit(onSubmit)}>
          <div className="row form-style bg-white p-4">

            <div className="col-lg-6 p-1">
                <label htmlFor="" className="data-form-label">First Name</label>
              <input type="text" className="input-form" name="firstName" ref={register({ required: true})} />
               {errors.firstName && <span className="text-danger">This field is required</span>}
            </div>
            <div className="col-lg-6 p-1">
                <label htmlFor="" className="data-form-label">Last Name</label>
              <input type="text" className="input-form" name="lastName" ref={register} />
            </div>

            <div className="col-lg-6 p-1">
                <label htmlFor="" className="data-form-label">Phone</label>
              <input type="text" className="input-form" ref={register} name="phone"  />
            </div>

            <div className="col-lg-6 p-1">
                <label htmlFor="" className="data-form-label">Email</label>
                <input type="text"className="input-form"  ref={register({ required: true})} name="email" />
               {errors.email && <span className="text-danger">This field is required</span>}
            </div>

            <div className="col-lg-6 p-1">
                <label htmlFor="" className="data-form-label">Busniess Name</label>
                <input type="text"className="input-form"  ref={register({ required: true})} name="businessName"  />
               {errors.businessName && <span className="text-danger">This field is required</span>}
            </div>

            <div className="col-lg-6 p-1">
                <label htmlFor="" className="data-form-label">Busniess Industry</label>
                <input type="text"className="input-form"  ref={register} name="businessIndustry"  />
            </div>
            

            {/* <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">How many dashboard do you want?</label>
                <input type="text"className="input-form"  ref={register({ required: true})} name="dashboard"  />
               {errors.dashboard && <span className="text-danger">This field is required</span>}
            </div> */}
            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">Solution Specificity</label>
                <Controller
                        name="solutions"
                        control={control}
                        as={<Select />}
                        options={technologyOptions}
                        isMulti
                        rules={{ required: true }}
                    />
               {errors.solutions && <span className="text-danger">This field is required</span>}
            </div>
            
            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">What goals do you wantt to achieve with this solution?</label>
                <input type="text"className="input-form"  ref={register} name="goalsSolutions"  />
            </div>

            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">Technology Preference</label>
                <Controller
                        name="technology"
                        control={control}
                        as={<Select />}
                        options={technologyOptions}
                        isMulti
                        rules={{ required: true }}
                    />
               {errors.technology && <span className="text-danger">This field is required</span>}
            </div>
            
            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">What goals do you wantt to achieve with this technoology?</label>
                <input type="text"className="input-form"  ref={register} name="goalsTechnology"  />
            </div>

            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">Data Source</label>
                <Controller
                        name="dataSource"
                        control={control}
                        as={<Select />}
                        options={technologyOptions}
                        isMulti
                        rules={{ required: true }}
                    />
               {errors.dataSource && <span className="text-danger">This field is required</span>}
            </div>

            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">Tell us more about the need for this solution/services</label>
                <input type="text" className="input-form"  ref={register()} name="tellUs" />
            </div>

            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">Please describe what you'd like done in under 300 words</label>
                <input type="text" className="input-form"  ref={register()} name="notes" />
            </div>

            <div className="col-lg-12 p-1">
                <button className="btn btn-primary" type="submit">Submit</button>
            </div>

          </div>
        </form>
    </>
  );
};

export default DataAnalysisSmallFormAllField;
