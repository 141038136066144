export const BASE_API_URL = "https://api.bestpracticify.co/api/";
export const BASE_URL = "https://api.bestpracticify.co/";
// export const BASE_API_URL = "https://bpapi.bestpracticify.io/api/";
// export const BASE_URL = "https://bpapi.bestpracticify.io/";
// export const BASE_API_URL = "https://localhost:5001/api/";
// export const BASE_URL = "https://localhost:5001/";
// export const BASE_API_URL = "https://localhost:28758/api/";
// export const BASE_URL = "https://localhost:28758/";
// export const BASE_API_URL = "http://199.192.16.72:5001/api/";
// export const BASE_URL = "http://199.192.16.72:5001/";
// export const BASE_API_URL = "http://bpapi.phonepie.net/api/"
// export const BASE_URL = "http://bpapi.phonepie.net/"
// export const BASE_API_URL = "https://alamin365.com:5000/api/";
// export const BASE_URL = "https://alamin365.com:5000/";
