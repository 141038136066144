import axios from "axios";
import React from "react";
import {BASE_API_URL, BASE_URL} from "../../Constants";
import "./ClassDetails.css";

const ClassDetails = (props) => {
	let [blogSubCategoryItem, setBlogSubCategoryItem] = React.useState([]);
	let [insightsBlogDetails, setInsightsBlogDetails] = React.useState([]);

	React.useEffect(() => {
		axios
			.get(
				BASE_API_URL + `BlogPost/detail/${props.match.params.blog_details_id}`,
				{}
			)
			.then(
				(res) => {
					////console.log(res);
					if (res.data.data.length != 0) {
						setBlogSubCategoryItem(res.data.data);
					}
				},
				(error) => {
					//console.log(error);
				}
			);
	}, [props.match.params.blog_details_id]);

	//console.log(blogSubCategoryItem);
	//console.log(insightsBlogDetails);

	return (
		<>
			<div className="container">
				<div className="class_details">
					<div className="row">
						<div className="col-md-12">
							<div className="blog-title pb-3">
								<h2 className="text-center">{blogSubCategoryItem.title}</h2>
							</div>
							<div className="blog-img pb-3">
								<img
									className="mx-auto d-block w-75"
									src={BASE_URL + blogSubCategoryItem.pictureUrl}
									alt=""
								/>
							</div>
							<div className="blog-description">
								{/* <p>{blogSubCategoryItem.content}</p> */}
								<p
									dangerouslySetInnerHTML={{
										__html: blogSubCategoryItem.content,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ClassDetails;
