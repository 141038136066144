import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import {context} from "../../../App";
import "./UserForm.css";
import UserFormDetailsCardFour from "./UserFormDetailsCardFour";
import UserFormDetailsCardOne from "./UserFormDetailsCardOne";
// import UserFormDetailsCardFive from "./UserFormDetailsCardFive";
import UserFormDetailsCardSix from "./UserFormDetailsCardSix";
import UserFormDetailsCardThree from "./UserFormDetailsCardThree";
import UserFormDetailsCardTwo from "./UserFormDetailsCardTwo";

const Form = (props) => {
	const {ID, setid, getFormData, setFormData} = React.useContext(context);
	// ("ID: ", ID, "getFormData: ", getFormData);
	let [step, setStep] = React.useState(1);
	let [id, setId] = React.useState(1);
	let [progressStep, setProgressStep] = React.useState(0);
	let [getInputVal, setInputVal] = React.useState({
		firstName: "",
		lastName: "",
		companyName: "",
		email: "",
		phone: "",

		businessIndustry: "",
		businessStage: "",
		solutionCategory: "",
		solutionSpecificity: "",
		textArea1: "",
		textArea2: "",
		textArea3: "",
		textArea4: "",
		textArea5: "",
		file: "",
	});

	let [getInputValAndErr, setInputValAndErr] = React.useState({
		firstName: "",
		firstNameError: false,
		lastName: "",
		lastNameError: false,
		companyName: "",
		companyNameError: false,
		email: "",
		emailError: false,
		phone: "",
		phoneError: false,
		BusinessIndustry: "",
		businessError: false,
		companyTypeId: "",
		companySizeId: "",
		companyTypesErr: false,
		companySizesErr: false,
	});

	const nextStep = () => {
		setStep(step + 1);
		setProgressStep(progressStep + 25);
	};

	const prevStep = () => {
		setStep(step - 1);
		setProgressStep(progressStep - 25);
	};

	const updateStep = (step) => {
		setStep(step);
	};

	const handleChange = (input) => (e) => {
		// //console.log({ [input]: e.target.value });

		setInputVal({...getInputVal, [input]: e.target.value});
	};

	const turnToFirstCard = () => {
		setInputVal({});
		setInputValAndErr({
			firstName: "",
			firstNameError: false,
			lastName: "",
			lastNameError: false,
			companyName: "",
			companyNameError: false,
			email: "",
			emailError: false,
			phone: "",
			phoneError: false,
			BusinessIndustry: "",
			businessError: false,
			companyTypeId: "",
			companySizeId: "",
			companyTypesErr: false,
			companySizesErr: false,
		});
		setFormData({});
		setid(0);
		setStep(1);
		setProgressStep(0);
		localStorage.removeItem("cta_category_id");
	};

	// const getVal = (id) => {
	//   setId(id);
	// }

	React.useEffect(() => {}, [step]);

	// //console.log(step);
	// //console.log(getInputVal);
	// //console.log(props);

	if (props.menuId) {
		return (
			<div className="progress-btn w-100 ">
				<div className="modal-header mb-3">
					<div className="container w-100">
						<div className="row modal-header-row ">
							<div className="d-flex justify-content-between w-100">
								<h2
									className="modal-title text-center"
									id="exampleModalLongTitle"
								>
									{props.homePage
										? "Request for Proposal rr"
										: "Schedule a Consult "}
								</h2>
								<button
									type="button"
									className="close"
									data-toggle="modal"
									data-target={props.formId}
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true" onClick={turnToFirstCard}>
										&times;
									</span>
								</button>
							</div>
						</div>

						<ProgressBar
							variant="success"
							now={progressStep}
							max={100}
							min={0}
						/>
					</div>
				</div>
				{step == 1 && (
					<UserFormDetailsCardOne
						menuId={props.menuId}
						step={step}
						getInputVal={getInputVal}
						nextStep={nextStep}
						prevStep={prevStep}
						handleChange={handleChange}
						// getInputValAndErr={getInputValAndErr}
					/>
				)}

				{step == 2 && (
					<UserFormDetailsCardTwo
						menuId={props.menuId}
						step={step}
						getInputVal={getInputVal}
						nextStep={nextStep}
						prevStep={prevStep}
						handleChange={handleChange}
					/>
				)}

				{step == 3 && (
					<UserFormDetailsCardThree
						menuId={props.menuId}
						step={step}
						getInputVal={getInputVal}
						nextStep={nextStep}
						prevStep={prevStep}
						handleChange={handleChange}
					/>
				)}

				{step == 4 && (
					<UserFormDetailsCardFour
						menuId={props.menuId}
						step={step}
						getInputVal={getInputVal}
						nextStep={nextStep}
						prevStep={prevStep}
						handleChange={handleChange}
					/>
				)}

				{/* {step == 5 && (
          <UserFormDetailsCardFive
            menuId={props.menuId}
            step={step}
            getInputVal={getInputVal}
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
          />
        )} */}

				{step == 5 && (
					<UserFormDetailsCardSix
						menuId={props.menuId}
						step={step}
						getInputVal={getInputVal}
						nextStep={nextStep}
						prevStep={prevStep}
						updateStep={updateStep}
						handleChange={handleChange}
						turnToFirstCard={() => {
							setTimeout(turnToFirstCard, 1000);
						}}
					/>
				)}
			</div>
		);
	} else {
		return (
			<div className="progress-btn w-100">
				<div className="modal-header mb-3">
					<div className="container w-100">
						<div className="row modal-header-row ">
							<div className="d-flex justify-content-between w-100">
								<h2
									className="modal-title text-center"
									id="exampleModalLongTitle"
								>
									{props.homePage
										? "Request for Proposal"
										: "Schedule a Consult"}
								</h2>
								<button
									type="button"
									className="close"
									data-toggle="modal"
									data-target={props.formId}
									data-dismiss="modal"
									aria-label="Close"
									onClick={turnToFirstCard}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>

						<ProgressBar
							variant="success"
							now={progressStep}
							max={100}
							min={0}
						/>
					</div>
				</div>
				{step === 1 && (
					<UserFormDetailsCardOne
						step={step}
						getInputVal={getInputVal}
						nextStep={nextStep}
						prevStep={prevStep}
						handleChange={handleChange}
						getInputValAndErr={getInputValAndErr}
						setInputValAndErr={setInputValAndErr}
					/>
				)}

				{step === 2 && (
					<UserFormDetailsCardTwo
						step={step}
						getInputVal={getInputVal}
						nextStep={nextStep}
						prevStep={prevStep}
						handleChange={handleChange}
					/>
				)}

				{step === 3 && (
					<UserFormDetailsCardThree
						step={step}
						getInputVal={getInputVal}
						nextStep={nextStep}
						prevStep={prevStep}
						handleChange={handleChange}
					/>
				)}

				{step === 4 && (
					<UserFormDetailsCardFour
						step={step}
						getInputVal={getInputVal}
						nextStep={nextStep}
						prevStep={prevStep}
						handleChange={handleChange}
					/>
				)}

				{/* {step == 5 && (
          <UserFormDetailsCardFive
            step={step}
            getInputVal={getInputVal}
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
          />
        )} */}

				{step === 5 && (
					<UserFormDetailsCardSix
						step={step}
						getInputVal={getInputVal}
						nextStep={nextStep}
						prevStep={prevStep}
						updateStep={updateStep}
						handleChange={handleChange}
						turnToFirstCard={turnToFirstCard}
					/>
				)}
			</div>
		);
	}
};

export default Form;
