
import React from "react";
import "./feature.css";

// import {
//   HiDotsCircleHorizontal,
//   HiOutlineDotsCircleHorizontal,
// } from "react-icons/hi";
import { FaRegCheckCircle } from "react-icons/fa";

const Feature = ({ className, data }) => {
  return (
    <>
      <div className="styles.featureContainer">
        <div className={`container-md featureWrapper ${className}`}>
          <div className={"featureImage"}>
            <img src={data.imgUrl} alt="" />
          </div>
          <div className={"featureContent"}>
            <h2 className={"featureContentTitle"}>{data.title}</h2>
            <h6 className={"featureContentSubTitle"}>{data.subTitle}</h6>
            {/* <p className={"featureContentDesc"}>{data.desc}</p> */}
            <div className={"featureContentList"}>
              {data.list.map((item, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-start align-items-center py-2"
                >
                  <div className="icon">
                    <FaRegCheckCircle />
                  </div>
                  <h6 className="m-0 ps-3">{item}</h6>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feature;
