import React, { useEffect, useState } from 'react';
import './JobApplyForm.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FaArrowLeft, FaFileUpload } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import { toast } from 'react-toastify';

const JobApplyForm = () => {
    const { id } = useParams();
    const [jobData, setJobData] = useState(null);
    const history = useHistory();
    const [submissionConfirmation, setSubmissionConfirmation] = useState('');


    useEffect(() => {
        const fetchJobData = async () => {
            try {
                const response = await fetch(`https://api.bestpracticify.co/api/BpJobInformation/detail/${id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setJobData(data.data);
            } catch (error) {
                console.error('Error fetching job data:', error);
            }
        };

        fetchJobData();
    }, [id]);

    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [resume, setResume] = useState(null);
    const [coverLetter, setCoverLetter] = useState('');
    const [address, setAddress] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        // formData.append('Id', jobData.id);
        formData.append('JobInformationId', id);
        formData.append('Name', fullName);
        formData.append('Phone', phone);
        formData.append('Email', email);
        formData.append('FileUrl', resume);
        formData.append('CoverLetter', coverLetter);
        formData.append('Address', address);
        console.log('Submitted values:', {
            Id: jobData.id,
            JobInformationId: id,
            Name: fullName,
            Phone: phone,
            Email: email,
            FileUrl: resume,
            coverLetter: coverLetter,
            Address: address,
        });
        try {
            const response = await fetch('https://api.bestpracticify.co/api/BpJobSeekerInformation/Create', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setSubmissionConfirmation('Application submitted successfully');
            // toast.success('Application submitted successfully', { autoClose: 20000 });
            // toast('Application submitted successfully')
            console.log('Application submitted successfully');
            setFullName('');
            setPhone('');
            setEmail('');
            setResume(null);
            setCoverLetter('');
            setAddress('');

        } catch (error) {
            console.error('Error submitting application:', error);
        }
    };

    if (!jobData) {
        return <p>Loading...</p>;
    }

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <div className="job-apply-form container">
            <div className='do-flex'>
                <div>

                    <button className="for-font-size text-decoration-none" onClick={handleGoBack} style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <FaArrowLeft /> Go Back
                    </button>


                </div>
                <div>
                    <p style={{fontSize:'15px'}} className='category mt-3 for-pp'> Department: <span style={{fontSize:'15px'}}>{jobData?.categoryName}</span></p>

                </div>

            </div>
            <div>
                {/* <button className="for-font-size text-decoration-none" onClick={handleGoBack} style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <FaArrowLeft /> Go Back
                </button> */}
            </div>
            <div className="container">
                <h1 className="title-center ">{jobData.jobTitle}</h1>
                {/* <h1 className="category-name title-center">Department: {jobData.categoryName}</h1> */}
                <form onSubmit={handleSubmit}>
                    <div className="personal-info-container">
                        <div className="div-1">
                            <h1 className="personal-info-title">Personal Information</h1>

                            <div className="form-group">
                                <label htmlFor="fullName">Full Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fullName"
                                    placeholder="Your Name"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone*</label>
                                <input
                                    type="phone"
                                    className="form-control"
                                    id="phone"
                                    placeholder="Your Phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email Address*</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Your Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="address">LinkedIn URL</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    placeholder="LinkedIn URL"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="resume">Resume* (Max: 10MB)</label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="resume"
                                        accept=".pdf,.doc,.docx"
                                        onChange={(e) => setResume(e.target.files[0])}
                                        required
                                    />
                                    <label className="custom-file-label" htmlFor="resume">
                                        {resume ? resume.name : 'Choose file'}
                                    </label>
                                </div>
                                {resume && resume.size > 10 * 1024 * 1024 && (
                                    <small className="text-danger">File size exceeds 10MB limit.</small>
                                )}
                            </div>

                            {/* <div className="form-group apply-button-2">
                                <button type="submit" className="btn btn-primary btn-send-application">
                                    <FiSend className="send-icon" /> Send Your Application
                                </button>
                            </div> */}

                        </div>
                        <div className="div-2">


                            <div className="form-group">
                                <label htmlFor="coverLetter">Cover Letter(Max: 2000 Word)</label>
                                <textarea
                                    className="form-control"
                                    id="coverLetter"
                                    rows="13"
                                    placeholder="Write your cover letter here"
                                    value={coverLetter}
                                    onChange={(e) => setCoverLetter(e.target.value)}
                                ></textarea>
                            </div>
                            {submissionConfirmation && <p>{submissionConfirmation}</p>}

                            <div className="form-group apply-button-2">
                                <button type="submit" className="btn btn-primary btn-send-application">
                                    <FiSend className="send-icon" /> Send Your Application
                                </button>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default JobApplyForm;
