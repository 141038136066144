import React, { useEffect, useState } from 'react';
import "./JobPostDetails.css"
import { FaArrowLeft, FaMapMarkerAlt } from 'react-icons/fa';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import WorkIcon from '@material-ui/icons/Work';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import MoneyIcon from '@material-ui/icons/Money';
import { FaDollarSign } from 'react-icons/fa'; // Replace with the desired icon from Font Awesome


const JobPostDetails = () => {
    const history = useHistory();
    // Function to sanitize HTML and remove tags
    const sanitizeHTML = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || '';
    };


    const { id } = useParams();
    console.log('Route Param ID:', id);
    const [jobData, setJobData] = useState(null);

    useEffect(() => {
        // Define a function to fetch data based on the id
        const fetchJobData = async () => {
            try {
                const response = await fetch(`https://api.bestpracticify.co/api/BpJobInformation/detail/${id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setJobData(data.data);
            } catch (error) {
                console.error('Error fetching job data:', error);
            }
        };

        // Call the fetch function when the component mounts
        fetchJobData();
    }, [id]);

    // Split the jobData.jobDescription into lines
    const jobDescriptionLines = jobData ? jobData.jobDescription.split('<br />') : [];

    // Function to split text into paragraphs of at most 3 lines
    const splitTextIntoParagraphs = (text) => {
        const lines = text.split('<br />');
        const paragraphs = [];
        let paragraph = '';

        for (let i = 0; i < lines.length; i++) {
            paragraph += lines[i] + '<br />';
            if ((i + 1) % 3 === 0 || i === lines.length - 1) {
                paragraphs.push(paragraph);
                paragraph = '';
            }
        }

        return paragraphs;
    };
    const aboutCompanyParagraphs = jobData ? splitTextIntoParagraphs(jobData.aboutComapny) : [];
    const handleGoBack = () => {
        history.goBack();
    };
    return (
        <div className='job-description container'>
            <div>
                <div className='do-flex'>
                    <div>
                    <button className="for-font-size text-decoration-none" onClick={handleGoBack} style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <FaArrowLeft /> Go Back
                </button>

                        {/* <Link target=""
                            className=" for-font-size text-decoration-none " to={`/careers`}><FaArrowLeft /> Go Back
                        </Link> */}


                    </div>
                    <div>
                        <p style={{fontSize:'15px'}} className='category mt-3 for-pp'> Department: <span style={{fontSize:'15px'}}>{jobData?.categoryName}</span></p>
                        <button type='button' className="btn btn-primary " >
                                    <Link target="" style={{fontSize:"18px"}}
                                        className="text-white text-decoration-none apply-now-btn-dsgn" to={`/JobApplyForm/${id}`}>Apply now</Link>

                        </button>
                    </div>

                </div>
                {jobData ? (
                    <div>
                        <h2 className='clr-black'>{jobData.jobTitle}</h2>
                        {/* <div className="apply-button for-top-gap ">
                            <div className='flex'>
                                 <button type='button' className="btn btn-primary title-center" >
                                    <Link target=""
                                        className="text-white text-decoration-none apply-now-btn-dsgn" to={`/JobApplyForm/${id}`}>Apply For This Position</Link>

                                </button> 
                            </div>
                        </div> */}
                        {/* <div className=''>

                            <div className='job-info-item'>
                                <h6 className='job-info-item'>Job Type: <span>{jobData.jobType}</span></h6>
                            </div>
                        </div> */}
                        <div className='job-details mt-3'>
                            <div className='job-info'>
                      

                            {jobData.jobType && (
                                    <div className='job-info-item'>
                                        {/* Use Work icon */}
                                        <div className='job-black'> Job Type: <span>{jobData.jobType}</span></div>

                                    </div>
                                )}
                                {jobData.careerLevel && (
                                    <div className='job-info-item'>
                                        {/* Use Work icon */}
                                        <div className='job-black'>Career Level: <span>{jobData.careerLevel}</span></div>

                                    </div>
                                )}
                                {jobData.salaryRange && (
                                    <div className=''>
                                       <div className='job-black'>  Salary Range: <span>{jobData.salaryRange}</span></div>

                                    </div>
                                )}

                              {jobData.numberOfVacancy && (
                                    <div className='job-info-item'>
                                        {/* Use Work icon */}
                                        <div className='job-black'> Available Position(s): <span>{jobData.numberOfVacancy}</span></div>

                                    </div>
                             )}
                                {jobData.location && (
                                    <div className='job-info-item '>
                                        {/* Use LocationOn icon */}
                                        <div className='job-black'> Location: <span>{jobData.location}</span></div>

                                    </div>
                                )}

                            


                            </div>
                            
                        </div>
                        {jobData.jobDescription && (
                            <div className='for-p-others-job'>
                                {/* <h3 className=''>Job Context/Responsibilities:</h3> */}
                                {splitTextIntoParagraphs(jobData.jobDescription).map((paragraph, index) => (
                                    <p key={index} className='for-top-gap paragraph-title p-1' dangerouslySetInnerHTML={{ __html: paragraph }} />
                                ))}
                            </div>
                        )}

                        {jobData.aboutComapny && (
                            <div className='for-p-others-job'>
                                {/* <h3>Job Other Description:</h3> */}
                                <p className='for-top-gap paragraph-title p-1' dangerouslySetInnerHTML={{ __html: jobData.aboutComapny }} />
                            </div>
                        )}


                        {jobData.jobOtherDescription && (
                            <div className='for-p-others-job'>
                                {/* <h3>Job Other Description:</h3> */}
                                <p className='for-top-gap paragraph-title p-1' dangerouslySetInnerHTML={{ __html: jobData.jobOtherDescription }} />
                            </div>
                        )}

                     
                        


                        {/* <h1>Job Title: {jobData.jobTitle}</h1>
          <p>Category: {jobData.categoryName}</p>
          <p>Job Type: {jobData.jobType}</p>
          <p>Job Description: {jobData.jobDescription}</p> */}
                        {/* <div className="container job-description-container">
                            <div className="part-1">
                                <div className="go-back">
                                    <button type='button' className="btn btn-primary " >
                                        <Link target=""
                                            className="text-white  text-decoration-none" to="/Career"> <FaArrowLeft className="back-icon" /> Go Back</Link>

                                    </button>
                                </div>
                                <div>

                                </div>
                                <div>

                                    <h1 className="title-button text-center">Job Title: {jobData.jobTitle}</h1>

                                    <h1 className='for-top-gap first-title'>Inside Sales Representatives</h1>
                                    <FaMapMarkerAlt className="location-icon  " />
                                    <span>Dallas, USA</span>
                                    <h5 className='for-top-gap second-title'>Career Level : <span >Mid-Senior</span></h5>
                                    <h5 className='for-top-gap second-title'>Job Type : <span>Full-time</span></h5>
                                    <p className='for-top-gap paragraph-title'>
                                        We are seeking an Inside Sales Representative who will be responsible for developing relationships with prospective clients that would benefit from Agency Partner Interactive’s Web and Digital Marketing services; including but not limited to web design, CMS, eCommerce, Custom Web Application, SEO, SEM, Social Media Advertising, Reputation Management, Email Marketing, etc. <br /> <br />

                                        The role is an integral part of the Agency Partner Interactive’s sales organization. Collaborate with Customer Success, Project Management and Support team to drive revenue.  You will manage the entire sales process from targeting and approaching prospective clients, completing uncovering (discovery) meetings, selling Agency Partner Interactive services, and managing client relationships through the handoff to the Account Management/Project team. <br /> <br />

                                        It is crucial to this role to ensure that we set realistic expectations with future clients by educating and selling them on the ideal package and services to set them up for success. You will also work with the Agency Partner Interactive reseller partner’s, marketing and project management teams to ensure our clients have an experience with our company that meets and exceeds the Agency Partner Interactive’s standard. Assist in building and executing a plan to help grow the business, both in terms of reactivating existing customers and growing existing customer relationships.
                                    </p>
                                    <h1 className='for-top-gap first-title'>
                                        What’s In It For You?
                                    </h1>
                                    <p className='for-top-gap paragraph-title'>
                                        Agency Partner Interactive will provide detailed and ongoing training programs to help drive your own personal success in this role as well as prepare you for multiple career path opportunities across Agency Partner Interactive.
                                    </p>
                                    <h1 className='for-top-gap first-title'>
                                        What You Will Be Doing:
                                    </h1>
                                    <div className="container-icon for-top-gap">
                                        <p className=''>
                                            <span className='icon-color'>●</span>
                                            Create and execute quarterly business plans that result in hitting monthly and quarterly sales goals
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Using internal CRM, complete and report 50-70 daily outbound activities
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Create and track sales opportunities
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Using internal playbook to manage the entire sales lifecycle
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Create meaningful business relationships with prospects, clients, and your colleagues
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Understand sales cycles in conjunction with business processes internally and externally
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Use the various training platforms to continuously grow your personal knowledge of our product, sales, and process
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Maintain comprehensive knowledge of the assigned vertical industry
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Meet assigned sales Quota
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Conduct assigned number of product presentations to the client per week
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Conduct assigned number of sales proposals delivered per week
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Address any customer satisfaction issues / requests in a timely manner
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Meet and exceed the sales activity metrics designed to make you productive and successful.
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Assist with customer presentations and demos via online tools (Microsoft Teams)
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Perform other duties and projects, as assigned to support the growth or our business
                                        </p>
                                        <p>
                                            <span className='icon-color'>●</span>
                                            Execute the role with the utmost professionalism and in a way that aligns to Agency Partner Interactive’s core values
                                        </p>
                                    </div>
                                    <h1 className='for-top-gap first-title'>
                                        Skills You Bring to the Table:
                                    </h1>
                                </div>
                                <div className="container-icon for-top-gap">
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Bachelor’s degree
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Coachable and flexible
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Proven record of exceeding sales goals and quotas
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Experience selling solutions over the phone and in-person B2B sales experience preferred
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Self-driven and able to manage a diverse, high-volume workload; Ability to quickly build productive relationships in a fast-paced, high-performance environment
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Be computer savvy
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Excellent written, verbal and presentation skills
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Well organized with effective time and activity management skills
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Ability to apply entrepreneurial strengths in a driven, forward-thinking manner
                                    </p>
                                </div>
                                <h1 className='for-top-gap first-title'>
                                    Preferred Qualifications:
                                </h1>
                                <div className="container-icon for-top-gap">
                                    <p>
                                        <span className='icon-color'>●</span>
                                        3-5 years inside sales experience, preferably in digital media, internet marketing, and/or technology sales
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Experience in an Agency a plus
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Knowledge working with platforms:-
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        CRM: Microsoft Dynamics 365 or Salesforce
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Sales Engagement: Outreach.io or similar
                                    </p>
                                    <p>
                                        <span className='icon-color'>●</span>
                                        Conversational Intelligence: Gong.io or Chorus.ai
                                    </p>
                                </div>
                                <h1 className='for-top-gap first-title'>
                                    To Apply:
                                </h1>
                                <p className='paragraph-title'>
                                    Step 1: check out our LinkedIn page, go to AgencyPartner.com, and see if things look good to you.
                                </p>
                                <p className='paragraph-title'>
                                    Step 2: Express your interest via Indeed
                                </p>
                                <p className='paragraph-title'>
                                    Step 3: If things add up, we’ll do a quick screen and ask you to complete our TestGorilla personality assessment.
                                </p>
                                <p className='paragraph-title'>
                                    Step 4: Next-level stuff.
                                </p>
                                <h1 className='for-top-gap first-title'>
                                    Pay:
                                </h1>
                                <p className='paragraph-title '>
                                    $75,000.00 – $95,000 per year (OTE) / per year
                                </p>
                                <h1 className='for-top-gap first-title'>
                                    Supplemental Pay:
                                </h1>
                                <div className="container-icon for-top-gap">
                                    <p className='paragraph-title'>
                                        <span className='icon-color'>●</span>
                                        Bonus pay
                                    </p>
                                    <p className='paragraph-title'>
                                        <span className='icon-color'>●</span>
                                        Commission pay
                                    </p>
                                </div>

                                <div className="apply-button for-top-gap ">
                                    <button type='button' className="btn btn-primary " >
                                        <Link target=""
                                            className="text-white text-decoration-none apply-now-btn-dsgn" to={`/JobApplyForm/${id}`}>Apply Now</Link>

                                    </button>
                                </div>

                            </div>


                            <div className="part-2-job-post">
                                <h1 className='first-title'>Interested?
                                    You know what to do.
                                </h1>
                                <p className='paragraph-title'>No need to be formal. Just send us
                                    your links and we’ll be in touch.
                                </p>

                                <div className="apply-button text-center">
                                    <button type='button' className="btn btn-primary " >
                                        <Link target=""
                                            className="text-white text-decoration-none " to={`/JobApplyForm/${id}`}>Apply Now</Link>

                                    </button>
                                </div>
                            </div>
                        </div> */}
                        <div className="apply-button for-top-gap pb-4">
                                <div className='flex'>
                                    <button type='button' className="btn btn-primary title-center" >
                                        <Link target=""
                                            className="text-white text-decoration-none apply-now-btn-dsgn" to={`/JobApplyForm/${id}`}>Apply now</Link>

                                    </button>
                                </div>
                            </div>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}

            </div>

        </div>
    );
};

export default JobPostDetails;