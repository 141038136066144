import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import {context} from "../../../../../App";
import "./UserForm.css";
import UserFormDetailsCardFive from "./UserFormDetailsCardFive";
import UserFormDetailsCardFour from "./UserFormDetailsCardFour";
import UserFormDetailsCardOne from "./UserFormDetailsCardOne";
import UserFormDetailsCardThree from "./UserFormDetailsCardThree";
import UserFormDetailsCardTwo from "./UserFormDetailsCardTwo";

const Form = (props) => {
	// //console.log(props.menuId);
	// //console.log(props.subMenuId);

	const {ID, setid, getFormData, setFormData} = React.useContext(context);
	//console.log("ID: ", ID, "getFormData: ", getFormData);

	let [step, setStep] = React.useState(1);
	let [progressStep, setProgressStep] = React.useState(0);

	let [getInputValAndErr, setInputValAndErr] = React.useState({
		firstName: getFormData.firstName ? getFormData.firstName : "",
		firstNameError: getFormData.firstNameError
			? getFormData.firstNameError
			: false,
		lastName: getFormData.lastName ? getFormData.lastName : "",
		lastNameError: getFormData.lastNameError
			? getFormData.lastNameError
			: false,
		companyName: getFormData.companyName ? getFormData.companyName : "",
		companyNameError: getFormData.companyNameError
			? getFormData.companyNameError
			: false,
		email: getFormData.email ? getFormData.email : "",
		emailError: getFormData.emailError ? getFormData.emailError : false,
		phone: getFormData.phone ? getFormData.phone : "",
		phoneError: getFormData.phoneError ? getFormData.phoneError : false,
		BusinessIndustry: getFormData.BusinessIndustry
			? getFormData.BusinessIndustry
			: "",
		businessError: getFormData.businessError
			? getFormData.businessError
			: false,
		subMenuId: props.subMenuId ? Number(props.subMenuId) : "",
		companyTypeId: getFormData.companyTypeId ? getFormData.companyTypeId : "",

		companySizeId: getFormData.companySizeId ? getFormData.companySizeId : "",
		companySizesErr: false,
		companyTypesErr: false,
	});

	const nextStep = () => {
		setStep(step + 1);
		setProgressStep(progressStep + 25);
	};

	const prevStep = () => {
		setStep(step - 1);
		setProgressStep(progressStep - 25);
	};

	const turnToFirstCard = () => {
		setInputValAndErr({
			firstName: getFormData.firstName ? getFormData.firstName : "",
			firstNameError: getFormData.firstNameError
				? getFormData.firstNameError
				: false,
			lastName: getFormData.lastName ? getFormData.lastName : "",
			lastNameError: getFormData.lastNameError
				? getFormData.lastNameError
				: false,
			companyName: getFormData.companyName ? getFormData.companyName : "",
			companyNameError: getFormData.companyNameError
				? getFormData.companyNameError
				: false,
			email: getFormData.email ? getFormData.email : "",
			emailError: getFormData.emailError ? getFormData.emailError : false,
			phone: getFormData.phone ? getFormData.phone : "",
			phoneError: getFormData.phoneError ? getFormData.phoneError : false,
			BusinessIndustry: getFormData.BusinessIndustry
				? getFormData.BusinessIndustry
				: "",
			businessError: getFormData.businessError
				? getFormData.businessError
				: false,
			subMenuId: props.subMenuId ? Number(props.subMenuId) : "",
			companyTypeId: getFormData.companyTypeId ? getFormData.companyTypeId : "",

			companySizeId: getFormData.companySizeId ? getFormData.companySizeId : "",
			companySizesErr: false,
			companyTypesErr: false,
		});
		setStep(1);
		setProgressStep(0);
		setFormData({});
		setid(0);
		localStorage.removeItem("cta_function_id");
	};

	return (
		<>
			<div className="progress-btn w-100">
				<div className="modal-header mb-3">
					<div className="container w-100">
						<div className="row modal-header-row ">
							<div className="d-flex justify-content-between w-100">
								<h2
									className="modal-title text-center"
									id="exampleModalLongTitle"
								>
									{props.title ? props.title : "Request For Proposal"}
								</h2>
								<button
									type="button"
									className="close"
									data-toggle="modal"
									data-target=".bd-example-modal-lg"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true" onClick={turnToFirstCard}>
										&times;
									</span>
								</button>
							</div>
						</div>
						<ProgressBar
							variant="success"
							now={progressStep}
							max={100}
							min={0}
						/>
					</div>
				</div>

				{step === 1 && (
					<UserFormDetailsCardOne
						menuId={props.menuId}
						subMenuId={props.subMenuId}
						step={step}
						nextStep={nextStep}
						prevStep={prevStep}
						getInputValAndErr={getInputValAndErr}
						setInputValAndErr={setInputValAndErr}
					/>
				)}

				{step === 2 && (
					<UserFormDetailsCardTwo
						menuId={props.menuId}
						subMenuId={props.subMenuId}
						step={step}
						nextStep={nextStep}
						prevStep={prevStep}
					/>
				)}

				{step === 3 && (
					<UserFormDetailsCardThree
						menuId={props.menuId}
						subMenuId={props.subMenuId}
						step={step}
						nextStep={nextStep}
						prevStep={prevStep}
					/>
				)}

				{step === 4 && (
					<UserFormDetailsCardFour
						menuId={props.menuId}
						subMenuId={props.subMenuId}
						step={step}
						nextStep={nextStep}
						prevStep={prevStep}
					/>
				)}

				{step === 5 && (
					<UserFormDetailsCardFive
						menuId={props.menuId}
						step={step}
						nextStep={nextStep}
						prevStep={prevStep}
						turnToFirstCard={turnToFirstCard}
						turnToFirstCard={() => {
							setTimeout(turnToFirstCard, 1000);
						}}
					/>
				)}
			</div>
		</>
	);
};

export default Form;
