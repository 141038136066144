import React, {useState} from "react";
import NewConsulntRegistration from "../Authentication/NewConsulntRegistration/NewConsulntRegistration";
import "./CardConsult.css";

const YearlyConsult = ({consultYearly, yearly}) => {
	const [signup, setSignup] = useState(false);
	const [createAccount, setCreateAccount] = useState(false);

	const items = yearly
		? yearly.filter((item) => item.companyTypeId === consultYearly)
		: "";

	let sortedItems = [...items];
	sortedItems.sort((a, b) => {
		if (a.displayOrder < b.displayOrder) {
			return -1;
		}
		if (a.displayOrder > b.displayOrder) {
			return 1;
		}
		return 0;
	});

	function currencyFormat(num) {
		return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	const openRegiserForm = () => {
		setSignup(true);
	};

	const closeAllCondition = () => {
		setSignup(false);
		setCreateAccount(false);
	};

	var texts = {};

	switch (consultYearly) {
		case 1:
			// Entrepreneur == 1
			texts.firstLine =
				"Receive on-demand, white-glove consulting services for a monthly fee ";
			texts.secondLine =
				"Receive a solutions document tailored to your business ";
			texts.price = 3;
			break;
		case 2:
			// Startup  == 2
			texts.firstLine =
				"Receive on-demand, white-glove consulting services for a monthly fee ";
			texts.secondLine = "Receive a solutions document to grow your startup ";
			texts.price = 3;
			break;
		case 3:
			// Ecommerce   == 3
			texts.firstLine =
				"Receive on-demand, white-glove consulting services for a monthly fee ";
			texts.secondLine =
				"Receive a solutions document tailored to grow your online store ";
			texts.price = 5;
			break;
		case 4:
			// Enterprise   == 4
			texts.firstLine =
				"Receive on-demand, white-glove consulting services for a monthly fee ";
			texts.secondLine =
				"Receive a solutions document with tailored enterprise tech solutions ";
			texts.price = 8;
			break;
		default:
			texts.firstLine =
				"Receive on-demand, white-glove consulting services for a monthly fee ";
			texts.secondLine = "";
			texts.price = 0;
	}

	return (
		<div class="pt-3 yearly">
			<div
				className="modal fade "
				tabIndex="-1"
				role="document"
				aria-labelledby="myLargeModalLabel"
				aria-hidden="true"
				id="purchase_modal"
			>
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content">
						<div className="modal-header mb-3">
							<div className="container w-100">
								<div className="row modal-header-row ">
									<div className="d-flex justify-content-end w-100">
										<h2
											class="modal-title text-center"
											id="exampleModalLongTitle"
										>
											{createAccount
												? "Sign In"
												: signup
												? "Create New Account"
												: "Sign In or Sign Up"}
										</h2>
										<button
											type="button"
											className="close"
											data-toggle="modal"
											data-target="#purchase_modal"
											data-dismiss="modal"
											aria-label="Close"
											onClick={closeAllCondition}
										>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							{createAccount ? (
								<div className="text-center m-4">
									<h6 className="mb-3 text-success">
										Your account has been created successfully. Please check
										your email to complete the purchase.
									</h6>

									<a
										href="https://client.bestpracticify.co/signin"
										target="_blank"
										class="btn btn-primary my-2"
										rel="noreferrer"
									>
										Sign In
									</a>

									{/* <div className="newToBP my-2">
                      <p className="text-center text-secondary bg-white"><span>New To Best Practicify?</span></p>
                      <hr />
                    </div>
                    <a onClick={ openRegiserForm } className="btn btn-primary my-2">Create Your Best Practicify Account</a> */}
								</div>
							) : (
								<>
									{!signup ? (
										<div className="text-center m-4">
											<h6 className="mb-3">
												To Purchase any this consultation you need to login
												first. Please click login button below.
											</h6>

											<a
												href="https://client.bestpracticify.co/signin"
												target="_blank"
												class="btn btn-primary my-2"
												rel="noreferrer"
											>
												Sign In
											</a>

											<div className="newToBP my-2">
												<p className="text-center text-secondary bg-white">
													<span>New To Best Practicify?</span>
												</p>
												<hr />
											</div>
											<a
												onClick={openRegiserForm}
												className="btn btn-primary my-2"
											>
												Create Your Best Practicify Account
											</a>
										</div>
									) : (
										<NewConsulntRegistration
											setCreateAccount={setCreateAccount}
										></NewConsulntRegistration>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{sortedItems.length ? (
				<div class="container">
					{/* <div class="row text-center">
            <h3 class="heading-title text-center">Hourly Pricing</h3>
          </div> */}

					<div class="row">
						<div class="col-md-3 col-sm-6 pricing-table-col">
							<div class="pricingTable pricing-hov">
								<div class="pricingTable-header">
									<div className="title">
										<h3>{sortedItems[0]?.submenuName}</h3>
									</div>
									<div className="suntitle">
										<p>{texts.firstLine}</p>
									</div>
								</div>
								<div className="pricing-details">
									<div class="price-value">
										<div className="d-flex flex-column align-items-center">
											<p className="">Price</p>
											<p className="">Starting at</p>
											<span>
												<small class="fa fa-usd"></small>
												{/* {currencyFormat(sortedItems[0]?.monthlyRate)} */}
												{currencyFormat(texts.price)}
												<span>
													<sub>/day</sub>
												</span>
											</span>

											{/* <span
												style={{
													color: "#66666",
													fontSize: "20px",
													paddingBottom: "5px",
												}}
											>
												per month
											</span>

											<span
												style={{
													color: "#66666",
													fontSize: "20px",
													padding: "10px",
												}}
											>
												or
											</span>

											<span>
												<small class="fa fa-usd"></small>
												{currencyFormat(sortedItems[0]?.yearlyRate)}
											</span>

											<span style={{color: "#66666", fontSize: "20px"}}>
												per year
											</span> */}
										</div>
									</div>
								</div>

								<div className="notes">
									<ul className="list-group">
										<li className="list-group-item">Each call: 30 min (max)</li>
										<li className="list-group-item">
											Monthly Limit: 3 hours (max)
										</li>
									</ul>
								</div>

								<div class="pricingTable-sign-up">
									<button
										type="button"
										data-toggle="modal"
										data-target="#purchase_modal"
										class="btn btn-block btn-default"
									>
										purchase
									</button>
								</div>
							</div>
						</div>

						<div class="col-md-3 col-sm-6 pricing-table-col">
							<div class="pricingTable pricing-hov">
								<div class="pricingTable-header">
									<div className="title">
										<h3>{sortedItems[1]?.submenuName}</h3>
									</div>
									<div className="suntitle">
										<p>{texts.firstLine}</p>
									</div>
								</div>
								<div className="pricing-details">
									<div class="price-value">
										<div className="d-flex flex-column align-items-center">
											<p className="">Price</p>
											<p className="">Starting at</p>
											<span>
												<small class="fa fa-usd"></small>
												{/* {currencyFormat(sortedItems[1]?.monthlyRate)} */}
												{currencyFormat(texts.price)}
												<span>
													<sub>/day</sub>
												</span>
											</span>

											{/* <span
												style={{
													color: "#66666",
													fontSize: "20px",
													paddingBottom: "5px",
												}}
											>
												per month
											</span>

											<span
												style={{
													color: "#66666",
													fontSize: "20px",
													padding: "10px",
												}}
											>
												or
											</span>

											<span>
												<small class="fa fa-usd"></small>
												{currencyFormat(sortedItems[1]?.yearlyRate)}
											</span>

											<span style={{color: "#66666", fontSize: "20px"}}>
												per year
											</span> */}
										</div>
									</div>
								</div>

								<div className="notes">
									<ul className="list-group">
										<li className="list-group-item">Each call: 30 min (max)</li>
										<li className="list-group-item">
											Monthly Limit: 3 hours (max)
										</li>
									</ul>
								</div>

								<div class="pricingTable-sign-up">
									<button
										type="button"
										data-toggle="modal"
										data-target="#purchase_modal"
										class="btn btn-block btn-default"
									>
										purchase
									</button>
								</div>
							</div>
						</div>

						<div class="col-md-3 col-sm-6 pricing-table-col">
							<div class="pricingTable pricing-hov">
								<div class="pricingTable-header">
									<div className="title">
										<h3>{sortedItems[2]?.submenuName}</h3>
									</div>
									<div className="suntitle">
										<p>{texts.firstLine}</p>
									</div>
								</div>

								<div className="pricing-details">
									<div class="price-value">
										<div className="d-flex flex-column align-items-center">
											<p className="">Price</p>
											<p className="">Starting at</p>
											<span>
												<small class="fa fa-usd"></small>
												{/* {currencyFormat(sortedItems[2]?.monthlyRate)} */}
												{currencyFormat(texts.price)}
												<span>
													<sub>/day</sub>
												</span>
											</span>

											{/* <span
												style={{
													color: "#66666",
													fontSize: "20px",
													paddingBottom: "5px",
												}}
											>
												per month
											</span>

											<span
												style={{
													color: "#66666",
													fontSize: "20px",
													padding: "10px",
												}}
											>
												or
											</span>

											<span>
												<small class="fa fa-usd"></small>
												{currencyFormat(sortedItems[2]?.yearlyRate)}
											</span>

											<span style={{color: "#66666", fontSize: "20px"}}>
												per year
											</span> */}
										</div>
									</div>
								</div>

								<div className="notes">
									<ul className="list-group">
										<li className="list-group-item">Each call: 30 min (max)</li>
										<li className="list-group-item">
											Monthly Limit: 3 hours (max)
										</li>
									</ul>
								</div>

								<div class="pricingTable-sign-up">
									<button
										type="button"
										data-toggle="modal"
										data-target="#purchase_modal"
										class="btn btn-block btn-default"
									>
										purchase
									</button>
								</div>
							</div>
						</div>

						<div class="col-md-3 col-sm-6 pricing-table-col">
							<div class="pricingTable pricing-hov">
								<div class="pricingTable-header">
									<div className="title">
										<h3>{sortedItems[3]?.submenuName}</h3>
									</div>
									<div className="suntitle">
										<p>{texts.firstLine}</p>
									</div>
								</div>

								<div className="pricing-details">
									<div class="price-value">
										<div className="d-flex flex-column align-items-center">
											<p className="">Price</p>
											<p className="">Starting at</p>
											<span>
												<small class="fa fa-usd"></small>
												{/* {currencyFormat(sortedItems[3]?.monthlyRate)} */}
												{currencyFormat(texts.price)}
												<span>
													<sub>/day</sub>
												</span>
											</span>

											{/* <span
												style={{
													color: "#66666",
													fontSize: "20px",
													paddingBottom: "5px",
												}}
											>
												per month
										details	</span>

											<span
												style={{
													color: "#66666",
													fontSize: "20px",
													padding: "10px",
												}}
											>
												or
											</span>

											<span>
												<small class="fa fa-usd"></small>
												{currencyFormat(sortedItems[3]?.yearlyRate)}
											</span>

											<span style={{color: "#66666", fontSize: "20px"}}>
												per year
											</span> */}
										</div>
									</div>
								</div>

								<div className="notes">
									<ul className="list-group">
										<li className="list-group-item">Each call: 30 min (max)</li>
										<li className="list-group-item">
											Monthly Limit: 3 hours (max)
										</li>
									</ul>
								</div>

								<div class="pricingTable-sign-up">
									<button
										type="button"
										data-toggle="modal"
										data-target="#purchase_modal"
										class="btn btn-block btn-default"
									>
										purchase
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};
export default YearlyConsult;
