import React from "react";
import "./title.css";

const Title = () => {
  return (
    <div className="title">
      <h3 className="heading">Real Time Performance Dashboards</h3>
      <h6>Any Analytics, Anywhere, Anytime</h6>
    </div>
  );
};

export default Title;
