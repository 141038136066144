import React from "react";
import {AiOutlineCheck} from "react-icons/ai";
import {Link} from "react-router-dom";
import subscribe from "../../assets/img/subscription.png";

function BlogModal({
	handleSubmit,
	onSubmit,
	mobileView,
	register,
	loading,
	confirm,
	err,
}) {
	React.useEffect(() => {}, []);

	return (
		<div id="blogModal" class="modal fade">
			<div class="modal-dialog modal-lg modal-newsletter">
				<div class="modal-content">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div class="modal-header">
							{/* <div class="icon-box mx-auto">
      <i class="fa fa-envelope-open-o"></i>
    </div> */}
							<button
								type="button"
								class="close"
								data-target="#blogModal"
								data-dismiss="modal"
								aria-hidden="true"
							>
								<span>
									<i className="fa fa-times" aria-hidden="true"></i>
								</span>
							</button>
						</div>

						<div class="modal-body text-center">
							{mobileView ? (
								<div className="d-flex">
									{" "}
									<div className="">
										<h4>Like What you are</h4>
										<h3>reading?</h3>
										<p>
											Signup for our weekly digest of the latest digital trends
											and insights delivered straight to your indox.
										</p>
										<div className="input-group" style={{marginTop: "5px"}}>
											<input
												type="text"
												class="form-control"
												placeholder="First name*"
												name="name"
												ref={register({required: true})}
												required
											/>
										</div>
										<div className="input-group" style={{marginTop: "5px"}}>
											<input
												type="email"
												class="form-control"
												placeholder="Email*"
												name="email"
												ref={register({required: true})}
												required
											/>
										</div>

										<div
											class="input-group-append d-flex justify-content-center"
											style={{position: "relative"}}
										>
											{loading && (
												<span
													class="spinner"
													style={{
														position: "absolute",
														top: "13px",
														right: "207px",
														zIndex: 1000,
													}}
												>
													<i class="fa fa-spinner fa-spin"></i>
												</span>
											)}
											{confirm ? (
												<button class="btn btn-primary">
													{" "}
													<AiOutlineCheck color="#fff" />
												</button>
											) : (
												<input
													type="submit"
													class="btn btn-primary"
													value="Subscribe"
												/>
											)}
										</div>
										<div className="text-center">
											{err && (
												<p className="mt-2 mb-2" style={{color: "red"}}>
													oops.. Something went wrong. Try again
												</p>
											)}
											<p>
												By clicking the button, you agree to the{" "}
												<Link to="/terms-and-conditions" target="_blank">
													Terms And Conditions
												</Link>
											</p>
											<p>
												Click{" "}
												<Link to="/privacy-policy" target="_blank">
													here
												</Link>{" "}
												to read BP's privacy policy
											</p>
										</div>
									</div>
								</div>
							) : (
								<div className="row">
									<div
										className="col-md-6 p-0"
										// style={{
										//   backgroundImage: `url(${subscribe})`,
										//   backgroundPosition: "center",
										//   backgroundSize: "cover",
										//   backgroundRepeat: "no-repeat",
										//   width: "100%",
										//   height: "100%",
										// }}
									>
										<img
											src={`${subscribe}`}
											alt=""
											className="img-fluid h-100"
										/>
									</div>

									<div className="col-md-6 pb-3 pt-3">
										<h4>Like What you are</h4>
										<h3>reading?</h3>
										<p>
											Signup for our weekly digest of the latest digital trends
											and insights delivered straight to your inbox.
										</p>
										<div className="input-group" style={{marginTop: "5px"}}>
											<input
												type="text"
												class="form-control"
												placeholder="Your Name*"
												name="name"
												ref={register({required: true})}
												required
											/>
										</div>
										<div className="input-group" style={{marginTop: "5px"}}>
											<input
												type="email"
												class="form-control"
												placeholder="Your Email*"
												name="email"
												ref={register({required: true})}
												required
											/>
										</div>

										<div
											class="input-group-append d-flex justify-content-center"
											style={{position: "relative"}}
										>
											{loading && (
												<span
													class="spinner"
													style={{
														position: "absolute",
														top: "13px",
														right: "207px",
														zIndex: 1000,
													}}
												>
													<i class="fa fa-spinner fa-spin"></i>
												</span>
											)}
											{confirm ? (
												<button class="btn btn-primary">
													{" "}
													<AiOutlineCheck color="#fff" />
												</button>
											) : (
												<input
													type="submit"
													class="btn btn-primary"
													value="Subscribe"
												/>
											)}
										</div>
										<div className="text-center">
											{err && (
												<p className="mt-2 mb-2" style={{color: "red"}}>
													oops.. Something went wrong. Try again
												</p>
											)}
											<p>
												By clicking the button, you agree to the{" "}
												<Link to="/terms-and-conditions" target="_blank">
													Terms And Conditions
												</Link>
											</p>
											<p>
												Click{" "}
												<Link to="/privacy-policy" target="_blank">
													here
												</Link>{" "}
												to read BP's privacy policy
											</p>
										</div>
									</div>
								</div>
							)}
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default BlogModal;
