import axios from "axios";
import $ from "jquery";
import React from "react";
import {Link} from "react-router-dom";
import {BASE_API_URL} from "../../Constants";
import topImg from "../../Images/logo.png";
import "./BlogHeader.css";

const BlogHeader = (props) => {
	let [blogSubCategory, setBlogSubCategory] = React.useState([]);

	$(document).ready(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 70) {
				$(".blog-nav-sticky").addClass("fixed-top");
				$(".blog-nav-sticky").css({"background-color": "#fff"});
			} else {
				$(".blog-nav-sticky").removeClass("fixed-top");
			}
		});

		$(".navbar-nav a").on("click", function () {
			$(this).parent().parent().find("a#actives").removeAttr("id");
			$(this).attr("id", "actives");
		});
	});

	React.useEffect(() => {
		axios.get(BASE_API_URL + "BlogSubCategory", {}).then(
			(res) => {
				//////console.log(res);
				if (res.data.data.length !== 0) {
					setBlogSubCategory(res.data.data);
				}
			},
			(error) => {
				//console.log(error);
			}
		);
	}, []);

	////console.log(blogSubCategory);

	return (
		<div className="container-fluid blog-top blog-nav-sticky">
			{/* <div className="topImg">
       
      </div> */}

			<nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-center">
				<div className="d-flex justify-content-between align-items-center blog-header">
					<div className="navbar-brand">
						<Link to="/">
							<img className=" mx-auto d-block" src={topImg} alt="" />
						</Link>
					</div>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarNavAltMarkup"
						aria-controls="navbarNavAltMarkup"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
				</div>
				<div className="d-md-flex d-block flex-row mx-md-auto mx-0">
					<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
						<div className="navbar-nav">
						   <Link className="nav-item nav-link" to={`/`} >
								Home
							</Link>
							<Link className="nav-item nav-link" to={`/insights`} id="actives">
								Insights
							</Link>
							{/* <a className="nav-item nav-link active" href="#">Home <span className="sr-only">(current)</span></a> */}
							{blogSubCategory.map(
								(item, index) =>
									item.name !== "Class" && (
										<Link
											className="nav-item nav-link"
											to={`/insights/${item.slug}`}
											key={index}
										>
											{item.name}
										</Link>
									)
							)}

							{/* {blogSubCategory.map(
                (item, index) =>
                  item.name !== "Class" && (
                    <Link
                      className="nav-item nav-link"
                      to={{
                        pathname: `/Blog/${item.name}`,
                        state: {
                          name: item.name,
                          id: item.id,
                        },
                      }}
                      key={index}
                    >
                      {item.name}
                    </Link>
                  )
              )} */}
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default BlogHeader;
