import React from "react";
import "./cform.css";
import ContactUs from "../Home/ContactUs";
// import ContactUsForm from "../ContactUs/ContactUsForm";
const ContactRequest = React.lazy(() =>
	import("../Home/ContactRequest/ContactRequest")
);

const CForm = (props) => {
	// //console.log(props);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="about-us" style={{marginTop: "4rem"}}>
			{/* <ContactUsForm/> */}
			<ContactUs/>
		</section>
	);
};

export default CForm;
