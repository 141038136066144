/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import styles from "./dashboard.module.css";

const DashboardSliderCard = ({ slider }) => {


  return (
    <div
      className={styles.dashboardSliderCardContainer}
    >
      <img src={slider.img} alt="dashboard ar" />
      <div className="row justify-content-center">
        <div className="col-6">
          <Link to={slider.link} className={styles.dashboardSliderCardContainer__content}>
            <h3>{slider.title}</h3>
            <ul className={styles.dashboardSliderCardContainer__content__categoriesList}>
              <li>
                {" "}
                <a href="#">Dashboard</a>{" "}
              </li>
              <li>
                {" "}
                <a href="#">Power BI</a>{" "}
              </li>
            </ul>
            {/* <div className={styles.dashboardSliderCardContainer__content__linkBox}>
              <Link to={slider.link}>See More</Link>
            </div> */}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardSliderCard;
