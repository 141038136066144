import axios from "axios";
import React from "react";
import Select from "react-select";
import  {useForm, Controller}  from "react-hook-form";
import './formstyle.css';
import { useState } from "react";

const DataAnalysisSmallForm = () => {

  const [success , setSuccess] = useState(false);


  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
  } = useForm();

  const technologyOptions = [
    {label : 'Power BI', value : 'powerbi'},
    {label : 'Tableau', value : 'tableau'},
    {label : 'Qlik', value : 'qlik'},
    {label : 'Amazon QuickSight', value : 'amazon quicksight'},
    {label : 'Microsoft Excel', value : 'microsoft excel'},
    {label : 'Python', value : 'python'},
    {label : 'R', value : 'r'},
    {label : 'Klipfolio', value : 'klipfolio'},
    {label : 'Alteryx', value : 'alteryx'},
  ]

  const techsource = [
    {label : 'QuickBooks', value : 'quickbooks'},
    {label : 'Xero', value : 'xero'},
    {label : 'Sage Intacct', value : 'sageintacct'},
    {label : 'Sage 100 | 300', value : 'sage100|300'},
    {label : 'NetSuite', value : 'netsuite'},
    {label : 'Shopify', value : 'shopify'},
    {label : 'Amazon', value : 'amazon'},
    {label : 'SalesForce', value : 'salesforce'},
    {label : 'Microsoft SharePoint', value : 'microsoft sharepoint'},
    {label : 'Bill.com', value : 'bill.com'},
    {label : 'Expensify', value : 'expensify'},
    {label : 'Tallie', value : 'tallie'},
  ]


  const onSubmit = (data) => {

    const allTechnology = data.technology.map( item => item.value).join(',');
    const allDataSource= data.dataSource.map( item => item.value).join(',');
    let date = new Date();

    const submitData = {
        name : data.name,
        phone : data.phone,
        email : data.email,
        businessName : data.businessName,
        howManyDashboard : Number(data.dashboard),
        technologyPreference : allTechnology,
        dataSource : allDataSource,
        note : data.notes,
        creationTime: date,
    }

    console.log(submitData);
    

    
    setSuccess( prev => !prev);
    axios({
        method: "post",
        url: "https://api.bestpracticify.co/api/DataAnalysisRequest",
        data: submitData,
      })
        .then(function (response) {
          if (response.data.status) {
            
            // setSuccess( prev => !prev);
            console.log(response.date);
            reset(
              {
                name: "",
                phone: "",
                email: "",
                technology: "",
                dataSource: "",
                dashboard: "",
                businessName: "",
                note: "",
              },
              {
                keepErrors: false,
              }
            );
            
          }
        })
        .catch(function (error) {
          console.log(error);
        });


  };

  return (
    <>
        { success && <h5 className="text-white">Your request is submitted successfully.</h5>}
        <form  onSubmit={handleSubmit(onSubmit)}>
          <div className="row form-style bg-white p-4">
            <div className="col-lg-6 p-1">
                <label htmlFor="" className="data-form-label">Name</label>
              <input type="text" className="input-form" name="name" ref={register({ required: true})} />
               {errors.name && <span className="text-danger">This field is required</span>}
            </div>

            <div className="col-lg-6 p-1">
                <label htmlFor="" className="data-form-label">Phone</label>
              <input type="text" className="input-form" ref={register} name="phone"  />
            </div>

            <div className="col-lg-6 p-1">
                <label htmlFor="" className="data-form-label">Email</label>
                <input type="text"className="input-form"  ref={register({ required: true})} name="email" />
               {errors.email && <span className="text-danger">This field is required</span>}
            </div>

            <div className="col-lg-6 p-1">
                <label htmlFor="" className="data-form-label">Busniess Name</label>
                <input type="text"className="input-form"  ref={register({ required: true})} name="businessName"  />
               {errors.businessName && <span className="text-danger">This field is required</span>}
            </div>
            

            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">How many dashboard do you want?</label>
                <input type="number"className="input-form"  
                ref={register({ 
                  required: true , 
                  valueAsNumber: true,
                  validate: (value) => value > 0,
                })} 
                  name="dashboard"  
                />
               {errors.dashboard && <span className="text-danger">This field is required and value should greater than zero</span>}
            </div>

            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">Technology Preference</label>
                <Controller
                        name="technology"
                        control={control}
                        as={<Select />}
                        options={technologyOptions}
                        isMulti
                        rules={{ required: true }}
                    />
               {errors.technology && <span className="text-danger">This field is required</span>}
            </div>

            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">Data Source</label>
                <Controller
                        name="dataSource"
                        control={control}
                        as={<Select />}
                        options={techsource}
                        isMulti
                        rules={{ required: true }}
                    />
               {errors.dataSource && <span className="text-danger">This field is required</span>}
            </div>

            <div className="col-lg-12 p-1">
                <label htmlFor="" className="data-form-label">Notes</label>
                {/* <input type="text" className="input-form"  ref={register()} name="notes" />
               {errors.notes && <span className="text-danger">This field is required</span>} */}
               <textarea name="notes" id="" cols="30" rows="4" className="input-form"  ref={register()} ></textarea>
               {errors.notes && <span className="text-danger">This field is required</span>}
            </div>

            <div className="col-lg-12 p-1">
                <button className="btn btn-primary" type="submit">Submit</button>
            </div>

          </div>
        </form>
    </>
  );
};

export default DataAnalysisSmallForm;
